import ExecAPI from 'appRoot/api/api';

export function GetLoginRecordsForSelf()
{
	return ExecAPI("LoginRecordData/GetLoginRecordsForSelf");
}
/**
 * Requires Admin Session
 * @param {Number} userId User ID
 * @param {Number} startDate Oldest allowed Date to filter by.
 * @param {Number} endDate Newest allowed Date to filter by.
 */
export function GetLoginRecords(userId, startDate, endDate)
{
	return ExecAPI("LoginRecordData/GetLoginRecords", { userId, startDate, endDate });
}
export function GeolocateIP(ip)
{
	return ExecAPI("LoginRecordData/GeolocateIP", { ip });
}
/**
 * Requires Admin Session
 * @param {String} ip IP address string to filter by, or null or empty for no IP filter.
 * @param {Number} startDate Oldest allowed Date to filter by.
 * @param {Number} endDate Newest allowed Date to filter by.
 */
export function GetFailedLogins(ip, startDate, endDate)
{
	return ExecAPI("LoginRecordData/GetFailedLogins", { ip, startDate, endDate });
} 