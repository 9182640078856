var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pageRoot" }, [
    _vm.error
      ? _c("div", { staticClass: "error" }, [_vm._v(_vm._s(_vm.error))])
      : _vm.loading
      ? _c(
          "div",
          { staticClass: "loading" },
          [_c("ScaleLoader"), _vm._v(" Loading…")],
          1
        )
      : _c(
          "div",
          [
            _c("h2", [_vm._v("Notifications Setup")]),
            _vm._v(" "),
            _vm.notificationMethods.length === 0
              ? _c("p", [
                  _vm._v(
                    "Please add at least one email address to this user account in order to receive notifications."
                  ),
                ])
              : [
                  _vm.eventNames && _vm.eventNames.length
                    ? [
                        _c("h3", [_vm._v("Events")]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            "You may register to receive a notification upon certain events below:"
                          ),
                        ]),
                        _vm._v(" "),
                        _c("table", { staticClass: "notificationTable" }, [
                          _c("thead", [
                            _c(
                              "tr",
                              [
                                _c("th", [_vm._v("Event")]),
                                _vm._v(" "),
                                _vm._l(_vm.notificationMethods, function (nm) {
                                  return _c("th", { key: nm.key }, [
                                    _vm._v(_vm._s(nm.value)),
                                  ])
                                }),
                              ],
                              2
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "tbody",
                            [
                              _c(
                                "tr",
                                { staticClass: "allMonitorsToggleRow" },
                                [
                                  _c("td", [_vm._v("-- All Events --")]),
                                  _vm._v(" "),
                                  _vm._l(
                                    _vm.notificationMethods,
                                    function (nm) {
                                      return _c(
                                        "td",
                                        {
                                          key: nm.key,
                                          staticClass: "toggleNotificationCell",
                                        },
                                        [
                                          _c("input", {
                                            staticClass:
                                              "toggleNotificationButton",
                                            attrs: {
                                              type: "button",
                                              value: "toggle all",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.toggleNotificationsForMethod(
                                                  nm,
                                                  _vm.eventNames
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _vm._l(_vm.eventNames, function (eventName) {
                                return _c(
                                  "tr",
                                  { staticClass: "zebra" },
                                  [
                                    _c("td", [_vm._v(_vm._s(eventName))]),
                                    _vm._v(" "),
                                    _vm._l(
                                      _vm.notificationMethods,
                                      function (nm) {
                                        return _c(
                                          "td",
                                          {
                                            key: nm.key,
                                            class: {
                                              notificationCheckboxCell: true,
                                              isUpdating: _vm.isUpdating(
                                                eventName,
                                                nm
                                              ),
                                            },
                                          },
                                          [
                                            _c("input", {
                                              staticClass:
                                                "notificationCheckbox",
                                              attrs: {
                                                type: "checkbox",
                                                disabled: _vm.isUpdating(
                                                  eventName,
                                                  nm
                                                ),
                                              },
                                              domProps: {
                                                checked: _vm.isChecked(
                                                  eventName,
                                                  nm
                                                ),
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.toggleNotification(
                                                    eventName,
                                                    nm
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                )
                              }),
                            ],
                            2
                          ),
                        ]),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("h3", [_vm._v("Status Monitors")]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      'Notifications will be sent via the following methods when any Monitor enters or exits the "Alerting" state. There is a cooldown between notifications to prevent excessive spamming during outages.'
                    ),
                  ]),
                  _vm._v(" "),
                  _c("table", { staticClass: "notificationTable" }, [
                    _c("thead", [
                      _c(
                        "tr",
                        [
                          _c("th", [_vm._v("Monitor")]),
                          _vm._v(" "),
                          _vm._l(_vm.notificationMethods, function (nm) {
                            return _c("th", { key: nm.key }, [
                              _vm._v(_vm._s(nm.value)),
                            ])
                          }),
                        ],
                        2
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      [
                        _c(
                          "tr",
                          { staticClass: "allMonitorsToggleRow" },
                          [
                            _c("td", [_vm._v("-- All Monitors --")]),
                            _vm._v(" "),
                            _vm._l(_vm.notificationMethods, function (nm) {
                              return _c(
                                "td",
                                {
                                  key: nm.key,
                                  staticClass: "toggleNotificationCell",
                                },
                                [
                                  _c("input", {
                                    staticClass: "toggleNotificationButton",
                                    attrs: {
                                      type: "button",
                                      value: "toggle all",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.toggleNotificationsForMethod(
                                          nm,
                                          _vm.jobNames
                                        )
                                      },
                                    },
                                  }),
                                ]
                              )
                            }),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _vm._l(_vm.jobNames, function (jobName) {
                          return _c(
                            "tr",
                            { staticClass: "zebra" },
                            [
                              _c("td", [_vm._v(_vm._s(jobName))]),
                              _vm._v(" "),
                              _vm._l(_vm.notificationMethods, function (nm) {
                                return _c(
                                  "td",
                                  {
                                    key: nm.key,
                                    class: {
                                      notificationCheckboxCell: true,
                                      isUpdating: _vm.isUpdating(jobName, nm),
                                    },
                                  },
                                  [
                                    _c("input", {
                                      staticClass: "notificationCheckbox",
                                      attrs: {
                                        type: "checkbox",
                                        disabled: _vm.isUpdating(jobName, nm),
                                      },
                                      domProps: {
                                        checked: _vm.isChecked(jobName, nm),
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.toggleNotification(
                                            jobName,
                                            nm
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                )
                              }),
                            ],
                            2
                          )
                        }),
                      ],
                      2
                    ),
                  ]),
                ],
          ],
          2
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }