<template>
	<div class="BibliographyEditor">
		<table>
			<draggable v-model="rev.Bibliography" tag="tbody" handle=".drag_handle" @change="dragged">
				<tr v-for="(b, index) in rev.Bibliography" :key="index" :class="{ delete: b.delete, newRow: b.newRow, shake: b.shake }">
					<td>
						<button v-if="b.Type !== b.originalType || b.Value !== b.originalValue || b.delete" class="undoButton btnIcon" role="button" tabindex="0" @click="onUndo(b)"
								title="undo changes to this row">
							<vsvg sprite="replay" />
						</button>
					</td>
					<td :class="{ drag_handle: true, moved: b.index !== b.originalIndex }"><vsvg sprite="drag_indicator" /></td>
					<td :class="{ changed: b.Type !== b.originalType }">
						<select v-model="b.Type" class="bTypeSelect">
							<option v-for="bType in allBibliographyTypes" :key="bType">{{bType}}</option>
						</select>
					</td>
					<Contenteditable tag="td" v-model="b.Value" :no-html="true" :class="{ changed: b.Value !== b.originalValue }" :no-nl="true" @returned="onEnterPressed(index)" ref="values" />
					<td>
						<img class="btnIcon" :src="appPath + 'Images/delete.png'" alt="delete" role="button" tabindex="0" @click="onDelete(b)" @keypress.space.enter.prevent="onDelete(b)"
							 title="mark/unmark this row for deletion" />
					</td>
				</tr>
			</draggable>
		</table>
		<div class="buttonBar">
			<button class="buttonBarButton" @click="onAdd()" title="add a row to the bibliography table">
				<img class="btnIconNoHover" :src="appPath + 'Images/add.png'" alt="" role="presentation" />
				<span>Add Row</span>
			</button>
			<button class="buttonBarButton" v-if="rowOrderChanged" @click="sortReset()" title="reset row order">
				<img class="btnIconNoHover" :src="appPath + 'Images/nav_undo_blue.png'" alt="" role="presentation" />
				<span>Reset Row Order</span>
			</button>
		</div>
	</div>
</template>

<script>
	import Contenteditable from 'appRoot/vues/common/controls/Contenteditable.vue';
	import draggable from 'vuedraggable'
	import { PreprocessBibliographyRecord, DefineReactiveNonEnumerableProperty } from 'appRoot/scripts/Util';
	import svg1 from 'appRoot/images/sprite/replay.svg';
	import svg2 from 'appRoot/images/sprite/drag_indicator.svg';

	export default {
		components: { Contenteditable, draggable },
		props:
		{
			rev: { // Modifiable revision
				type: Object,
				required: true
			}
		},
		data()
		{
			return {
				appPath: appContext.appPath
			};
		},
		created()
		{
		},
		computed:
		{
			allBibliographyTypes()
			{
				return this.$store.state.bibliographyTypes;
			},
			rowOrderChanged()
			{
				for (let i = 0; i < this.rev.Bibliography.length; i++)
				{
					let b = this.rev.Bibliography[i];
					if (b.index !== b.originalIndex)
						return true;
				}
				return false;
			}
		},
		methods:
		{
			onDelete(b)
			{
				b.delete = !b.delete;
			},
			onUndo(b)
			{
				b.Type = b.originalType;
				b.Value = b.originalValue;
				b.delete = false;
			},
			onEnterPressed(i)
			{
				if (this.$refs.values && i + 1 < this.$refs.values.length)
				{
					let component = this.$refs.values[i + 1];
					if (component && component.$el)
						component.$el.focus();
				}
			},
			onAdd()
			{
				let record = PreprocessBibliographyRecord({ Type: "", Value: "" }, this.rev.Bibliography.length);
				DefineReactiveNonEnumerableProperty(record, "newRow", true);
				this.rev.Bibliography.push(record);
			},
			dragged()
			{
				for (let i = 0; i < this.rev.Bibliography.length; i++)
				{
					console.log(this.rev.Bibliography[i].index + " -> " + i);
					this.rev.Bibliography[i].index = i;
				}
			},
			sortReset() // Obsolete
			{
				this.rev.Bibliography.sort(compareFn([o => o.originalIndex], false));
				this.dragged();
			},
			sortByType() // Obsolete
			{
				let beforeSort = JSON.stringify(this.rev.Bibliography);
				this.rev.Bibliography.sort(compareType(false));
				let afterSort = JSON.stringify(this.rev.Bibliography);
				if (beforeSort === afterSort)
					this.rev.Bibliography.sort(compareType(true));
				this.dragged();
			},
			sortByValue() // Obsolete
			{
				let beforeSort = JSON.stringify(this.rev.Bibliography);
				this.rev.Bibliography.sort(compareValue(false));
				let afterSort = JSON.stringify(this.rev.Bibliography);
				if (beforeSort === afterSort)
					this.rev.Bibliography.sort(compareValue(true));
				this.dragged();
			}
		},
		watch:
		{
		}
	}
	function compareType(descending)
	{
		return compareFn([o => o.Type.toUpperCase(), o => o.Value.toUpperCase()], descending);
	}
	function compareValue(descending)
	{
		return compareFn([o => o.Value.toUpperCase(), o => o.Type.toUpperCase()], descending);
	}
	function compareFn(keySelectors, descending)
	{
		return (a, b) =>
		{
			for (let i = 0; i < keySelectors.length; i++)
			{
				const keyA = keySelectors[i](a);
				const keyB = keySelectors[i](b);
				if (keyA < keyB)
					return descending ? 1 : -1;
				else if (keyB < keyA)
					return descending ? -1 : 1;
			}
			return 0;
		};
	}
</script>

<style scoped>
	.BibliographyEditor table
	{
		border-collapse: collapse;
		table-layout: fixed;
		width: 100%;
	}

	.BibliographyEditor tr:nth-child(2n)
	{
		/*background-color: rgba(0,0,0,0.065);*/
	}

	.BibliographyEditor tr.newRow
	{
		background-color: rgba(0,0,255,0.05);
	}

		.BibliographyEditor tr.newRow:nth-child(2n)
		{
			/*background-color: rgba(0,255,0,0.19);*/
		}

	.BibliographyEditor tr.delete
	{
		text-decoration: line-through;
		background-color: rgba(255,0,0,0.15);
	}

		.BibliographyEditor tr.delete:nth-child(2n)
		{
			/*background-color: rgba(255,0,0,0.295);*/
		}


	.BibliographyEditor td
	{
		border: 1px solid #333333;
		padding: 2px 3px;
		font-size: 14px;
	}

		.BibliographyEditor td:nth-child(1)
		{
			width: 20px;
			vertical-align: top;
			padding: 0px;
		}

		.BibliographyEditor td:nth-child(2)
		{
			width: 20px;
			vertical-align: top;
			padding: 0px;
		}

		.BibliographyEditor td:nth-child(3)
		{
			padding: 0px;
			width: 120px;
			word-break: break-word;
			vertical-align: top;
		}

		.BibliographyEditor td:nth-child(4)
		{
		}

		.BibliographyEditor td:nth-child(5)
		{
			width: 16px;
			vertical-align: top;
		}

		.BibliographyEditor td.changed
		{
			background-color: rgba(0,0,255,0.14);
			outline: 2px solid #0000FF;
		}

		.BibliographyEditor td.drag_handle
		{
			width: 20px;
			height: 20px;
			padding: 0px;
			font-size: 0px;
			cursor: grab;
		}

			.BibliographyEditor td.drag_handle.moved
			{
				background-color: rgba(0,0,255,0.14);
				outline: 2px solid #0000FF;
			}

			.BibliographyEditor td.drag_handle svg
			{
				width: 20px;
				height: 20px;
			}

	.BibliographyEditor tr.newRow td
	{
		border-color: #0000FF;
	}

	.btnIcon,
	.btnIconNoHover
	{
		width: 16px;
		height: 16px;
		cursor: pointer;
		vertical-align: bottom;
	}

		.sortResetBtn:hover,
		.sortResetBtn:focus,
		.sortAZBtn:hover,
		.sortAZBtn:focus,
		.btnIcon:hover,
		.btnIcon:focus
		{
			background-color: rgba(0,0,0,0.2);
			outline: 2px solid rgba(0,0,255,0.5);
		}


	.undoButton
	{
		margin: 0px;
		border: 0px;
		width: 20px;
		height: 20px;
		padding: 0px;
		font-size: 0px;
	}

		.undoButton svg
		{
			width: 20px;
			height: 20px;
		}

	.bTypeSelect
	{
		background-color: transparent;
		border: none;
		box-sizing: border-box;
		padding: 2px 3px;
		width: 100%;
		appearance: none;
		cursor: pointer;
		height: 20px;
	}

		.bTypeSelect:hover,
		.bTypeSelect:focus
		{
			background-color: rgba(0,0,0,0.2);
		}

		.bTypeSelect option
		{
			background-color: #FFFFFF;
		}

	.BibliographyEditor tr.delete .bTypeSelect
	{
		text-decoration: line-through;
	}

	.editableValue
	{
		white-space: pre;
	}

	.shake
	{
		-webkit-animation: kf_shake 0.4s 1 linear;
		-moz-animation: kf_shake 0.4s 1 linear;
		-o-animation: kf_shake 0.4s 1 linear;
		animation: kf_shake 0.4s linear 0 1;
	}

		.shake td
		{
			border-color: #FF0000;
			background-color: rgba(255,0,0,0.15);
		}

	@-webkit-keyframes kf_shake
	{
		0%
		{
			-webkit-transform: translate(30px);
		}

		20%
		{
			-webkit-transform: translate(-30px);
		}

		40%
		{
			-webkit-transform: translate(15px);
		}

		60%
		{
			-webkit-transform: translate(-15px);
		}

		80%
		{
			-webkit-transform: translate(8px);
		}

		100%
		{
			-webkit-transform: translate(0px);
		}
	}

	@-moz-keyframes kf_shake
	{
		0%
		{
			-moz-transform: translate(30px);
		}

		20%
		{
			-moz-transform: translate(-30px);
		}

		40%
		{
			-moz-transform: translate(15px);
		}

		60%
		{
			-moz-transform: translate(-15px);
		}

		80%
		{
			-moz-transform: translate(8px);
		}

		100%
		{
			-moz-transform: translate(0px);
		}
	}

	@-o-keyframes kf_shake
	{
		0%
		{
			-o-transform: translate(30px);
		}

		20%
		{
			-o-transform: translate(-30px);
		}

		40%
		{
			-o-transform: translate(15px);
		}

		60%
		{
			-o-transform: translate(-15px);
		}

		80%
		{
			-o-transform: translate(8px);
		}

		100%
		{
			-o-origin-transform: translate(0px);
		}
	}

	@keyframes kf_shake
	{
		0%
		{
			transform: translate(30px);
		}

		20%
		{
			transform: translate(-30px);
		}

		40%
		{
			transform: translate(15px);
		}

		60%
		{
			transform: translate(-15px);
		}

		80%
		{
			transform: translate(8px);
		}

		100%
		{
			transform: translate(0px);
		}
	}
</style>