var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "TextFileLoadBackupDialog",
      attrs: {
        role: "alertdialog",
        "aria-labelledby": "TextFileLoadBackupTitle",
      },
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "dialogBody" }, [
        _c(
          "div",
          { staticClass: "backupList" },
          [
            _c("div", { staticClass: "backupListItem" }, [
              _c(
                "button",
                {
                  staticClass: "loadBackupBtn",
                  on: {
                    click: function ($event) {
                      return _vm.loadBackup(null)
                    },
                  },
                },
                [_vm._v("\n\t\t\t\t\tCurrent Version\n\t\t\t\t")]
              ),
            ]),
            _vm._v(" "),
            _vm._l(_vm.backupDates, function (date) {
              return _c("div", { key: date, staticClass: "backupListItem" }, [
                _c(
                  "button",
                  {
                    staticClass: "loadBackupBtn",
                    on: {
                      click: function ($event) {
                        return _vm.loadBackup(date)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(new Date(date).toString()) +
                        "\n\t\t\t\t"
                    ),
                  ]
                ),
              ])
            }),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "controls" }, [
        _c(
          "div",
          {
            ref: "btnCancel",
            staticClass: "dialogButton cencelButton",
            attrs: { role: "button", tabindex: "0" },
            on: {
              click: function ($event) {
                return _vm.$emit("close", false)
              },
              keydown: [
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "space", 32, $event.key, [
                      " ",
                      "Spacebar",
                    ]) &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  $event.preventDefault()
                  return _vm.$emit("close", false)
                },
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "left", 37, $event.key, [
                      "Left",
                      "ArrowLeft",
                    ])
                  )
                    return null
                  if ("button" in $event && $event.button !== 0) return null
                  $event.preventDefault()
                  return _vm.FocusYes.apply(null, arguments)
                },
              ],
            },
          },
          [_vm._v("\n\t\t\tCancel\n\t\t")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "titleBar" }, [
      _c(
        "div",
        {
          staticClass: "title",
          attrs: { id: "TextFileLoadBackupTitle", role: "alert" },
        },
        [_vm._v("Load Backup")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }