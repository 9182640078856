<template>
	<div class="aceCodeEditorRoot" role="dialog" aria-labelledby="aceCodeEditorTitle">
		<div class="titleBar">
			<div id="aceCodeEditorTitle" class="title" role="alert">Code Editor: {{fileName}}</div>
		</div>
		<div class="dialogBody">
			<div class="codeEditorWrapper" :style="codeEditorCalcStyle">
				<AceCodeEditor ref="editor" :mode="mode" :disabled="isCommitting" />
			</div>
			<div class="backupList" v-if="backupsAvailable" ref="backupList">
				<p>Backups Available:</p>
				<ul>
					<li v-for="b in backupInfo" :key="b.Name"><a role="button" @click="openBackup(b)">{{b.Name}}</a> - {{new Date(b.Date).toString()}}</li>
				</ul>
			</div>
		</div>
		<div class="buttons">
			<div class="dialogButton" tabindex="0" @click="CommitChanges" @keydown.space.enter.prevent="CommitChanges" :disabled="isCommitting">
				Commit Changes
			</div>
			<div ref="discardChangesBtn" class="dialogButton" tabindex="0" @click="DiscardChanges" @keydown.space.enter.prevent="DiscardChanges" :disabled="isCommitting">
				Discard Changes
			</div>
		</div>
	</div>
</template>

<script>
	import AceCodeEditor from 'appRoot/vues/common/controls/AceCodeEditor.vue';
	import { ModalConfirmDialog } from 'appRoot/scripts/ModalDialog';

	export default {
		components: { AceCodeEditor },
		props:
		{
			fileName: {
				type: String,
				required: true
			},
			value: {
				type: String,
				required: true
			},
			mode: {
				type: String,
				default: ""
			},
			/** Optional function that will be called when committing changes, before closing the dialog. If provided, the function must return a promise that resolves with true when the dialog is allowed to close. The function may return false, indicating the dialog is not allowed to close.  In such a case, the user is not informed of why the dialog did not close. */
			commitFn: {
				type: Function,
				default: null
			},
			backupInfo: {
				type: Array,
				default: null
			},
			loadBackupFn: {
				type: Function,
				default: null
			}
		},
		data()
		{
			return {
				isCommitting: false
			};
		},
		created()
		{
		},
		mounted()
		{
			this.$refs.editor.setValue(this.value);
		},
		computed:
		{
			backupsAvailable()
			{
				return !!(this.backupInfo && this.backupInfo.length && this.loadBackupFn);
			},
			codeEditorCalcStyle()
			{
				if (this.backupsAvailable)
				{
					return { maxHeight: "calc(100vh - 400px)" };
				}
				return null;
			}
		},
		methods:
		{
			SetFocus()
			{
				if (this.$refs.discardChangesBtn)
					this.$refs.discardChangesBtn.focus();
			},
			CommitChanges()
			{
				if (this.isCommitting)
					return;
				if (!this.$refs.editor)
				{
					toaster.Error("Application error: Text editor was lost.");
					this.$emit("close", null);
				}
				let currentValue = this.$refs.editor.getValue();
				if (typeof this.commitFn === "function")
				{
					this.isCommitting = true;
					this.commitFn(currentValue)
						.then(result =>
						{
							this.isCommitting = false;
							if (result)
								this.$emit("close", currentValue);
						})
						.catch(err =>
						{
							this.isCommitting = false;
							toaster.Error(err);
						});
				}
				else
					this.$emit("close", currentValue);
			},
			DiscardChanges()
			{
				if (this.isCommitting)
					return;
				this.$emit("close", null);
			},
			DefaultClose()
			{
				if (this.isCommitting)
					return;
				let currentValue = this.$refs.editor ? this.$refs.editor.getValue() : null;
				if (currentValue === null || this.value === currentValue)
					this.DiscardChanges();
				else
					ModalConfirmDialog("Do you want to close this dialog and discard changes?", "Discard Changes?", "Discard", "Keep Open")
						.then(result =>
						{
							if (result)
								this.DiscardChanges();
						});
			},
			openBackup(b)
			{
				this.loadBackupFn(b)
					.then(result =>
					{
						if (this.$refs.editor)
							this.$refs.editor.setValue(result);
					})
					.catch(err =>
					{
						toaster.Error(err);
					});
			}
		},
		watch:
		{
		}
	}
</script>

<style scoped>
	.aceCodeEditorRoot
	{
		width: calc(100vw - 30px);
		max-height: calc(100vh - 30px);
		background-color: #FFFFFF;
	}

	.titleBar
	{
		background-color: #FFFFFF;
		padding: 8px 14px;
		box-sizing: border-box;
	}

	.title
	{
		text-align: center;
		color: black;
		font-weight: bold;
		font-size: 16pt;
	}

	.dialogBody
	{
		padding: 4px 12px 0px 12px;
	}

	.buttons
	{
		display: flex;
	}

	.dialogButton
	{
		display: inline-block;
		cursor: pointer;
		color: #1e78af;
		font-weight: bold;
		font-size: 16pt;
		box-sizing: border-box;
		position: relative;
		padding: 9px 5px 8px 5px;
		flex: 1 0 auto;
		text-align: center;
	}

		.dialogButton:hover
		{
			background-color: rgba(0,0,0,0.05);
		}

	.codeEditorWrapper
	{
		display: flex;
		height: 100vh;
		max-height: calc(100vh - 120px);
		border-top: 1px solid #b5b5b5;
		border-bottom: 1px solid #b5b5b5;
		position: relative;
	}

	a[role="button"]
	{
		color: #0066FF;
		text-decoration: underline;
	}

	@media (min-width: 600px)
	{
		.aceCodeEditorRoot
		{
			width: 90vw;
		}
	}
</style>