<template>
	<!--<div v-if="contained">
		<svg v-if="!contained" class="svgbtn" :style="btnStyle" @click="onclick" @keypress.enter.prevent="onclick" role="button" tabindex="0"><use :xlink:href="'#' + sprite"></use><title v-if="title" role="presentation">{{title}}</title></svg>
	</div>-->
	<svg :class="{ svgbtn: true, contained, round, disabled: !!disabled }"
		 :style="btnStyle" @click="onclick" @keypress.enter.prevent="onclick"
		 role="button" tabindex="0"
		 :disabled="disabled?'disabled':''">
		<use :xlink:href="'#' + sprite"></use>
	</svg>
</template>

<script>
	export default {
		props:
		{
			sprite: {
				type: String,
				required: true
			},
			size: {
				type: Number,
				default: 40
			},
			contained: {
				type: Boolean,
				default: false
			},
			round: {
				type: Boolean,
				default: false
			},
			disabled: false
		},
		data()
		{
			return {
			};
		},
		computed:
		{
			btnStyle()
			{
				let style = {};
				let size = this.size;
				if (size < 4)
					size = 4;
				style.width = style.height = (size * 0.6) + "px";
				style.padding = (size * 0.2) + "px";
				if (this.borderWidth)
					style.borderWidth = this.borderWidth;

				return style;
			}
		},
		methods:
		{
			onclick(e)
			{
				if (!this.disabled)
					this.$emit("click", e);
			}
		}
	}
</script>

<style scoped>
	.svgbtn
	{
		cursor: pointer;
		border-radius: 8%;
	}

		.svgbtn:hover:active,
		.svgbtn:active,
		.svgbtn:focus
		{
			background-color: rgba(0,0,0,0.12);
		}

		.svgbtn:hover
		{
			background-color: rgba(0,0,0,0.08);
		}

		.svgbtn.contained
		{
			background-color: var(--primary-color);
			fill: var(--text-color-on-primary-color);
		}

			.svgbtn.contained:hover:active,
			.svgbtn.contained:active,
			.svgbtn.contained:focus
			{
				background-color: var(--primary-color-light2);
			}

			.svgbtn.contained:hover
			{
				background-color: var(--primary-color-light1);
			}

		.svgbtn.round
		{
			border-radius: 50%;
		}

		.svgbtn.disabled
		{
			opacity: 0.38;
		}
</style>