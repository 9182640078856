<template>
	<div>
		<template v-if="multiline">
			<textarea class="multilineTextContainer" v-model="inputText" :disabled="disabled" />
			<input type="button" @click="saveText" value="save" :disabled="disabled || !hasChanges" />
		</template>
		<template v-else>
			<input type="text" class="singlelineTextContainer" v-model="inputText" :disabled="disabled" />
			<input type="button" class="singlelineSave" @click="saveText" value="save" :disabled="disabled || !hasChanges" />
		</template>
	</div>
</template>

<script>
	export default {
		props:
		{
			value: {
				type: String,
				default: ""
			},
			multiline: {
				type: Boolean,
				default: false
			},
			disabled: {
				type: Boolean,
				default: false
			}
		},
		data()
		{
			return {
				inputText: ""
			};
		},
		created()
		{
			this.inputText = this.value;
		},
		computed:
		{
			hasChanges()
			{
				return this.inputText !== this.value;
			}
		},
		methods:
		{
			saveText(e)
			{
				this.$emit("input", this.inputText);
			}
		},
		watch:
		{
			value()
			{
				this.inputText = this.value;
			}
		}
	}
</script>

<style scoped>
	.multilineTextContainer
	{
		padding: 3px;
		border: 2px inset;
		margin-bottom: 0.5em;
		width: 100%;
		min-width: 100%;
		max-width: 100%;
		height: 200px;
		min-height: 50px;
		font-family: Consolas, monospace;
	}

	.singlelineTextContainer
	{
		font-size: 1.1em;
	}

	.singlelineSave
	{
		font-size: 1.1em;
	}
</style>