import { CloseAllDialogs, ModalConfirmDialog } from 'appRoot/scripts/ModalDialog';
import { HTMLToText, GetRouteMatched } from 'appRoot/scripts/Util';

let updateNoticeShownAt = -9999999;
let isShowingUpdateNotice = false;

/**
 * Executes an API call to the specified method, using the specified arguments.  Returns a promise which resolves with any graceful response from the server.  Rejects if an error occurred that prevents the normal functioning of the API (e.g. the server was unreachable or returned an entirely unexpected response such as HTTP 500).
 * @param {String} method Server route, e.g. "Auth/Login"
 * @param {Object} args arguments
 * @returns {Promise} A promise which resolves with any graceful response from the server.
 */
export default function ExecAPI(method, args)
{
	if (!args)
		args = {};
	if (!args.sid)
		args.sid = window.myApp.$store.getters.sid;
	return fetch(appContext.appPath + method, {
		method: 'POST',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(args)
	})
		.then(response =>
		{
			if (response.status === 200)
				return response.json();
			else if (response.status === 403)
			{
				if (!GetRouteMatched(window.myApp.$route, route => route.name === "login"))
				{
					toaster.error("Your session was lost.");
					CloseAllDialogs();
					window.myApp.$store.commit("SessionLost");
					window.myApp.$router.push({ name: "login", query: { path: window.myApp.$route.fullPath } });
				}
				return Promise.reject(new ApiError("Your session was lost."));
			}
			else if (response.status === 418)
			{
				if (!GetRouteMatched(window.myApp.$route, route => route.name === "login"))
				{
					toaster.error("Your session does not have sufficient privilege to access the requested resource.");
					CloseAllDialogs();
					window.myApp.$router.replace({ name: "login" });
				}
				return new Promise((resolve, reject) => { });
			}
			else
			{
				let errText = "API response was " + response.status + " " + response.statusText;
				console.error(errText);
				logResponseBodyHtmlAsError(response);
				return Promise.reject(new ApiError(errText));
			}
		})
		.then(data =>
		{
			if (data.v && appContext.appVersion !== data.v)
			{
				if (performance.now() - 120000 > updateNoticeShownAt) // 2-minute period between version mismatch notices.
				{
					updateNoticeShownAt = performance.now();
					if (!isShowingUpdateNotice)
					{
						isShowingUpdateNotice = true;
						ModalConfirmDialog(appContext.systemName + " has been updated.\n\nSome features and functions may not work \nuntil you reload this page.", "Reload to Update", "Reload Now", "Remind in 2 minutes").then(result =>
						{
							isShowingUpdateNotice = false;
							updateNoticeShownAt = performance.now();
							if (result)
								location.reload();
						});
					}
				}
			}
			return Promise.resolve(data);
		})
		.catch(err =>
		{
			if (err.message === "Failed to fetch")
				console.log("Server unreachable for API " + method);
			else
			{
				console.log("API request failed " + method)
				console.error(err);
			}
			return Promise.reject(err);
		});
}
function logResponseBodyHtmlAsError(response)
{
	response.text()
		.then(data =>
		{
			console.log(HTMLToText(data).trim());
		})
		.catch(err =>
		{
			console.error("Unable to retrieve response body", err);
		});
}
export class ApiError extends Error
{
	constructor(message, data)
	{
		super(message);
		this.name = "ApiError";
		this.data = data;
	}
}