var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: {
        statusLine: true,
        isAlerting: _vm.isAlerting,
        mobileLayout: _vm.mobileLayout,
      },
      attrs: { title: _vm.jobStatus.AlertState },
      on: { mouseenter: _vm.onMouseIn, mouseleave: _vm.onMouseOut },
    },
    [
      _c("div", { staticClass: "name" }, [_vm._v(_vm._s(_vm.jobStatus.Name))]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "details" },
        [
          _c("StatusGraph", {
            staticClass: "inlineGraph",
            attrs: { jobStatus: [_vm.jobStatus] },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "status" }, [
            _vm._v(_vm._s(_vm.statusText)),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }