var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "editUserRoot" },
    [
      _vm.isAddingUser
        ? _c("div", { staticClass: "title" }, [_vm._v("Add New User")])
        : _vm._e(),
      _vm._v(" "),
      !_vm.isAddingUser
        ? _c("div", { staticClass: "title" }, [
            _vm._v("Edit " + _vm._s(_vm.originalUserName)),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("Editor", {
        ref: "userEditor",
        attrs: { object: _vm.editableUser, spec: _vm.editSpec },
        on: { valueChanged: _vm.valueChanged },
      }),
      _vm._v(" "),
      !_vm.isAddingUser
        ? _c("div", { staticClass: "deleteButtonRow" }, [
            _c("input", {
              attrs: {
                type: "button",
                value: "Delete User",
                disabled: "disabled",
              },
              on: { click: _vm.Remove },
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.isAddingUser
        ? _c("div", { staticClass: "loginHistoryButtonRow" }, [
            _c(
              "a",
              { attrs: { href: _vm.loginHistoryLink, target: "_blank" } },
              [
                _vm._v("Login History and Activity Logs "),
                _c("vsvg", {
                  staticClass: "open_in_new",
                  attrs: { sprite: "open_in_new" },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "buttonRow" }, [
        !_vm.isAddingUser
          ? _c("input", {
              attrs: { type: "button", value: "Save" },
              on: { click: _vm.Save },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.isAddingUser
          ? _c("input", {
              attrs: { type: "button", value: "Add User" },
              on: { click: _vm.Add },
            })
          : _vm._e(),
        _vm._v(" "),
        _c("input", {
          attrs: { type: "button", value: "Cancel" },
          on: { click: _vm.Cancel },
        }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }