var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pageRoot" }, [
    _vm.error
      ? _c("div", { staticClass: "error" }, [_vm._v(_vm._s(_vm.error))])
      : _vm.loading
      ? _c(
          "div",
          { staticClass: "loading" },
          [_c("ScaleLoader"), _vm._v(" Loading…")],
          1
        )
      : _vm.users
      ? _c(
          "div",
          {},
          [
            _c("vue-good-table", {
              attrs: {
                columns: _vm.columns,
                rows: _vm.users,
                "sort-options": {
                  enabled: true,
                  initialSortBy: { field: "Name", type: "asc" },
                },
              },
              on: { "on-row-click": _vm.rowClick },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "buttonRow" }, [
              _c("input", {
                attrs: { type: "button", value: "Add User" },
                on: { click: _vm.addUser },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "heading" }, [
              _vm._v("Note About Permission Changes"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "bodySection" }, [
              _c("p", [
                _vm._v(
                  "After granting Read access to privileged tab, the user must log out and back in before the tab becomes visible."
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _c("img", {
                  staticClass: "borderImage",
                  attrs: {
                    src: _vm.appPath + "images/PrivilegedTabs.png",
                    alt: "screenshot",
                  },
                }),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "heading" }, [_vm._v("Active Sessions")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "bodySection" },
              [_c("ActiveSessionList", { attrs: { allSessions: true } })],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "heading" }, [_vm._v("Login History")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "bodySection" },
              [_c("LoginRecordsTable", { attrs: { allUsers: true } })],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "heading" }, [
              _vm._v("User Activity Logs"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "bodySection" },
              [_c("UserActivityTable", { attrs: { allUsers: true } })],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "heading" }, [_vm._v("Login Failures")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "bodySection" },
              [
                _c("p", [
                  _vm._v(
                    "Login failures are counted as an aggregate per hour, per IP address."
                  ),
                ]),
                _vm._v(" "),
                _c("FailedLoginsTable"),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }