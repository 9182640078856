<template>
	<div class="CreatePasskeyDialog" role="alertdialog" aria-labelledby="createPasskeyTitle">
		<div class="titleBar">
			<div class="title" id="createPasskeyTitle" role="alert">Create Passkey</div>
		</div>
		<div class="dialogBody">
			<div class="bodyContent">
				<img class="keyIconLg" :src="appPath + 'Images/key256.png'" alt="" role="presentation" />
				<div class="message">
					<p>We are enhancing security by transitioning from traditional passwords to <b>passkeys</b>.</p>
					<p>Based on <a href="https://fidoalliance.org/passkeys/" target="_blank">FIDO standards</a>, passkeys are a replacement for passwords that provide faster, easier, and more secure sign-ins to websites and apps across a user’s devices. Unlike passwords, passkeys are always strong and phishing-resistant.​</p>
				</div>
				<div class="message warningMessage" v-if="!passkeySupported">
					<vsvg sprite="warning" class="warningIcon" /><span>This web browser or device does not support <b>passkeys</b>.</span>
				</div>
			</div>
			<div class="controls">
				<div ref="btnOK" role="button" class="dialogButton okButton" tabindex="0" @click="Close" @keydown.space.enter.prevent="Close">
					{{okText}}
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import { passkey_supportedAsync } from 'appRoot/scripts/Passkeys';
	import svg1 from 'appRoot/images/sprite/warning.svg';

	export default {
		components: {},
		props:
		{
		},
		data()
		{
			return {
				appPath: appContext.appPath,
				allowPasswordAuth: appContext.passwordAuth,
				passkeySupported: false
			};
		},
		async created()
		{
			this.passkeySupported = await passkey_supportedAsync();
		},
		mounted()
		{
		},
		computed:
		{
			okText()
			{
				return this.passkeySupported ? "Create Passkey" : "OK";
			}
		},
		methods:
		{
			SetFocus()
			{
			},
			DefaultClose()
			{
			},
			Close()
			{
				this.$emit('close', {});
			}
		},
		watch:
		{
		}
	}
</script>
<style scoped>
	.CreatePasskeyDialog
	{
		background-color: #FFFFFF;
		box-shadow: rgba(0,0,0,0.7) 2px 9px 20px 7px;
		min-width: 300px;
		max-width: 500px;
		word-break: break-word;
		word-wrap: break-word;
	}

	.titleBar
	{
		background-color: #FFFFFF;
		padding: 8px 14px;
		box-sizing: border-box;
	}

	.title
	{
		text-align: center;
		color: black;
		font-weight: bold;
		font-size: 16pt;
	}

	.bodyContent
	{
	}

	.keyIconLg
	{
		width: 64px;
		height: 64px;
		float: left;
		padding: 0px 1em 0.25em 16px;
	}

	.message
	{
		margin: 16px;
	}

	.warningMessage
	{
		display: flex;
		align-items: center;
		border: 1px dashed #FF0000;
		padding: 2px;
	}

		.warningMessage svg
		{
			flex: 0 0 auto;
		}

		.warningMessage span
		{
			flex: 1 1 auto;
		}

	.controls
	{
		display: flex;
	}

	.dialogButton
	{
		display: inline-block;
		cursor: pointer;
		color: black;
		font-weight: bold;
		font-size: 12pt;
		box-sizing: border-box;
		position: relative;
		padding: 12px 5px;
		flex: 1 0 auto;
		text-align: center;
	}

		.dialogButton:hover
		{
			background-color: rgba(0,0,0,0.05);
		}

	.warningIcon
	{
		width: 36px;
		height: 36px;
		fill: #FF0000;
		float: left;
		margin-right: 8px;
	}
</style>
