<template>
	<div :id="editorId" class="aceEditor">
	</div>
</template>

<script>
	import ace from 'ace-builds/src-noconflict/ace';
	import 'ace-builds/src-noconflict/theme-chrome';
	import 'ace-builds/src-noconflict/keybinding-vscode';
	import 'ace-builds/src-noconflict/mode-css';
	import 'ace-builds/src-noconflict/mode-javascript';
	import 'ace-builds/src-noconflict/mode-json';
	import 'ace-builds/src-noconflict/mode-html';
	import 'ace-builds/src-noconflict/mode-xml';
	import 'ace-builds/src-noconflict/ext-searchbox';
	// Define web workers to load in the CopyWebpackPlugin constructor in webpack.config.js.  See the list in C:\TFS2010\Utilities\TdsAppService\TdsAppService\node_modules\ace-builds\src-noconflict
	import EventBus from 'appRoot/scripts/EventBus';

	export default {
		props:
		{
			mode: {
				type: String,
				default: ""
			},
			disabled: {
				type: Boolean,
				default: false
			}
		},
		data()
		{
			return {
				editorId: "aceEditor" + (++EventBus.aceEditorCounter),
				editor: null
			};
		},
		created()
		{
		},
		mounted()
		{
			ace.config.set('basePath', '/dist/ace');
			this.editor = ace.edit(this.editorId);

			this.editor.setTheme("ace/theme/chrome");
			this.editor.setKeyboardHandler("ace/keyboard/vscode");
			if (this.mode)
				this.editor.session.setMode(this.mode);
			this.editor.setOption("wrapBehavioursEnabled", true); // Maybe not necessary
			//this.editor.setOption("useWorker", false); // Prevents script error due to not knowing how to load the web worker scripts into the www files (dist folder).
			this.editor.session.setOption("wrap", true);
			//this.editor.session.on('change', this.onChange);

			this.editor.setReadOnly(this.disabled);
		},
		beforeDestroy()
		{
			//if (this.editor)
			//{
			//	this.editor.session.off('change', this.onChange);
			//}
		},
		computed:
		{
		},
		methods:
		{
			//onChange(e)
			//{
			//	this.$emit("change", this.editor.getValue());
			//},
			getValue()
			{
				return this.editor.getValue();
			},
			setValue(v, resetUndoStack)
			{
				this.editor.setValue(v);
				if (resetUndoStack)
				{
					// Reset undo stack otherwise undo will remove the value we just programmatically set.
					this.editor.getSession().setUndoManager(new ace.UndoManager());
				}

				// Range arguments are:
				// Starting Line index (0+)
				// Starting Column index (0+)
				// Ending Line index (0+)
				// Ending Column index (0+)
				let aceRange = new ace.Range(0, 0, 0, 0);
				this.editor.getSelection().setSelectionRange(aceRange, false);
			}
		},
		watch:
		{
			//value()
			//{
			//	console.log("AceCodeEditor > watch > Vue value", { v: this.value });
			//	if (this.editor && this.value !== this.editor.getValue())
			//		this.editor.setValue(this.value);
			//},
			disabled()
			{
				if (this.editor)
					this.editor.setReadOnly(this.disabled);
			}
		}
	}
</script>

<style scoped>
	.aceEditor
	{
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}
</style>