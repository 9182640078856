var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "editMonitorRoot" },
    [
      _c("div", { staticClass: "title" }, [_vm._v("Edit Monitor")]),
      _vm._v(" "),
      _c("Editor", {
        ref: "monitorEditor",
        attrs: { object: _vm.monitor, spec: _vm.editSpec },
        on: { valueChanged: _vm.valueChanged },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "buttonRow" }, [
        _c("input", {
          attrs: { type: "button", value: "Delete" },
          on: { click: _vm.Delete },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "buttonRow" }, [
        _c("input", {
          attrs: { type: "button", value: "Close" },
          on: { click: _vm.DefaultClose },
        }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }