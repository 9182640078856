<template>
	<div class="CmsProductHistorySelector">
		<div class="title">{{type}} History</div>
		<div class="error" v-if="error">{{error}}</div>
		<div class="loading" v-else-if="loading">Loading…</div>
		<div class="history" v-else-if="!history || !history.length">
			No History
		</div>
		<div class="history" v-else>
			<table>
				<thead>
					<tr>
						<th>Date</th>
						<th>User</th>
						<th>Diff</th>
					</tr>
				</thead>
				<tbody>
					<template v-for="(item, index) in history">
						<tr class="historyItem" :key="'history_' + index">
							<td>{{GetDateStr(new Date(item.Timestamp))}}</td>
							<td>{{item.UserName}}</td>
							<td class="DiffCell">
								<button class="DiffExpandBtn" @click="ExpandDiff(item, diffs[index])" @keypress.space.enter.prevent="ExpandDiff(item, diffs[index])" title="Expand"><vsvg sprite="open_in_new" /></button>
								<div class="DiffBlock" v-html="diffs[index]"></div>
							</td>
						</tr>
					</template>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
	import { GetDateStrPadded } from 'appRoot/scripts/Util';
	import { ModalMessageDialog } from 'appRoot/scripts/ModalDialog';
	import { CMSGetProductRootHistory, CMSGetProductRevisionHistory } from 'appRoot/api/CMSUserData';
	import svg1 from 'appRoot/images/sprite/open_in_new.svg';

	export default {
		components: {},
		props:
		{
			type: {
				type: String,
				required: true
			},
			id: {
				type: Number,
				required: true
			}
		},
		data()
		{
			return {
				loading: false,
				error: null,
				history: [],
				diffs: []
			};
		},
		created()
		{
			this.loadHistory();
		},
		methods:
		{
			async loadHistory()
			{
				if (this.loading)
					return;
				this.loading = true;
				this.error = null;
				this.history = [];
				try
				{
					let data;
					if (this.type === "ProductRoot")
						data = await CMSGetProductRootHistory(this.id);
					else if (this.type === "ProductRevision")
						data = await CMSGetProductRevisionHistory(this.id);
					else
						throw new Error("Unrecognized object type: " + this.type);

					if (data.success)
					{
						this.history = data.history;
						this.diffs = data.diffs;
					}
					else
						this.error = data.error;
				}
				catch (ex)
				{
					this.error = ex.message;
				}
				finally
				{
					this.loading = false;
				}
			},
			SelectVersion(item)
			{
				let obj = JSON.parse(JSON.stringify(item[this.type]));
				this.$emit('select', { obj });
			},
			async ExpandDiff(item, diffHtml)
			{
				let result = await ModalMessageDialog('<div style="white-space: pre-wrap; word-break: break-word;">' + diffHtml + '</div>', undefined, { messageHtml: true, focusMessage: true, confirm: true, yesText: "Load Into Form", noText: "Close" });
				if (result)
				{
					this.SelectVersion(item);
				}
			},
			GetDateStr(date)
			{
				return GetDateStrPadded(date);
			}
		}
	}
	function jclone(src, target, name)
	{
		target[name] = JSON.parse(JSON.stringify(src[name]));
	}
</script>

<style scoped>
	.CmsProductHistorySelector
	{
		background-color: #FFFFFF;
	}

	.title
	{
		font-size: 1.2em;
		margin-bottom: 0.5em;
	}

	.CmsProductHistorySelector table
	{
		border-collapse: collapse;
	}

	.CmsProductHistorySelector th,
	.CmsProductHistorySelector td
	{
		border: 1px solid #666666;
		padding: 1px 4px;
		vertical-align: top;
	}

	.CmsProductHistorySelector .DiffCell
	{
		padding: 0px;
		position: relative;
	}

	.DiffCell
	{
	}

	.DiffBlock
	{
		padding: 1px 4px;
		white-space: pre-wrap;
		word-break: break-word;
		min-width: 40px;
		min-height: 39px;
		max-height: 200px;
		overflow-y: hidden;
		display: block;
		font-size: 0px;
	}

		.DiffBlock::v-deep ins,
		.DiffBlock::v-deep del
		{
			font-size: 16px;
		}

	.DiffExpandBtn
	{
		position: absolute;
		top: 4px;
		right: 4px;
		z-index: 1;
	}

		.DiffExpandBtn svg
		{
			width: 24px;
			height: 24px;
		}

	.CmsProductHistorySelector th
	{
		background-color: rgba(0,0,0,0.1);
	}
</style>