<template>
	<div class="AgentStatusContainer">
		<div class="FloatRight" v-if="!loading1 && !loading2">
			<button class="refreshButton" @click="loadData">
				<vsvg sprite="refresh" class="refreshIcon" />
			</button>
		</div>
		<h2 class="Heading">CMS Agent Status</h2>
		<h3 class="Heading">{{myAgentName}}</h3>
		<div v-if="error1 || error2" class="error">
			<p v-if="error1">{{error1}}</p>
			<p v-if="error2">{{error2}}</p>
		</div>
		<div v-else-if="loading1 || loading2" class="loading">
			<ScaleLoader /> Loading…
		</div>
		<div v-else>
			<div class="connectionStatus" :class="agentStatus.connected ? 'connected' : 'disconnected'">
				<vsvg sprite="cable" class="cableIcon" />
				<span v-html="agentConnectionStatusString">
				</span>
			</div>

			<p>This agent has CMSRole <b>{{agentStatus.CMSRole}}</b>.</p>
			<div v-if="productRoots && productRevisionMap">
				<template v-if="agentStatus.CMSRole !== 'None'">
					<p>
						The following is a snapshot of the status of this agent.
						<template v-if="agentStatus.connected">
							May be incomplete if the agent reconnected very recently.
						</template>
						Refresh page to update.
					</p>
					<h4>Status String</h4>
					<p>{{agentStatus.status}}</p>
					<h4>Current File Upload</h4>
					<div class="archiveTransfer" v-if="agentStatus.fileSync">
						{{agentStatus.fileSync.FilePath}} - {{agentStatus.fileSync.FileSizeStr}} @ {{agentStatus.fileSync.BitsPerSecondStr}} <progress max="100" :value="agentStatus.fileSync.Progress" :title="agentStatus.fileSync.Progress + '% ' + agentStatus.fileSync.FilePath" />
						<div>ETA: {{agentStatus.fileSync.RemainingTime}}</div>
					</div>
					<div v-else>None</div>
					<h4>Current Installation Job</h4>
					<div class="titleRow" v-if="agentStatus.installStatus" title="'Revision ID ' + agentStatus.installingProductRevisionId">
						<progress max="100" :value="agentStatus.installationProgressPercent" />
						<div class="preformattedText">{{agentStatus.installAcronym}}: {{agentStatus.installStatus}}</div>
					</div>
					<div v-else>None</div>
					<h4>Revisions Staging or Staged ({{stagingRevs.length}})</h4>
					<div v-if="stagingRevs.length === 0">None</div>
					<router-link :to="GetRouteToProductRevision(sr.rev)" class="ProductRevisionClickable"
								 v-for="sr in stagingRevs" :key="'rev' + sr.rev.ProductRevisionId" :title="getRevisionTooltip(sr.rev)">
						<img :src="appPath + 'ProductImage/' + sr.rev.ImageId" class="RevisionImage" />
						<span class="ProductRevisionTitle" v-html="sr.rev.RevisionNameHtml"></span>
						<CmsLifecycleStateBadge :text="sr.rev.LifecycleState" :compact="true" />
						<span v-if="sr.isReadyForInstall" title="Archive is fully uploaded">
							<img :src="appPath + 'images/document_plain_green.png'" class="IconImage" />
						</span>
						<span v-if="sr.installStatus">Installing: {{sr.installProgress}}% <progress max="100" :value="sr.installProgress" /></span>
					</router-link>
					<h4>Products With No Revision Installed (not trashed) ({{productsNoRevInstalled.length}})</h4>
					<div v-if="!productsNoRevInstalled.length">None</div>
					<div v-for="proot in productsNoRevInstalled" :key="'nri' + proot.ProductRootId" class="NonInstallationRecordRow">
						<router-link :to="GetRouteToProductRoot(proot)" class="ProductRootClickable" v-html="proot.InternalNameHtml"></router-link>
						<span class="refused" v-if="refusedProductRootIdMap[proot.ProductRootId]">Agent configured to refuse syncing this product.</span>
					</div>
					<h4>Products With Revision Installed ({{productsWithRevInstalled.length}})</h4>
					<div v-if="!productsWithRevInstalled.length">None</div>
					<div v-for="item in productsWithRevInstalled" :key="'ri' + item.proot.ProductRootId" class="InstallationRecordRow">
						<router-link :to="GetRouteToProductRoot(item.proot)" class="ProductRootClickable" v-html="item.proot.InternalNameHtml"></router-link>
						<router-link :to="GetRouteToProductRevision(item.rev)" class="ProductRevisionClickable" v-html="getIrRevLinkHtml(item.rev)"></router-link>
						<span class="refused" v-if="refusedProductRootIdMap[item.proot.ProductRootId]">Agent configured to refuse syncing this product.</span>
					</div>
				</template>
			</div>
		</div>
		<div v-else>Failed to load for an unknown reason.</div>
	</div>
</template>

<script>
	import { GetCmsProductInformation, GetContentSyncAgentStatus } from 'appRoot/api/CMSUserData';
	import CmsLifecycleStateBadge from 'appRoot/vues/client/controls/CmsLifecycleStateBadge.vue';
	import { HTMLToText } from 'appRoot/scripts/Util';
	import svg1 from 'appRoot/images/sprite/refresh.svg';
	import svg2 from 'appRoot/images/sprite/cable.svg';

	export default {
		components: { CmsLifecycleStateBadge },
		props:
		{
			agentName: {
				type: String,
				required: true
			},
			agentId: {
				type: String,
				required: true
			}
		},
		data()
		{
			return {
				appPath: appContext.appPath,
				loading1: false,
				error1: null,
				loading2: false,
				error2: null,
				productRoots: null,
				productRevisionMap: null,
				agentStatus: null,
			};
		},
		created()
		{
			this.loadData();
		},
		destroyed()
		{
		},
		mounted()
		{
		},
		computed:
		{
			myAgentName()
			{
				if (this.agentStatus)
					return this.agentStatus.name;
				return this.agentName;
			},
			rootMap()
			{
				let map = {};
				let roots = this.productRoots;
				if (roots)
				{
					for (let i = 0; i < roots.length; i++)
						map[roots[i].ProductRootId] = roots[i];
				}
				return map;
			},
			revMap()
			{
				let map = {};
				let rm = this.productRevisionMap;
				if (rm)
				{
					for (let key in rm)
					{
						if (rm.hasOwnProperty(key))
						{
							let revArr = rm[key];
							for (let i = 0; i < revArr.length; i++)
							{
								map[revArr[i].ProductRevisionId] = revArr[i];
							}
						}
					}
				}
				return map;
			},
			stagingRevs()
			{
				let rm = this.revMap;
				if (this.agentStatus.stagingRevs)
				{
					let stagingRevs = [...this.agentStatus.stagingRevs];
					stagingRevs.sort((a, b) =>
					{
						if (a.RevisionNameHtml < b.RevisionNameHtml)
							return -1;
						if (a.RevisionNameHtml > b.RevisionNameHtml)
							return 1;
						return 0;
					});
					return stagingRevs;
				}
				return [];
			},
			installationRecordMap()
			{
				let map = {};
				let irs = this.agentStatus.installationRecords;
				if (irs)
				{
					for (let i = 0; i < irs.length; i++)
					{
						let ir = irs[i];
						map[ir.ProductRootId] = ir;
					}
				}
				return map;
			},
			//installationRecords()
			//{
			//	let arr = [];
			//	let irMap = this.installationRecordMap;
			//	let productRoots = this.productRoots;
			//	for (let i = 0; i < productRoots.length; i++)
			//	{
			//		let proot = productRoots[i];
			//		let ir = irMap[proot.ProductRootId];
			//		if (ir)
			//			arr.push({ ProductRoot: proot, ProductRevisionId: ir.ProductRevisionId, ProductRootChangeId: ir.ProductRootChangeId, ProductRevisionChangeId: ir.ProductRevisionChangeId });
			//	}
			//	return arr;
			//},
			refusedProductRootIdMap()
			{
				let map = {};
				let rpids = this.agentStatus.refusedProductRootIds;
				if (rpids)
				{
					for (let i = 0; i < rpids.length; i++)
					{
						let id = rpids[i];
						map[id] = id;
					}
				}
				return map;

			},
			productRootIdToInstalledProductRevisionIdMap()
			{
				let map = {};
				let irMap = this.installationRecordMap;
				let productRoots = this.productRoots;
				if (productRoots && irMap)
				{
					for (let i = 0; i < productRoots.length; i++)
					{
						let proot = productRoots[i];
						let ir = irMap[proot.ProductRootId];
						if (ir && ir.ProductRevisionId > 0)
							map[proot.ProductRootId] = ir.ProductRevisionId;
					}
				}
				return map;
			},
			productsNoRevInstalled()
			{
				let arr = [];
				let idMap = this.productRootIdToInstalledProductRevisionIdMap;
				let productRoots = this.productRoots;
				if (productRoots && idMap)
				{
					for (let i = 0; i < productRoots.length; i++)
					{
						let proot = productRoots[i];
						if (proot.Trash)
							continue;
						let revId = idMap[proot.ProductRootId];
						if (!revId)
							arr.push(proot);
					}
				}
				return arr;
			},
			productsWithRevInstalled()
			{
				let arr = [];
				let idMap = this.productRootIdToInstalledProductRevisionIdMap;
				let revMap = this.revMap;
				let productRoots = this.productRoots;
				if (productRoots && idMap && revMap)
				{
					for (let i = 0; i < productRoots.length; i++)
					{
						let proot = productRoots[i];
						let revId = idMap[proot.ProductRootId];
						if (revId)
							arr.push({ proot: proot, rev: revMap[revId] });
					}
				}
				return arr;
			},
			agentConnectionStatusString()
			{
				if (this.agentStatus)
				{
					if (this.agentStatus.connected)
						return "Agent Connected, version " + (this.agentStatus.version ? this.agentStatus.version : "unknown") + ".";
					else
						return "Agent Not Connected";
				}
				return "";
			},
			shouldReloadData()
			{
				if (this.error2)
					return false;
				if (this.agentStatus)
					return this.agentStatus.agentId !== this.agentId;
				return true;
			}
		},
		methods:
		{
			loadData()
			{
				if (this.loading1 || this.loading2)
					return;
				this.loading1 = this.loading2 = true;
				this.error1 = this.error2 = null;

				GetCmsProductInformation()
					.then(data =>
					{
						if (data.success)
						{
							this.productRoots = data.productRoots;
							this.productRevisionMap = data.productRevisionMap;
						}
						else
							this.error1 = data.error;
					})
					.catch(err =>
					{
						this.error1 = err;
					})
					.finally(() =>
					{
						this.loading1 = false;
						if (this.shouldReloadData)
							this.loadData();
					})

				GetContentSyncAgentStatus(this.agentId)
					.then(data =>
					{
						if (data.success)
							this.agentStatus = data.status;
						else
							this.error2 = data.error;
					})
					.catch(err =>
					{
						this.error2 = err;
					})
					.finally(() =>
					{
						this.loading2 = false;
						if (this.shouldReloadData)
							this.loadData();
					})
			},
			GetRouteToProductRevision(rev)
			{
				return {
					name: "clientCMSProductRevision",
					params: { id: rev.ProductRevisionId }
				};
			},
			GetRouteToProductRoot(productRoot)
			{
				return {
					name: "clientCMSProductRoot",
					params: { id: productRoot.ProductRootId }
				};
			},
			getRevisionTooltip(productRevision)
			{
				let installedRevision = this.getInstalledRevision(productRevision);
				if (installedRevision)
				{
					if (installedRevision.ProductRevisionId === productRevision.productRevisionId)
						return "This is the revision that is currently installed.";
					else
						return "The currently installed revision is " + installedRevision.ProductRevisionId + ": " + HTMLToText(installedRevision.RevisionNameHtml) + " [" + installedRevision.LifecycleState + "]";
				}
				else
				{
					return "We don't know what revision of this product the agent has installed (if any).";
				}
			},
			getInstalledRevision(productRevision)
			{
				let productRootId = productRevision.ProductRootId;
				let installationRecord = this.installationRecordMap[productRootId];
				if (installationRecord)
					return this.revMap[installationRecord.ProductRevisionId];
				return undefined;
			},
			getIrRevLinkHtml(rev)
			{
				return '&nbsp;🡒 Rev ID: ' + rev.ProductRevisionId + ', ' + rev.RevisionNameHtml;
			}
		},
		watch:
		{
			shouldReloadData()
			{
				if (this.shouldReloadData)
					this.loadData();
			}
		}
	}
</script>

<style scoped>
	.AgentStatusContainer
	{
		box-sizing: border-box;
		padding: 1em 8px;
	}

	.loading
	{
		text-align: center;
	}

	.FloatRight
	{
		float: right;
	}

	.refreshButton
	{
		width: 44px;
		height: 44px;
		padding: 0px;
	}

	.refreshIcon
	{
		width: 32px;
		height: 32px;
		vertical-align: middle;
	}

	.IconImage
	{
		width: 16px;
		height: 16px;
		vertical-align: text-top;
	}

	.connectionStatus
	{
		display: flex;
		align-items: center;
		font-weight: bold;
	}

		.connectionStatus .cableIcon
		{
			width: 48px;
			height: 48px;
			fill: currentColor;
			margin-right: 0.5em;
		}

		.connectionStatus.connected
		{
			color: #00AA00;
		}

		.connectionStatus.disconnected
		{
			color: #AA0000;
		}

	.RevisionImage
	{
		max-width: 22px;
		height: 22px;
		vertical-align: bottom;
	}

	.ProductRootClickable,
	.ProductRevisionClickable
	{
		display: block;
		padding: 3px 0px;
		cursor: pointer;
		color: #000000;
		text-decoration: none;
	}

		.ProductRootClickable:hover,
		.ProductRootClickable:focus,
		.ProductRevisionClickable:hover,
		.ProductRevisionClickable:focus
		{
			background-color: rgba(0,0,0,0.1);
		}

		.ProductRevisionClickable progress
		{
			width: 50px;
		}

	.InstallationRecordRow
	{
	}

		.InstallationRecordRow:nth-child(2n+1)
		{
			background-color: rgba(0,0,0,0.1);
		}

	.NonInstallationRecordRow
	{
		background-color: rgba(255,0,0,0.03);
	}

		.NonInstallationRecordRow:nth-child(2n+1)
		{
			background-color: rgba(255,0,0,0.08);
		}

	.preformattedText
	{
		white-space: pre-wrap;
	}

	.refused
	{
		background-color: #FF0000;
		color: #FFFFFF;
		font-weight: bold;
		padding: 0px 2px;
		border-radius: 4px;
	}
</style>