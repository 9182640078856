var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      class: {
        svgbtn: true,
        contained: _vm.contained,
        round: _vm.round,
        disabled: !!_vm.disabled,
      },
      style: _vm.btnStyle,
      attrs: {
        role: "button",
        tabindex: "0",
        disabled: _vm.disabled ? "disabled" : "",
      },
      on: {
        click: _vm.onclick,
        keypress: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          )
            return null
          $event.preventDefault()
          return _vm.onclick.apply(null, arguments)
        },
      },
    },
    [_c("use", { attrs: { "xlink:href": "#" + _vm.sprite } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }