<template>
	<div :class="{ pageRoot: true }">
		<div v-if="error" class="error">{{error}}</div>
		<div v-else-if="loading" class="loading"><ScaleLoader /> Loading…</div>
		<div v-else>
			<div class="heading">Manage User Account</div>
			<div class="bodySection">
				<p>
					Primary email: {{userEmail}} <input type="button" value="change" @click="changePrimaryEmail()" :disabled="isUpdating" /> <input type="button" value="test" @click="testEmail(userEmail)" :disabled="isUpdating" />
				</p>
				<p v-if="allowPasswordAuth">
					<router-link :to="{ name: 'changePassword' }">Change Password</router-link>
				</p>
				<p>
					<input type="button" @click="logoutClicked" class="logoutButton" value="Log Out" :disabled="isUpdating" />
				</p>
			</div>
			<div class="heading" :class="{ highlight: createPasskey }">
				<img class="headingIcon" :src="appPath + 'Images/key.png'" alt="" role="presentation" />
				Passkeys
			</div>
			<div class="bodySection" :class="{ highlight: createPasskey }">
				<p>
					<div v-for="pk in passkeys" :key="pk.Id" class="passkeyContainer">
						<input type="button" value="delete" @click="deletePasskey(pk)" :disabled="isUpdating" class="passkeyDelete" />
						<div>Id: {{pk.Id}}</div>
						<div>Created {{getFuzzyTimeDistance(pk.RegDate)}}</div>
						<div>Last used {{getFuzzyTimeDistance(pk.LastUsed)}}</div>
					</div>
					<div v-if="!passkeys || !passkeys.length">Your account does not have any Passkeys registered.</div>
					<div class="buttonBar" v-if="passkeySupported">
						<button class="buttonBarButton" @click="BeginCreatePasskey" :disabled="isUpdating">
							<img class="btnIconNoHover" :src="appPath + 'Images/key_add.png'" alt="" role="presentation" />
							<span>Create Passkey</span>
						</button>
					</div>
					<div v-else>This web browser does not support Passkeys.</div>
				</p>
			</div>
			<div class="heading">Additional Email Addresses</div>
			<div class="bodySection">
				<p>
					<div v-for="a in additionalEmails" :key="a">
						{{a}} <input type="button" value="delete" @click="deleteEmail(a)" :disabled="isUpdating" /> <input type="button" value="test" @click="testEmail(a)" :disabled="isUpdating" />
					</div>
				</p>
				<p><input type="button" value="Add Another Email Address" @click="addEmail" :disabled="isUpdating" /></p>
			</div>
			<div class="heading">Email-To-SMS</div>
			<p><a href="https://www.digitaltrends.com/mobile/how-to-send-a-text-from-your-email-account/">https://www.digitaltrends.com/mobile/how-to-send-a-text-from-your-email-account/</a></p>
			<div class="bodySection">
				<table class="emailToSmsTable">
					<thead>
						<tr>
							<th align="left">Carrier</th>
							<th align="center">SMS Gateway Domain</th>
							<th align="center">MMS Gateway Domain</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td align="left"><strong>AT&amp;T</strong></td>
							<td align="center">number@txt.att.net</td>
							<td align="center">number@mms.att.net</td>
						</tr>
						<tr>
							<td align="left"><strong>Boost Mobile</strong></td>
							<td align="center">number@sms.myboostmobile.com</td>
							<td align="center">number@myboostmobile.com</td>
						</tr>
						<tr>
							<td align="left"><strong>Cricket Wireless</strong></td>
							<td align="center">number@mms.cricketwireless.net</td>
							<td align="center">number@mms.cricketwireless.net</td>
						</tr>
						<tr>
							<td align="left"><strong>Google Project Fi</strong></td>
							<td align="center">number@msg.fi.google.com</td>
							<td align="center">number@msg.fi.google.com</td>
						</tr>
						<tr>
							<td align="left"><strong>Republic Wireless</strong></td>
							<td align="center">number@text.republicwireless.com</td>
							<td align="center">None</td>
						</tr>
						<tr>
							<td align="left"><strong>Sprint</strong></td>
							<td align="center">number@messaging.sprintpcs.com</td>
							<td align="center">number@pm.sprint.com</td>
						</tr>
						<tr>
							<td align="left"><strong>Straight Talk</strong></td>
							<td align="center">number@vtext.com</td>
							<td align="center">number@mypixmessages.com</td>
						</tr>
						<tr>
							<td align="left"><strong>T-Mobile</strong></td>
							<td align="center">number@tmomail.net</td>
							<td align="center">number@tmomail.net</td>
						</tr>
						<tr>
							<td align="left"><strong>Ting</strong></td>
							<td align="center">number@message.ting.com</td>
							<td align="center">None</td>
						</tr>
						<tr>
							<td align="left"><strong>Tracfone</strong></td>
							<td align="center">Depends on underlying carrier</td>
							<td align="center">number@mmst5.tracfone.com</td>
						</tr>
						<tr>
							<td align="left"><strong>U.S. Cellular</strong></td>
							<td align="center">number@email.uscc.net</td>
							<td align="center">number@mms.uscc.net</td>
						</tr>
						<tr>
							<td align="left"><strong>Verizon</strong></td>
							<td align="center">number@vtext.com</td>
							<td align="center">number@vzwpix.com</td>
						</tr>
						<tr>
							<td align="left"><strong>Virgin Mobile</strong></td>
							<td align="center">number@vmobl.com</td>
							<td align="center">number@vmpix.com</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="heading">Active Sessions</div>
			<div class="bodySection">
				<ActiveSessionList :allSessions="false" />
			</div>
			<div class="heading">Login History</div>
			<div class="bodySection">
				<LoginRecordsTable />
			</div>
			<div class="heading">User Activity Logs</div>
			<div class="bodySection">
				<UserActivityTable />
			</div>
		</div>
	</div>
</template>

<script>
	import LoginRecordsTable from 'appRoot/vues/common/loginrecords/LoginRecordsTable.vue';
	import UserActivityTable from 'appRoot/vues/common/useractivity/UserActivityTable.vue';
	import ActiveSessionList from 'appRoot/vues/common/controls/ActiveSessionList.vue';
	import { SetPrimaryEmail, TestEmail, GetUserData, SetAdditionalEmails, DeletePasskey } from 'appRoot/api/UserManageData';
	import { ModalConfirmDialog, TextInputDialog, CreatePasskeyDialog, ModalMessageDialog } from 'appRoot/scripts/ModalDialog';
	import { passkey_supportedAsync, BeginCreatePasskey } from 'appRoot/scripts/Passkeys';
	import { GetFuzzyTime, CopyRoute } from 'appRoot/scripts/Util';

	export default {
		components: { LoginRecordsTable, ActiveSessionList, UserActivityTable },
		props:
		{
			createPasskey: {
				type: Boolean,
				default: false
			}
		},
		data()
		{
			return {
				appPath: appContext.appPath,
				allowPasswordAuth: appContext.passwordAuth,
				error: null,
				loading: false,
				userName: null,
				userEmail: null,
				isUpdating: false,
				additionalEmails: [],
				unfinishedPrimaryEmailInput: "",
				unfinishedEmailInput: "",
				passkeys: [],
				passkeySupported: false
			};
		},
		async created()
		{
			this.LoadUserData();
			this.passkeySupported = await passkey_supportedAsync();
		},
		computed:
		{
		},
		methods:
		{
			LoadUserData()
			{
				this.error = null;
				this.loading = true;
				GetUserData()
					.then(data =>
					{
						this.handleUserDataResponse(data);
					})
					.catch(err =>
					{
						this.error = err.message;
					})
					.finally(() =>
					{
						this.loading = false;
					});
			},
			async handleUserDataResponse(data)
			{
				if (data.success)
				{
					this.userName = data.userName;
					this.userEmail = data.email;
					this.additionalEmails = data.additionalEmails;
					this.passkeys = data.passkeys;
					if (this.createPasskey)
					{
						await CreatePasskeyDialog();
						1
						if (this.passkeySupported)
						{
							await this.BeginCreatePasskey();
							ModalMessageDialog("You can create more Passkeys at any time by visiting the \"Account\" page, \"Passkeys\" section.");
						}

						let route = CopyRoute(this.$route);
						delete route.query.createPasskey;
						this.$router.replace(route);
					}
				}
				else
					this.error = data.error;
			},
			deleteEmail(address)
			{
				ModalConfirmDialog('Please confirm your intent to remove "' + address + '" from your user account.', 'Confirm', 'Remove', "Cancel")
					.then(result =>
					{
						if (result)
						{
							let newEmailArray = [];
							for (let i = 0; i < this.additionalEmails.length; i++)
								if (this.additionalEmails[i] !== address)
									newEmailArray.push(this.additionalEmails[i]);

							this.isUpdating = true;
							SetAdditionalEmails(newEmailArray)
								.then(data =>
								{
									if (data.success)
									{
										this.handleUserDataResponse(data);
									}
									else
										toaster.error(data.error);
								})
								.catch(err =>
								{
									toaster.error(err.message);
								})
								.finally(() =>
								{
									this.isUpdating = false;
								});
						}
					});
			},
			addEmail()
			{
				TextInputDialog("Add Email Address", "Enter a new email address:", "me@example.com", this.unfinishedEmailInput)
					.then(result =>
					{
						if (result)
						{
							let newEmailArray = [];
							for (let i = 0; i < this.additionalEmails.length; i++)
								newEmailArray.push(this.additionalEmails[i]);
							newEmailArray.push(result.value);

							this.unfinishedEmailInput = result.value;
							this.isUpdating = true;
							SetAdditionalEmails(newEmailArray)
								.then(data =>
								{
									if (data.success)
									{
										this.unfinishedEmailInput = "";
										this.handleUserDataResponse(data);
									}
									else
										toaster.error(data.error);
								})
								.catch(err =>
								{
									toaster.error(err.message);
								})
								.finally(() =>
								{
									this.isUpdating = false;
								});
						}
					});
			},
			changePrimaryEmail()
			{
				TextInputDialog("Change Primary Email Address", "Enter a new email address:", "me@example.com", this.userEmail)
					.then(result =>
					{
						if (result)
						{
							this.unfinishedPrimaryEmailInput = result.value;
							this.isUpdating = true;

							SetPrimaryEmail(result.value)
								.then(data =>
								{
									if (data.success)
									{
										this.unfinishedPrimaryEmailInput = "";
										this.handleUserDataResponse(data);
									}
									else
										toaster.error(data.error);
								})
								.catch(err =>
								{
									toaster.error(err.message);
								})
								.finally(() =>
								{
									this.isUpdating = false;
								});
						}
					});
			},
			testEmail(address)
			{
				this.isUpdating = true;
				TestEmail(address)
					.then(data =>
					{
						if (data.success)
							toaster.success("A test email was sent to " + address);
						else
							toaster.error(data.error);
					})
					.catch(err =>
					{
						toaster.error(err.message);
					})
					.finally(() =>
					{
						this.isUpdating = false;
					});
			},
			logoutClicked()
			{
				this.$store.dispatch("Logout")
					.then(data =>
					{
						this.$router.push({ name: "login" });
					})
					.catch(err =>
					{
						toaster.error(err);
					});
			},
			deletePasskey(passkey)
			{
				ModalConfirmDialog('Please confirm your intent to remove passkey "' + passkey.Id + '" from your user account.', 'Confirm', 'Remove', "Cancel")
					.then(result =>
					{
						if (result)
						{
							this.isUpdating = true;
							DeletePasskey(passkey.Id)
								.then(data =>
								{
									this.handleUserDataResponse(data);
								})
								.catch(err =>
								{
									toaster.error(err.message);
								})
								.finally(() =>
								{
									this.isUpdating = false;
								});
						}
					});
			},
			async BeginCreatePasskey()
			{
				let result = await BeginCreatePasskey(this.userName, null, false);
				if (result)
					this.LoadUserData();
			},
			getFuzzyTimeDistance(time)
			{
				if (time <= 0)
					return "Never";
				return GetFuzzyTime(Date.now() - time) + " ago (" + new Date(time).toLocaleString() + ")";
			}
		},
		watch:
		{
		}
	}
</script>

<style scoped>
	.pageRoot
	{
		margin: 8px;
	}

	.loading
	{
		margin-top: 80px;
		text-align: center;
	}

	.error
	{
		color: #FF0000;
		font-weight: bold;
	}

	.heading
	{
		font-size: 20px;
		border-bottom: 1px solid #000000;
		margin-bottom: 10px;
		margin-top: 30px;
		max-width: 400px;
	}

		.heading:first-child
		{
			margin-top: 0px;
		}

	.headingIcon
	{
		width: 20px;
		height: 20px;
		padding: 1px;
		vertical-align: bottom;
	}

	.bodySection
	{
		margin: 0px 20px;
	}

		.bodySection ul
		{
			padding-left: 20px;
		}

			.bodySection ul li
			{
				margin-bottom: 10px;
			}

				.bodySection ul li:last-child
				{
					margin-bottom: 0px;
				}

	.additionalEmailsTextArea
	{
		min-width: 200px;
		height: 300px;
	}

	.emailToSmsTable
	{
		border-collapse: collapse;
	}

		.emailToSmsTable th
		{
			border: 1px solid black;
			padding: 4px 8px;
			background-color: #E2E2E2;
		}

		.emailToSmsTable td
		{
			border: 1px solid black;
			padding: 4px 8px;
		}

	.passkeyContainer
	{
		border: 1px solid #666666;
		border-radius: 5px;
		padding: 4px 8px;
		margin-bottom: 0.5em;
	}

	.passkeyDelete
	{
		float: right;
	}

	.highlight
	{
		background-color: #FFFF00;
	}

	@media (min-width: 600px)
	{
		.additionalEmailsTextArea
		{
			min-width: 400px;
		}
	}
</style>