var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: { editor: true, showHelp: _vm.showHelp } },
    [
      _c("div", { staticClass: "SettingsSettingsContainer" }, [
        _c("label", [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.showHelp,
                expression: "showHelp",
              },
            ],
            attrs: { type: "checkbox" },
            domProps: {
              checked: Array.isArray(_vm.showHelp)
                ? _vm._i(_vm.showHelp, null) > -1
                : _vm.showHelp,
            },
            on: {
              change: function ($event) {
                var $$a = _vm.showHelp,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.showHelp = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.showHelp = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.showHelp = $$c
                }
              },
            },
          }),
          _vm._v(" Show Editor Help\n\t\t"),
        ]),
      ]),
      _vm._v(" "),
      _vm._l(_vm.itemPairs, function (pair) {
        return _c("PropEdit", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: pair.show,
              expression: "pair.show",
            },
          ],
          key: pair.spec.key,
          ref: "fields",
          refInFor: true,
          staticClass: "propEdit",
          attrs: { initialValue: pair.initialValue, spec: pair.spec },
          on: { valueChanged: _vm.onValueChanged },
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }