<template>
	<div class="scaledImageItem" :class="{ selected: selected }" role="button" tabindex="0" @click="selectImage(img)" @keypress.enter.space.prevent="selectImage(img)">
		<img :src="dataUri" alt="image" class="scaledImage" />
		<div class="caption">
			<a :href="dataUri" :download="downloadName" title="download image">{{downloadName}} <vsvg sprite="upload" class="download_img" /></a>
			<div>{{fileSize}}</div>
		</div>
	</div>
</template>

<script>
	import svg1 from 'appRoot/images/sprite/upload.svg';
	import { formatBytesF10 } from 'appRoot/scripts/Util';

	export default {
		components: {},
		props:
		{
			img: {
				type: Object,
				required: true
			},
			selected: {
				type: Boolean,
				default: false
			}
		},
		computed:
		{
			fileSize()
			{
				return formatBytesF10(this.img.ByteLength, 2);
			},
			downloadName()
			{
				return this.img.Name + this.img.FileExtension;
			},
			dataUri()
			{
				return 'data:' + this.img.ContentType + ';base64,' + this.img.DataBase64;
			}
		},
		methods:
		{
			selectImage(img)
			{
				this.$emit("select", { img });
			},
		}
	}
</script>

<style scoped>
	.scaledImageItem
	{
		border: 10px solid #FFFFFF;
		box-shadow: 1px 1px 3px 2px rgba(0,0,0,1);
		padding: 10px;
		margin: 5px;
		cursor: pointer;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
	}

		.scaledImageItem:focus
		{
			outline: 8px solid #0000FF80;
		}

		.scaledImageItem:hover
		{
			outline: 8px solid #0080FF80;
		}

		.scaledImageItem.selected
		{
			border-color: #6666FF;
			cursor: default;
		}

	.caption
	{
		text-align: center;
		text-decoration: none;
		word-break: break-word;
		max-width: 220px;
	}

		.caption > *
		{
			display: block;
			max-width: 220px;
			white-space: pre-line;
			text-decoration: none;
		}

		.caption a:hover,
		.caption a:focus
		{
			text-decoration: underline;
		}

	.download_img
	{
		fill: currentColor;
		height: 1em;
		width: 1em;
		vertical-align: text-bottom;
		transform: rotate(180deg);
	}
</style>