var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pageRoot" }, [
    _c("h2", [_vm._v("Adding a Release Agent")]),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _vm.error
      ? _c("div", { staticClass: "error" }, [_vm._v(_vm._s(_vm.error))])
      : _c("div", [
          _c("h2", [_vm._v("Approval Settings")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "The selected settings will be applied to the agent you choose to approve below."
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "settingsRow" }, [
            _c("div", { staticClass: "settingsTitle" }, [
              _vm._v("Environment"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "settingsValue" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.environmentName,
                      expression: "environmentName",
                    },
                  ],
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.environmentName = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                _vm._l(_vm.environmentNames, function (env) {
                  return _c("option", { key: env }, [_vm._v(_vm._s(env))])
                }),
                0
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "settingsRow" }, [
            _c("div", { staticClass: "settingsTitle" }, [_vm._v("CMS Role")]),
            _vm._v(" "),
            _c("div", { staticClass: "settingsValue" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.cmsRole,
                      expression: "cmsRole",
                    },
                  ],
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.cmsRole = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                _vm._l(_vm.cmsRoles, function (role) {
                  return _c("option", { key: role }, [_vm._v(_vm._s(role))])
                }),
                0
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "FloatRight" }, [
            _c(
              "button",
              { staticClass: "refreshButton", on: { click: _vm.loadData } },
              [
                _c("vsvg", {
                  staticClass: "refreshIcon",
                  attrs: { sprite: "refresh" },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("h2", [_vm._v("Adoption Requests")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "adoptionRequests" },
            [
              _vm.loading
                ? _c(
                    "div",
                    { staticClass: "loading" },
                    [_c("ScaleLoader"), _vm._v(" Loading…")],
                    1
                  )
                : !_vm.pendingAdoptions.length
                ? _c("div", [_vm._v("No adoptions pending.")])
                : _vm._l(_vm.pendingAdoptions, function (ar, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "adoptionRequest" },
                      [
                        _c("div", { staticClass: "agentName" }, [
                          _vm._v("Agent Name: " + _vm._s(ar.AgentName)),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "agentIp" }, [
                          _vm._v("IP Address: " + _vm._s(ar.AgentIp)),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "agentPublicKey" }, [
                          _vm._v("Public Key: "),
                          _c("input", {
                            attrs: { type: "text", disabled: "disabled" },
                            domProps: { value: ar.AgentPublicKey },
                          }),
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          attrs: {
                            type: "button",
                            value: "Approve " + ar.AgentName,
                            disabled: !_vm.canApprove,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.approve(ar)
                            },
                          },
                        }),
                      ]
                    )
                  }),
            ],
            2
          ),
          _vm._v(" "),
          _c("h2", [_vm._v("A Note on Security")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "TdsAppService and each Release Agent must be configured with each other's public keys.  These public keys are used for encryption and signing to prove the identity of each party when the services communicate with each other.  More details on the implementation of this security mechanism can be found in each application's source code."
            ),
          ]),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(
        "To configure a Release Agent, run the TdsAppReleaseAgent service on a new machine, then use the "
      ),
      _c("code", { staticClass: "inline" }, [_vm._v("Begin Adoption")]),
      _vm._v(
        " button in its service manager GUI. This will cause the agent to request adoption.  You can discover and approve adoption requests below."
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }