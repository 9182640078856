var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: { pageRoot: true } },
    [
      _vm.error
        ? _c("div", { staticClass: "error" }, [_vm._v(_vm._s(_vm.error))])
        : _vm.loading
        ? _c(
            "div",
            { staticClass: "loading" },
            [_c("ScaleLoader"), _vm._v(" Loading…")],
            1
          )
        : [
            _c(
              "div",
              { staticClass: "outerContainer" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "gradientButton lightBlue",
                    attrs: { to: _vm.getFileEditorRoute("ReleaseNotes") },
                  },
                  [_vm._v("Release Notes")]
                ),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass: "gradientButton green",
                    attrs: { to: _vm.getFileEditorRoute("TitleUpdates") },
                  },
                  [_vm._v("Title Updates")]
                ),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass: "gradientButton tdsHealthBrown",
                    attrs: { to: _vm.getFileEditorRoute("AdminIPWhitelist") },
                  },
                  [_vm._v("Admin IP Whitelist")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _vm._l(_vm.environments, function (env) {
              return _c("div", { staticClass: "outerContainer" }, [
                _c("div", { staticClass: "primaryHeading" }, [
                  _vm._v(_vm._s(env.Name) + " Environment"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "availableApps" },
                  _vm._l(env.Apps, function (app) {
                    return _c(
                      "div",
                      { key: app.AppName, staticClass: "appRow" },
                      [
                        _vm._v(
                          '\n\t\t\t\t\tWebsite "' + _vm._s(app.AppName) + '" '
                        ),
                        app.ReleaseName
                          ? _c("span", [
                              _vm._v(
                                ' has release "' +
                                  _vm._s(app.ReleaseName) +
                                  '" installed.'
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        !app.AppPoolNames || app.AppPoolNames.length > 1
                          ? [
                              _c("input", {
                                staticClass: "recycleAppPoolBtn",
                                attrs: {
                                  type: "button",
                                  value: "Recycle All",
                                  title: "Recycle App Pool(s)",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.recycleAppPools(env, app, "")
                                  },
                                },
                              }),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _vm._l(app.AppPoolNames, function (appPoolName) {
                          return app.AppPoolNames
                            ? _c("input", {
                                staticClass: "recycleAppPoolBtn",
                                attrs: {
                                  type: "button",
                                  value: "Recycle " + appPoolName,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.recycleAppPools(
                                      env,
                                      app,
                                      appPoolName
                                    )
                                  },
                                },
                              })
                            : _vm._e()
                        }),
                        _vm._v(" "),
                        !app.Coherent
                          ? _c(
                              "div",
                              { staticClass: "appIncoherent darkBadge" },
                              [_vm._v("INCOHERENT")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !app.Coherent
                          ? _c("div", [
                              _vm._v(
                                'Website "' +
                                  _vm._s(app.AppName) +
                                  '" is incoherent and requires manual repair.'
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        app.Error
                          ? _c("div", { staticClass: "appError" }, [
                              _vm._v(
                                'Website "' +
                                  _vm._s(app.AppName) +
                                  '" has error: ' +
                                  _vm._s(app.Error)
                              ),
                            ])
                          : _vm._e(),
                      ],
                      2
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "availableReleases" },
                  _vm._l(env.Releases, function (r) {
                    return _c(
                      "div",
                      { key: r.Name, staticClass: "releaseRow" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "gradientButton tdsHealthBrown",
                            attrs: {
                              to: _vm.getReleaseRoute(env, r),
                              replace: "",
                            },
                          },
                          [_vm._v(_vm._s(r.Name))]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "releaseDate",
                            attrs: {
                              title: "Build date of the application binaries",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.timestampToDateString(r.BuildDate))
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm._l(
                          _vm.getAppsWhereReleaseIsInstalled(env, r),
                          function (app) {
                            return _c(
                              "div",
                              {
                                key: app.AppName,
                                staticClass:
                                  "gradientButton lightBlue releaseInstalled",
                                attrs: {
                                  role: "button",
                                  tabindex: "0",
                                  title:
                                    "Click to open the " +
                                    app.AppName +
                                    (app.Links.length > 1
                                      ? " websites in " +
                                        app.Links.length +
                                        " new tabs."
                                      : " website in a new tab."),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.openAppLinks(app)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t" +
                                    _vm._s(app.AppName) +
                                    "\n\t\t\t\t\t"
                                ),
                              ]
                            )
                          }
                        ),
                        _vm._v(" "),
                        r.IsArchiving
                          ? _c(
                              "div",
                              {
                                staticClass: "releaseArchiving darkBadge",
                                attrs: {
                                  title:
                                    "The service was ordered to archive this release.",
                                },
                              },
                              [_vm._v("Archiving")]
                            )
                          : _vm._e(),
                      ],
                      2
                    )
                  }),
                  0
                ),
              ])
            }),
            _vm._v(" "),
            _vm.environmentWarnings.length
              ? _c("div", { staticClass: "outerContainer warningsContainer" }, [
                  _c("div", { staticClass: "primaryHeading" }, [
                    _vm._v("Warnings"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "ul",
                    { staticClass: "warningsSection" },
                    _vm._l(_vm.environmentWarnings, function (warning) {
                      return _c("li", [_vm._v(_vm._s(warning))])
                    }),
                    0
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "outerContainer newReleaseContainer" }, [
              _c("div", { staticClass: "primaryHeading" }, [
                _vm._v("New Release"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "newReleases" }, [
                _c("div", { staticClass: "formInputRow" }, [
                  _c("div", { staticClass: "formInputLabel" }, [
                    _vm._v("Environment"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "formInputValue" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.newReleaseEnvironmentName,
                            expression: "newReleaseEnvironmentName",
                          },
                        ],
                        staticClass: "selectNewReleaseEnvironment",
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.newReleaseEnvironmentName = $event.target
                              .multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                        },
                      },
                      _vm._l(_vm.environments, function (env) {
                        return _c("option", { domProps: { value: env.Name } }, [
                          _vm._v(_vm._s(env.Name)),
                        ])
                      }),
                      0
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "formInputRow" }, [
                  _c("div", { staticClass: "formInputLabel" }, [
                    _vm._v("Name"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "formInputValue" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.newReleaseName,
                          expression: "newReleaseName",
                        },
                      ],
                      staticClass: "inputNewReleaseName",
                      attrs: { type: "text", placeholder: "New release name" },
                      domProps: { value: _vm.newReleaseName },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.newReleaseName = $event.target.value
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.newReleaseNameLength > 15
                      ? _c("span", { staticClass: "newReleaseNameWarning" }, [
                          _vm._v("Consider using a shorter name."),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.newReleaseNameContainsSpaces
                      ? _c("span", { staticClass: "newReleaseNameWarning" }, [
                          _vm._v(
                            "This will be a directory name on disk. Are you sure you want it to contain spaces?"
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "formInputRow" }, [
                  _c("div", { staticClass: "formInputLabel" }, [
                    _vm._v("Build"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "formInputValue" },
                    _vm._l(_vm.latestBuilds, function (build) {
                      return _c(
                        "div",
                        { key: build.Id, staticClass: "buildOptionRow" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "buildOptionLabel",
                              attrs: { title: build.Name },
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.newReleaseBuildId,
                                    expression: "newReleaseBuildId",
                                  },
                                ],
                                attrs: { type: "radio" },
                                domProps: {
                                  value: build.Id,
                                  checked: _vm._q(
                                    _vm.newReleaseBuildId,
                                    build.Id
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    _vm.newReleaseBuildId = build.Id
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "buildRow" }, [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t\t" +
                                    _vm._s(build.Name) +
                                    " " +
                                    _vm._s(
                                      _vm.truncateStr(build.Description, 256)
                                    ) +
                                    "\n\t\t\t\t\t\t\t\t"
                                ),
                              ]),
                            ]
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "formInputRow" }, [
                  _c("div", { staticClass: "formInputLabel" }, [
                    _vm._v("AppSettings From"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "formInputValue" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.newReleaseAppSettingsFrom,
                            expression: "newReleaseAppSettingsFrom",
                          },
                        ],
                        staticClass: "selectNewReleaseAppSettingsFrom",
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.newReleaseAppSettingsFrom = $event.target
                              .multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                        },
                      },
                      _vm._l(
                        _vm.newReleaseAppSettingsOptions,
                        function (option) {
                          return _c(
                            "option",
                            { domProps: { value: option.Value } },
                            [_vm._v(_vm._s(option.Name))]
                          )
                        }
                      ),
                      0
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "formInputRow" }, [
                  _c("div", { staticClass: "formInputLabel" }, [
                    _vm._v("Description"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "formInputValue" }, [
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.newReleaseDescription,
                          expression: "newReleaseDescription",
                        },
                      ],
                      staticClass: "inputNewReleaseDescription",
                      attrs: { type: "text" },
                      domProps: { value: _vm.newReleaseDescription },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.newReleaseDescription = $event.target.value
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "formInputRow",
                    staticStyle: { "margin-top": "1em" },
                  },
                  [
                    _c("input", {
                      attrs: {
                        type: "button",
                        disabled: _vm.newReleaseButtonDisabled,
                        value: "Create Release",
                      },
                      on: { click: _vm.createRelease },
                    }),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm.newReleaseError
              ? _c("div", { staticClass: "outerContainer errorsContainer" }, [
                  _c("div", { staticClass: "primaryHeading" }, [
                    _vm._v("New Release Error"),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n\t\t\t\t" + _vm._s(_vm.newReleaseError) + "\n\t\t\t"
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "outerContainer releaseStatusContainer" },
              [
                _c("div", { staticClass: "primaryHeading" }, [
                  _vm._v("\n\t\t\t\tRelease Engine Status "),
                  _c(
                    "div",
                    {
                      class: {
                        webSocketStatus: true,
                        connected: _vm.releaseStatusSocketIsConnected,
                        notConnected: !_vm.releaseStatusSocketIsConnected,
                      },
                      attrs: {
                        title: _vm.releaseStatusSocketIsConnected
                          ? "WebSocket is connected. Release Engine status is streaming into browser."
                          : "WebSocket is not connected!",
                      },
                    },
                    [
                      _c("vsvg", {
                        staticClass: "webSocketIcon",
                        attrs: { sprite: "cable" },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.releaseStatus ? _vm.releaseStatus : "     idle")
                  ),
                ]),
              ]
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }