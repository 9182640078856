<template>
	<div :class="{ pageRoot: true, inTrash: productRoot && productRoot.Trash }">
		<div v-if="error" class="error SidePadding">
			<h2>CMS Product Root {{productRootId}}</h2>
			<p>{{error}}</p>
		</div>
		<div v-else-if="loading" class="loading SidePadding">
			<h2>CMS Product Root {{productRootId}}</h2>
			<div class="center"><ScaleLoader /> Loading…</div>
		</div>
		<SavableRegion v-else-if="productRoot" :saving="saving">
			<div class="stickyTop">
				<div class="productRootBadge" title="This page represents a ProductRoot">Root</div>
				<h3><img :src="starImgProductRoot" alt="star" class="product-star" role="button" tabindex="0" @click="toggleStarProductRoot(productRoot)" @keypress.enter.space.prevent="toggleStarProductRoot(productRoot)" /><span v-html="productRoot.InternalNameHtml"></span></h3>
				<button class="SaveButton" :class="{ disabled: !hasChanges }" @click="saveProductRoot">Save</button>
				<div class="FloatingBelow" v-if="currentUsersHtml || recordChangedServerside || pageStateUnavailable">
					<vsvg v-if="pageStateUnavailable" sprite="cable" class="page_state_unavailable_img" title="This icon means the page state could not be loaded" />
					<span v-html="currentUsersHtml"></span>
					<div v-if="recordChangedServerside">Record Changed Serverside!</div>
				</div>
			</div>
			<div class="SidePadding">
				<div class="trashContainer" v-if="productRoot.Trash">
					<div class="trash"><vsvg sprite="delete" class="trash_img" /> This product is in the trash, making it inaccessible to customers.</div>
				</div>
				<div><a role="button" tabindex="0" @click="OpenCmsProductLinksDialog" @keypress.enter.space.prevent="OpenCmsProductLinksDialog" class="alwaysUnvisited">Taylor Product ID: {{productRoot.TaylorProductId}}</a></div>
				<div class="EditField" title="This product image is determined by the currently-published Revision.">
					<div class="EditFieldTitle">Product Image <a :href="productImgSrc" :download="'p' + productRootId.toString()" class="openImgLink" title="download image"><vsvg sprite="upload" class="download_img" /></a></div>
					<div class="EditFieldValue">
						<img :src="productImgSrc" alt="product image" class="ProductImage" />
					</div>
				</div>
				<CmsEditField v-model="productRoot.InternalNameHtml" :orig="orig.InternalNameHtml" name="Internal Product Name (Html Supported)" comment="©®™" />
				<CmsEditField v-model="productRoot.FileName" :orig="orig.FileName" name="Fx File Name" />
				<CmsEditField v-model="productRoot.ProductType" :orig="orig.ProductType" name="Product Type" kind="select" :options="productTypes" />
				<CmsEditField v-model="productRoot.PermissionLevel" :orig="orig.PermissionLevel" name="Minimum Permission Level" kind="select" :options="securityLevels" comment="(Permission level is primarily for Help titles. Most should be &quot;User&quot;)" />
				<div class="CmsConfigFields">
					<div class="CmsConfigFieldsLabel">Internal Configuration (ProductRoot)</div>
					<CmsEditField v-model="productRoot.CmsConfig.CategorizeAsOfflineTitle" :orig="orig.CmsConfig.CategorizeAsOfflineTitle" name="Categorize as Offline Title" kind="checkbox" title="If checked, this product gets categorized as an 'offline title' so that it does not appear in the 'Upcoming' or 'Titles at Rest' categories of the CMS homepage." />
					<CmsEditField v-model="productRoot.CmsConfig.Comment" :orig="orig.CmsConfig.Comment" name="Internal notes for this ProductRoot" title="A comment field for internal documentation or note-taking purposes.  For example, it could explain why the product was categorized as an offline title." kind="textarea" :multiLine="true" />
				</div>
				<div class="EditField">
					<div class="EditFieldTitle">Product Revisions</div>
					<div class="EditFieldValue">
						<template v-if="productRevisions.length">
							<CmsProductRevisionListItem v-for="rev in productRevisions" :key="rev.ProductRevisionId" :rev="rev" class="ProductRevision" />
						</template>
						<div v-else class="NoRevisions">
							[No Revisions Yet]
						</div>
						<div class="buttonBar">
							<button class="buttonBarButton" @click="onCreateRevision()" title="Create a new ProductRevision for this ProductRoot.">
								<img class="btnIconNoHover" :src="appPath + 'Images/add.png'" alt="" role="presentation" />
								<span>Create New Revision</span>
							</button>
						</div>
					</div>
				</div>
				<div class="buttonBar">
					<button class="buttonBarButton" @click="showHistory = !showHistory">
						<vsvg sprite="history" /> {{showHistory ? 'Hide' : 'Show'}} History
					</button>
				</div>
				<div class="historySelectorContainer">
					<CmsProductHistorySelector v-if="showHistory" type="ProductRoot" :id="productRootId" @select="HistoryItemSelected" />
				</div>
			</div>
		</SavableRegion>
	</div>
</template>

<script>
	import { GetCMSProductRoot, CreateCMSProductRevisionForProductRoot, UpdateCMSProductRoot } from 'appRoot/api/CMSUserData';
	import CmsEditField from 'appRoot/vues/client/controls/CmsEditField.vue';
	import SavableRegion from 'appRoot/vues/common/controls/SavableRegion.vue';
	import CmsLifecycleStateBadge from 'appRoot/vues/client/controls/CmsLifecycleStateBadge.vue';
	import svg1 from 'appRoot/images/sprite/upload.svg';
	import svg2 from 'appRoot/images/sprite/delete.svg';
	import svg3 from 'appRoot/images/sprite/cable.svg';
	import svg4 from 'appRoot/images/sprite/history.svg';
	import { ModalMergeConflictsDialog, CmsRevisionSelectorDialog, CmsProductLinksDialog } from 'appRoot/scripts/ModalDialog';
	import { HTMLToText, ApplyObjectPatches, ArrayToMap } from 'appRoot/scripts/Util';
	import PageStateMonitoringMixin from 'appRoot/scripts/PageStateMonitoringMixin';
	import AbandonChangesMixin from 'appRoot/scripts/AbandonChangesMixin';
	import CmsProductRevisionListItem from 'appRoot/vues/client/controls/CmsProductRevisionListItem.vue';
	import CmsProductHistorySelector from 'appRoot/vues/client/controls/CmsProductHistorySelector.vue';
	import StarredRowMixin from 'appRoot/scripts/StarredRowMixin.js';
	import EventBus from 'appRoot/scripts/EventBus';

	export default {
		components: { CmsEditField, SavableRegion, CmsLifecycleStateBadge, CmsProductRevisionListItem, CmsProductHistorySelector },
		mixins: [PageStateMonitoringMixin, AbandonChangesMixin, StarredRowMixin],
		props:
		{
			productRootId: {
				type: Number,
				required: true
			}
		},
		data()
		{
			return {
				appPath: appContext.appPath,
				productTypes: [],
				securityLevels: [],
				error: null,
				loading: false,
				saving: false,
				orig: {},
				originalProductRootJson: "",
				productRevisions: [],
				productRoot: null,
				showHistory: false
			};
		},
		created()
		{
			this.loadData();
		},
		computed:
		{
			productImgSrc()
			{
				return appContext.appPath + 'ProductImage/ByProductRootId/' + this.productRoot.ProductRootId;
			},
			hasChanges()
			{
				return !!(this.productRoot && this.originalProductRootJson !== JSON.stringify(this.productRoot));
			},
			recordChangedServerside()
			{
				return !this.saving && this.pageChangeState && this.productRoot && this.pageChangeState.ChangeId !== this.productRoot.ChangeId;
			},
			shouldReloadData()
			{
				return !!(this.recordChangedServerside && !this.hasChanges && !this.saving && !this.loading && !this.error);
			},
			star_ProductRootId()
			{
				return this.productRootId;
			},
			star_ProductRevisionIds()
			{
				return [];
			}
		},
		methods:
		{
			async loadData()
			{
				this.loading = true;
				this.error = null;
				try
				{
					let data = await GetCMSProductRoot(this.productRootId);
					if (data.success)
						this.processProductRootFromServer(data);
					else
						this.error = data.error;
				}
				catch (err)
				{
					this.error = err;
				}
				finally
				{
					this.loading = false;
				}
			},
			processProductRootFromServer(data)
			{
				this.productTypes = data.productTypes;
				this.securityLevels = data.securityLevels;
				this.productRoot = data.productRoot;
				this.originalProductRootJson = JSON.stringify(data.productRoot);
				this.orig = JSON.parse(this.originalProductRootJson);
				this.productRevisions = data.productRevisions;
				this.pageChangeState = null;
				EventBus.myStarredProductRootIds = ArrayToMap(data.myStarredProductRootIds, item => item, item => true);
				let revMap = ArrayToMap(this.productRevisions, item => item.ProductRevisionId, item => item);
				EventBus.myStarredProductRevisions = ArrayToMap(data.myStarredProductRevisionIds, item => item, item => revMap[item]);
				this.checkPageState();
			},
			GetRouteToProductRevision(rev)
			{
				return {
					name: "clientCMSProductRevision",
					params: { id: rev.ProductRevisionId }
				};
			},
			async onCreateRevision()
			{
				let result = await CmsRevisionSelectorDialog(this.productRevisions);
				if (result && typeof result.ProductRevisionId === "number")
				{
					this.loading = true;
					this.error = null;
					let data = await CreateCMSProductRevisionForProductRoot(this.productRootId, result.ProductRevisionId)
					try
					{
						if (data.success)
						{
							this.$router.push(this.GetRouteToProductRevision(data.productRevision));
						}
						else
						{
							toaster.error(data.error);
							this.loading = false;
						}
					}
					catch (err)
					{
						toaster.error(err);
						this.loading = false;
					}
				}
			},
			async saveProductRoot()
			{
				if (!this.hasChanges)
					return;
				this.saving = true;
				let data = await UpdateCMSProductRoot(JSON.parse(this.originalProductRootJson), this.productRoot)
				try
				{
					if (data.success)
						this.processProductRootFromServer(data);
					else
					{
						if (data.mergeConflicts)
						{
							let closeArgs = await ModalMergeConflictsDialog(data.mergeConflicts);
							this.ApplyMergeResults(closeArgs);
						}
						else
							toaster.error(data.error);
					}
				}
				catch (err)
				{
					toaster.error(err);
				}
				finally
				{
					this.saving = false;
				}
			},
			ApplyMergeResults({ mergeResults })
			{
				if (mergeResults)
				{
					ApplyObjectPatches(this, this.orig, this.productRoot, mergeResults);
					this.originalProductRootJson = JSON.stringify(this.orig);
					// It should be safe to load "their" changes into rows where "you" haven't changed anything, but that is a lot of extra complexity so it doesn't happen yet.
				}
			},
			OpenCmsProductLinksDialog()
			{
				CmsProductLinksDialog(this.productRoot, this.productRevision).then(result => { });
			},
			HistoryItemSelected(arg)
			{
				// This is brittle code, I'm aware, and I'm sorry.
				jset(arg.obj, this.productRoot, "CmsConfig");
				jset(arg.obj, this.productRoot, "FileName");
				jset(arg.obj, this.productRoot, "InternalNameHtml");
				jset(arg.obj, this.productRoot, "PermissionLevel");
				jset(arg.obj, this.productRoot, "ProductType");
			}
		},
		watch:
		{
			shouldReloadData()
			{
				if (this.shouldReloadData)
					this.loadData();
			}
		}
	};
	function jset(src, target, name)
	{
		target[name] = src[name];
	}
</script>

<style scoped>
	.pageRoot
	{
		box-sizing: border-box;
		overflow-x: clip;
	}

	.inTrash
	{
		color: #440000;
		background-color: #FFF6E1;
	}

	.trashContainer
	{
		margin-bottom: 1em;
	}

	.center
	{
		text-align: center;
	}

	h2
	{
		margin-top: 0px;
	}

	.trash
	{
		display: inline-flex;
		align-items: center;
		font-weight: bold;
		background-color: rgba(255,245,180,1);
		border: 1px solid rgba(200,165,0,1);
		border-radius: 3px;
		padding: 3px 13px 3px 8px;
		color: #AA0000;
	}

	.trash_img
	{
		flex: 1 0 auto;
		fill: currentColor;
		height: 2em;
		width: 2em;
		margin-right: 0.5em;
	}

	.SidePadding
	{
		padding: 0px 8px;
		padding-top: 1em;
	}

	.stickyTop
	{
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: sticky;
		background-color: rgba(235,255,255,1);
		z-index: 4; /* Slider Buttons otherwise overlap */
		top: 0px;
		padding: 2px 8px 4px 8px;
		border-bottom: 1px solid #000000;
		box-shadow: rgba(0,0,0,0.4) 0px 1px 1px 1px;
	}

		.stickyTop h3
		{
			margin: 0px;
			flex: 1 1 auto;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}

	.productRootBadge
	{
		display: inline-block;
		align-items: center;
		font-weight: bold;
		background-color: rgba(235,255,255,1);
		border: 1px solid rgba(113,208,208,1);
		border-radius: 3px;
		padding: 3px 8px;
		margin-right: 0.5em;
		color: #0000AA;
	}

	.SaveButton
	{
		display: inline-flex;
		align-items: center;
		border: 1px solid #888888;
		border-radius: 5px;
		height: 34px;
		box-sizing: border-box;
		padding: 0px 1em;
		font-size: 1.1em;
		cursor: pointer;
		background-color: #DDFFDD;
		box-shadow: rgba(0,0,0,0.4) 1px 1px 1px 1px;
		animation: SaveBackground 1s steps(2, end) infinite;
	}

	@keyframes SaveBackground
	{
		from
		{
			background-color: #DDFFDD;
		}

		to
		{
			background-color: #88FF88;
		}
	}

	.SaveButton:hover
	{
		background-color: #44FF44;
		animation: none;
	}

	.SaveButton.disabled
	{
		background-color: #DDDDDD !important;
		color: #999999;
		animation: none !important;
		cursor: inherit;
	}

	.ProductImage
	{
		max-width: 140px;
		height: 140px;
	}

	.download_img
	{
		fill: currentColor;
		height: 1em;
		width: 1em;
		vertical-align: text-bottom;
		transform: rotate(180deg);
	}

	.FloatingBelow
	{
		float: right;
		border: 1px solid #000000;
		border-radius: 5px;
		padding: 5px;
		background-color: #f3f3f3;
		font-size: 0.9em;
		font-family: Consolas, monospace;
		position: absolute;
		top: calc(100% + 10px);
		right: 8px;
		max-width: calc(100vw - 245px);
	}

	.page_state_unavailable_img
	{
		width: 17px;
		height: 17px;
		fill: #FF0000;
		vertical-align: bottom;
	}

	.CmsConfigFields
	{
		border: 1px solid #000000;
		border-radius: 4px;
		margin-top: 2.5em;
		padding: 0.75em 1em 0.33em 1em;
		/*background-color: #EEEEEE;*/
		position: relative;
		/*box-shadow: rgba(36,91,199,0.2) 0px 0px 10px 5px inset;*/
		box-shadow: rgba(99,99,99,0.2) 0px 0px 10px 5px inset;
	}

	.CmsConfigFieldsLabel
	{
		display: inline-block;
		position: absolute;
		top: -12px;
		background-color: #FFFFFF;
		border: 1px solid #000000;
		padding: 1px 7px;
		font-size: 1.2em;
		font-weight: bold;
		/*cursor: pointer;*/
	}

	.NoRevisions
	{
		display: block;
		padding: 3px 0px;
		cursor: pointer;
		color: #000000;
		text-decoration: none;
	}

	.NoRevisions
	{
		cursor: default;
		opacity: 0.48;
	}

	.historySelectorContainer
	{
		margin-top: 1em;
	}
</style>