<template>
	<div class="CmsConsoleDeployment">
		<table class="deployTbl" v-if="contentSyncStatus">
			<thead>
				<tr>
					<th>Agent</th>
					<th title="This title is being uploaded">Upload</th>
					<th title="This title is being installed">Install</th>
					<th title="This title's DB metadata is being synced">Sync</th>
					<th title="Queue Size (Upload / Install)">Q</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="agent in contentSyncStatus.agents" :key="'contentSyncStatus_' + agent.name"
					:class="{ contentSyncAgentRow: true, connected: agent.connected, disconnected: !agent.connected  }"
					v-if="agent.CMSRole !== 'None'">
					<td><router-link :to="getAgentStatusRoute(agent)" :style="{ color: agent.color }">{{agent.name}}</router-link></td>
					<td>
						<CustomProgress class="CustomProgress"
										:value="agent.fileSync ? agent.fileSync.Progress : 0"
										:color="agent.color"
										:borderColor="agent.fileSync ? agent.color : 'transparent'"
										:title="getAgentUploadTooltip(agent)"
										:text="getAgentUploadText(agent)" />
					</td>
					<td>
						<CustomProgress class="CustomProgress"
										:value="agent.installationProgressPercent ? agent.installationProgressPercent : 0"
										:color="agent.color"
										:borderColor="agent.installStatus ? agent.color : 'transparent'"
										:title="getAgentInstallationTooltip(agent)"
										:text="agent.installAcronym" />
					</td>
					<td>{{agent.syncAcronym}}</td>
					<td>{{agent.connected ? agent.queueSizes : 'D/C'}}</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
	import EventBus from 'appRoot/scripts/EventBus';
	import CustomProgress from 'appRoot/vues/common/controls/CustomProgress.vue';


	export default {
		components: { CustomProgress },
		props:
		{
		},
		data()
		{
			return {
			};
		},
		created()
		{
		},
		destroyed()
		{
		},
		mounted()
		{
		},
		computed:
		{
			contentSyncStatus()
			{
				return EventBus.contentSyncStatus; // { agents: Array }
			}
		},
		methods:
		{
			getAgentStatusRoute(agent)
			{
				return {
					name: "clientCMSAgentStatus",
					params: { id: agent.agentId },
					query: { name: agent.name }
				};
			},
			getAgentUploadTooltip(agent)
			{
				if (agent.fileSync)
					return agent.fileSync.Progress + '% ' + agent.fileSync.FilePath + ': ' + agent.fileSync.FileSizeStr + ' @ ' + agent.fileSync.BitsPerSecondStr + ' (ETA: ' + agent.fileSync.RemainingTime + ')';
				return "";
			},
			getAgentUploadText(agent)
			{
				if (agent.fileSync && agent.fileSync.FilePath)
				{
					let path = agent.fileSync.FilePath;
					let idxSlash = path.lastIndexOf('/');
					if (idxSlash > -1)
						path = path.substr(idxSlash + 1);
					path = path.replace(/(.*?)-\d+\.zip/gi, "$1");
					return path;
				}
				return "";
			},
			getAgentInstallationTooltip(agent)
			{
				if (agent.installStatus)
					return agent.installationProgressPercent + '% ' + agent.installAcronym + ': ' + agent.installStatus;
				return "";
			},
		},
		watch:
		{
		}
	}
</script>

<style scoped>
	.deployTbl
	{
		border-collapse: collapse;
	}

		.deployTbl th
		{
			border: 1px solid #006600;
		}

		.deployTbl tr.disconnected
		{
			color: #DD6666;
		}

		.deployTbl td
		{
			border: 1px solid #006600;
			padding: 1px 2px;
		}

			.deployTbl td:nth-child(1)
			{
				max-width: 100px;
				white-space: nowrap;
				overflow-x: hidden;
				text-overflow: clip;
			}

			.deployTbl td:nth-child(4)
			{
				width: 100px;
				white-space: nowrap;
				overflow-x: hidden;
				text-overflow: clip;
			}

			.deployTbl td:nth-child(5)
			{
				width: 31px;
				white-space: nowrap;
				overflow-x: hidden;
				text-overflow: clip;
				text-decoration: none;
			}

		.deployTbl tr.disconnected td:nth-child(1)
		{
			text-decoration: line-through;
		}

	.CustomProgress
	{
		width: 100px;
	}
</style>