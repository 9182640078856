var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: { pageRoot: true } },
    [
      _c("h2", [_vm._v("CMS - Taylor DB Data Sync")]),
      _vm._v(" "),
      _vm.error
        ? _c("div", { staticClass: "error" }, [_vm._v(_vm._s(_vm.error))])
        : _vm.loading
        ? _c(
            "div",
            { staticClass: "loading" },
            [_c("ScaleLoader"), _vm._v(" Loading…")],
            1
          )
        : _vm.syncData
        ? [
            _c("p", [
              _vm._v(
                "The following lists are stored in the Taylor database, and are periodically synchronized to the CMS."
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "section" }, [
              _c("h3", [_vm._v("Security Levels")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "list" },
                _vm._l(_vm.syncData.securityLevels, function (str) {
                  return _c("div", { key: str, staticClass: "listItem" }, [
                    _c("span", { staticClass: "listItemText" }, [
                      _vm._v(_vm._s(str)),
                    ]),
                  ])
                }),
                0
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "section" }, [
              _c("h3", [_vm._v("Bibliography Types")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "list" },
                _vm._l(_vm.syncData.bibliographyTypes, function (str) {
                  return _c("div", { key: str, staticClass: "listItem" }, [
                    _c("span", { staticClass: "listItemText" }, [
                      _vm._v(_vm._s(str)),
                    ]),
                  ])
                }),
                0
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "section" }, [
              _c("h3", [_vm._v("Simple Publisher Options")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "list" },
                _vm._l(_vm.syncData.publisherNames, function (str) {
                  return _c(
                    "div",
                    {
                      key: str,
                      staticClass: "listItem",
                      class: { noRefs: _vm.refCount(str) === 0 },
                    },
                    [
                      _c("span", { staticClass: "listItemText" }, [
                        _vm._v(_vm._s(str)),
                      ]),
                      _vm._v(" "),
                      _vm.syncData.publisherReferenceCounts &&
                      typeof _vm.syncData.publisherReferenceCounts[str] !==
                        "undefined"
                        ? _c(
                            "span",
                            {
                              attrs: {
                                title:
                                  "Number of Products that referenced this publisher type as of the last data sync.",
                              },
                            },
                            [_vm._v(" (" + _vm._s(_vm.refCount(str)) + ")")]
                          )
                        : _vm._e(),
                    ]
                  )
                }),
                0
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "buttonBar" }, [
              _c(
                "button",
                {
                  staticClass: "buttonBarButton",
                  attrs: {
                    title:
                      "Adds a new string to the PublisherTypeTbl table in the Taylor DB",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.addNewSimplePublisher()
                    },
                  },
                },
                [
                  _c("vsvg", { attrs: { sprite: "add" } }),
                  _vm._v(" "),
                  _c("span", [_vm._v("Add New Simple Publisher")]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "buttonBarButton",
                  attrs: {
                    title:
                      "Deletes a string from the PublisherTypeTbl table in the Taylor DB. Requires the publisher to not be referenced by any products.",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.deleteSimplePublisher()
                    },
                  },
                },
                [
                  _c("vsvg", { attrs: { sprite: "delete_forever" } }),
                  _vm._v(" "),
                  _c("span", [_vm._v("Delete a Simple Publisher")]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "buttonBarButton",
                  attrs: {
                    title:
                      "Gets new Bibliography Types, Publisher Names, Permission Levels, Product Trash states, etc.  This operation occurs automatically on a 10 minute interval, but this button will trigger it manually.",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.cmsSyncNow()
                    },
                  },
                },
                [
                  _c("vsvg", { attrs: { sprite: "update" } }),
                  _vm._v(" "),
                  _c("span", [_vm._v("Sync from Taylor DB")]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "buttonBarButton",
                  on: {
                    click: function ($event) {
                      return _vm.forceFullSync()
                    },
                  },
                },
                [
                  _c("vsvg", { attrs: { sprite: "update" } }),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      "Force Full Metadata Sync outside normal schedule (~midnight)"
                    ),
                  ]),
                ],
                1
              ),
            ]),
          ]
        : _c("div", [
            _vm._v("\n\t\tCMS data loading failed for an unknown reason.\n\t"),
          ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }