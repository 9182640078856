<template>
	<nav id="sideNav" class="clientNav">
		<router-link :to="{ name: 'clientStatus' }">Status</router-link>
		<router-link v-if="permissionCMS" :to="{ name: 'clientCMS' }">CMS</router-link>
		<router-link v-if="permissionRelease" :to="{ name: 'clientRelease' }">Release</router-link>
		<router-link :to="{ name: 'clientGraphite' }">Graphite</router-link>
		<!--<router-link :to="{ name: 'clientServerInfo', query: { sn: 'Web5'} }">Web5</router-link>
		<router-link :to="{ name: 'clientServerInfo', query: { sn: 'Web6'} }">Web6</router-link>
		<router-link :to="{ name: 'clientServerInfo', query: { sn: 'Db5'} }">Db5</router-link>-->
		<router-link :to="{ name: 'clientNotificationsSetup' }">Notify</router-link>
		<router-link :to="{ name: 'clientManage' }">Account</router-link>
		<router-link v-if="isAdmin" :to="{ name: 'adminHome' }">Admin</router-link>
	</nav>
</template>

<script>

	export default {
		data()
		{
			return {
			};
		},
		computed:
		{
			isAdmin()
			{
				return this.$store.state.userData.IsAdmin;
			},
			permissionCMS()
			{
				return this.$store.state.userData.CMSReadAccess;
			},
			permissionRelease()
			{
				return this.$store.state.userData.ReleaseReadAccess;
			}
		},
		methods:
		{
		}
	}
</script>

<style scoped>
	#sideNav
	{
		flex: 0 0 auto;
		display: flex;
		flex-direction: column;
		width: 100px;
		background-color: #D0D0D0;
		border-right: 1px solid #AAAAAA;
		box-sizing: border-box;
		overflow-x: clip;
		overflow-y: auto;
	}

		#sideNav > *
		{
			padding: 10px 2px;
			cursor: pointer;
			font-size: 16px;
			color: #000000;
			text-decoration: none;
			text-align: center;
			border-bottom: 1px solid #AAAAAA;
			border-right: 1px solid #AAAAAA;
			background-color: #E8E8E8;
			margin-right: -1px;
		}

			#sideNav > *:hover
			{
				background-color: #F2F2F2;
			}

		#sideNav > .router-link-active
		{
			background-color: #FFFFFF;
			border-right: none;
		}

		#sideNav::-webkit-scrollbar
		{
			width: 5px;
		}

		#sideNav::-webkit-scrollbar-thumb
		{
			background: #888888;
			border-radius: 2.5px;
		}

			#sideNav::-webkit-scrollbar-thumb:hover
			{
				background: #666666;
			}
</style>