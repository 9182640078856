<template>
	<div class="LifecycleStateDialog" role="alertdialog" aria-labelledby="lifecycleStateTitle">
		<div class="titleBar">
			<div class="title" id="lifecycleStateTitle" role="alert">Lifecycle State</div>
		</div>
		<div class="dialogBody">
			<div class="revisionName" v-html="productRevision.RevisionNameHtml"></div>
			<CmsLifecycleStateBadge :text="productRevision.LifecycleState" />
			<template v-if="productRevision.LifecycleState === 'Upcoming'">
				<div class="Message" v-if="!productRevision.ArchiveFile">
					Staging will be available after an Fx/Media Archive is assigned.
				</div>
				<div class="Message" v-if="!productRevision.ArchiveFile">
					See the <b>Fx/Media</b> box outside this dialog for Archival options.
				</div>
				<div class="SliderContainer" v-else>
					<slide-button ref="slideBtn1" key="sb1" :auto-width="false" :circle="true" :disabled="false"
								  :noanimate="false" :width="256" :height="44" :requireReleaseToComplete="true"
								  text="stage" success-text="staging…" @completed="onStageCommanded()" />
				</div>
			</template>
			<template v-else-if="productRevision.LifecycleState === 'Staging'">
				<div v-if="loadingAgentStatus" class="Message"><ScaleLoader /> Loading Agent Status…</div>
				<div v-else-if="errorLoadingAgentStatus" class="Message">
					<vsvg sprite="warning" class="warningIcon" /> {{errorLoadingAgentStatus}}
				</div>
				<template v-else>
					<div class="Message" v-if="agentNamesNotSynced && agentNamesNotSynced.length" title="These agents from the 'Online' environment are not fully staged yet and will be unable to immediately install the product revision.">
						<vsvg sprite="warning" class="warningIcon" />
						Not Fully Staged On:<br /><b>{{agentNamesNotSynced.join(', ')}}</b>
					</div>
					<div class="Message" v-if="agentNamesNotConnected && agentNamesNotConnected.length" title="These agents from the 'Online' environment are not connected and will be unable to immediately install the product revision.">
						<vsvg sprite="warning" class="warningIcon" />
						Agents Not Connected:<br /><b>{{agentNamesNotConnected.join(', ')}}</b>
					</div>
					<div class="SliderContainer">
						<slide-button ref="slideBtn2" key="sb2" :auto-width="false" :circle="true" :disabled="false"
									  :noanimate="false" :width="256" :height="44" :requireReleaseToComplete="true"
									  text="live" success-text="going live…" @completed="onLiveCommanded()" />
					</div>
					<div class="SliderContainer">
						<slide-button ref="slideBtn3" key="sb3" :auto-width="false" :circle="true" :disabled="false"
									  :noanimate="false" :width="256" :height="44" :requireReleaseToComplete="true"
									  text="deactivate" success-text="deactivating…" @completed="onInactiveCommanded()" />
					</div>
				</template>
			</template>
			<template v-else-if="productRevision.LifecycleState === 'Live'">
				<div class="SliderContainer">
					<slide-button ref="slideBtn4" key="sb4" :auto-width="false" :circle="true" :disabled="false"
								  :noanimate="false" :width="256" :height="44" :requireReleaseToComplete="true"
								  text="deactivate" success-text="deactivating…" @completed="onInactiveCommanded()" />
				</div>
			</template>
			<template v-else-if="productRevision.LifecycleState === 'Inactive'">
				<div class="SliderContainer">
					<slide-button ref="slideBtn5" key="sb5" :auto-width="false" :circle="true" :disabled="false"
								  :noanimate="false" :width="256" :height="44" :requireReleaseToComplete="true"
								  text="stage" success-text="staging…" @completed="onStageCommanded()" />
				</div>
			</template>
		</div>
	</div>
</template>
<script>
	import { GetOnlineAgentsNotStaged } from 'appRoot/api/CMSUserData';
	import CmsLifecycleStateBadge from 'appRoot/vues/client/controls/CmsLifecycleStateBadge.vue';
	import SlideButton from 'appRoot/vues/common/controls/SlideButton.vue';
	import { ModalConfirmDialog, TextInputDialog } from 'appRoot/scripts/ModalDialog';
	import { HTMLToText } from 'appRoot/scripts/Util';
	import svg1 from 'appRoot/images/sprite/warning.svg';

	export default {
		components: { CmsLifecycleStateBadge, SlideButton },
		props:
		{
			productRevision: {
				type: Object,
				required: true
			}
		},
		data()
		{
			return {
				loadingAgentStatus: false,
				errorLoadingAgentStatus: null,
				agentNamesNotSynced: null,
				agentNamesNotConnected: null
			};
		},
		created()
		{
			if (this.productRevision.LifecycleState === "Staging")
				this.loadAgentStatus();
		},
		mounted()
		{
		},
		computed:
		{
		},
		methods:
		{
			SetFocus()
			{
			},
			DefaultClose()
			{
				this.$emit('close', {});
			},
			async loadAgentStatus()
			{
				if (this.loadingAgentStatus)
					return;
				this.loadingAgentStatus = true;
				this.errorLoadingAgentStatus = null;
				this.agentNamesNotSynced = null;
				this.agentNamesNotConnected = null;
				try
				{
					let result = await GetOnlineAgentsNotStaged(this.productRevision.ProductRevisionId);
					if (result.success)
					{
						this.agentNamesNotSynced = result.agentNames;
						this.agentNamesNotConnected = result.agentNamesNotConnected;
					}
					else
						this.errorLoadingAgentStatus = result.error;
				}
				catch (ex)
				{
					this.errorLoadingAgentStatus = ex;
				}
				finally
				{
					this.loadingAgentStatus = false;
				}

			},
			onStageCommanded()
			{
				this.$emit("close", { newState: "Staging" });
			},
			async onLiveCommanded()
			{
				if ((this.agentNamesNotSynced && this.agentNamesNotSynced.length)
					|| (this.agentNamesNotConnected && this.agentNamesNotConnected.length))
				{
					let result = await ModalConfirmDialog('Some Agents in the \"Online\" environment are not ready to install the revision.\n\n'
						+ 'If you proceed now, those agents may be late to install this revision, and some customers may have a degraded experience.', "Confirm", "Set Live", "Cancel");
					if (result)
						this.$emit("close", { newState: "Live" });
					else
						this.resetSlideButtons();
				}
				else
					this.$emit("close", { newState: "Live" });
			},
			async onInactiveCommanded()
			{
				if (this.productRevision.LifecycleState === "Live")
				{
					let result = await TextInputDialog("Confirm Deactivation", "You are about to deactivate the LIVE revision "
						+ this.productRevision.ProductRevisionId + ": \""
						+ HTMLToText(this.productRevision.RevisionNameHtml) + "\".\n\n"
						+ "If you proceed, this product will not have a \"Live\" revision anymore.\n\n"
						+ "Type this revision's ID to confirm:", "Revision ID");
					if (result)
					{
						if (result.value === this.productRevision.ProductRevisionId.toString())
						{
							this.$emit("close", { newState: "Inactive" });
						}
						else
						{
							toaster.info("User-entered Product Revision ID did not match.");
							this.resetSlideButtons();
						}
					}
					else
						this.resetSlideButtons();
				}
				else
				{
					let result = await ModalConfirmDialog("You are about to deactivate revision " + HTMLToText(this.productRevision.RevisionNameHtml), "Confirm Deactivation", "Confirm", "Cancel");
					if (result)
					{
						this.$emit("close", { newState: "Inactive" });
					}
					else
						this.resetSlideButtons();
				}
			},
			resetSlideButtons()
			{
				this.resetSlideButton(this.$refs.slideBtn1);
				this.resetSlideButton(this.$refs.slideBtn2);
				this.resetSlideButton(this.$refs.slideBtn3);
				this.resetSlideButton(this.$refs.slideBtn4);
				this.resetSlideButton(this.$refs.slideBtn5);
			},
			resetSlideButton(slideBtn)
			{
				if (slideBtn)
				{
					if (typeof slideBtn.reset === "function")
						slideBtn.reset();
				}
			}
		},
		watch:
		{
		}
	}
</script>
<style scoped>
	.LifecycleStateDialog
	{
		background-color: #FFFFFF;
		box-shadow: rgba(0,0,0,0.7) 2px 9px 20px 7px;
		min-width: 300px;
		max-width: calc(100vw - 50px);
		word-break: break-word;
		word-wrap: break-word;
	}

	.titleBar
	{
		background-color: #FFFFFF;
		padding: 8px 14px;
		box-sizing: border-box;
	}

	.title
	{
		text-align: center;
		color: black;
		font-weight: bold;
		font-size: 16pt;
	}

	.revisionName
	{
		margin-bottom: 1em;
		text-align: left;
	}

	.dialogBody
	{
		padding: 4px 16px 16px 16px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.SliderContainer,
	.Message
	{
		margin-top: 1em;
	}

	.warningIcon
	{
		width: 36px;
		height: 36px;
		fill: #FF0000;
		float: left;
		margin-right: 8px;
	}
</style>
