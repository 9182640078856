var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "LoECreatingPopupRoot" },
    [
      _c("div", { staticClass: "heading" }, [
        _vm._v("LoE Creation (fxid " + _vm._s(_vm.fxid) + ")"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "imgContainer" }, [
        _c("img", {
          staticClass: "book_copy",
          attrs: {
            src: _vm.appPath + "Images/book_copy.webp",
            alt: "",
            role: "presentation",
          },
        }),
        _vm._v(" "),
        _c("img", {
          staticClass: "product_img",
          attrs: { src: _vm.imageUrl, alt: "", role: "presentation" },
        }),
      ]),
      _vm._v(" "),
      _vm.loading
        ? _c(
            "div",
            { staticClass: "loading" },
            [_c("ScaleLoader"), _vm._v(" Loading…\n\t")],
            1
          )
        : _vm.error
        ? _c("div", { staticClass: "error" }, [
            _vm._v("\n\t\t" + _vm._s(_vm.error) + "\n\t"),
          ])
        : _vm.loeData
        ? [
            !_vm.creating
              ? [
                  _c("div", [
                    _vm._v(
                      'When you click "Create", the following procedure is executed:'
                    ),
                  ]),
                  _vm._v(" "),
                  _c("ol", [
                    _vm.loeData.rootLoE
                      ? _c(
                          "li",
                          [
                            _vm._v(
                              "\n\t\t\t\t\tThe LoE product already exists:\n\t\t\t\t\t"
                            ),
                            _c("router-link", {
                              attrs: {
                                to: _vm.GetRouteToProductRoot(
                                  _vm.loeData.rootLoE.ProductRootId
                                ),
                                target: "_blank",
                              },
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.loeData.rootLoE.InternalNameHtml +
                                    " [fxid " +
                                    _vm.loeData.rootLoE.TaylorProductId +
                                    "]"
                                ),
                              },
                            }),
                          ],
                          1
                        )
                      : _c("li", [
                          _vm._v(
                            "Fxid " +
                              _vm._s(_vm.fxid) +
                              " is copied to create a new product: "
                          ),
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(_vm.loeData.loeNameHtml),
                            },
                          }),
                          _vm._v("."),
                        ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("b", [_vm._v(_vm._s(_vm.loeData.originalAcronym))]),
                      _vm._v(" files will be copied to "),
                      _c("b", [_vm._v(_vm._s(_vm.loeData.loeAcronym))]),
                      _vm._v(
                        " on the production server.  The CMS's stored archive files are not used for this copy."
                      ),
                    ]),
                    _vm._v(" "),
                    _vm._m(0),
                    _vm._v(" "),
                    _vm._m(1),
                  ]),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass: "overwriteLabel",
                      attrs: {
                        title:
                          "If you've already created the LoE for this edition before, you will need to check this box in order to allow the CMS to modify files on the production server.",
                      },
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.overwrite,
                            expression: "overwrite",
                          },
                        ],
                        attrs: { type: "checkbox", disabled: _vm.creating },
                        domProps: {
                          checked: Array.isArray(_vm.overwrite)
                            ? _vm._i(_vm.overwrite, null) > -1
                            : _vm.overwrite,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.overwrite,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 && (_vm.overwrite = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.overwrite = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.overwrite = $$c
                            }
                          },
                        },
                      }),
                      _vm._v(" Allow overwriting files for "),
                      _c("b", [_vm._v(_vm._s(_vm.loeData.loeAcronym))]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "buttonBar" }, [
                    _c(
                      "button",
                      {
                        staticClass: "buttonBarButton green",
                        attrs: { disabled: _vm.creating },
                        on: { click: _vm.create },
                      },
                      [_vm._v("Create")]
                    ),
                  ]),
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.creating
              ? _c("img", {
                  staticClass: "progress_img",
                  attrs: {
                    src: _vm.appPath + "Images/ajax-loader-c.gif",
                    alt: "",
                    role: "presentation",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.creating
              ? _c("p", [
                  _vm._v(
                    "Please keep this window open.  If you leave, you won't be notified upon success or error."
                  ),
                ])
              : _vm._e(),
          ]
        : _c("div", [
            _vm._v("\n\t\tLoE Data failed to load from the CMS server.\n\t"),
          ]),
      _vm._v(" "),
      !_vm.creating
        ? _c(
            "button",
            {
              ref: "cancelBtn",
              staticClass: "buttonBarButton",
              attrs: { disabled: _vm.creating },
              on: { click: _vm.cancel },
            },
            [_vm._v("Cancel")]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _vm._v("The "),
      _c("b", [_vm._v("Product Revision")]),
      _vm._v(
        " visible behind this dialog will be copied and added to the LoE Product, its Fx/Media archive will begin building, and if this succeeds, the new revision will begin Staging automatically."
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _vm._v("This browser window will be "),
      _c("b", [_vm._v("redirected")]),
      _vm._v(" to the new Product Revision."),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }