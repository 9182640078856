<template>
	<div class="LifecycleStateContainer" role="button" tabindex="0" @click.prevent="showPopout()" @keypress.enter.space.prevent="showPopout()" :title="'Current lifecycle state: ' + productRevision.LifecycleState">
		<div class="BadgeContainer" v-for="state in ['Upcoming', 'Staging', 'Live', 'Inactive']" :key="'badge_' + state">
			<CmsLifecycleStateBadge :text="state" :disabled="state !== productRevision.LifecycleState" />
		</div>
	</div>
</template>

<script>
	import CmsLifecycleStateBadge from 'appRoot/vues/client/controls/CmsLifecycleStateBadge.vue';
	import { CmsLifecycleStateDialog } from 'appRoot/scripts/ModalDialog';

	export default {
		components: { CmsLifecycleStateBadge },
		props:
		{
			productRevision: {
				type: Object,
				required: true
			}
		},
		data()
		{
			return {
			};
		},
		created()
		{
		},
		mounted()
		{
		},
		computed:
		{
		},
		methods:
		{
			async showPopout()
			{
				let result = await CmsLifecycleStateDialog(this.productRevision);
				if (result && result.newState)
					this.$emit("change", { newState: result.newState });
			}
		},
		watch:
		{
		}
	}
</script>

<style scoped>
	.LifecycleStateContainer
	{
		width: calc(50vw - 74px);
		cursor: pointer;
		user-select: none;
	}

	.BadgeContainer
	{
		display: inline-block;
		margin: 3px 0px;
	}

		.BadgeContainer::before
		{
			margin: 0px 4px;
			content: '🡆';
		}

		.BadgeContainer:first-child::before
		{
			display: none;
		}
</style>