<template>
	<div class="pageRoot">
		<div class="heading">Login History for UserId {{userId}}</div>
		<div class="bodySection">
			<LoginRecordsTable :userId="userId" />
		</div>
		<div class="heading">User Activity Logs for UserId {{userId}}</div>
		<div class="bodySection">
			<UserActivityTable :userId="userId" />
		</div>
	</div>
</template>

<script>
	import LoginRecordsTable from 'appRoot/vues/common/loginrecords/LoginRecordsTable.vue';
	import UserActivityTable from 'appRoot/vues/common/useractivity/UserActivityTable.vue';

	export default {
		components: { LoginRecordsTable, UserActivityTable },
		props:
		{
			userId: {
				type: Number,
				default: 0
			}
		}
	}
</script>

<style scoped>
	.pageRoot
	{
		margin: 8px;
	}

	.heading
	{
		font-size: 20px;
		border-bottom: 1px solid #000000;
		margin-bottom: 10px;
		margin-top: 30px;
		max-width: 400px;
	}

		.heading:first-child
		{
			margin-top: 0px;
		}

	.bodySection
	{
		margin: 0px 20px;
	}
</style>