var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pageRoot" }, [
    _c("div", { staticClass: "heading" }, [
      _vm._v("Login History for UserId " + _vm._s(_vm.userId)),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "bodySection" },
      [_c("LoginRecordsTable", { attrs: { userId: _vm.userId } })],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "heading" }, [
      _vm._v("User Activity Logs for UserId " + _vm._s(_vm.userId)),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "bodySection" },
      [_c("UserActivityTable", { attrs: { userId: _vm.userId } })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }