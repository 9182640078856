<template>
	<div class="TextFileLoadBackupDialog" role="alertdialog" aria-labelledby="TextFileLoadBackupTitle">
		<div class="titleBar">
			<div class="title" id="TextFileLoadBackupTitle" role="alert">Load Backup</div>
		</div>
		<div class="dialogBody">
			<div class="backupList">
				<div class="backupListItem">
					<button class="loadBackupBtn" @click="loadBackup(null)">
						Current Version
					</button>
				</div>
				<div class="backupListItem" v-for="date in backupDates" :key="date">
					<button class="loadBackupBtn" @click="loadBackup(date)">
						{{new Date(date).toString()}}
					</button>
				</div>
			</div>
		</div>
		<div class="controls">
			<div ref="btnCancel" role="button" class="dialogButton cencelButton" tabindex="0" @click="$emit('close', false)" @keydown.space.enter.prevent="$emit('close', false)" @keydown.left.prevent="FocusYes">
				Cancel
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		props:
		{
			backupDates: {
				type: Array,
				required: true
			}
		},
		methods:
		{
			SetFocus()
			{
				if (this.$refs.btnCancel)
					this.$refs.btnCancel.focus();
			},
			DefaultClose()
			{
				this.$emit('close', {});
			},
			loadBackup(date)
			{
				this.$emit('close', { date });
			},
		},
	}
</script>
<style scoped>
	.TextFileLoadBackupDialog
	{
		background-color: #FFFFFF;
		box-shadow: rgba(0,0,0,0.7) 2px 9px 20px 7px;
		min-width: 300px;
		max-width: calc(100vw - 50px);
		word-break: break-word;
		word-wrap: break-word;
	}

	.titleBar
	{
		background-color: #FFFFFF;
		padding: 8px 14px;
		box-sizing: border-box;
	}

	.title
	{
		text-align: center;
		color: black;
		font-weight: bold;
		font-size: 16pt;
	}

	.dialogBody
	{
		padding: 4px 16px 16px 16px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.loadBackupBtn
	{
		margin-bottom: 0.25em;
		background-color: transparent;
		border: none;
		color: var(--link-color);
		cursor: pointer;
		text-decoration: underline;
	}

	.controls
	{
		display: flex;
	}

	.dialogButton
	{
		display: inline-block;
		cursor: pointer;
		color: black;
		font-weight: bold;
		font-size: 12pt;
		box-sizing: border-box;
		position: relative;
		padding: 12px 5px;
		flex: 1 0 auto;
		text-align: center;
	}

		.dialogButton:hover
		{
			background-color: rgba(0,0,0,0.05);
		}

	.cancelButton
	{
		color: #CE7D29;
		border-left: 1px solid #DDDDDD;
	}
</style>
