var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "BibliographyEditor" }, [
    _c(
      "table",
      [
        _c(
          "draggable",
          {
            attrs: { tag: "tbody", handle: ".drag_handle" },
            on: { change: _vm.dragged },
            model: {
              value: _vm.rev.Bibliography,
              callback: function ($$v) {
                _vm.$set(_vm.rev, "Bibliography", $$v)
              },
              expression: "rev.Bibliography",
            },
          },
          _vm._l(_vm.rev.Bibliography, function (b, index) {
            return _c(
              "tr",
              {
                key: index,
                class: { delete: b.delete, newRow: b.newRow, shake: b.shake },
              },
              [
                _c("td", [
                  b.Type !== b.originalType ||
                  b.Value !== b.originalValue ||
                  b.delete
                    ? _c(
                        "button",
                        {
                          staticClass: "undoButton btnIcon",
                          attrs: {
                            role: "button",
                            tabindex: "0",
                            title: "undo changes to this row",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onUndo(b)
                            },
                          },
                        },
                        [_c("vsvg", { attrs: { sprite: "replay" } })],
                        1
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    class: {
                      drag_handle: true,
                      moved: b.index !== b.originalIndex,
                    },
                  },
                  [_c("vsvg", { attrs: { sprite: "drag_indicator" } })],
                  1
                ),
                _vm._v(" "),
                _c("td", { class: { changed: b.Type !== b.originalType } }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: b.Type,
                          expression: "b.Type",
                        },
                      ],
                      staticClass: "bTypeSelect",
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            b,
                            "Type",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    _vm._l(_vm.allBibliographyTypes, function (bType) {
                      return _c("option", { key: bType }, [
                        _vm._v(_vm._s(bType)),
                      ])
                    }),
                    0
                  ),
                ]),
                _vm._v(" "),
                _c("Contenteditable", {
                  ref: "values",
                  refInFor: true,
                  class: { changed: b.Value !== b.originalValue },
                  attrs: { tag: "td", "no-html": true, "no-nl": true },
                  on: {
                    returned: function ($event) {
                      return _vm.onEnterPressed(index)
                    },
                  },
                  model: {
                    value: b.Value,
                    callback: function ($$v) {
                      _vm.$set(b, "Value", $$v)
                    },
                    expression: "b.Value",
                  },
                }),
                _vm._v(" "),
                _c("td", [
                  _c("img", {
                    staticClass: "btnIcon",
                    attrs: {
                      src: _vm.appPath + "Images/delete.png",
                      alt: "delete",
                      role: "button",
                      tabindex: "0",
                      title: "mark/unmark this row for deletion",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.onDelete(b)
                      },
                      keypress: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k($event.keyCode, "space", 32, $event.key, [
                            " ",
                            "Spacebar",
                          ]) &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        $event.preventDefault()
                        return _vm.onDelete(b)
                      },
                    },
                  }),
                ]),
              ],
              1
            )
          }),
          0
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "buttonBar" }, [
      _c(
        "button",
        {
          staticClass: "buttonBarButton",
          attrs: { title: "add a row to the bibliography table" },
          on: {
            click: function ($event) {
              return _vm.onAdd()
            },
          },
        },
        [
          _c("img", {
            staticClass: "btnIconNoHover",
            attrs: {
              src: _vm.appPath + "Images/add.png",
              alt: "",
              role: "presentation",
            },
          }),
          _vm._v(" "),
          _c("span", [_vm._v("Add Row")]),
        ]
      ),
      _vm._v(" "),
      _vm.rowOrderChanged
        ? _c(
            "button",
            {
              staticClass: "buttonBarButton",
              attrs: { title: "reset row order" },
              on: {
                click: function ($event) {
                  return _vm.sortReset()
                },
              },
            },
            [
              _c("img", {
                staticClass: "btnIconNoHover",
                attrs: {
                  src: _vm.appPath + "Images/nav_undo_blue.png",
                  alt: "",
                  role: "presentation",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("Reset Row Order")]),
            ]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }