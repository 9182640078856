var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.productRoots.length || _vm.filteredStarRevList.length,
          expression: "productRoots.length || filteredStarRevList.length",
        },
      ],
      staticClass: "ProductList",
      class: _vm.dynClass,
    },
    [
      _c(
        "h3",
        {
          staticClass: "ProductListTitle",
          attrs: { role: "button", tabindex: "0" },
          on: {
            click: _vm.toggle,
            keypress: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "space", 32, $event.key, [
                  " ",
                  "Spacebar",
                ]) &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              $event.preventDefault()
              return _vm.toggle.apply(null, arguments)
            },
          },
        },
        [
          _vm._v(
            _vm._s(_vm.title) +
              " (" +
              _vm._s(_vm.productCount) +
              ") " +
              _vm._s(!_vm.show ? "(collapsed)" : "")
          ),
        ]
      ),
      _vm._v(" "),
      _vm.show
        ? _c(
            "div",
            { staticClass: "ProductListRows" },
            [
              _vm._l(_vm.filteredList, function (item) {
                return [
                  item.indexChar
                    ? [
                        _c("div", { key: item.id, staticClass: "IndexChar" }, [
                          _vm._v(_vm._s(item.indexChar)),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "IndexCharBorder" }),
                      ]
                    : _c("CmsProductRootTreeNode", {
                        key: item.id,
                        attrs: {
                          proot: item.proot,
                          lsText: _vm.lsMap[item.proot.ProductRootId],
                        },
                      }),
                ]
              }),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showStarredRevs
        ? _c(
            "div",
            { staticClass: "ProductListRows" },
            _vm._l(_vm.filteredStarRevList, function (item) {
              return _c("CmsProductRevisionListItem", {
                key: item.id,
                attrs: { rev: item.prev, showFxInfo: true },
              })
            }),
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }