<template>
	<div class="loginRecordsRoot">
		<div v-if="error">{{error}}</div>
		<div v-else-if="loading" class="loading"><ScaleLoader /> Loading…</div>
		<div v-else-if="rows" class="recordsTableContainer">
			<vue-good-table :columns="columns" :rows="rows" />
		</div>
		<div v-else>
			No data.
		</div>
	</div>
</template>

<script>
	import { GetUserActivityForSelf, GetUserActivity } from 'appRoot/api/UserActivityData';
	import { GetDateStr } from 'appRoot/scripts/Util';

	export default {
		components: {},
		props:
		{
			userId: {
				type: Number,
				default: 0
			},
			allUsers: {
				type: Boolean,
				default: false
			}
		},
		data()
		{
			return {
				error: null,
				loading: false,
				columns: [
					{ label: "User", field: "UserId", formatFn: id => this.getUserText(id) },
					{ label: "Date", field: "Date", formatFn: d => d ? GetDateStr(new Date(d)) : "" },
					{ label: "Activity", field: "Activity" },
					{ label: "Argument", field: "Argument" },
					{ label: "Session", field: "Session" },
				],
				rows: null,
				userIdToUserNameMap: {}
			};
		},
		created()
		{
			this.loadRecords();
		},
		methods:
		{
			loadRecords()
			{
				if (this.allUsers)
				{
					let startDate = new Date();
					startDate.setDate(startDate.getDate() - 30);
					let endDate = new Date();
					endDate.setDate(endDate.getDate() + 30);
					this.handleRecordsPromise(GetUserActivity(0, startDate.getTime(), endDate.getTime()));
				}
				else if (this.userId)
				{
					this.handleRecordsPromise(GetUserActivity(this.userId, 0, 0));
				}
				else
				{
					this.handleRecordsPromise(GetUserActivityForSelf());
				}
			},
			handleRecordsPromise(promise)
			{
				this.loading = true;
				this.rows = null;
				this.error = null;
				promise
					.then(data =>
					{
						this.loading = false;
						if (data.success)
						{
							this.userIdToUserNameMap = data.userIdToUserNameMap;
							this.rows = data.records;
						}
						else
							this.error = data.error;
					})
					.catch(err =>
					{
						this.loading = false;
						this.error = err.message;
					});
			},
			getUserText(userId)
			{
				let txt = this.userIdToUserNameMap[userId];
				if (txt)
					return txt;
				return "" + userId;
			},
		}
	};
</script>

<style scoped>
	.loading
	{
		margin: 20px;
		text-align: center;
	}
</style>