var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "dialogRoot",
      class: { inputDialogRoot: true },
      attrs: {
        role: "dialog",
        "aria-labelledby": "mergeConflictsTitle",
        "aria-describedby": "mergeConflictsMsg",
      },
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "dialogBody" }, [
        _c(
          "div",
          { staticClass: "messageText", attrs: { id: "mergeConflictsMsg" } },
          [
            _vm._v(
              "Your changes may conflict with someone else's recent changes.  Please select from the following options for each field:"
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "conflictsContainer" },
          _vm._l(_vm.mergeConflicts, function (c) {
            return _c("div", { key: c.path, staticClass: "conflict" }, [
              _c("div", { staticClass: "conflictPath" }, [
                _vm._v(_vm._s(c.path)),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  ref: "baseValue",
                  refInFor: true,
                  staticClass: "conflictValue baseValue",
                  class: {
                    selected: c.selected === 0,
                    noneSelected: c.selected === -1,
                  },
                  attrs: { tabindex: "0" },
                  on: {
                    click: function ($event) {
                      return _vm.select(c, 0)
                    },
                    keypress: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "space", 32, $event.key, [
                          " ",
                          "Spacebar",
                        ]) &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      $event.preventDefault()
                      return _vm.select(c, 0)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "valueHeader" }, [
                    _vm._v("Original"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "conflictText" }, [
                    _vm._v(_vm._s(c.baseValue)),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "conflictValue yourValue",
                  class: {
                    selected: c.selected === 1,
                    noneSelected: c.selected === -1,
                  },
                  attrs: { tabindex: "0" },
                  on: {
                    click: function ($event) {
                      return _vm.select(c, 1)
                    },
                    keypress: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "space", 32, $event.key, [
                          " ",
                          "Spacebar",
                        ]) &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      $event.preventDefault()
                      return _vm.select(c, 1)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "valueHeader" }, [_vm._v("Yours")]),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "conflictText",
                    domProps: { innerHTML: _vm._s(c.diffYours) },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "conflictValue theirValue",
                  class: {
                    selected: c.selected === 2,
                    noneSelected: c.selected === -1,
                  },
                  attrs: { tabindex: "0" },
                  on: {
                    click: function ($event) {
                      return _vm.select(c, 2)
                    },
                    keypress: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "space", 32, $event.key, [
                          " ",
                          "Spacebar",
                        ]) &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      $event.preventDefault()
                      return _vm.select(c, 2)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "valueHeader" }, [_vm._v("Theirs")]),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "conflictText",
                    domProps: { innerHTML: _vm._s(c.diffTheirs) },
                  }),
                ]
              ),
            ])
          }),
          0
        ),
        _vm._v(" "),
        _c("div", { staticClass: "buttons" }, [
          _c(
            "button",
            {
              staticClass: "dialogButton okButton",
              attrs: { tabindex: "0", disabled: !_vm.okButtonEnabled },
              on: { click: _vm.okClicked },
            },
            [_vm._v("\n\t\t\t\tOK\n\t\t\t")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              ref: "cancelBtn",
              staticClass: "dialogButton cancelButton",
              attrs: { tabindex: "0" },
              on: { click: _vm.cancelClicked },
            },
            [_vm._v("\n\t\t\t\tCancel\n\t\t\t")]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "titleBar" }, [
      _c(
        "div",
        {
          staticClass: "title",
          attrs: { id: "mergeConflictsTitle", role: "alert" },
        },
        [_vm._v("Merge Conflicts")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }