<template>
	<div :class="{ editor: true, showHelp }">
		<div class="SettingsSettingsContainer">
			<label>
				<input type="checkbox" v-model="showHelp" /> Show Editor Help
			</label>
		</div>
		<PropEdit ref="fields" v-for="pair in itemPairs" :key="pair.spec.key" :initialValue="pair.initialValue" :spec="pair.spec" @valueChanged="onValueChanged"
				  class="propEdit" v-show="pair.show" />
	</div>
</template>

<script>
	import PropEdit from 'appRoot/vues/common/editor/PropEdit.vue';

	export default {
		components: { PropEdit },
		props:
		{
			object: {
				type: Object,
				required: true
			},
			spec: {
				type: Array,
				required: true
			}
		},
		data()
		{
			return {
				specMap: {},
				showHelp: false,
				recomputeItemPairs: 0
			};
		},
		computed:
		{
			itemPairs()
			{
				if (this.recomputeItemPairs)
				{
				}
				let arr = [];
				for (let i = 0; i < this.spec.length; i++)
				{
					let spec = this.spec[i];
					let initialValue = this.object[spec.key];
					if (typeof initialValue === "undefined")
						initialValue = spec.value;
					let obj = { initialValue: initialValue, spec: spec, show: true };
					if (spec.conditions && spec.conditions.length)
					{
						for (let j = 0; j < spec.conditions.length; j++)
						{
							let condition = spec.conditions[j];
							obj.show = this.evaluateCondition(condition);
						}
					}
					arr.push(obj);
				}
				return arr;
			}
		},
		methods:
		{
			onValueChanged(key, value)
			{
				this.recomputeItemPairs++;
				this.$emit("valueChanged", key, value);
			},
			focusFirst() // Called by parent component
			{
				if (this.$refs.fields && this.$refs.fields.length > 0)
					this.$refs.fields[0].focus();
			},
			getValue(key, keyIsLiteralValue)
			{
				return keyIsLiteralValue ? key : this.object[key];
			},
			evaluateCondition(condition)
			{
				try
				{
					let A = this.getValue(condition.A, condition.A_Is_Literal);
					let B = this.getValue(condition.B, condition.B_Is_Literal);
					if (typeof A === "undefined")
						A = "";
					if (typeof B === "undefined")
						B = "";
					let Operator = condition.Operator;
					switch (Operator)
					{
						case "Equals":
							return A.toString() === B.toString();
						case "NotEquals":
							return A.toString() !== B.toString();
						case "LessThan":
							return A < parseFloat(B);
						case "LessThanOrEqual":
							return A <= parseFloat(B);
						case "GreaterThan":
							return A > parseFloat(B);
						case "GreaterThanOrEqual":
							return A >= parseFloat(B);
						case "StartsWith":
							return A.toString().startsWith(B.toString());
						case "EndsWith":
							return A.toString().endsWith(B.toString());
						default:
							console.error("Unknown operator:", Operator);
							return true;
					}
				}
				catch (ex)
				{
					console.error("Error while evaluating FieldEditSpec.condition:", JSON.stringify(condition), ex);
				}
				return true;
			}
		},
		created()
		{
		},
		mounted()
		{
		},
		beforeDestroy()
		{
		}
	};
</script>

<style scoped>
	.SettingsSettingsContainer
	{
		display: inline-block;
		background-color: #f7e5ac;
		padding: 10px;
		border: 1px solid #721f00;
		border-radius: 5px;
		margin-bottom: 20px;
	}

	.propEdit
	{
		margin-bottom: 10px;
	}
</style>