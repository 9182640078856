var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: { pageRoot: true } }, [
    _vm.error
      ? _c("div", { staticClass: "error" }, [_vm._v(_vm._s(_vm.error))])
      : _vm.loading
      ? _c(
          "div",
          { staticClass: "loading" },
          [_c("ScaleLoader"), _vm._v(" Loading…")],
          1
        )
      : _vm.graphiteData
      ? _c("div", [
          _c("h2", [_vm._v("General Status")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "graphContainer" },
            _vm._l(_vm.graphiteData.SingleServerQueryStrings, function (g) {
              return _c("img", {
                staticClass: "graph",
                attrs: { src: _vm.getImgSrc(g), alt: "Graph" },
              })
            }),
            0
          ),
          _vm._v(" "),
          _c("h2", [_vm._v("Individual Server Status")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "graphColumnContainer" },
            _vm._l(_vm.graphiteData.ServerNames, function (sn) {
              return _c(
                "div",
                { staticClass: "graphColumn" },
                [
                  _c("div", { staticClass: "serverName" }, [
                    _vm._v(_vm._s(sn)),
                  ]),
                  _vm._v(" "),
                  _vm._l(
                    _vm.graphiteData.MultiServerQueryStrings,
                    function (g) {
                      return _c("img", {
                        staticClass: "graph",
                        attrs: { src: _vm.getImgSrc(g, sn), alt: "Graph" },
                      })
                    }
                  ),
                ],
                2
              )
            }),
            0
          ),
          _vm._v(" "),
          _c("h2", [_vm._v("Database Status")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "graphContainer" },
            _vm._l(_vm.graphiteData.DatabaseQueryStrings, function (g) {
              return _c("img", {
                staticClass: "graph",
                attrs: { src: _vm.getImgSrc(g), alt: "Graph" },
              })
            }),
            0
          ),
        ])
      : _c("div", [
          _vm._v(
            "\n\t\tGraphite data loading failed for an unknown reason.\n\t"
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }