<template>
	<div class="CmsConsoleProduction">
		<div class="sectionHeading">Transfer From Production</div>
		<div class="archiveTransfer">
			<template v-if="tamStatus && tamStatus.titlesReady.length">
				<div class="archiveTransfer titleRow" v-for="item in tamStatus.titlesReady" :key="'r' + item.ProductRevisionId" :title="item.Name + ' rev ' + item.ProductRevisionId" v-if="item.Downloader">
					{{item.FileName}} - {{item.Size}} @ {{item.Downloader.BitsPerSecondStr}} <progress max="100" :value="item.Downloader.Progress" :title="item.Downloader.Progress + '% ' + item.Name + ' rev ' + item.ProductRevisionId" />
					<div>ETA: {{item.Downloader.RemainingTime}}</div>
				</div>
			</template>
		</div>
		<div class="productionServ">
			<div class="sectionHeading">Production Server</div>
			<!-- Title currently being copied for LoE on Production Server -->
			<div v-if="loeStatus">LoE creation: {{loeStatus}}</div>
			<template v-if="tamStatus">
				<!-- Title Currently Being Archived on Production Server -->
				<div v-if="tamStatus.titleProcessing" :title="tamStatus.titleProcessing.StatusStr" class="activeArchival">
					<div>{{tamStatus.titleProcessing.FileName}}</div>
					<div><progress max="100" :value="tamStatus.titleProcessing.Progress">{{Math.max(0, tamStatus.titleProcessing.Progress)}}%</progress></div>
					<div class="plainText">{{tamStatus.titleProcessing.StatusStr}}</div>
				</div>
				<div v-else class="activeArchival">No active archival job</div>
				<!-- Count of archival requests not yet submitted to Production Server -->
				<div v-if="tamStatus.unsubmittedArchivalRequestCount > 0" title="Count of archival requests not yet submitted to Production Server">
					Pending Request Count: {{tamStatus.unsubmittedArchivalRequestCount}}
				</div>
				<!-- Archives Ready on Production Server -->
				<div class="ready">
					<div class="subHeading">Ready ({{tamStatus.titlesReady.length}}): <span v-if="!tamStatus.titlesReady.length">[None]</span></div>
					<template v-if="tamStatus.titlesReady.length">
						<div class="titleRow" v-for="item in tamStatus.titlesReady" :key="'r' + item.ProductRevisionId" :title="item.Name + ' rev ' + item.ProductRevisionId">
							{{item.FileName}} - {{item.Size}}
						</div>
					</template>
				</div>
				<!-- Titles Queued for Archival on Production Server -->
				<div class="queued">
					<div class="subHeading">Queued ({{tamStatus.titlesQueued.length}}): <span v-if="!tamStatus.titlesQueued.length">[None]</span></div>
					<template v-if="tamStatus.titlesQueued.length">
						<span class="acronymCell" v-for="item in tamStatus.titlesQueued" :key="'q' + item.ProductRevisionId" :title="item.Name + ' rev ' + item.ProductRevisionId">{{item.FileName}}</span>
					</template>
				</div>
			</template>
			<br />
		</div>
		<div class="sectionHeading">Title Archive Manager Status</div>
		<div class="appServiceStatus plainText">{{tamEngineText}}</div>
	</div>
</template>

<script>
	import EventBus from 'appRoot/scripts/EventBus';


	export default {
		components: {},
		props:
		{
		},
		data()
		{
			return {
			};
		},
		computed:
		{
			tamStatus()
			{
				return EventBus.tamStatus; // { downloader: Object, engine: String, production: Object }
			},
			tamEngineText()
			{
				if (this.tamStatus)
					return this.tamStatus.engine;
				else
					return "…";
			},
			loeStatus()
			{
				return EventBus.loeStatus;
			},
		},
		methods:
		{
		},
		watch:
		{
		}
	}
</script>

<style scoped>
	.plainText
	{
		white-space: pre-wrap;
	}

	.sectionHeading
	{
		display: block;
		text-decoration: none;
		background-color: #006600;
		color: #DDDDDD;
		font-weight: bold;
		font-size: 1.1em;
		text-align: center;
		margin-bottom: 2px;
	}

	.appServiceStatus,
	.activeArchival,
	.archiveTransfer
	{
		min-height: 3.643em;
	}

	.productionServ .titleRow
	{
		margin-left: 10px;
	}

		.productionServ .titleRow progress
		{
			width: 50px;
		}

	.acronymCell:after
	{
		content: ', ';
	}

	.acronymCell:last-of-type:after
	{
		content: '';
	}
</style>