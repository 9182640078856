import ExecAPI from 'appRoot/api/api';

/**
 * Gets the content of a local text file. "ReleaseNotes", "TitleUpdates", "AdminIPWhitelist"
 */
export function GetLocalTextFile(name, backupDate)
{
	return ExecAPI("FileEditorData/GetLocalTextFile", { name, backupDate });
}
/**
 * Sets the content of a local text file.
 */
export function SetLocalTextFile(name, fileContent)
{
	return ExecAPI("FileEditorData/SetLocalTextFile", { name, fileContent });
}