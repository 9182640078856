import { EscapeHTML } from 'appRoot/scripts/Util';
import { PageChangeTracker } from 'appRoot/api/CMSUserData';

export default { /* PageStateMonitoringMixin */
	data()
	{
		return {
			pageChangeState: null,
			isCheckingPageState: false,
			checkPageStateQueued: false,
			checkPageStateTimeout: null,
			isDestroyed: false,
			pageStateUnavailable: false,
			archiveNameCurrent: null
		};
	},
	created()
	{
		this.checkPageState();
		document.addEventListener("visibilitychange", this.onVisibilityChange);
	},
	beforeDestroy()
	{
		this.isDestroyed = true;
		document.removeEventListener("visibilitychange", this.onVisibilityChange);
		PageChangeTracker(null, null, false).then(data => { }).catch(err => { });
	},
	computed:
	{
		currentUsersHtml()
		{
			if (this.pageChangeState == null)
				return "";
			let users = [];
			let added = {};
			for (let i = 0; i < this.pageChangeState.UserActivity.length; i++)
			{
				let ua = this.pageChangeState.UserActivity[i];
				if (!added[ua.UserName])
				{
					added[ua.UserName] = true;
					let str = EscapeHTML(ua.UserName);
					if (ua.Editing)
						str += " (editing)";
					if (!ua.ActiveTab)
						str = '<span class="inactiveUserName" title="The browser tab is inactive">' + str + '</span>';
					users.push(str);
				}
			}
			return users.length ? ('<span title="These users also have this page open">Users: ' + users.join(", ") + '</span>') : '';
		}
	},
	methods:
	{
		async checkPageState()
		{
			if (this.isDestroyed)
				return;
			if (this.isCheckingPageState)
			{
				this.checkPageStateQueued = true;
				return;
			}
			this.isCheckingPageState = true;
			this.checkPageStateQueued = false;
			clearTimeout(this.checkPageStateTimeout);
			try
			{
				let productRootId = typeof this.$props.productRootId === "number" ? this.$props.productRootId : null;
				let productRevisionId = typeof this.$props.productRevisionId === "number" ? this.$props.productRevisionId : null;
				let data = await PageChangeTracker(productRootId, productRevisionId, this.hasChanges, document.visibilityState === "visible");
				if (data.success)
				{
					this.pageStateUnavailable = false;
					this.pageChangeState = data.state;
					this.archiveNameCurrent = data.archiveName;
				}
				else
				{
					this.pageStateUnavailable = true;
					toaster.error(data.error);
				}
			}
			catch (err)
			{
				this.pageStateUnavailable = true;
			}
			finally
			{
				this.isCheckingPageState = false;
				if (this.checkPageStateQueued)
					this.checkPageState();
				else
					this.checkPageStateTimeout = setTimeout(this.checkPageState, 1000);

			}
		},
		onVisibilityChange()
		{
			this.checkPageState();
		}
	},
	watch:
	{
		hasChanges()
		{
			this.checkPageState();
		}
	}
};