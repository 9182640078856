var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "loginRoot" },
    [
      _c(
        "div",
        { staticClass: "loginPanel" },
        [
          _c("div", { staticClass: "systemName" }, [
            _vm._v(_vm._s(_vm.systemName)),
          ]),
          _vm._v(" "),
          !_vm.secureContext
            ? _c("div", { staticClass: "secureContextWarning" }, [
                _c("p", [_vm._v("Unsecure Connection")]),
                _c("p", [_vm._v("To log in, please connect with HTTPS.")]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.loaded
            ? [
                _vm.showUserInput
                  ? _c("label", { staticClass: "lblUser" }, [
                      _c("div", [_vm._v("User Name")]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.user,
                            expression: "user",
                          },
                        ],
                        staticClass: "txtUser",
                        attrs: {
                          type: "text",
                          placeholder: "User Name",
                          autocomplete: "username webauthn",
                        },
                        domProps: { value: _vm.user },
                        on: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.UserNameEnter.apply(null, arguments)
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.user = $event.target.value
                          },
                        },
                      }),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.showPasswordInput
                  ? [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.pass,
                            expression: "pass",
                          },
                        ],
                        ref: "passwordField",
                        staticClass: "txtPass",
                        attrs: {
                          type: "password",
                          placeholder: "Password",
                          autocomplete: "current-password",
                        },
                        domProps: { value: _vm.pass },
                        on: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.TryPasswordLogin.apply(null, arguments)
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.pass = $event.target.value
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.emailConfigured
                        ? _c(
                            "div",
                            { staticClass: "forgotPwLinkWrapper" },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "forgotPwLink",
                                  attrs: { to: _vm.forgotPwRoute },
                                },
                                [_vm._v("Forgot Password?")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                [
                  _vm.showLogInButton || _vm.showEmailLoginCodeButton
                    ? _c("div", { staticClass: "buttonBar" }, [
                        _vm.showLogInButton
                          ? _c(
                              "button",
                              {
                                staticClass: "buttonBarButton",
                                attrs: { disabled: _vm.busy },
                                on: {
                                  click: function ($event) {
                                    return _vm.LoginWithPasskey(false)
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  staticClass: "btnIconNoHover",
                                  attrs: {
                                    src: _vm.appPath + "Images/key.png",
                                    alt: "",
                                    role: "presentation",
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", [_vm._v("Log In")]),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.showEmailLoginCodeButton
                          ? _c(
                              "button",
                              {
                                staticClass: "buttonBarButton",
                                attrs: { disabled: _vm.busy },
                                on: { click: _vm.SendLoginRequestToEmail },
                              },
                              [
                                _c("img", {
                                  staticClass: "btnIconNoHover",
                                  attrs: {
                                    src:
                                      _vm.appPath + "Images/mail_forward.png",
                                    alt: "",
                                    role: "presentation",
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", [_vm._v("Email a Login Code")]),
                              ]
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ],
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _vm.loaded
        ? [
            _vm.error
              ? _c("div", { staticClass: "error" }, [_vm._v(_vm._s(_vm.error))])
              : _vm._e(),
            _vm._v(" "),
            !_vm.passkeySupported
              ? _c("div", { staticClass: "error" }, [
                  _vm._v("This web browser does not support Passkey login."),
                ])
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }