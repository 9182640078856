/* eslint-disable */
/*
 A JavaScript implementation of the SHA family of hashes, as
 defined in FIPS PUB 180-4 and FIPS PUB 202, as well as the corresponding
 HMAC implementation as defined in FIPS PUB 198a

 Copyright Brian Turek 2008-2016
 Distributed under the BSD License
 See http://caligatio.github.com/jsSHA/ for more information

 Several functions taken from Paul Johnston
*/
'use strict';(function(L){function y(n,b,d){var c=0,f=[],e=0,a,h,l,k,m,t,u,q,v=!1,r=[],A=[],x,B=!1;d=d||{};a=d.encoding||"UTF8";x=d.numRounds||1;l=C(b,a);if(x!==parseInt(x,10)||1>x)throw Error("numRounds must a integer >= 1");if(0===n.lastIndexOf("SHA-",0))if(t=function(b,c){return D(b,c,n)},u=function(b,c,d,e){var p,f;if("SHA-384"===n||"SHA-512"===n)p=(c+129>>>10<<5)+31,f=32;else throw Error("Unexpected error in SHA-2 implementation");for(;b.length<=p;)b.push(0);b[c>>>5]|=128<<24-c%32;c=c+d;b[p]=
c&4294967295;b[p-1]=c/4294967296|0;d=b.length;for(c=0;c<d;c+=f)e=D(b.slice(c,c+f),e,n);if("SHA-384"===n)b=[e[0].a,e[0].b,e[1].a,e[1].b,e[2].a,e[2].b,e[3].a,e[3].b,e[4].a,e[4].b,e[5].a,e[5].b];else if("SHA-512"===n)b=[e[0].a,e[0].b,e[1].a,e[1].b,e[2].a,e[2].b,e[3].a,e[3].b,e[4].a,e[4].b,e[5].a,e[5].b,e[6].a,e[6].b,e[7].a,e[7].b];else throw Error("Unexpected error in SHA-2 implementation");return b},q=function(b){return b.slice()},"SHA-384"===n)m=1024,k=384;else if("SHA-512"===n)m=1024,k=512;else throw Error("Chosen SHA variant is not supported");
else throw Error("Chosen SHA variant is not supported");h=z(n);this.setHMACKey=function(b,d,e){var f;if(!0===v)throw Error("HMAC key already set");if(!0===B)throw Error("Cannot set HMAC key after calling update");a=(e||{}).encoding||"UTF8";d=C(d,a)(b);b=d.binLen;d=d.value;f=m>>>3;e=f/4-1;if(f<b/8){for(d=u(d,b,0,z(n),k);d.length<=e;)d.push(0);d[e]&=4294967040}else if(f>b/8){for(;d.length<=e;)d.push(0);d[e]&=4294967040}for(b=0;b<=e;b+=1)r[b]=d[b]^909522486,A[b]=d[b]^1549556828;h=t(r,h);c=m;v=!0};this.update=
function(b){var d,n,a,g=0,k=m>>>5;d=l(b,f,e);b=d.binLen;n=d.value;d=b>>>5;for(a=0;a<d;a+=k)g+m<=b&&(h=t(n.slice(a,a+k),h),g+=m);c+=g;f=n.slice(g>>>5);e=b%m;B=!0};this.getHash=function(b,d){var a,g,m,l;if(!0===v)throw Error("Cannot call getHash after setting HMAC key");m=E(d);switch(b){case "HEX":a=function(b){return F(b,k,m)};break;case "B64":a=function(b){return G(b,k,m)};break;case "BYTES":a=function(b){return H(b,k)};break;case "ARRAYBUFFER":try{g=new ArrayBuffer(0)}catch(w){throw Error("ARRAYBUFFER not supported by this environment");
}a=function(b){return I(b,k)};break;default:throw Error("format must be HEX, B64, BYTES, or ARRAYBUFFER");}l=u(f.slice(),e,c,q(h),k);for(g=1;g<x;g+=1)l=u(l,k,0,z(n),k);return a(l)};this.getHMAC=function(b,d){var a,g,l,r;if(!1===v)throw Error("Cannot call getHMAC without first setting HMAC key");l=E(d);switch(b){case "HEX":a=function(b){return F(b,k,l)};break;case "B64":a=function(b){return G(b,k,l)};break;case "BYTES":a=function(b){return H(b,k)};break;case "ARRAYBUFFER":try{a=new ArrayBuffer(0)}catch(w){throw Error("ARRAYBUFFER not supported by this environment");
}a=function(b){return I(b,k)};break;default:throw Error("outputFormat must be HEX, B64, BYTES, or ARRAYBUFFER");}g=u(f.slice(),e,c,q(h),k);r=t(A,z(n));r=u(g,k,m,r,k);return a(r)}}function c(c,b){this.a=c;this.b=b}function M(c,b,d){var a=c.length,f,e,g,h,l;b=b||[0];d=d||0;l=d>>>3;if(0!==a%2)throw Error("String of HEX type must be in byte increments");for(f=0;f<a;f+=2){e=parseInt(c.substr(f,2),16);if(isNaN(e))throw Error("String of HEX type contains invalid characters");h=(f>>>1)+l;for(g=h>>>2;b.length<=
g;)b.push(0);b[g]|=e<<8*(3-h%4)}return{value:b,binLen:4*a+d}}function N(c,b,d){var a=[],f,e,g,h,a=b||[0];d=d||0;e=d>>>3;for(f=0;f<c.length;f+=1)b=c.charCodeAt(f),h=f+e,g=h>>>2,a.length<=g&&a.push(0),a[g]|=b<<8*(3-h%4);return{value:a,binLen:8*c.length+d}}function O(c,b,d){var a=[],f=0,e,g,h,l,k,m,a=b||[0];d=d||0;b=d>>>3;if(-1===c.search(/^[a-zA-Z0-9=+\/]+$/))throw Error("Invalid character in base-64 string");g=c.indexOf("=");c=c.replace(/\=/g,"");if(-1!==g&&g<c.length)throw Error("Invalid '=' found in base-64 string");
for(g=0;g<c.length;g+=4){k=c.substr(g,4);for(h=l=0;h<k.length;h+=1)e="ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/".indexOf(k[h]),l|=e<<18-6*h;for(h=0;h<k.length-1;h+=1){m=f+b;for(e=m>>>2;a.length<=e;)a.push(0);a[e]|=(l>>>16-8*h&255)<<8*(3-m%4);f+=1}}return{value:a,binLen:8*f+d}}function P(c,b,d){var a=[],f,e,g,a=b||[0];d=d||0;f=d>>>3;for(b=0;b<c.byteLength;b+=1)g=b+f,e=g>>>2,a.length<=e&&a.push(0),a[e]|=c[b]<<8*(3-g%4);return{value:a,binLen:8*c.byteLength+d}}function F(c,b,d){var a=
"";b/=8;var f,e;for(f=0;f<b;f+=1)e=c[f>>>2]>>>8*(3-f%4),a+="0123456789abcdef".charAt(e>>>4&15)+"0123456789abcdef".charAt(e&15);return d.outputUpper?a.toUpperCase():a}function G(c,b,d){var a="",f=b/8,e,g,h;for(e=0;e<f;e+=3)for(g=e+1<f?c[e+1>>>2]:0,h=e+2<f?c[e+2>>>2]:0,h=(c[e>>>2]>>>8*(3-e%4)&255)<<16|(g>>>8*(3-(e+1)%4)&255)<<8|h>>>8*(3-(e+2)%4)&255,g=0;4>g;g+=1)8*e+6*g<=b?a+="ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/".charAt(h>>>6*(3-g)&63):a+=d.b64Pad;return a}function H(c,
b){var d="",a=b/8,f,e;for(f=0;f<a;f+=1)e=c[f>>>2]>>>8*(3-f%4)&255,d+=String.fromCharCode(e);return d}function I(c,b){var d=b/8,a,f=new ArrayBuffer(d);for(a=0;a<d;a+=1)f[a]=c[a>>>2]>>>8*(3-a%4)&255;return f}function E(c){var b={outputUpper:!1,b64Pad:"=",shakeLen:-1};c=c||{};b.outputUpper=c.outputUpper||!1;!0===c.hasOwnProperty("b64Pad")&&(b.b64Pad=c.b64Pad);if("boolean"!==typeof b.outputUpper)throw Error("Invalid outputUpper formatting option");if("string"!==typeof b.b64Pad)throw Error("Invalid b64Pad formatting option");
return b}function C(c,b){var d;switch(b){case "UTF8":case "UTF16BE":case "UTF16LE":break;default:throw Error("encoding must be UTF8, UTF16BE, or UTF16LE");}switch(c){case "HEX":d=M;break;case "TEXT":d=function(c,d,a){var n=[],h=[],l=0,k,m,t,u,q,n=d||[0];d=a||0;t=d>>>3;if("UTF8"===b)for(k=0;k<c.length;k+=1)for(a=c.charCodeAt(k),h=[],128>a?h.push(a):2048>a?(h.push(192|a>>>6),h.push(128|a&63)):55296>a||57344<=a?h.push(224|a>>>12,128|a>>>6&63,128|a&63):(k+=1,a=65536+((a&1023)<<10|c.charCodeAt(k)&1023),
h.push(240|a>>>18,128|a>>>12&63,128|a>>>6&63,128|a&63)),m=0;m<h.length;m+=1){q=l+t;for(u=q>>>2;n.length<=u;)n.push(0);n[u]|=h[m]<<8*(3-q%4);l+=1}else if("UTF16BE"===b||"UTF16LE"===b)for(k=0;k<c.length;k+=1){a=c.charCodeAt(k);"UTF16LE"===b&&(m=a&255,a=m<<8|a>>>8);q=l+t;for(u=q>>>2;n.length<=u;)n.push(0);n[u]|=a<<8*(2-q%4);l+=2}return{value:n,binLen:8*l+d}};break;case "B64":d=O;break;case "BYTES":d=N;break;case "ARRAYBUFFER":try{d=new ArrayBuffer(0)}catch(a){throw Error("ARRAYBUFFER not supported by this environment");
}d=P;break;default:throw Error("format must be HEX, TEXT, B64, BYTES, or ARRAYBUFFER");}return d}function v(a,b){var d=null,d=new c(a.a,a.b);return d=32>=b?new c(d.a>>>b|d.b<<32-b&4294967295,d.b>>>b|d.a<<32-b&4294967295):new c(d.b>>>b-32|d.a<<64-b&4294967295,d.a>>>b-32|d.b<<64-b&4294967295)}function J(a,b){var d=null;return d=32>=b?new c(a.a>>>b,a.b>>>b|a.a<<32-b&4294967295):new c(0,a.a>>>b-32)}function Q(a,b,d){return new c(a.a&b.a^~a.a&d.a,a.b&b.b^~a.b&d.b)}function R(a,b,d){return new c(a.a&b.a^
a.a&d.a^b.a&d.a,a.b&b.b^a.b&d.b^b.b&d.b)}function S(a){var b=v(a,28),d=v(a,34);a=v(a,39);return new c(b.a^d.a^a.a,b.b^d.b^a.b)}function T(a){var b=v(a,14),d=v(a,18);a=v(a,41);return new c(b.a^d.a^a.a,b.b^d.b^a.b)}function U(a){var b=v(a,1),d=v(a,8);a=J(a,7);return new c(b.a^d.a^a.a,b.b^d.b^a.b)}function V(a){var b=v(a,19),d=v(a,61);a=J(a,6);return new c(b.a^d.a^a.a,b.b^d.b^a.b)}function W(a,b){var d,p,f;d=(a.b&65535)+(b.b&65535);p=(a.b>>>16)+(b.b>>>16)+(d>>>16);f=(p&65535)<<16|d&65535;d=(a.a&65535)+
(b.a&65535)+(p>>>16);p=(a.a>>>16)+(b.a>>>16)+(d>>>16);return new c((p&65535)<<16|d&65535,f)}function X(a,b,d,p){var f,e,g;f=(a.b&65535)+(b.b&65535)+(d.b&65535)+(p.b&65535);e=(a.b>>>16)+(b.b>>>16)+(d.b>>>16)+(p.b>>>16)+(f>>>16);g=(e&65535)<<16|f&65535;f=(a.a&65535)+(b.a&65535)+(d.a&65535)+(p.a&65535)+(e>>>16);e=(a.a>>>16)+(b.a>>>16)+(d.a>>>16)+(p.a>>>16)+(f>>>16);return new c((e&65535)<<16|f&65535,g)}function Y(a,b,d,p,f){var e,g,h;e=(a.b&65535)+(b.b&65535)+(d.b&65535)+(p.b&65535)+(f.b&65535);g=(a.b>>>
16)+(b.b>>>16)+(d.b>>>16)+(p.b>>>16)+(f.b>>>16)+(e>>>16);h=(g&65535)<<16|e&65535;e=(a.a&65535)+(b.a&65535)+(d.a&65535)+(p.a&65535)+(f.a&65535)+(g>>>16);g=(a.a>>>16)+(b.a>>>16)+(d.a>>>16)+(p.a>>>16)+(f.a>>>16)+(e>>>16);return new c((g&65535)<<16|e&65535,h)}function z(a){var b=[],d;if(0===a.lastIndexOf("SHA-",0))switch(b=[3238371032,914150663,812702999,4144912697,4290775857,1750603025,1694076839,3204075428],d=[1779033703,3144134277,1013904242,2773480762,1359893119,2600822924,528734635,1541459225],a){case "SHA-224":break;
case "SHA-256":b=d;break;case "SHA-384":b=[new c(3418070365,b[0]),new c(1654270250,b[1]),new c(2438529370,b[2]),new c(355462360,b[3]),new c(1731405415,b[4]),new c(41048885895,b[5]),new c(3675008525,b[6]),new c(1203062813,b[7])];break;case "SHA-512":b=[new c(d[0],4089235720),new c(d[1],2227873595),new c(d[2],4271175723),new c(d[3],1595750129),new c(d[4],2917565137),new c(d[5],725511199),new c(d[6],4215389547),new c(d[7],327033209)];break;default:throw Error("Unknown SHA variant");}else throw Error("No SHA variants supported");
return b}function D(a,b,d){var p,f,e,g,h,l,k,m,t,u,q,v,r,A,x,B,y,z,C,D,E,F,w=[],G;if("SHA-384"===d||"SHA-512"===d)u=80,v=2,F=c,r=W,A=X,x=Y,B=U,y=V,z=S,C=T,E=R,D=Q,G=K;else throw Error("Unexpected error in SHA-2 implementation");d=b[0];p=b[1];f=b[2];e=b[3];g=b[4];h=b[5];l=b[6];k=b[7];for(q=0;q<u;q+=1)16>q?(t=q*v,m=a.length<=t?0:a[t],t=a.length<=t+1?0:a[t+1],w[q]=new F(m,t)):w[q]=A(y(w[q-2]),w[q-7],B(w[q-15]),w[q-16]),m=x(k,C(g),D(g,h,l),G[q],w[q]),t=r(z(d),E(d,p,f)),k=l,l=h,h=g,g=r(e,m),e=f,f=p,p=
d,d=r(m,t);b[0]=r(d,b[0]);b[1]=r(p,b[1]);b[2]=r(f,b[2]);b[3]=r(e,b[3]);b[4]=r(g,b[4]);b[5]=r(h,b[5]);b[6]=r(l,b[6]);b[7]=r(k,b[7]);return b}var a,K;a=[1116352408,1899447441,3049323471,3921009573,961987163,1508970993,2453635748,2870763221,3624381080,310598401,607225278,1426881987,1925078388,2162078206,2614888103,3248222580,3835390401,4022224774,264347078,604807628,770255983,1249150122,1555081692,1996064986,2554220882,2821834349,2952996808,3210313671,3336571891,3584528711,113926993,338241895,666307205,
773529912,1294757372,1396182291,1695183700,1986661051,2177026350,2456956037,2730485921,2820302411,3259730800,3345764771,3516065817,3600352804,4094571909,275423344,430227734,506948616,659060556,883997877,958139571,1322822218,1537002063,1747873779,1955562222,2024104815,2227730452,2361852424,2428436474,2756734187,3204031479,3329325298];K=[new c(a[0],3609767458),new c(a[1],602891725),new c(a[2],3964484399),new c(a[3],2173295548),new c(a[4],4081628472),new c(a[5],3053834265),new c(a[6],2937671579),new c(a[7],
3664609560),new c(a[8],2734883394),new c(a[9],1164996542),new c(a[10],1323610764),new c(a[11],3590304994),new c(a[12],4068182383),new c(a[13],991336113),new c(a[14],633803317),new c(a[15],3479774868),new c(a[16],2666613458),new c(a[17],944711139),new c(a[18],2341262773),new c(a[19],2007800933),new c(a[20],1495990901),new c(a[21],1856431235),new c(a[22],3175218132),new c(a[23],2198950837),new c(a[24],3999719339),new c(a[25],766784016),new c(a[26],2566594879),new c(a[27],3203337956),new c(a[28],1034457026),
new c(a[29],2466948901),new c(a[30],3758326383),new c(a[31],168717936),new c(a[32],1188179964),new c(a[33],1546045734),new c(a[34],1522805485),new c(a[35],2643833823),new c(a[36],2343527390),new c(a[37],1014477480),new c(a[38],1206759142),new c(a[39],344077627),new c(a[40],1290863460),new c(a[41],3158454273),new c(a[42],3505952657),new c(a[43],106217008),new c(a[44],3606008344),new c(a[45],1432725776),new c(a[46],1467031594),new c(a[47],851169720),new c(a[48],3100823752),new c(a[49],1363258195),new c(a[50],
3750685593),new c(a[51],3785050280),new c(a[52],3318307427),new c(a[53],3812723403),new c(a[54],2003034995),new c(a[55],3602036899),new c(a[56],1575990012),new c(a[57],1125592928),new c(a[58],2716904306),new c(a[59],442776044),new c(a[60],593698344),new c(a[61],3733110249),new c(a[62],2999351573),new c(a[63],3815920427),new c(3391569614,3928383900),new c(3515267271,566280711),new c(3940187606,3454069534),new c(4118630271,4000239992),new c(116418474,1914138554),new c(174292421,2731055270),new c(289380356,
3203993006),new c(460393269,320620315),new c(685471733,587496836),new c(852142971,1086792851),new c(1017036298,365543100),new c(1126000580,2618297676),new c(1288033470,3409855158),new c(1501505948,4234509866),new c(1607167915,987167468),new c(1816402316,1246189591)];"function"===typeof define&&define.amd?define(function(){return y}):"undefined"!==typeof exports?("undefined"!==typeof module&&module.exports&&(module.exports=y),exports=y):L.jsSHA=y})(this);
