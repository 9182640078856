var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "confirmRoot",
      class: _vm.customDialogClasses,
      attrs: {
        role: "alertdialog",
        "aria-labelledby": "confirmMsgTitle",
        "aria-describedby": "confirmMsgLabel",
      },
    },
    [
      _vm.title
        ? _c("div", { staticClass: "titleBar" }, [
            _c(
              "div",
              {
                staticClass: "title",
                attrs: { id: "confirmMsgTitle", role: "alert" },
              },
              [_vm._v(_vm._s(_vm.title))]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "dialogBody" }, [
        _vm.messageHtml
          ? _c("div", {
              ref: "msgLabel",
              staticClass: "messageText",
              style: _vm.messageTextStyle,
              attrs: { id: "confirmMsgLabel", role: "document", tabindex: "0" },
              domProps: { innerHTML: _vm._s(_vm.message) },
            })
          : _c(
              "div",
              {
                ref: "msgLabel",
                staticClass: "messageText",
                style: _vm.messageTextStyle,
                attrs: {
                  id: "confirmMsgLabel",
                  role: "document",
                  tabindex: "0",
                },
              },
              [_vm._v(_vm._s(_vm.message))]
            ),
        _vm._v(" "),
        _vm.showButtons
          ? _c("div", { staticClass: "controls" }, [
              !_vm.confirm
                ? _c(
                    "div",
                    {
                      ref: "btnOK",
                      staticClass: "dialogButton okButton",
                      attrs: { role: "button", tabindex: "0" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("close", true)
                        },
                        keydown: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k($event.keyCode, "space", 32, $event.key, [
                              " ",
                              "Spacebar",
                            ]) &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          $event.preventDefault()
                          return _vm.$emit("close", true)
                        },
                      },
                    },
                    [_vm._v("\n\t\t\t\t" + _vm._s(_vm.okText) + "\n\t\t\t")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.confirm
                ? _c(
                    "div",
                    {
                      ref: "btnYes",
                      staticClass: "dialogButton yesButton",
                      attrs: { role: "button", tabindex: "0" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("close", true)
                        },
                        keydown: [
                          function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "space", 32, $event.key, [
                                " ",
                                "Spacebar",
                              ]) &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            $event.preventDefault()
                            return _vm.$emit("close", true)
                          },
                          function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "right", 39, $event.key, [
                                "Right",
                                "ArrowRight",
                              ])
                            )
                              return null
                            if ("button" in $event && $event.button !== 2)
                              return null
                            $event.preventDefault()
                            return _vm.FocusNo.apply(null, arguments)
                          },
                        ],
                      },
                    },
                    [_vm._v("\n\t\t\t\t" + _vm._s(_vm.yesText) + "\n\t\t\t")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.confirm
                ? _c(
                    "div",
                    {
                      ref: "btnNo",
                      staticClass: "dialogButton noButton",
                      attrs: { role: "button", tabindex: "0" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("close", false)
                        },
                        keydown: [
                          function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "space", 32, $event.key, [
                                " ",
                                "Spacebar",
                              ]) &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            $event.preventDefault()
                            return _vm.$emit("close", false)
                          },
                          function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "left", 37, $event.key, [
                                "Left",
                                "ArrowLeft",
                              ])
                            )
                              return null
                            if ("button" in $event && $event.button !== 0)
                              return null
                            $event.preventDefault()
                            return _vm.FocusYes.apply(null, arguments)
                          },
                        ],
                      },
                    },
                    [_vm._v("\n\t\t\t\t" + _vm._s(_vm.noText) + "\n\t\t\t")]
                  )
                : _vm._e(),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }