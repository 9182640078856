import { render, staticRenderFns } from "./MergeConflictsPopup.vue?vue&type=template&id=5a8ace80&scoped=true&"
import script from "./MergeConflictsPopup.vue?vue&type=script&lang=js&"
export * from "./MergeConflictsPopup.vue?vue&type=script&lang=js&"
import style0 from "./MergeConflictsPopup.vue?vue&type=style&index=0&id=5a8ace80&prod&lang=css&"
import style1 from "./MergeConflictsPopup.vue?vue&type=style&index=1&id=5a8ace80&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a8ace80",
  null
  
)

export default component.exports