var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.error
    ? _c("div", { staticClass: "error" }, [_vm._v(_vm._s(_vm.error))])
    : _c(
        "div",
        { staticClass: "loading" },
        [_c("ScaleLoader"), _vm._v("\n\tChecking session status…\n")],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }