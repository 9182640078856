<template>
	<div class="editMonitorRoot">
		<div class="title">Edit Monitor</div>
		<Editor ref="monitorEditor" :object="monitor" :spec="editSpec" @valueChanged="valueChanged" />
		<div class="buttonRow">
			<input type="button" value="Delete" @click="Delete" />
		</div>
		<div class="buttonRow">
			<input type="button" value="Close" @click="DefaultClose" />
		</div>
	</div>
</template>
<script>
	import Editor from 'appRoot/vues/common/editor/Editor.vue';
	import { ModalConfirmDialog } from 'appRoot/scripts/ModalDialog';
	import Vue from 'vue';

	export default {
		components: { Editor },
		data()
		{
			return {
			};
		},
		props:
		{
			monitor: {
				type: Object,
				required: true
			},
			editSpec: {
				type: Array,
				required: true
			},
			isAddingMonitor: {
				type: Boolean,
				required: true
			}
		},
		created()
		{
		},
		computed:
		{
		},
		methods:
		{
			SetFocus()
			{
				this.$refs.monitorEditor.focusFirst();
			},
			DefaultClose()
			{
				this.$emit("close", false);
			},
			valueChanged(key, value)
			{
				Vue.set(this.monitor, key, value);
			},
			Delete()
			{
				ModalConfirmDialog("The monitor will be deleted.", "Confirm", "Delete", "Cancel")
					.then(userClickedYes =>
					{
						if (userClickedYes)
						{
							this.monitor.deleteMe = true;
							this.DefaultClose();
						}
					});
			}
		}
	}
</script>
<style scoped>
	.editMonitorRoot
	{
		margin: 10px;
		min-width: 260px;
	}

	.title
	{
		border-bottom: 1px solid #000000;
		margin-bottom: 10px;
		font-size: 20px;
	}

	.buttonRow
	{
		text-align: center;
		margin-top: 1em;
	}
</style>
