let CmsKey = {
	CmsTAMStatus: "CmsTAMStatus",
	CmsContentSyncStatus: "CmsContentSyncStatus",
	CmsLoEStatus: "CmsLoEStatus"
};
export { CmsKey };
/**
 * Construct one instance via "new CmsEventStream()" and use it for the life of the browser tab.
 * 
 * The instance will work with other instances of CmsEventStream in other browser tabs to maintain just one WebSocket connection to the server which retrieves CMS data and shares it with all tabs.
 * 
 * This class will malfunction if the BroadcastChannel Web API is not reliable.
 */
export function CmsEventStream()
{
	let self = this;
	this.debugLog = false;
	this.wsConnectionLog = false;
	/**
	 * My Window ID.
	 */
	const myId = Date.now() + "-" + (appContext ? appContext.guid : Math.random());
	const bc = new BroadcastChannel("CmsEventStream");
	let visible = document.visibilityState === "visible";
	/**
	 * A map of Window ID to an object containing a little data about the window.
	 */
	let knownWindows = {};
	let tickInterval;
	let consoleDisconnectedAt = -99999;
	/**
	 * Title Archive Manager status
	 */
	let lastObjects = {};
	/////////////////////////////////////////
	// Initialization ///////////////////////
	/////////////////////////////////////////
	function Initialize()
	{
		bc.addEventListener("message", onMessage);
		bc.addEventListener("messageerror", onMessageError);
		document.addEventListener("visibilitychange", onVisibilityChange);
		window.addEventListener("beforeunload", onBeforeOnload);
		sendKeepalive();
		tickInterval = setInterval(tick, 1000);
	}
	/////////////////////////////////////////
	// Native event handlers ////////////////
	/////////////////////////////////////////
	function onVisibilityChange()
	{
		visible = document.visibilityState === "visible";
		sendKeepalive();
	}
	function onBeforeOnload()
	{
		document.removeEventListener("visibilitychange", onVisibilityChange);
		send({ isClosing: true });
		bc.close();
		clearInterval(tickInterval);
	}
	/////////////////////////////////////////
	// BroadcastChannel event handlers //////
	/////////////////////////////////////////
	function onMessage(event)
	{
		receive(event.data);
	}
	function onMessageError(event)
	{
		console.error(event);
	}
	/////////////////////////////////////////
	// Send/Receive /////////////////////////
	/////////////////////////////////////////
	function receive(msg)
	{
		// Identify which window this message came from
		let wnd = knownWindows[msg.id];

		if (msg.isClosing)
		{
			// This message was a window informing us it has disconnected.
			if (wnd)
			{
				if (self.debugLog)
					console.log("Window Disconnect: " + msg.id);
				delete knownWindows[msg.id];
			}
			// It is possible that the window that sent the message was running the websocket, so we need to choose a new master window.
			RefreshWebsocketState();
		}
		else
		{
			if (!wnd)
			{
				// We just had a new window connect to the BroadcastChannel.
				knownWindows[msg.id] = wnd = { id: msg.id, age: 0, visible: false };
				if (self.debugLog)
					console.log("Window Connect: " + msg.id);
				if (msg.id !== myId && localStorage.cms_event_stream_master === myId)
				{
					// I am the master window, so I should publish the latest list of objects so the new window gets them.
					send({ consoleConnection: isConnected });
					if (self.debugLog)
						console.log("Broadcasting Last Object State");
					for (let key in lastObjects)
						if (lastObjects.hasOwnProperty(key))
							send({ objKey: key, objValue: lastObjects[key] });
				}
			}
			wnd.age = 0;
			if (msg.alive)
			{
				// This is just a "keepalive" message to let all windows know the visibility state and let them know the window is still active.
				wnd.visible = msg.visible;
				//if (self.debugLog)
				//	console.log("keepalive", msg.visible ? "visible" : "hidden");
			}
			else if (msg.consoleConnection === true)
			{
				consoleDisconnectedAt = Number.MAX_SAFE_INTEGER;
				document.dispatchEvent(new CustomEvent("CmsConsoleConnected"));
			}
			else if (msg.consoleConnection === false)
			{
				consoleDisconnectedAt = performance.now();
				document.dispatchEvent(new CustomEvent("CmsConsoleDisconnected"));
			}
			else if (msg.objKey)
			{
				lastObjects[msg.objKey] = msg.objValue;
				if (self.debugLog)
					console.log("Receive " + msg.objKey, msg.objValue);
				document.dispatchEvent(new CustomEvent(msg.objKey, { detail: msg.objValue }));
			}
			else
			{
				// Unhandled message:
				console.log("Unhandled CmsEventStream message", msg);
			}
		}
	}
	/**
	 * Sends a message to all browser tabs including this one.
	 */
	function send(msg)
	{
		if (!msg)
			msg = { id: myId };
		else
		{
			msg = JSON.parse(JSON.stringify(msg));
			msg.id = myId;
		}
		try
		{
			bc.postMessage(msg);
		}
		catch (ex)
		{
			if (ex.name === 'InvalidStateError')
				return;
			else
				throw ex;
		}
		receive(msg);
	}
	function viewingCms()
	{
		return location.pathname.toLowerCase() === '/client/cms' || location.pathname.toLowerCase().startsWith('/client/cms/');
	}
	function sendKeepalive()
	{
		send({ alive: true, visible: visible && viewingCms() });
	}
	/////////////////////////////////////////
	// Keepalive interval ///////////////////
	/////////////////////////////////////////
	/**
	 * Called on a 1000ms interval.
	 */
	function tick()
	{
		sendKeepalive();
		// Mark windows as disconnected if they have not pinged recently.
		for (let key in knownWindows)
		{
			if (knownWindows.hasOwnProperty(key))
			{
				let wnd = knownWindows[key];
				if (wnd)
				{
					wnd.age++;
					if (wnd.age > 2)
					{
						if (self.debugLog)
							console.log("Window Expired: " + key);
						delete knownWindows[key];
					}
				}
			}
		}
		RefreshWebsocketState();
	}
	let refreshWsStateTimeout = null;
	function RefreshWebsocketState()
	{
		// Find the master window. We're using localStorage to track who is the master window.
		let masterWindowId = localStorage.cms_event_stream_master;
		let wnd = masterWindowId ? knownWindows[masterWindowId] : null;
		if (wnd && wnd.id !== myId && consoleDisconnectedAt < performance.now() - 1000)
			wnd = null;
		if (!wnd)
		{
			if (visible && viewingCms())
			{
				// The master window is not registered?  Make this window the master.
				// This can happen fairly frequently, e.g. when a new window is opened or when a window is closed
				//   and other windows attempt to make themselves master all at the same time.
				// So we'll just propose our ID and check on the next tick if it came out on top.
				localStorage.cms_event_stream_master = myId;
				clearTimeout(refreshWsStateTimeout);
				refreshWsStateTimeout = setTimeout(RefreshWebsocketState, 50);
			}
			return;
		}

		if (wnd.id === myId)
			IShouldHaveWebsocket();
		else
			IShouldNotHaveWebsocket();
	}
	/////////////////////////////////////////
	// WebSocket ////////////////////////////
	/////////////////////////////////////////
	let ws = null;
	let wsPingInterval = null;
	let isConnected = false;
	function IShouldHaveWebsocket()
	{
		if (!ws)
		{
			if (self.debugLog || self.wsConnectionLog)
				console.log("Open WebSocket", new Date().toString());
			ws = new WebSocket(location.origin.replace(/^http/i, "ws") + appContext.appPath + "DataStream/CmsEventStreamWS?sid=" + encodeURIComponent(myApp.$store.state.sid));
			ws.onopen = onWebSocketOpen;
			ws.onclose = onWebSocketClose;
			ws.onerror = onWebSocketError;
			ws.onmessage = onWebSocketMessage;
		}
	}
	function IShouldNotHaveWebsocket()
	{
		clearInterval(wsPingInterval);
		isConnected = false;
		if (ws)
		{
			if (self.debugLog || self.wsConnectionLog)
				console.log("Close WebSocket", new Date().toString());
			if (ws.readyState < 2) // 0: Connecting, 1: Open, 2: Closing, 3: Closed
				ws.close();
			ws = null;
			send({ consoleConnection: false });
		}
	}
	function onWebSocketOpen(e)
	{
		isConnected = true;
		send({ consoleConnection: true });
		clearInterval(wsPingInterval);
		wsPingInterval = setInterval(() =>
		{
			if (isConnected)
			{
				send({ consoleConnection: true });
				ws.send('ping');
			}
		}, 5000);
	}
	function onWebSocketClose(e)
	{
		IShouldNotHaveWebsocket();
	}
	function onWebSocketError(e)
	{
		console.error("WebSocket error:", e);
		IShouldNotHaveWebsocket();
	}
	function onWebSocketMessage(e)
	{
		const data = JSON.parse(e.data);
		if (data.tamStatusJson)
			send({ objKey: CmsKey.CmsTAMStatus, objValue: JSON.parse(data.tamStatusJson) });
		if (data.contentSyncStatus)
			send({ objKey: CmsKey.CmsContentSyncStatus, objValue: data.contentSyncStatus });
		if (typeof data.loeStatus !== "undefined")
			send({ objKey: CmsKey.CmsLoEStatus, objValue: data.loeStatus });
	}
	/////////////////////////////////////////
	// End WebSocket ////////////////////////
	/////////////////////////////////////////
	/**
	 * Gets the unique identifier of this browser tab.
	 */
	this.getId = function ()
	{
		return myId;
	}
	/**
	 * Returns a reference to the last object with the given key received from the web server.
	 */
	this.get = function (key)
	{
		return lastObjects[key];
	}
	this.isConnected = function ()
	{
		return ws && ws.readyState === 1;
	}

	if (typeof BroadcastChannel === "function")
		Initialize();
	else
		alert("This browser is not supported because the browser does not support BroadcastChannel.");
}