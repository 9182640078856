var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "AgentStatusContainer" }, [
    !_vm.loading1 && !_vm.loading2
      ? _c("div", { staticClass: "FloatRight" }, [
          _c(
            "button",
            { staticClass: "refreshButton", on: { click: _vm.loadData } },
            [
              _c("vsvg", {
                staticClass: "refreshIcon",
                attrs: { sprite: "refresh" },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("h2", { staticClass: "Heading" }, [_vm._v("CMS Agent Status")]),
    _vm._v(" "),
    _c("h3", { staticClass: "Heading" }, [_vm._v(_vm._s(_vm.myAgentName))]),
    _vm._v(" "),
    _vm.error1 || _vm.error2
      ? _c("div", { staticClass: "error" }, [
          _vm.error1 ? _c("p", [_vm._v(_vm._s(_vm.error1))]) : _vm._e(),
          _vm._v(" "),
          _vm.error2 ? _c("p", [_vm._v(_vm._s(_vm.error2))]) : _vm._e(),
        ])
      : _vm.loading1 || _vm.loading2
      ? _c(
          "div",
          { staticClass: "loading" },
          [_c("ScaleLoader"), _vm._v(" Loading…\n\t")],
          1
        )
      : _c("div", [
          _c(
            "div",
            {
              staticClass: "connectionStatus",
              class: _vm.agentStatus.connected ? "connected" : "disconnected",
            },
            [
              _c("vsvg", {
                staticClass: "cableIcon",
                attrs: { sprite: "cable" },
              }),
              _vm._v(" "),
              _c("span", {
                domProps: {
                  innerHTML: _vm._s(_vm.agentConnectionStatusString),
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("p", [
            _vm._v("This agent has CMSRole "),
            _c("b", [_vm._v(_vm._s(_vm.agentStatus.CMSRole))]),
            _vm._v("."),
          ]),
          _vm._v(" "),
          _vm.productRoots && _vm.productRevisionMap
            ? _c(
                "div",
                [
                  _vm.agentStatus.CMSRole !== "None"
                    ? [
                        _c(
                          "p",
                          [
                            _vm._v(
                              "\n\t\t\t\t\tThe following is a snapshot of the status of this agent.\n\t\t\t\t\t"
                            ),
                            _vm.agentStatus.connected
                              ? [
                                  _vm._v(
                                    "\n\t\t\t\t\t\tMay be incomplete if the agent reconnected very recently.\n\t\t\t\t\t"
                                  ),
                                ]
                              : _vm._e(),
                            _vm._v(
                              "\n\t\t\t\t\tRefresh page to update.\n\t\t\t\t"
                            ),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c("h4", [_vm._v("Status String")]),
                        _vm._v(" "),
                        _c("p", [_vm._v(_vm._s(_vm.agentStatus.status))]),
                        _vm._v(" "),
                        _c("h4", [_vm._v("Current File Upload")]),
                        _vm._v(" "),
                        _vm.agentStatus.fileSync
                          ? _c("div", { staticClass: "archiveTransfer" }, [
                              _vm._v(
                                "\n\t\t\t\t\t" +
                                  _vm._s(_vm.agentStatus.fileSync.FilePath) +
                                  " - " +
                                  _vm._s(_vm.agentStatus.fileSync.FileSizeStr) +
                                  " @ " +
                                  _vm._s(
                                    _vm.agentStatus.fileSync.BitsPerSecondStr
                                  ) +
                                  " "
                              ),
                              _c("progress", {
                                attrs: {
                                  max: "100",
                                  title:
                                    _vm.agentStatus.fileSync.Progress +
                                    "% " +
                                    _vm.agentStatus.fileSync.FilePath,
                                },
                                domProps: {
                                  value: _vm.agentStatus.fileSync.Progress,
                                },
                              }),
                              _vm._v(" "),
                              _c("div", [
                                _vm._v(
                                  "ETA: " +
                                    _vm._s(
                                      _vm.agentStatus.fileSync.RemainingTime
                                    )
                                ),
                              ]),
                            ])
                          : _c("div", [_vm._v("None")]),
                        _vm._v(" "),
                        _c("h4", [_vm._v("Current Installation Job")]),
                        _vm._v(" "),
                        _vm.agentStatus.installStatus
                          ? _c(
                              "div",
                              {
                                staticClass: "titleRow",
                                attrs: {
                                  title:
                                    "'Revision ID ' + agentStatus.installingProductRevisionId",
                                },
                              },
                              [
                                _c("progress", {
                                  attrs: { max: "100" },
                                  domProps: {
                                    value:
                                      _vm.agentStatus
                                        .installationProgressPercent,
                                  },
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "preformattedText" }, [
                                  _vm._v(
                                    _vm._s(_vm.agentStatus.installAcronym) +
                                      ": " +
                                      _vm._s(_vm.agentStatus.installStatus)
                                  ),
                                ]),
                              ]
                            )
                          : _c("div", [_vm._v("None")]),
                        _vm._v(" "),
                        _c("h4", [
                          _vm._v(
                            "Revisions Staging or Staged (" +
                              _vm._s(_vm.stagingRevs.length) +
                              ")"
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.stagingRevs.length === 0
                          ? _c("div", [_vm._v("None")])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm._l(_vm.stagingRevs, function (sr) {
                          return _c(
                            "router-link",
                            {
                              key: "rev" + sr.rev.ProductRevisionId,
                              staticClass: "ProductRevisionClickable",
                              attrs: {
                                to: _vm.GetRouteToProductRevision(sr.rev),
                                title: _vm.getRevisionTooltip(sr.rev),
                              },
                            },
                            [
                              _c("img", {
                                staticClass: "RevisionImage",
                                attrs: {
                                  src:
                                    _vm.appPath +
                                    "ProductImage/" +
                                    sr.rev.ImageId,
                                },
                              }),
                              _vm._v(" "),
                              _c("span", {
                                staticClass: "ProductRevisionTitle",
                                domProps: {
                                  innerHTML: _vm._s(sr.rev.RevisionNameHtml),
                                },
                              }),
                              _vm._v(" "),
                              _c("CmsLifecycleStateBadge", {
                                attrs: {
                                  text: sr.rev.LifecycleState,
                                  compact: true,
                                },
                              }),
                              _vm._v(" "),
                              sr.isReadyForInstall
                                ? _c(
                                    "span",
                                    {
                                      attrs: {
                                        title: "Archive is fully uploaded",
                                      },
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "IconImage",
                                        attrs: {
                                          src:
                                            _vm.appPath +
                                            "images/document_plain_green.png",
                                        },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              sr.installStatus
                                ? _c("span", [
                                    _vm._v(
                                      "Installing: " +
                                        _vm._s(sr.installProgress) +
                                        "% "
                                    ),
                                    _c("progress", {
                                      attrs: { max: "100" },
                                      domProps: { value: sr.installProgress },
                                    }),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                        _vm._v(" "),
                        _c("h4", [
                          _vm._v(
                            "Products With No Revision Installed (not trashed) (" +
                              _vm._s(_vm.productsNoRevInstalled.length) +
                              ")"
                          ),
                        ]),
                        _vm._v(" "),
                        !_vm.productsNoRevInstalled.length
                          ? _c("div", [_vm._v("None")])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm._l(_vm.productsNoRevInstalled, function (proot) {
                          return _c(
                            "div",
                            {
                              key: "nri" + proot.ProductRootId,
                              staticClass: "NonInstallationRecordRow",
                            },
                            [
                              _c("router-link", {
                                staticClass: "ProductRootClickable",
                                attrs: { to: _vm.GetRouteToProductRoot(proot) },
                                domProps: {
                                  innerHTML: _vm._s(proot.InternalNameHtml),
                                },
                              }),
                              _vm._v(" "),
                              _vm.refusedProductRootIdMap[proot.ProductRootId]
                                ? _c("span", { staticClass: "refused" }, [
                                    _vm._v(
                                      "Agent configured to refuse syncing this product."
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                        _vm._v(" "),
                        _c("h4", [
                          _vm._v(
                            "Products With Revision Installed (" +
                              _vm._s(_vm.productsWithRevInstalled.length) +
                              ")"
                          ),
                        ]),
                        _vm._v(" "),
                        !_vm.productsWithRevInstalled.length
                          ? _c("div", [_vm._v("None")])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm._l(_vm.productsWithRevInstalled, function (item) {
                          return _c(
                            "div",
                            {
                              key: "ri" + item.proot.ProductRootId,
                              staticClass: "InstallationRecordRow",
                            },
                            [
                              _c("router-link", {
                                staticClass: "ProductRootClickable",
                                attrs: {
                                  to: _vm.GetRouteToProductRoot(item.proot),
                                },
                                domProps: {
                                  innerHTML: _vm._s(
                                    item.proot.InternalNameHtml
                                  ),
                                },
                              }),
                              _vm._v(" "),
                              _c("router-link", {
                                staticClass: "ProductRevisionClickable",
                                attrs: {
                                  to: _vm.GetRouteToProductRevision(item.rev),
                                },
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.getIrRevLinkHtml(item.rev)
                                  ),
                                },
                              }),
                              _vm._v(" "),
                              _vm.refusedProductRootIdMap[
                                item.proot.ProductRootId
                              ]
                                ? _c("span", { staticClass: "refused" }, [
                                    _vm._v(
                                      "Agent configured to refuse syncing this product."
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                      ]
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }