var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "v-spinner",
    },
    [
      _c("div", {
        staticClass: "v-scale v-scale1",
        style: [_vm.spinnerStyle, _vm.spinnerDelay1],
      }),
      _c("div", {
        staticClass: "v-scale v-scale2",
        style: [_vm.spinnerStyle, _vm.spinnerDelay2],
      }),
      _c("div", {
        staticClass: "v-scale v-scale3",
        style: [_vm.spinnerStyle, _vm.spinnerDelay3],
      }),
      _c("div", {
        staticClass: "v-scale v-scale4",
        style: [_vm.spinnerStyle, _vm.spinnerDelay4],
      }),
      _c("div", {
        staticClass: "v-scale v-scale5",
        style: [_vm.spinnerStyle, _vm.spinnerDelay5],
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }