var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    { staticClass: "clientNav", attrs: { id: "sideNav" } },
    [
      _c("router-link", { attrs: { to: { name: "clientStatus" } } }, [
        _vm._v("Status"),
      ]),
      _vm._v(" "),
      _vm.permissionCMS
        ? _c("router-link", { attrs: { to: { name: "clientCMS" } } }, [
            _vm._v("CMS"),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.permissionRelease
        ? _c("router-link", { attrs: { to: { name: "clientRelease" } } }, [
            _vm._v("Release"),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("router-link", { attrs: { to: { name: "clientGraphite" } } }, [
        _vm._v("Graphite"),
      ]),
      _vm._v(" "),
      _c(
        "router-link",
        { attrs: { to: { name: "clientNotificationsSetup" } } },
        [_vm._v("Notify")]
      ),
      _vm._v(" "),
      _c("router-link", { attrs: { to: { name: "clientManage" } } }, [
        _vm._v("Account"),
      ]),
      _vm._v(" "),
      _vm.isAdmin
        ? _c("router-link", { attrs: { to: { name: "adminHome" } } }, [
            _vm._v("Admin"),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }