<template>
	<div class="loginRecordsRoot">
		<div v-if="error">{{error}}</div>
		<div v-else-if="loading" class="loading"><ScaleLoader /> Loading…</div>
		<div v-else-if="rows" class="recordsTableContainer">
			<vue-good-table :columns="columns" :rows="rows" @on-row-click="rowClick" />
		</div>
		<div v-else>
			No data.
		</div>
	</div>
</template>

<script>
	import { GetLoginRecordsForSelf, GetLoginRecords } from 'appRoot/api/LoginRecordData';
	import { GetDateStr } from 'appRoot/scripts/Util';
	import { GeolocateIPDialog } from 'appRoot/scripts/ModalDialog';

	export default {
		components: {},
		props:
		{
			userId: {
				type: Number,
				default: 0
			},
			allUsers: {
				type: Boolean,
				default: false
			}
		},
		data()
		{
			return {
				error: null,
				loading: false,
				columns: [
					//{ label: "ID", field: "UserId" },
					{ label: "Name", field: "UserName" },
					{ label: "IP Address", field: "IPAddress" },
					{ label: "Session ID", field: "SessionID" },
					{ label: "Date", field: "Date", formatFn: d => d ? GetDateStr(new Date(d)) : "" },
					{ label: "Passkey ID", field: "CredentialId", tdClass: "credentialIdCell", /*formatFn: str => { return str && str.length > 8 ? str.substr(0, 8) + "…" : str; }*/ },
					{ label: "Logout Date", field: "LogoutDate", formatFn: d => d ? GetDateStr(new Date(d)) : "" },
					{ label: "Logout Reason", field: "LogoutReason", formatFn: r => r !== "None" ? r : "" },
					{ label: "Logout IP", field: "LogoutIp" },
				],
				rows: null
			};
		},
		created()
		{
			this.loadRecords();
		},
		methods:
		{
			loadRecords()
			{
				if (this.allUsers)
				{
					let startDate = new Date();
					startDate.setDate(startDate.getDate() - 30);
					let endDate = new Date();
					endDate.setDate(endDate.getDate() + 30);
					this.handleRecordsPromise(GetLoginRecords(0, startDate.getTime(), endDate.getTime()));
				}
				else if (this.userId)
				{
					this.handleRecordsPromise(GetLoginRecords(this.userId, 0, 0));
				}
				else
				{
					this.handleRecordsPromise(GetLoginRecordsForSelf());
				}
			},
			handleRecordsPromise(promise)
			{
				this.loading = true;
				this.rows = null;
				this.error = null;
				promise
					.then(data =>
					{
						this.loading = false;
						if (data.success)
						{
							this.rows = data.records;
						}
						else
							this.error = data.error;
					})
					.catch(err =>
					{
						this.loading = false;
						this.error = err.message;
					});
			},
			rowClick(params)
			{
				GeolocateIPDialog(params.row.IPAddress);
			},
		}
	};
</script>

<style scoped>
	.loading
	{
		margin: 20px;
		text-align: center;
	}

	.recordsTableContainer::v-deep .credentialIdCell
	{
		max-width: 100px;
		white-space: nowrap;
		overflow-x: hidden;
		text-overflow: ellipsis;
	}
</style>