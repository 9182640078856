<template>
	<div class="loginRecordsRoot">
		<div v-if="error">{{error}}</div>
		<div v-else-if="loading" class="loading"><ScaleLoader /> Loading…</div>
		<div v-else-if="rows" class="recordsTableContainer">
			<vue-good-table :columns="columns" :rows="rows" @on-row-click="rowClick" />
		</div>
		<div v-else>
			No data.
		</div>
	</div>
</template>

<script>
	import { GetFailedLogins } from 'appRoot/api/LoginRecordData';
	import { GetDateStr } from 'appRoot/scripts/Util';
	import { GeolocateIPDialog } from 'appRoot/scripts/ModalDialog';

	export default {
		components: {},
		props:
		{
			ip: {
				type: String,
				default: ""
			}
		},
		data()
		{
			return {
				error: null,
				loading: false,
				columns: [
					{ label: "Date", field: "Date", formatFn: d => d ? GetDateStr(new Date(d)) : "" },
					{ label: "IP Address", field: "IPAddress" },
					{ label: "Count", field: "Count" },
				],
				rows: null,
			};
		},
		created()
		{
			this.loadRecords();
		},
		methods:
		{
			loadRecords()
			{
				let startDate = new Date();
				startDate.setDate(startDate.getDate() - 60);
				let endDate = new Date();
				endDate.setDate(endDate.getDate() + 30);
				this.handleRecordsPromise(GetFailedLogins(this.ip, startDate.getTime(), endDate.getTime()));
			},
			handleRecordsPromise(promise)
			{
				this.loading = true;
				this.rows = null;
				this.error = null;
				promise
					.then(data =>
					{
						this.loading = false;
						if (data.success)
						{
							this.rows = data.records;
						}
						else
							this.error = data.error;
					})
					.catch(err =>
					{
						this.loading = false;
						this.error = err.message;
					});
			},
			rowClick(params)
			{
				GeolocateIPDialog(params.row.IPAddress);
			},
		}
	};
</script>

<style scoped>
	.loading
	{
		margin: 20px;
		text-align: center;
	}
</style>