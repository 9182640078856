import ExecAPI from 'appRoot/api/api';

/**
 * Gets active sessions for the current user.
 * @returns A promise.
 */
export function GetActiveSessions()
{
	return ExecAPI("SessionStatus/GetActiveSessions");
}

/**
 * Requires admin session. Gets all active sessions.
 * @returns A promise.
 */
export function GetAllActiveSessions()
{
	return ExecAPI("SessionStatus/GetAllActiveSessions");
}