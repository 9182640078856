var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pageRoot" }, [
    _c("div", [
      _c("h2", [_vm._v("Graphite Render Configuration")]),
      _vm._v(" "),
      !_vm.graphiteJson
        ? _c("p", [
            _c("input", {
              attrs: { type: "button", value: "Edit GraphiteConfig.json" },
              on: { click: _vm.OpenEditor },
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.error
        ? _c("p", { staticClass: "error" }, [_vm._v(_vm._s(_vm.error))])
        : _vm.loading
        ? _c(
            "div",
            { staticClass: "loading" },
            [_c("ScaleLoader"), _vm._v(" Loading…")],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }