var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "dialogRoot",
      class: { inputDialogRoot: true },
      attrs: {
        role: "dialog",
        "aria-labelledby": "textInputMsgTitle",
        "aria-describedby": "colorInputMsg",
      },
    },
    [
      _vm.title
        ? _c("div", { staticClass: "titleBar" }, [
            _c(
              "div",
              {
                staticClass: "title",
                attrs: { id: "textInputMsgTitle", role: "alert" },
              },
              [_vm._v(_vm._s(_vm.title))]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "dialogBody" }, [
        _vm.message
          ? _c(
              "div",
              { staticClass: "messageText", attrs: { id: "colorInputMsg" } },
              [_vm._v(_vm._s(_vm.message))]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "inputWrapper" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model:value",
                value: _vm.value,
                expression: "value",
                arg: "value",
              },
            ],
            ref: "inputColor",
            staticClass: "colorInput",
            attrs: { type: "color" },
            domProps: { value: _vm.value },
            on: {
              keypress: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.okClicked.apply(null, arguments)
              },
              input: function ($event) {
                if ($event.target.composing) return
                _vm.value = $event.target.value
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "buttons" }, [
          _c(
            "div",
            {
              staticClass: "dialogButton okButton",
              attrs: { tabindex: "0" },
              on: {
                click: _vm.okClicked,
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "space", 32, $event.key, [
                      " ",
                      "Spacebar",
                    ]) &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  $event.preventDefault()
                  return _vm.okClicked.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n\t\t\t\tOK\n\t\t\t")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialogButton cancelButton",
              attrs: { tabindex: "0" },
              on: {
                click: _vm.cancelClicked,
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "space", 32, $event.key, [
                      " ",
                      "Spacebar",
                    ]) &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  $event.preventDefault()
                  return _vm.cancelClicked.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n\t\t\t\tCancel\n\t\t\t")]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }