<template>
	<div class="SavableRegion">
		<div class="SavableContent"><slot></slot></div>
		<div class="SavableOverlay" v-if="saving">
			<div class="SavingProgress">
				<ScaleLoader />
				<br />
				{{text}}
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props:
		{
			saving: {
				type: Boolean,
				required: true
			},
			text: {
				type: String,
				default: "Saving"
			}
		}
	}
</script>

<style scoped>
	.SavableRegion
	{
		position: relative;
	}

	.SavableOverlay
	{
		position: absolute;
		z-index: 10;
		top: 0px;
		left: 0px;
		right: 0px;
		bottom: 0px;
		background-color: rgba(0,0,0,0.5);
		display: flex;
		justify-content: center;
		align-items: flex-start;
	}

	.SavingProgress
	{
		position: sticky;
		top: 50px;
		background-color: #FFFFFF;
		padding: 10px 20px;
		border: 1px solid #EEEEEE;
		border-radius: 5px;
		box-shadow: rgba(0,0,0,0.4) 2px 2px 4px 2px;
	}
</style>