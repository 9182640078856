var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "CreatePasskeyDialog",
      attrs: { role: "alertdialog", "aria-labelledby": "createPasskeyTitle" },
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "dialogBody" }, [
        _c("div", { staticClass: "bodyContent" }, [
          _c("img", {
            staticClass: "keyIconLg",
            attrs: {
              src: _vm.appPath + "Images/key256.png",
              alt: "",
              role: "presentation",
            },
          }),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          !_vm.passkeySupported
            ? _c(
                "div",
                { staticClass: "message warningMessage" },
                [
                  _c("vsvg", {
                    staticClass: "warningIcon",
                    attrs: { sprite: "warning" },
                  }),
                  _vm._m(2),
                ],
                1
              )
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "controls" }, [
          _c(
            "div",
            {
              ref: "btnOK",
              staticClass: "dialogButton okButton",
              attrs: { role: "button", tabindex: "0" },
              on: {
                click: _vm.Close,
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "space", 32, $event.key, [
                      " ",
                      "Spacebar",
                    ]) &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  $event.preventDefault()
                  return _vm.Close.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n\t\t\t\t" + _vm._s(_vm.okText) + "\n\t\t\t")]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "titleBar" }, [
      _c(
        "div",
        {
          staticClass: "title",
          attrs: { id: "createPasskeyTitle", role: "alert" },
        },
        [_vm._v("Create Passkey")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "message" }, [
      _c("p", [
        _vm._v(
          "We are enhancing security by transitioning from traditional passwords to "
        ),
        _c("b", [_vm._v("passkeys")]),
        _vm._v("."),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v("Based on "),
        _c(
          "a",
          {
            attrs: {
              href: "https://fidoalliance.org/passkeys/",
              target: "_blank",
            },
          },
          [_vm._v("FIDO standards")]
        ),
        _vm._v(
          ", passkeys are a replacement for passwords that provide faster, easier, and more secure sign-ins to websites and apps across a user’s devices. Unlike passwords, passkeys are always strong and phishing-resistant.​"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _vm._v("This web browser or device does not support "),
      _c("b", [_vm._v("passkeys")]),
      _vm._v("."),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }