var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: { statusGraphWrapper: true, hasLabels: _vm.labels } },
    [
      _c(
        "div",
        { staticClass: "statusGraph" },
        [
          _c("canvas", { ref: "graphCanvas", staticClass: "graphCanvas" }, [
            _vm._v("HTML5 canvas is not supported"),
          ]),
          _vm._v(" "),
          _vm.labels
            ? [
                _c("div", { staticClass: "axisLabel xAxisLeftLabel" }, [
                  _vm._v(_vm._s(_vm.xAxisLeftLabel)),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "axisLabel xAxisRightLabel" }, [
                  _vm._v(_vm._s(_vm.xAxisRightLabel)),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "axisLabel yAxisTopLabel" }, [
                  _vm._v(_vm._s(_vm.yAxisTopLabel)),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "axisLabel yAxisBottomLabel" }, [
                  _vm._v(_vm._s(_vm.yAxisBottomLabel)),
                ]),
              ]
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }