var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pageRoot" }, [
    _vm.error
      ? _c("div", { staticClass: "error" }, [_vm._v(_vm._s(_vm.error))])
      : _vm.loading
      ? _c(
          "div",
          { staticClass: "loading" },
          [_c("ScaleLoader"), _vm._v(" Loading…")],
          1
        )
      : _vm.editObj && _vm.editSpec
      ? _c(
          "div",
          {},
          [
            _c("Editor", {
              attrs: { object: _vm.editObj, spec: _vm.editSpec },
              on: { valueChanged: _vm.valueChanged },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "buttonRow" }, [
              _c("input", {
                attrs: { type: "button", value: "Save Changes" },
                on: { click: _vm.SaveChanges },
              }),
              _vm._v(" "),
              _c("input", {
                attrs: {
                  type: "button",
                  value: "Forget Changes / Reload Form",
                },
                on: { click: _vm.ForgetChanges },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "buttonRow" }, [
              _c("input", {
                attrs: { type: "button", value: "Restart Server" },
                on: { click: _vm.RestartServerClicked },
              }),
            ]),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }