<template>
	<div class="ProductList" :class="dynClass" v-show="productRoots.length || filteredStarRevList.length">
		<h3 class="ProductListTitle" role="button" tabindex="0" @click="toggle" @keypress.space.enter.prevent="toggle">{{title}} ({{productCount}}) {{!show ? "(collapsed)" : ""}}</h3>
		<div class="ProductListRows" v-if="show">
			<template v-for="item in filteredList">
				<template v-if="item.indexChar">
					<div class="IndexChar" :key="item.id">{{item.indexChar}}</div>
					<div class="IndexCharBorder"></div>
				</template>
				<CmsProductRootTreeNode v-else :key="item.id" :proot="item.proot" :lsText="lsMap[item.proot.ProductRootId]" />
			</template>
		</div>
		<div class="ProductListRows" v-if="showStarredRevs">
			<CmsProductRevisionListItem v-for="item in filteredStarRevList" :key="item.id" :rev="item.prev" :showFxInfo="true" />
		</div>
	</div>
</template>

<script>
	import CmsProductRootTreeNode from 'appRoot/vues/client/controls/CmsProductRootTreeNode.vue';
	import CmsProductRevisionListItem from 'appRoot/vues/client/controls/CmsProductRevisionListItem.vue';
	import { HTMLToText } from 'appRoot/scripts/Util';
	import EventBus from 'appRoot/scripts/EventBus';

	export default {
		components: { CmsProductRootTreeNode, CmsProductRevisionListItem },
		props:
		{
			productRoots: {
				type: Array,
				required: true
			},
			title: {
				type: String,
				required: true
			},
			setName: {
				type: String,
				required: true
			},
			filterText: {
				type: String,
				default: ""
			},
			lsMap: {
				type: Object,
				default: {}
			},
			showStarredRevs: {
				type: Boolean,
				default: false
			}
		},
		data()
		{
			return {
				appPath: appContext.appPath,
				show: true
			};
		},
		created()
		{
			this.show = GetShowSetting(this.setName);
		},
		mounted()
		{

		},
		computed:
		{
			productCount()
			{
				let count = 0;

				let filteredList = this.filteredList;
				for (let i = 0; i < filteredList.length; i++)
					if (filteredList[i].proot)
						count++;

				let filteredStarRevList = this.filteredStarRevList;
				for (let i = 0; i < filteredStarRevList.length; i++)
					if (filteredStarRevList[i].prev)
						count++;

				return count;
			},
			dynClass()
			{
				return "ProductList_" + this.setName;
			},
			filteredList()
			{
				let arr = [];
				let productRoots = this.productRoots;
				let filterTextUpper = this.filterText.toUpperCase();
				let filterTextNumber = parseInt(filterTextUpper);
				if (filterTextNumber.toString() !== filterTextUpper.trim())
					filterTextNumber = 0;
				let lastIndexChar = "";
				for (let i = 0; i < productRoots.length; i++)
				{
					let root = productRoots[i];
					let name = HTMLToText(root.InternalNameHtml).toUpperCase();
					let add = false;
					if (!filterTextUpper)
						add = true;
					else
					{
						if (name.indexOf(filterTextUpper) > -1)
							add = true;
						else if (root.TaylorProductId == filterTextNumber)
							add = true;
						else if (root.FileName.toUpperCase().indexOf(filterTextUpper) > -1)
							add = true;
					}
					if (add)
					{
						let indexChar = name.charAt(0);
						if (indexChar === '_')
							indexChar = '📖';
						if (lastIndexChar !== indexChar)
						{
							if (productRoots.length > 26)
								arr.push({ id: 'indexChar_' + indexChar, indexChar: indexChar });
							lastIndexChar = indexChar;
						}
						arr.push({ id: 'proot_' + root.ProductRootId, proot: root });
					}
				}
				return arr;
			},
			filteredStarRevList()
			{
				let arr = [];
				if (this.showStarredRevs)
				{
					let revs = EventBus.myStarredProductRevisions;
					let filterTextUpper = this.filterText.toUpperCase();
					for (let key in revs)
					{
						if (revs.hasOwnProperty(key))
						{
							let rev = revs[key];
							if (rev)
							{
								let name = HTMLToText(rev.RevisionNameHtml).toUpperCase();
								let add = false;
								if (!filterTextUpper)
									add = true;
								else
								{
									if (name.indexOf(filterTextUpper) > -1)
										add = true;
								}
								if (add)
								{
									arr.push({ id: 'prev_' + rev.ProductRevisionId, prev: rev });
								}
							}
						}
					}
					arr.sort((a, b) =>
					{
						let diff = a.prev.RevisionNameHtml.localeCompare(b.prev.RevisionNameHtml);
						if (diff === 0)
							diff = b.prev.ProductRevisionId - a.prev.ProductRevisionId;
						return diff;
					});
				}
				return arr;
			}
		},
		methods:
		{
			toggle()
			{
				this.show = !this.show;
				SetShowSetting(this.setName, this.show);
			}
		},
		watch:
		{
			setName()
			{
				this.show = GetShowSetting(this.setName);
			}
		}
	}
	function GetShowSetting(name)
	{
		return localStorage["cms_show_" + name] !== '0';
	}
	function SetShowSetting(name, value)
	{
		localStorage["cms_show_" + name] = value ? "1" : "0";
	}
</script>

<style scoped>
	.ProductList
	{
		word-break: break-word;
		padding: 1px 0px !important;
		margin: 1em 8px;
	}

	.IndexChar
	{
		font-size: 1.2em;
		font-weight: bold;
		/*margin: 0.25em 0px 0.25em 0px;*/
		padding: 1em 0.25em 0.15em 0.25em;
	}

		.IndexChar:first-child
		{
			padding-top: 0px;
		}

	.IndexCharBorder
	{
		background: linear-gradient(0.25turn, #1A6999 0%, #ffffff 75%);
		height: 2px;
		margin-bottom: 0.25em;
	}

	.ProductListTitle
	{
		border-top: 1px solid #AAAAAA;
		border-bottom: 1px solid #AAAAAA;
		background-color: rgba(0,0,0,0.03);
		cursor: pointer;
		margin: 0px;
		padding: 0.5em;
	}

		.ProductListTitle:hover
		{
			background-color: rgba(0,0,0,0.09);
		}

	.ProductListRows
	{
		margin: 1em 0px;
	}

	.ProductList_liveDeployingOnline
	{
		background-color: rgb(255 180 180);
	}

	.ProductList_starredByYou .ProductListTitle
	{
		background-color: rgb(255 255 128);
	}

	.ProductList_staging .ProductListTitle
	{
		background-color: rgb(255 245 180);
	}

	.ProductList_upcoming .ProductListTitle
	{
		background-color: rgb(255 207 180);
	}

	.ProductList_atRest .ProductListTitle
	{
	}

	.ProductList_noLiveRevision .ProductListTitle
	{
		background-color: rgb(255 180 180);
	}

	.ProductList_trash .ProductListTitle
	{
		background-color: #FFEEEE;
	}
</style>