<template>
	<div class="pageRoot">
		<div v-if="(error)" class="error">{{error}}</div>
		<div v-else-if="loading" class="loading"><ScaleLoader /> Loading…</div>
		<template v-else-if="monitors">
			<draggable v-model="monitors" class="monitorsContainer">
				<div v-for="(m, index) in monitors"
					 :key="index"
					 :class="{ monitorRow: true, disabled: !m.Enabled }"
					 @click="rowClick(m, index)"
					 v-html="serializeMonitorHtml(m)">
				</div>
			</draggable>
			<div class="buttonRow">
				<input type="button" value="Add Monitor" @click="addMonitor" />
			</div>
			<div class="buttonRow">
				<input type="button" value="Save Changes" @click="SaveChanges" />
			</div>
			<p>When you save changes to the monitor list, TDS App Service will immediately adopt the new list.  A service restart is not required.</p>
			<p>Please note: Any actions which change the name of a monitor will not migrate existing notification registrations.  Users will need to resubscribe to notifications for the monitor.</p>
		</template>
	</div>
</template>

<script>
	import { GetMonitoringConfig, SetMonitoringConfig } from 'appRoot/api/MonitoringConfig';
	import { ModalDialog } from 'appRoot/scripts/ModalDialog';
	import EditMonitor from 'appRoot/vues/admin/controls/EditMonitor.vue';
	import draggable from 'vuedraggable';
	import { EscapeHTML, CreateEditorObject } from 'appRoot/scripts/Util';

	export default {
		components: { draggable },
		data()
		{
			return {
				error: null,
				loading: false,
				monitors: null
			};
		},
		computed:
		{
		},
		created()
		{
			this.LoadMonitors();
		},
		methods:
		{
			LoadMonitors()
			{
				this.loading = true;
				this.monitors = null;
				this.editSpec = null;
				GetMonitoringConfig()
					.then(data =>
					{
						if (data.success)
						{
							this.monitors = data.monitors;
							this.editSpec = data.editSpec;
						}
						else
							this.error = data.error;
					})
					.catch(err =>
					{
						this.error = err.message;
					})
					.finally(() =>
					{
						this.loading = false;
					});
			},
			SaveChanges()
			{
				this.loading = true;
				SetMonitoringConfig(this.monitors)
					.then(data =>
					{
						if (data.success)
						{
							if (data.message)
								toaster.success(data.message);
							this.LoadMonitors();
						}
						else
						{
							toaster.error(data.error);
							this.loading = false;
						}
					})
					.catch(err =>
					{
						toaster.error(err.message);
						this.loading = false;
					});
			},
			rowClick(monitor, index)
			{
				ModalDialog(EditMonitor, { monitor: monitor, editSpec: this.editSpec, isAddingMonitor: false })
					.then(result =>
					{
						if (monitor.deleteMe)
						{
							for (var i = 0; i < this.monitors.length; i++)
							{
								if (this.monitors[i] === monitor)
									this.monitors.splice(i, 1);
							}
						}
						// Force vue to re-render the list
						this.monitors.push({});
						this.monitors.pop();
					});
			},
			addMonitor()
			{
				let monitor = CreateEditorObject(this.editSpec);

				this.monitors.push(monitor);
				ModalDialog(EditMonitor, { monitor: monitor, editSpec: this.editSpec, isAddingMonitor: true })
					.then(result =>
					{
						if (monitor.deleteMe)
						{
							for (var i = 0; i < this.monitors.length; i++)
							{
								if (this.monitors[i] === monitor)
									this.monitors.splice(i, 1);
							}
						}
						// Force vue to re-render the list
						this.monitors.push({});
						this.monitors.pop();
					});
			},
			serializeMonitorHtml(m)
			{
				let str = "<b>" + EscapeHTML(m.Type + ": ") + '</b><span class="monitorUrl">' + EscapeHTML(m.HostOrUrl) + "</span>";
				if (m.ExpectedResponse)
					str += ' -&gt; <span class="monitorResponseText">' + EscapeHTML(m.ExpectedResponse) + "</span>";
				if (m.ExpectedResponseStatusCodes && m.ExpectedResponseStatusCodes.length)
					str += ' <span class="monitorResponseCodes">[' + EscapeHTML(m.ExpectedResponseStatusCodes.join(", ")) + "]</span>";
				if (m.NameOverride)
					str += " Name: <b>" + EscapeHTML(m.NameOverride) + "</b>";
				if (m.Port)
					str += " Port " + EscapeHTML(m.Port);
				if (m.FtpExplicitTls)
					str += " ExplicitTLS"
				if (m.User)
					str += " User: " + EscapeHTML(m.User);
				if (m.Pass)
					str += " Pass: [" + m.Pass.length + "]";
				if (m.UserAppPath)
					str += " " + EscapeHTML(m.UserAppPath);
				if (m.AdminAppPath)
					str += " " + EscapeHTML(m.AdminAppPath);
				if (m.ServerName)
					str += " (<b>" + EscapeHTML(m.ServerName) + "</b>)";
				return str;
			}
		}
	}
</script>

<style>
	/* unscoped */
	.monitorUrl
	{
		font-weight: bold;
		color: #0060DF;
	}

	.monitorResponseText
	{
		font-weight: bold;
		color: #00CD00;
	}

	.monitorResponseCodes
	{
		font-weight: bold;
		color: #FF8800;
	}
</style>
<style scoped>
	.pageRoot
	{
		margin: 8px;
	}

	.loading
	{
		margin-top: 80px;
		text-align: center;
	}

	.error
	{
		color: #FF0000;
		font-weight: bold;
	}

	.buttonRow
	{
		margin-top: 30px;
		border-top: 1px solid #AAAAAA;
		padding-top: 10px;
	}

	.monitorsContainer
	{
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	.monitorRow
	{
		border: 1px solid #000000;
		border-radius: 3px;
		padding: 1px 4px;
		margin-bottom: 5px;
		cursor: grab;
	}

		.monitorRow:hover
		{
			background-color: #f0f8ff;
			box-shadow: 1px 1px 2px 0px rgba(0,0,0,0.6);
		}

		.monitorRow.disabled
		{
			background-color: #ffcece;
			border-color: #FF0000;
		}
</style>