<template>
	<div :class="{ pageRoot: true }">
		<div v-if="error" class="error">{{error}}</div>
		<div v-else-if="loading" class="loading"><ScaleLoader /> Loading…</div>
		<div v-else>
			This page is not implemented.
		</div>
	</div>
</template>

<script>

	export default {
		components: {},
		props:
		{
			serverName: {
				type: String,
				default: ""
			}
		},
		data()
		{
			return {
				error: null,
				loading: false
			};
		},
		created()
		{
		},
		computed:
		{
		},
		methods:
		{
		},
		watch:
		{
		}
	}
</script>

<style scoped>
	.pageRoot
	{
		margin: 8px;
	}

	.loading
	{
		margin-top: 80px;
		text-align: center;
	}

	.error
	{
		color: #FF0000;
		font-weight: bold;
	}
</style>