var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.multiline
        ? [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.inputText,
                  expression: "inputText",
                },
              ],
              staticClass: "multilineTextContainer",
              attrs: { disabled: _vm.disabled },
              domProps: { value: _vm.inputText },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.inputText = $event.target.value
                },
              },
            }),
            _vm._v(" "),
            _c("input", {
              attrs: {
                type: "button",
                value: "save",
                disabled: _vm.disabled || !_vm.hasChanges,
              },
              on: { click: _vm.saveText },
            }),
          ]
        : [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.inputText,
                  expression: "inputText",
                },
              ],
              staticClass: "singlelineTextContainer",
              attrs: { type: "text", disabled: _vm.disabled },
              domProps: { value: _vm.inputText },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.inputText = $event.target.value
                },
              },
            }),
            _vm._v(" "),
            _c("input", {
              staticClass: "singlelineSave",
              attrs: {
                type: "button",
                value: "save",
                disabled: _vm.disabled || !_vm.hasChanges,
              },
              on: { click: _vm.saveText },
            }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }