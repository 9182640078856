<template>
	<div class="pageRoot">
		<h2>Ace Editor Tests</h2>
		<div class="buttonBar">
			<button class="buttonBarButton" @click="testAceEditor('css')">
				<span>CSS</span>
			</button>
			<button class="buttonBarButton" @click="testAceEditor('javascript')">
				<span>JavaScript</span>
			</button>
			<button class="buttonBarButton" @click="testAceEditor('json')">
				<span>JSON</span>
			</button>
			<button class="buttonBarButton" @click="testAceEditor('html')">
				<span>HTML</span>
			</button>
			<button class="buttonBarButton" @click="testAceEditor('xml')">
				<span>XML</span>
			</button>
		</div>
		<h2>Product Image Data</h2>
		<div class="buttonBar">
			<button class="buttonBarButton" @click="GetProductImageData()">
				<span>Get ProductImage Data</span>
			</button>
		</div>
		<h2>ChromeDriver Problems</h2>
		<p>Current overall CPU usage is {{overallCPU}} with {{chromeInstanceCount}} chrome processes using {{chromeCPU}} CPU.</p>
		<div class="buttonBar">
			<button class="buttonBarButton" @click="StopChromeInstances()">
				<span>Stop Chrome Instances</span>
			</button>
		</div>
	</div>
</template>

<script>
	import { AceCodeEditorDialog, ModalMessageDialog, ProgressDialog } from 'appRoot/scripts/ModalDialog';
	import ExecAPI from 'appRoot/api/api';

	export default {
		components: {},
		data()
		{
			return {
				overallCPU: "[loading]",
				chromeInstanceCount: "[loading]",
				chromeCPU: "[loading]"
			};
		},
		created()
		{
			this.GetCPUData();
		},
		computed:
		{
		},
		methods:
		{
			async testAceEditor(type)
			{
				let ext = "." + type;
				let testContent = "Unknown type: " + type;
				if (type === 'css')
					testContent = 'body\n{\n\tbackground-color: #00FF00;\n}';
				else if (type === 'javascript')
				{
					ext = ".js";
					testContent = 'function Fun(arg)\n{\n\talert("Argument: " + arg);\n}\n\nFun(42);';
				}
				else if (type === 'json')
					testContent = '{\n\t"Name": "TDS App Service"\n}';
				else if (type === 'html')
					testContent = 'My <b>Test</b> Content\n<p>includes a paragraph</p>';
				else if (type === 'xml')
					testContent = '<test>\n\t<el attr="value">Text</el>\n</test>';
				let result = await AceCodeEditorDialog("Test" + ext, testContent, "ace/mode/" + type, null, null, null);
			},
			async GetCPUData()
			{
				try
				{
					let data = await ExecAPI("TestData/GetCPUData");
					if (data.error)
						ModalMessageDialog(data.error, "Error");
					else
					{
						this.overallCPU = data.systemInfo.CpuUsage.toFixed(1) + "%";
						this.chromeInstanceCount = data.systemInfo.ChromeCount;
						this.chromeCPU = data.systemInfo.ChromeCpuUsage.toFixed(1) + "%";
					}
				}
				catch (ex)
				{
					ModalMessageDialog(ex.toString(), "Exception");
				}
			},
			async StopChromeInstances()
			{
				let progressDialog = ProgressDialog("Stopping Chrome Instances");
				try
				{
					let data = await ExecAPI("TestData/StopChromeInstances");
					if (data.error)
						ModalMessageDialog(data.error, "Error");
					else
						ModalMessageDialog("Chrome Instances Stopped.", "Completed");
				}
				catch (ex)
				{
					ModalMessageDialog(ex.toString(), "Exception");
				}
				finally
				{
					progressDialog.close();
				}
			},
			async GetProductImageData()
			{
				let progressDialog = ProgressDialog("Loading ProductImage data");
				try
				{
					let data = await ExecAPI("TestData/GetProductImageData");
					if (data.success)
					{
						ModalMessageDialog(data.str, "ProductImage Data");
					}
					else
						ModalMessageDialog(data.error, "Error");
				}
				catch (ex)
				{
					ModalMessageDialog(ex.toString(), "Exception");
				}
				finally
				{
					progressDialog.close();
				}
			}
		}
	}
</script>

<style scoped>
	.pageRoot
	{
		margin: 8px;
	}

	.buttonBar
	{
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}
</style>