<template>
	<div v-if="error" class="error">{{error}}</div>
	<div v-else class="loading">
		<ScaleLoader />
		Checking session status…
	</div>
</template>
<script>
	import ExecAPI from 'appRoot/api/api';

	export default {
		components: {},
		data()
		{
			return {
				error: null
			};
		},
		async created()
		{
			try
			{
				let response = await ExecAPI("SessionStatus/IsSessionActive");
				if (!response.error)
					this.$router.push({ name: 'clientStatus' });
				else
					this.$router.push({ name: 'login' });
			}
			catch (ex)
			{
				this.error = ex.toString();
			}
		}
	}
</script>
<style scoped>
	.loading
	{
		padding-top: 2em;
		text-align: center;
		font-size: 12pt;
	}

	.error
	{
		padding-top: 2em;
		text-align: center;
		font-size: 12pt;
	}
</style>
