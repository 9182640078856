<template>
	<div :class="{ pageRoot: true }">
		<h2>CMS - Taylor DB Data Sync</h2>
		<div v-if="error" class="error">{{error}}</div>
		<div v-else-if="loading" class="loading"><ScaleLoader /> Loading…</div>
		<template v-else-if="syncData">
			<p>The following lists are stored in the Taylor database, and are periodically synchronized to the CMS.</p>
			<div class="section">
				<h3>Security Levels</h3>
				<div class="list">
					<div class="listItem" v-for="str in syncData.securityLevels" :key="str">
						<span class="listItemText">{{str}}</span>
					</div>
				</div>
			</div>
			<div class="section">
				<h3>Bibliography Types</h3>
				<div class="list">
					<div class="listItem" v-for="str in syncData.bibliographyTypes" :key="str">
						<span class="listItemText">{{str}}</span>
					</div>
				</div>
			</div>
			<div class="section">
				<h3>Simple Publisher Options</h3>
				<div class="list">
					<div class="listItem" v-for="str in syncData.publisherNames" :key="str" :class="{ noRefs: refCount(str) === 0 }">
						<span class="listItemText">{{str}}</span>
						<span v-if="syncData.publisherReferenceCounts && typeof syncData.publisherReferenceCounts[str] !== 'undefined'" title="Number of Products that referenced this publisher type as of the last data sync."> ({{refCount(str)}})</span>
					</div>
				</div>
			</div>
			<div class="buttonBar">
				<button class="buttonBarButton" @click="addNewSimplePublisher()" title="Adds a new string to the PublisherTypeTbl table in the Taylor DB">
					<vsvg sprite="add" />
					<span>Add New Simple Publisher</span>
				</button>
				<button class="buttonBarButton" @click="deleteSimplePublisher()" title="Deletes a string from the PublisherTypeTbl table in the Taylor DB. Requires the publisher to not be referenced by any products.">
					<vsvg sprite="delete_forever" />
					<span>Delete a Simple Publisher</span>
				</button>
				<button class="buttonBarButton" @click="cmsSyncNow()" title="Gets new Bibliography Types, Publisher Names, Permission Levels, Product Trash states, etc.  This operation occurs automatically on a 10 minute interval, but this button will trigger it manually.">
					<vsvg sprite="update" />
					<span>Sync from Taylor DB</span>
				</button>
				<button class="buttonBarButton" @click="forceFullSync()">
					<vsvg sprite="update" />
					<span>Force Full Metadata Sync outside normal schedule (~midnight)</span>
				</button>
			</div>
		</template>
		<div v-else>
			CMS data loading failed for an unknown reason.
		</div>
	</div>
</template>

<script>
	import { CMSSyncNow, GetCMSSyncData, CMSAddSimplePublisherOption, CMSDeleteSimplePublisherOption, ForceFullSync } from 'appRoot/api/CMSUserData';
	import svg1 from 'appRoot/images/sprite/update.svg';
	import svg2 from 'appRoot/images/sprite/add.svg';
	import svg3 from 'appRoot/images/sprite/delete_forever.svg';
	import SavableRegion from 'appRoot/vues/common/controls/SavableRegion.vue';
	import { ModalMessageDialog, TextInputDialog, ProgressDialog } from 'appRoot/scripts/ModalDialog';

	export default {
		components: { SavableRegion },
		props:
		{
		},
		data()
		{
			return {
				error: null,
				loading: false,
				syncData: null
			};
		},
		created()
		{
			this.loadData();
		},
		computed:
		{
		},
		methods:
		{
			async loadData()
			{
				this.loading = true;
				this.error = null;
				try
				{
					let response = await GetCMSSyncData();
					if (!response.error)
						this.handleDataResponse(response);
					else
						this.error = response.error;
				}
				catch (ex)
				{
					this.error = ex.toString();
				}
				finally
				{
					this.loading = false;
				}
			},
			handleDataResponse(response)
			{
				this.syncData = {
					securityLevels: response.securityLevels,
					publisherNames: response.publisherNames,
					bibliographyTypes: response.bibliographyTypes,
					publisherReferenceCounts: response.publisherReferenceCounts
				};
			},
			async cmsSyncNow()
			{
				this.loading = true;
				this.error = null;
				try
				{
					let data = await CMSSyncNow();
					if (data.success)
						this.loadData();
					else
					{
						this.error = data.error;
						this.loading = false;
					}
				}
				catch (err)
				{
					this.error = err;
					this.loading = false;
				}
			},
			async addNewSimplePublisher(nameStr)
			{
				if (!nameStr)
					nameStr = "";
				let result = await TextInputDialog("New Simple Publisher Option", "Enter a name for the new Simple Publisher option. It will be added to the Taylor DB, then synced into the CMS and made available in Simple Publisher dropdown lists.", "Publisher Name", nameStr);
				if (result && result.value)
				{
					let progressDialog = ProgressDialog("Creating new Simple Publisher…");
					try
					{
						let data = await CMSAddSimplePublisherOption(result.value);
						if (data.success)
						{
							this.handleDataResponse(data);
						}
						else
						{
							await ModalMessageDialog(data.error, "Error");
							this.addNewSimplePublisher(result.value);
						}
					}
					catch (err)
					{
						await ModalMessageDialog(err, "Error");
						this.addNewSimplePublisher(result.value);
					}
					finally
					{
						if (progressDialog)
							progressDialog.close();
					}
				}
			},
			async deleteSimplePublisher(nameStr)
			{
				if (!nameStr)
					nameStr = "";
				let result = await TextInputDialog("Delete Simple Publisher", "Enter the name of the Simple Publisher you want to delete. It will be deleted from the Taylor DB if no products are referencing it.", "Publisher Name", nameStr);
				if (result && result.value)
				{
					let progressDialog = ProgressDialog("Deleting Simple Publisher…");
					try
					{
						let data = await CMSDeleteSimplePublisherOption(result.value);
						if (data.success)
						{
							this.handleDataResponse(data);
						}
						else
						{
							await ModalMessageDialog(data.error, "Error");
							this.deleteSimplePublisher(result.value);
						}
					}
					catch (err)
					{
						await ModalMessageDialog(err, "Error");
						this.deleteSimplePublisher(result.value);
					}
					finally
					{
						if (progressDialog)
							progressDialog.close();
					}
				}
			},
			async forceFullSync()
			{
				try
				{
					let data = await ForceFullSync();
					if (data.success)
					{
						await ModalMessageDialog("Sync Starting", "Success");
					}
					else
					{
						await ModalMessageDialog(data.error, "Error");
					}
				}
				catch (err)
				{
					await ModalMessageDialog(err, "Error");
				}
			},
			refCount(publisherName)
			{
				if (this.syncData && this.syncData.publisherReferenceCounts)
					return this.syncData.publisherReferenceCounts[publisherName];
				return undefined;
			},
		},
		watch:
		{
		}
	}
</script>

<style scoped>
	.pageRoot
	{
		padding-bottom: 8px;
		box-sizing: border-box;
		overflow-x: hidden;
	}

		.pageRoot > *
		{
			padding-left: 8px;
			padding-right: 8px;
		}

			.pageRoot > *:first-child
			{
				margin-top: 8px;
			}

			.pageRoot > *:last-child
			{
				margin-bottom: 8px;
			}

	h2
	{
		margin-top: 0.2em;
	}

	.loading
	{
		margin-top: 80px;
		text-align: center;
	}

	.error
	{
		color: #FF0000;
		font-weight: bold;
	}

	.list
	{
		columns: 16em auto;
		margin: 0em 1em;
	}

	.listItem
	{
		padding: 2px;
		background-color: rgba(0,0,0,0.08);
	}

		.listItem:nth-child(2n+1)
		{
			background-color: rgba(0,0,0,0.02);
		}

		.listItem.noRefs
		{
			color: #CC0000;
		}

	.listItemText
	{
		user-select: all;
	}

	.buttonBar
	{
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}
</style>