var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: {
        pageRoot: true,
        inTrash: _vm.productRoot && _vm.productRoot.Trash,
      },
    },
    [
      _vm.error
        ? _c("div", { staticClass: "error SidePadding" }, [
            _c("h2", [_vm._v("CMS Product Root " + _vm._s(_vm.productRootId))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.error))]),
          ])
        : _vm.loading
        ? _c("div", { staticClass: "loading SidePadding" }, [
            _c("h2", [_vm._v("CMS Product Root " + _vm._s(_vm.productRootId))]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "center" },
              [_c("ScaleLoader"), _vm._v(" Loading…")],
              1
            ),
          ])
        : _vm.productRoot
        ? _c("SavableRegion", { attrs: { saving: _vm.saving } }, [
            _c("div", { staticClass: "stickyTop" }, [
              _c(
                "div",
                {
                  staticClass: "productRootBadge",
                  attrs: { title: "This page represents a ProductRoot" },
                },
                [_vm._v("Root")]
              ),
              _vm._v(" "),
              _c("h3", [
                _c("img", {
                  staticClass: "product-star",
                  attrs: {
                    src: _vm.starImgProductRoot,
                    alt: "star",
                    role: "button",
                    tabindex: "0",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.toggleStarProductRoot(_vm.productRoot)
                    },
                    keypress: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k(
                          $event.keyCode,
                          "enter",
                          13,
                          $event.key,
                          "Enter"
                        ) &&
                        _vm._k($event.keyCode, "space", 32, $event.key, [
                          " ",
                          "Spacebar",
                        ])
                      )
                        return null
                      $event.preventDefault()
                      return _vm.toggleStarProductRoot(_vm.productRoot)
                    },
                  },
                }),
                _c("span", {
                  domProps: {
                    innerHTML: _vm._s(_vm.productRoot.InternalNameHtml),
                  },
                }),
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "SaveButton",
                  class: { disabled: !_vm.hasChanges },
                  on: { click: _vm.saveProductRoot },
                },
                [_vm._v("Save")]
              ),
              _vm._v(" "),
              _vm.currentUsersHtml ||
              _vm.recordChangedServerside ||
              _vm.pageStateUnavailable
                ? _c(
                    "div",
                    { staticClass: "FloatingBelow" },
                    [
                      _vm.pageStateUnavailable
                        ? _c("vsvg", {
                            staticClass: "page_state_unavailable_img",
                            attrs: {
                              sprite: "cable",
                              title:
                                "This icon means the page state could not be loaded",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("span", {
                        domProps: { innerHTML: _vm._s(_vm.currentUsersHtml) },
                      }),
                      _vm._v(" "),
                      _vm.recordChangedServerside
                        ? _c("div", [_vm._v("Record Changed Serverside!")])
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "SidePadding" },
              [
                _vm.productRoot.Trash
                  ? _c("div", { staticClass: "trashContainer" }, [
                      _c(
                        "div",
                        { staticClass: "trash" },
                        [
                          _c("vsvg", {
                            staticClass: "trash_img",
                            attrs: { sprite: "delete" },
                          }),
                          _vm._v(
                            " This product is in the trash, making it inaccessible to customers."
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "a",
                    {
                      staticClass: "alwaysUnvisited",
                      attrs: { role: "button", tabindex: "0" },
                      on: {
                        click: _vm.OpenCmsProductLinksDialog,
                        keypress: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            ) &&
                            _vm._k($event.keyCode, "space", 32, $event.key, [
                              " ",
                              "Spacebar",
                            ])
                          )
                            return null
                          $event.preventDefault()
                          return _vm.OpenCmsProductLinksDialog.apply(
                            null,
                            arguments
                          )
                        },
                      },
                    },
                    [
                      _vm._v(
                        "Taylor Product ID: " +
                          _vm._s(_vm.productRoot.TaylorProductId)
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "EditField",
                    attrs: {
                      title:
                        "This product image is determined by the currently-published Revision.",
                    },
                  },
                  [
                    _c("div", { staticClass: "EditFieldTitle" }, [
                      _vm._v("Product Image "),
                      _c(
                        "a",
                        {
                          staticClass: "openImgLink",
                          attrs: {
                            href: _vm.productImgSrc,
                            download: "p" + _vm.productRootId.toString(),
                            title: "download image",
                          },
                        },
                        [
                          _c("vsvg", {
                            staticClass: "download_img",
                            attrs: { sprite: "upload" },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "EditFieldValue" }, [
                      _c("img", {
                        staticClass: "ProductImage",
                        attrs: { src: _vm.productImgSrc, alt: "product image" },
                      }),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("CmsEditField", {
                  attrs: {
                    orig: _vm.orig.InternalNameHtml,
                    name: "Internal Product Name (Html Supported)",
                    comment: "©®™",
                  },
                  model: {
                    value: _vm.productRoot.InternalNameHtml,
                    callback: function ($$v) {
                      _vm.$set(_vm.productRoot, "InternalNameHtml", $$v)
                    },
                    expression: "productRoot.InternalNameHtml",
                  },
                }),
                _vm._v(" "),
                _c("CmsEditField", {
                  attrs: { orig: _vm.orig.FileName, name: "Fx File Name" },
                  model: {
                    value: _vm.productRoot.FileName,
                    callback: function ($$v) {
                      _vm.$set(_vm.productRoot, "FileName", $$v)
                    },
                    expression: "productRoot.FileName",
                  },
                }),
                _vm._v(" "),
                _c("CmsEditField", {
                  attrs: {
                    orig: _vm.orig.ProductType,
                    name: "Product Type",
                    kind: "select",
                    options: _vm.productTypes,
                  },
                  model: {
                    value: _vm.productRoot.ProductType,
                    callback: function ($$v) {
                      _vm.$set(_vm.productRoot, "ProductType", $$v)
                    },
                    expression: "productRoot.ProductType",
                  },
                }),
                _vm._v(" "),
                _c("CmsEditField", {
                  attrs: {
                    orig: _vm.orig.PermissionLevel,
                    name: "Minimum Permission Level",
                    kind: "select",
                    options: _vm.securityLevels,
                    comment:
                      '(Permission level is primarily for Help titles. Most should be "User")',
                  },
                  model: {
                    value: _vm.productRoot.PermissionLevel,
                    callback: function ($$v) {
                      _vm.$set(_vm.productRoot, "PermissionLevel", $$v)
                    },
                    expression: "productRoot.PermissionLevel",
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "CmsConfigFields" },
                  [
                    _c("div", { staticClass: "CmsConfigFieldsLabel" }, [
                      _vm._v("Internal Configuration (ProductRoot)"),
                    ]),
                    _vm._v(" "),
                    _c("CmsEditField", {
                      attrs: {
                        orig: _vm.orig.CmsConfig.CategorizeAsOfflineTitle,
                        name: "Categorize as Offline Title",
                        kind: "checkbox",
                        title:
                          "If checked, this product gets categorized as an 'offline title' so that it does not appear in the 'Upcoming' or 'Titles at Rest' categories of the CMS homepage.",
                      },
                      model: {
                        value:
                          _vm.productRoot.CmsConfig.CategorizeAsOfflineTitle,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.productRoot.CmsConfig,
                            "CategorizeAsOfflineTitle",
                            $$v
                          )
                        },
                        expression:
                          "productRoot.CmsConfig.CategorizeAsOfflineTitle",
                      },
                    }),
                    _vm._v(" "),
                    _c("CmsEditField", {
                      attrs: {
                        orig: _vm.orig.CmsConfig.Comment,
                        name: "Internal notes for this ProductRoot",
                        title:
                          "A comment field for internal documentation or note-taking purposes.  For example, it could explain why the product was categorized as an offline title.",
                        kind: "textarea",
                        multiLine: true,
                      },
                      model: {
                        value: _vm.productRoot.CmsConfig.Comment,
                        callback: function ($$v) {
                          _vm.$set(_vm.productRoot.CmsConfig, "Comment", $$v)
                        },
                        expression: "productRoot.CmsConfig.Comment",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "EditField" }, [
                  _c("div", { staticClass: "EditFieldTitle" }, [
                    _vm._v("Product Revisions"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "EditFieldValue" },
                    [
                      _vm.productRevisions.length
                        ? _vm._l(_vm.productRevisions, function (rev) {
                            return _c("CmsProductRevisionListItem", {
                              key: rev.ProductRevisionId,
                              staticClass: "ProductRevision",
                              attrs: { rev: rev },
                            })
                          })
                        : _c("div", { staticClass: "NoRevisions" }, [
                            _vm._v(
                              "\n\t\t\t\t\t\t[No Revisions Yet]\n\t\t\t\t\t"
                            ),
                          ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "buttonBar" }, [
                        _c(
                          "button",
                          {
                            staticClass: "buttonBarButton",
                            attrs: {
                              title:
                                "Create a new ProductRevision for this ProductRoot.",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.onCreateRevision()
                              },
                            },
                          },
                          [
                            _c("img", {
                              staticClass: "btnIconNoHover",
                              attrs: {
                                src: _vm.appPath + "Images/add.png",
                                alt: "",
                                role: "presentation",
                              },
                            }),
                            _vm._v(" "),
                            _c("span", [_vm._v("Create New Revision")]),
                          ]
                        ),
                      ]),
                    ],
                    2
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "buttonBar" }, [
                  _c(
                    "button",
                    {
                      staticClass: "buttonBarButton",
                      on: {
                        click: function ($event) {
                          _vm.showHistory = !_vm.showHistory
                        },
                      },
                    },
                    [
                      _c("vsvg", { attrs: { sprite: "history" } }),
                      _vm._v(
                        " " +
                          _vm._s(_vm.showHistory ? "Hide" : "Show") +
                          " History\n\t\t\t\t"
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "historySelectorContainer" },
                  [
                    _vm.showHistory
                      ? _c("CmsProductHistorySelector", {
                          attrs: { type: "ProductRoot", id: _vm.productRootId },
                          on: { select: _vm.HistoryItemSelected },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }