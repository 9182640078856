<template>
	<div class="pageRoot">
		<div>
			<h2>Graphite Render Configuration</h2>
			<p v-if="!graphiteJson"><input type="button" @click="OpenEditor" value="Edit GraphiteConfig.json" /></p>
			<p v-if="error" class="error">{{error}}</p>
			<div v-else-if="loading" class="loading"><ScaleLoader /> Loading…</div>
		</div>
	</div>
</template>

<script>
	import { GetGraphiteAdminData, SetGraphiteAdminData } from 'appRoot/api/GraphiteAdminData';
	import { AceCodeEditorDialog } from 'appRoot/scripts/ModalDialog';

	export default {
		components: {},
		data()
		{
			return {
				error: null,
				loading: false,
				graphiteJson: null
			};
		},
		created()
		{
		},
		computed:
		{
		},
		methods:
		{
			OpenEditor()
			{
				this.loading = true;
				this.error = null;
				GetGraphiteAdminData()
					.then(data =>
					{
						if (data.success)
						{
							AceCodeEditorDialog("GraphiteConfig.json", data.json, "ace/mode/json", (updatedJson) =>
							{
								if (updatedJson === data.json)
									return new Promise((resolve, reject) => { resolve(true); });
								return SetGraphiteAdminData(updatedJson)
									.then(data =>
									{
										if (data.success)
										{
											return true;
										}
										else
										{
											toaster.error(data.error);
											return false;
										}
									})
									.catch(err =>
									{
										toaster.error(err);
										return false;
									});
							}, null, null);
						}
						else
							this.error = data.error;
					})
					.catch(err =>
					{
						this.error = err.message;
					})
					.finally(() =>
					{
						this.loading = false;
					});
			}
		}
	}
</script>

<style scoped>
	.pageRoot
	{
		margin: 8px;
	}

	.loading
	{
		margin-top: 80px;
		text-align: center;
	}

	.error
	{
		color: #FF0000;
		font-weight: bold;
	}

	.buttonRow
	{
		margin-top: 30px;
		border-top: 1px solid #AAAAAA;
		padding-top: 10px;
	}
</style>