import { CMSStarProductRoot, CMSStarProductRevision } from 'appRoot/api/CMSUserData';
import EventBus from 'appRoot/scripts/EventBus';
import Vue from 'vue';

export default { /* StarredRowMixin provides data, computed properties, and methods for handling star buttons in ProductRoot and ProductRevision list items. */
	data()
	{
		return {
			starImgSrc: {
				0: appContext.appPath + "images/star_white.png",
				1: appContext.appPath + "images/star_yellow.png",
				2: appContext.appPath + "images/ajax-loader.gif"
			},
			savingProductRootStar: false, // Loading flags for the star for the product root.
			starLoadingForRev: {} // Loading flags for the star for each product revision.
		};
	},
	computed:
	{
		starImgProductRoot()
		{
			return this.starImgSrc[this.productStarState];
		},
		starImgProductRevisionMap()
		{
			let map = {};
			for (let revId in this.revStarState)
			{
				let state = this.revStarState[revId];
				map[revId] = this.starImgSrc[state];
			}
			return map;
		},
		productStarState()
		{
			if (this.savingProductRootStar)
				return 2;
			else if (EventBus.myStarredProductRootIds[this.star_ProductRootId])
				return 1;
			else
				return 0;
		},
		revStarState()
		{
			let map = {};
			if (this.star_ProductRevisionIds)
			{
				for (let i = 0; i < this.star_ProductRevisionIds.length; i++)
				{
					let revId = this.star_ProductRevisionIds[i];
					if (this.starLoadingForRev[revId])
						map[revId] = 2;
					else if (EventBus.myStarredProductRevisions[revId])
						map[revId] = 1;
					else
						map[revId] = 0;
				}
			}
			return map;
		}
	},
	methods:
	{
		async toggleStarProductRoot(root)
		{
			if (this.savingProductRootStar)
				return;
			console.log("toggleStarProductRoot", root.ProductRootId);
			let starEnable = this.productStarState === 0;
			this.savingProductRootStar = true;
			try
			{
				let data = await CMSStarProductRoot(root.ProductRootId, starEnable);
				if (!data.error)
				{
					console.log("starEnable", starEnable);
					if (starEnable)
						Vue.set(EventBus.myStarredProductRootIds, root.ProductRootId, true);
					else
					{
						Vue.delete(EventBus.myStarredProductRootIds, root.ProductRootId);
						toaster.custom({
							type: "success",
							title: "Click to Restore Star",
							msg: "To restore the star you just removed, click this message before it disappears in 5 seconds.",
							timeout: 5000,
							onClicked: () => { if (this.productStarState === 0) this.toggleStarProductRoot(root); }
						});
					}
				}
				else
					toaster.error(data.error);
			}
			catch (ex)
			{
				toaster.error(ex);
			}
			finally
			{
				this.savingProductRootStar = false;
			}
		},
		async toggleStarProductRevision(rev)
		{
			if (this.starLoadingForRev[rev.ProductRevisionId])
				return;
			let starEnable = this.revStarState[rev.ProductRevisionId] === 0;
			Vue.set(this.starLoadingForRev, rev.ProductRevisionId, true);
			try
			{
				let data = await CMSStarProductRevision(rev.ProductRevisionId, starEnable);
				if (!data.error)
				{
					if (starEnable)
						Vue.set(EventBus.myStarredProductRevisions, rev.ProductRevisionId, rev);
					else
					{
						Vue.delete(EventBus.myStarredProductRevisions, rev.ProductRevisionId);
						toaster.custom({
							type: "success",
							title: "Click to Restore Star",
							msg: "To restore the star you just removed, click this message before it disappears in 5 seconds.",
							timeout: 5000,
							onClicked: () => { if (this.revStarState[rev.ProductRevisionId] === 0) this.toggleStarProductRevision(rev); }
						});
					}
				}
				else
					toaster.error(data.error);
			}
			catch (ex)
			{
				toaster.error(ex);
			}
			finally
			{
				this.starLoadingForRev[rev.ProductRevisionId] = false;
			}
		}
	}
};