<template>
	<div class="loginRoot">
		<div class="loginPanel">
			<div class="systemName">{{systemName}}</div>
			<div v-if="!secureContext" class="secureContextWarning"><p>Unsecure Connection</p><p>To log in, please connect with HTTPS.</p></div>
			<template v-if="loaded">
				<label class="lblUser" v-if="showUserInput">
					<div>User Name</div>
					<input type="text" class="txtUser" v-model="user" placeholder="User Name" v-on:keyup.enter="UserNameEnter" autocomplete="username webauthn" />
				</label>
				<template v-if="showPasswordInput">
					<input type="password" class="txtPass" v-model="pass" placeholder="Password" v-on:keyup.enter="TryPasswordLogin" ref="passwordField" autocomplete="current-password" />
					<div class="forgotPwLinkWrapper" v-if="emailConfigured">
						<router-link :to="forgotPwRoute" class="forgotPwLink">Forgot Password?</router-link>
					</div>
				</template>
				<template>
					<div class="buttonBar" v-if="showLogInButton || showEmailLoginCodeButton">
						<button class="buttonBarButton" @click="LoginWithPasskey(false)" :disabled="busy" v-if="showLogInButton">
							<img class="btnIconNoHover" :src="appPath + 'Images/key.png'" alt="" role="presentation" />
							<span>Log In</span>
						</button>
						<button class="buttonBarButton" @click="SendLoginRequestToEmail" :disabled="busy" v-if="showEmailLoginCodeButton">
							<img class="btnIconNoHover" :src="appPath + 'Images/mail_forward.png'" alt="" role="presentation" />
							<span>Email a Login Code</span>
						</button>
					</div>
				</template>
			</template>
		</div>
		<template v-if="loaded">
			<div class="error" v-if="error">{{error}}</div>
			<div v-if="!passkeySupported" class="error">This web browser does not support Passkey login.</div>
		</template>
	</div>
</template>
<script>
	import { ProgressDialog, TextInputDialog, ModalMessageDialog } from 'appRoot/scripts/ModalDialog';
	import { passkey_supportedAsync, passkey_autofillSupportedAsync, LoginWithPasskey } from 'appRoot/scripts/Passkeys';
	import { SendLoginRequestToEmail, LoginWithOneTimeCode } from 'appRoot/api/EmailedCodeLoginData';

	export default {
		components: {},
		data()
		{
			return {
				systemName: appContext.systemName,
				appPath: appContext.appPath,
				allowPasswordAuth: appContext.passwordAuth,
				loaded: false,
				altLoginModes: false,
				user: "",
				pass: "",
				emailOTP: "",
				busy: false,
				error: null,
				emailConfigured: appContext.emailConfigured,
				secureContext: window.isSecureContext,
				passkeySupported: false,
				passkeyAutofillSupported: false
			};
		},
		async created()
		{
			try
			{
				let storedUser = localStorage.tds_login_user;
				if (storedUser)
					this.user = storedUser;
				this.passkeySupported = await passkey_supportedAsync();
				if (this.secureContext && this.passkeySupported)
				{
					this.passkeyAutofillSupported = this.passkeySupported && await passkey_autofillSupportedAsync();
					if (this.passkeyAutofillSupported)
					{
						this.LoginWithPasskey(true); // We are intentionally not `await`ing the response.
					}
				}
				else
				{
					this.altLoginModes = true;
				}
			}
			finally
			{
				this.loaded = true;
			}
		},
		computed:
		{
			forgotPwRoute()
			{
				return { name: "forgotPassword", query: { u: this.user } };
			},
			showUserInput()
			{
				return this.secureContext;
			},
			showPasswordInput()
			{
				return this.secureContext && this.allowPasswordAuth && (!this.passkeySupported || this.altLoginModes);
			},
			showLogInButton()
			{
				return this.secureContext && (this.allowPasswordAuth || this.passkeySupported);
			},
			showEmailLoginCodeButton()
			{
				return this.secureContext && (!this.passkeySupported || this.altLoginModes);
			}
		},
		methods:
		{
			TryPasswordLogin()
			{
				if (this.busy)
					return;
				if (!this.user)
				{
					toaster.info("Enter a user name.");
					return;
				}
				this.busy = true;
				this.error = null;
				let progressDialog = ProgressDialog("Logging in…");
				this.$store.dispatch("Login", { user: this.user, pass: this.pass })
					.then(data =>
					{
						this.AfterUserPassLogin(data);
					})
					.catch(err =>
					{
						this.error = err;
					})
					.finally(() =>
					{
						this.busy = false;
						progressDialog.close();
					});
			},
			UserNameEnter()
			{
				if (!this.allowPasswordAuth)
				{
					this.LoginWithPasskey(false);
				}
				if (this.$refs.passwordField)
				{
					this.$refs.passwordField.focus();
					if (this.pass)
					{
						this.$refs.passwordField.setSelectionRange(this.pass.length, this.pass.length);
						this.TryPasswordLogin();
					}
				}
			},
			async AfterUserPassLogin(data)
			{
				this.AfterLoginRedirect(data);
			},
			AfterLoginRedirect(data)
			{
				this.busy = true;

				let route;
				if (!data.usedPasskeyAuth)
					route = { name: "clientManage", query: { sid: this.$store.state.sid, createPasskey: "1" } };
				else if (this.$route.query.path)
					route = { path: this.$route.query.path, query: { sid: this.$store.state.sid } };
				else
					route = { name: "clientStatus", query: { sid: this.$store.state.sid } };

				// After login completion, don't just push a route. We must actually navigate (load a new browser page) otherwise some bugs can occur like lastpass leaving its icons on screen.
				location.href = this.$router.resolve(route).href;
			},
			async LoginWithPasskey(isConditional)
			{
				if (this.busy)
					return;
				if (!isConditional && !this.user)
				{
					toaster.info("Enter a user name.");
					return;
				}
				if (this.showPasswordInput && this.pass)
				{
					this.TryPasswordLogin();
					return;
				}
				const result = await LoginWithPasskey(this.user, isConditional);
				if (result.authenticated)
				{
					this.$store.commit("SessionAuthenticated", { sid: result.response.sid, userData: result.response.userData });
					this.AfterLoginRedirect(result.response);
				}
				else
				{
					if (result.tryPassword)
						this.altLoginModes = true;
				}
			},
			async SendLoginRequestToEmail()
			{
				if (this.busy)
					return;
				if (!this.user)
				{
					toaster.info("Enter a user name.");
					return;
				}
				let progressDialog = ProgressDialog("Sending Request…");
				try
				{
					let response = await SendLoginRequestToEmail(this.user);
					if (response.success)
					{
						progressDialog.close();
						let result = await TextInputDialog("Enter One-Time Code", response.message
							+ "\r\n\r\n" + "Please enter the one-time code we sent to your email:");
						if (result)
						{
							this.emailOTP = result.value;
							await this.LoginWithOneTimeCode();
						}
					}
					else
						ModalMessageDialog(response.error, "Email a Login Code");
				}
				catch (ex)
				{
					ModalMessageDialog("Request failed: " + ex.name + ": " + ex.message, "Email a Login Code");
				}
				finally
				{
					progressDialog.close();
				}
			},
			async LoginWithOneTimeCode()
			{
				if (this.busy)
					return;
				let progressDialog = ProgressDialog("Logging in…");
				try
				{
					let response = await LoginWithOneTimeCode(this.user, this.emailOTP);
					if (response.success)
					{
						//if (response.appContext)
						//	window.appContext = response.appContext;

						if (response.message)
						{
							ModalMessageDialog(response.message, "Authentication Message");
							return;
						}

						if (response.authenticated)
						{
							this.$store.commit("SessionAuthenticated", { sid: response.sid, userData: response.userData });
							this.AfterLoginRedirect(response);
						}
					}
					else
						ModalMessageDialog(response.error, "Authentication Failure");
				}
				catch (ex)
				{
					ModalMessageDialog("Request failed: " + ex.name + ": " + ex.message, "Request Failed");
				}
				finally
				{
					progressDialog.close();
				}
			},
		},
		watch:
		{
			user()
			{
				if (this.user)
					localStorage.tds_login_user = this.user;
				else
					localStorage.tds_login_user = "";
			}
		}
	}
</script>
<style scoped>
	.loginPanel
	{
		padding: 20px 20px;
		background-color: rgb(26,105,153,0.75);
		color: var(--text-color-on-primary-color);
		border: 1px solid rgba(0,0,0,1);
		border-radius: 8px;
		box-shadow: 0 0 16px rgba(0,0,0,0.5);
		backdrop-filter: blur(8px);
		max-width: 300px;
	}

	.lblUser, .lblPass
	{
		font-size: 18px;
		text-shadow: 1px 1px 4px rgba(0,0,0,1);
	}

	.systemName, .txtUser, .txtPass
	{
		width: 300px;
		box-sizing: border-box;
	}

	.systemName
	{
		font-size: 24px;
		text-shadow: 1px 1px 4px rgba(0,0,0,1);
		margin-bottom: 15px;
		text-align: center;
		overflow-x: hidden;
		word-break: break-word;
		white-space: pre-line;
	}

	.txtUser, .txtPass
	{
		display: block;
		font-size: 24px;
		border: 1px solid gray;
		border-radius: 5px;
		padding: 7px 8px;
	}

	.error
	{
		margin: 20px 10px;
		border: 2px dashed #FF0000;
		border-radius: 5px;
		background-color: #efc7c7;
		padding: 8px;
		max-width: 600px;
		box-sizing: border-box;
		word-break: break-word;
		white-space: pre-line;
		backdrop-filter: blur(8px);
	}

	.forgotPwLinkWrapper
	{
		text-align: center;
		margin-top: 10px;
		font-size: 10pt;
	}

	.forgotPwLink
	{
		color: var(--text-color-on-primary-color);
	}

	.secureContextWarning
	{
		background-color: #FF0000;
		border-radius: 5px;
		border: 2px dashed #FFFF00;
		padding: 4px 1px;
		text-align: center;
		color: #FFFFFF;
		font-weight: bold;
	}

	.passkeyRequired
	{
		background-color: rgba(255,255,255,0.75);
		border-radius: 5px;
		padding: 4px;
	}

	.buttonBar
	{
		display: block;
	}

	.buttonBarButton
	{
		width: 100%;
		justify-content: center;
	}
</style>
