var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dialogContainer" },
    [
      _c(
        "transition-group",
        { attrs: { name: _vm.transitionName } },
        _vm._l(_vm.components, function (c) {
          return _c(
            c.component,
            _vm._b(
              {
                key: c.key,
                tag: "component",
                on: {
                  close: function (dialogResult) {
                    _vm.closeRequested(c.key, dialogResult)
                  },
                },
              },
              "component",
              c.props,
              false
            )
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }