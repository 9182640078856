<template>
	<div :class="{ pageRoot: true }">
		<div v-if="error" class="error">{{error}}</div>
		<div v-else-if="loading" class="loading"><ScaleLoader /> Loading…</div>
		<template v-else>
			<!--<div class="outerContainer">
				<div class="primaryHeading">Under Construction</div>
				<p>This page is incomplete.</p>
			</div>-->
			<div class="outerContainer">
				<router-link :to="getFileEditorRoute('ReleaseNotes')" class="gradientButton lightBlue">Release Notes</router-link>
				<router-link :to="getFileEditorRoute('TitleUpdates')" class="gradientButton green">Title Updates</router-link>
				<router-link :to="getFileEditorRoute('AdminIPWhitelist')" class="gradientButton tdsHealthBrown">Admin IP Whitelist</router-link>
			</div>
			<div class="outerContainer" v-for="env in environments">
				<div class="primaryHeading">{{env.Name}} Environment</div>
				<div class="availableApps">
					<div class="appRow" v-for="app in env.Apps" :key="app.AppName">
						Website "{{app.AppName}}" <span v-if="app.ReleaseName"> has release "{{app.ReleaseName}}" installed.</span>
						<template v-if="!app.AppPoolNames || app.AppPoolNames.length > 1">
							<input class="recycleAppPoolBtn" type="button" value="Recycle All" @click="recycleAppPools(env, app, '')" title="Recycle App Pool(s)" />
						</template>
						<input class="recycleAppPoolBtn" v-if="app.AppPoolNames" v-for="appPoolName in app.AppPoolNames" type="button" :value="'Recycle ' + appPoolName" @click="recycleAppPools(env, app, appPoolName)" />
						<div class="appIncoherent darkBadge" v-if="!app.Coherent">INCOHERENT</div>
						<div v-if="!app.Coherent">Website "{{app.AppName}}" is incoherent and requires manual repair.</div>
						<div class="appError" v-if="app.Error">Website "{{app.AppName}}" has error: {{app.Error}}</div>
					</div>
				</div>
				<div class="availableReleases">
					<div class="releaseRow" v-for="r in env.Releases" :key="r.Name">
						<router-link class="gradientButton tdsHealthBrown" :to="getReleaseRoute(env, r)" replace>{{r.Name}}</router-link>
						<div class="releaseDate" title="Build date of the application binaries">{{timestampToDateString(r.BuildDate)}}</div>
						<div class="gradientButton lightBlue releaseInstalled" v-for="app in getAppsWhereReleaseIsInstalled(env, r)" :key="app.AppName"
							 role="button" tabindex="0" @click="openAppLinks(app)"
							 :title="'Click to open the ' + app.AppName + (app.Links.length > 1 ? ' websites in ' + app.Links.length + ' new tabs.' : ' website in a new tab.')">
							{{app.AppName}}
						</div>
						<div class="releaseArchiving darkBadge" v-if="r.IsArchiving" title="The service was ordered to archive this release.">Archiving</div>
					</div>
				</div>
			</div>
			<div class="outerContainer warningsContainer" v-if="environmentWarnings.length">
				<div class="primaryHeading">Warnings</div>
				<ul class="warningsSection">
					<li v-for="warning in environmentWarnings">{{warning}}</li>
				</ul>
			</div>
			<div class="outerContainer newReleaseContainer">
				<div class="primaryHeading">New Release</div>
				<div class="newReleases">
					<div class="formInputRow">
						<div class="formInputLabel">Environment</div>
						<div class="formInputValue">
							<select class="selectNewReleaseEnvironment" v-model="newReleaseEnvironmentName">
								<option v-for="env in environments" :value="env.Name">{{env.Name}}</option>
							</select>
						</div>
					</div>
					<div class="formInputRow">
						<div class="formInputLabel">Name</div>
						<div class="formInputValue">
							<input type="text" class="inputNewReleaseName" placeholder="New release name" v-model="newReleaseName" />
							<span v-if="newReleaseNameLength > 15" class="newReleaseNameWarning">Consider using a shorter name.</span>
							<span v-if="newReleaseNameContainsSpaces" class="newReleaseNameWarning">This will be a directory name on disk. Are you sure you want it to contain spaces?</span>
						</div>
					</div>
					<div class="formInputRow">
						<div class="formInputLabel">Build</div>
						<div class="formInputValue">
							<div class="buildOptionRow" v-for="build in latestBuilds" :key="build.Id">
								<label :title="build.Name" class="buildOptionLabel">
									<input type="radio" v-model="newReleaseBuildId" :value="build.Id" />
									<span class="buildRow">
										{{build.Name}} {{truncateStr(build.Description, 256)}}
									</span>
								</label>
							</div>
						</div>
					</div>
					<div class="formInputRow">
						<div class="formInputLabel">AppSettings From</div>
						<div class="formInputValue">
							<select class="selectNewReleaseAppSettingsFrom" v-model="newReleaseAppSettingsFrom">
								<option v-for="option in newReleaseAppSettingsOptions" :value="option.Value">{{option.Name}}</option>
							</select>
						</div>
					</div>
					<div class="formInputRow">
						<div class="formInputLabel">Description</div>
						<div class="formInputValue">
							<textarea type="text" class="inputNewReleaseDescription" v-model="newReleaseDescription" />
						</div>
					</div>
					<div class="formInputRow" style="margin-top: 1em;">
						<input type="button" :disabled="newReleaseButtonDisabled" value="Create Release" @click="createRelease" />
					</div>
				</div>
			</div>
			<div class="outerContainer errorsContainer" v-if="newReleaseError">
				<div class="primaryHeading">New Release Error</div>
				<p>
					{{newReleaseError}}
				</p>
			</div>
			<div class="outerContainer releaseStatusContainer">
				<div class="primaryHeading">
					Release Engine Status <div :class="{ webSocketStatus: true, connected: releaseStatusSocketIsConnected, notConnected: !releaseStatusSocketIsConnected }" :title="releaseStatusSocketIsConnected ? 'WebSocket is connected. Release Engine status is streaming into browser.' : 'WebSocket is not connected!'">
						<vsvg sprite="cable" class="webSocketIcon" />
					</div>
				</div>
				<p>{{releaseStatus ? releaseStatus : "     idle"}}</p>
			</div>
		</template>
	</div>
</template>
<script>
	import { ReleaseDetailsDialog } from 'appRoot/scripts/ModalDialog';
	import { GetEnvironmentInfo, GetRecentBuilds, CreateRelease, RecycleAppPools } from 'appRoot/api/ReleaseData';
	import svg1 from 'appRoot/images/sprite/cable.svg';

	export default {
		components: {},
		props:
		{
			environmentName: {
				type: String,
				default: ""
			},
			releaseName: {
				type: String,
				default: ""
			}
		},
		data()
		{
			return {
				error: "",
				loadingEnvironmentInfo: false,
				loadingBuilds: false,
				requestingCreateRelease: false,
				environments: [
					{
						Name: "Placeholder A",
						Releases: [
							{ BuildDate: Date.now(), Name: "ExRelease1" },
							{ BuildDate: Date.now() - (1440 * 60000), Name: "ExRelease2" },
						],
						Apps: [
							{
								AppName: "Online",
								Links: ["https://example.com/"],
								ReleaseName: "ExRelease1"
							},
							{
								AppName: "Test",
								Links: ["https://test1.example.com/", "https://test2.example.com/"],
								ReleaseName: "ExRelease1"
							}
						],
					},
					{
						Name: "Placeholder B",
						Releases: [
							{ BuildDate: Date.now(), Name: "20220806.7" },
							{ BuildDate: Date.now() - (1440 * 60000), Name: "20220804.1" },
						],
						Apps: [
							{
								AppName: "devApp",
								Links: ["https://devApp/"],
								ReleaseName: "20220804.1"
							}
						],
					}
				],
				environmentWarnings: [],
				latestBuilds: [],
				newReleaseEnvironmentName: "",
				newReleaseName: "",
				newReleaseBuildId: 0,
				newReleaseAppSettingsFrom: "",
				newReleaseDescription: "",
				newReleaseError: null,
				releaseStatusSocket: null,
				releaseStatus: null,
				releaseStatusSocketIsConnected: false,
				releaseStatusSocketPingInterval: null
			};
		},
		created()
		{
			this.loadBuilds();
			this.loadEnvironmentInfo();
			this.openWebSocket();
		},
		computed:
		{
			newReleaseButtonDisabled()
			{
				if (this.requestingCreateRelease)
					return true;
				let validReleaseName = !!this.newReleaseName && this.newReleaseName.length >= 3;
				if (!validReleaseName)
					return true;
				return false;
			},
			newReleaseAppSettingsOptions()
			{
				let options = [];
				let env = this.getEnvironmentByName(this.newReleaseEnvironmentName);
				if (env)
				{
					for (let i = 0; i < env.Releases.length; i++)
					{
						let release = env.Releases[i];
						options.push({ Value: release.Name, Name: release.Name + " (" + this.timestampToDateString(release.BuildDate) + ")" });
					}
				}
				return options;
			},
			loading()
			{
				return this.loadingBuilds || this.loadingEnvironmentInfo;
			},
			selectedEnvironment()
			{
				for (let i = 0; i < this.environments.length; i++)
					if (this.environments[i].Name === this.environmentName)
						return this.environments[i];
				return null;
			},
			selectedRelease()
			{
				return this.getRelease(this.environmentName, this.releaseName);
			},
			newReleaseNameContainsSpaces()
			{
				return this.newReleaseName && this.newReleaseName.indexOf(' ') > -1;
			},
			newReleaseNameLength()
			{
				return this.newReleaseName ? this.newReleaseName.length : 0;
			}
		},
		methods:
		{
			loadEnvironmentInfo()
			{
				this.loadingEnvironmentInfo = true;
				this.environments = [];
				GetEnvironmentInfo()
					.then(data =>
					{
						if (data.success)
						{
							if (data.environments)
								this.environments = data.environments;
							if (data.warnings)
								this.environmentWarnings = data.warnings;
						}
						else
							this.error += data.error + "\n";
					})
					.catch(err =>
					{
						this.error += err + "\n";
					})
					.finally(() =>
					{
						this.loadingEnvironmentInfo = false;
					});
			},
			loadBuilds()
			{
				this.loadingBuilds = true;
				GetRecentBuilds()
					.then(data =>
					{
						if (data.success)
						{
							this.latestBuilds = data.builds;
							if (this.latestBuilds.length)
								this.newReleaseBuildId = this.latestBuilds[0].Id;
						}
						else
							this.error += data.error + "\n";
					})
					.catch(err =>
					{
						this.error += err + "\n";
					})
					.finally(() =>
					{
						this.loadingBuilds = false;
					});
			},
			truncateStr(str, maxLen)
			{
				if (str && str.length > maxLen)
					return str.substr(0, maxLen) + "…";
				else
					return str;
			},
			timestampToDateString(timestamp)
			{
				let date = new Date(timestamp);
				var str = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
				return str;
			},
			getRelease(environment, releaseName)
			{
				if (typeof environment === "string")
				{
					for (let i = 0; i < this.environments.length; i++)
					{
						if (this.environments[i].Name === environment)
						{
							environment = this.environments[i];
							break;
						}
					}
				}
				if (environment.Releases)
				{
					for (let i = 0; i < environment.Releases.length; i++)
					{
						let r = environment.Releases[i];
						if (r.Name === releaseName)
							return r;
					}
				}
				return null;
			},
			getEnvironmentByName(environmentName)
			{
				for (let i = 0; i < this.environments.length; i++)
				{
					if (this.environments[i].Name === environmentName)
						return this.environments[i];
				}
				return null;
			},
			getAppsWhereReleaseIsInstalled(environment, release)
			{
				let list = [];
				for (let j = 0; j < environment.Apps.length; j++)
				{
					let app = environment.Apps[j];
					if (app.ReleaseName === release.Name)
						list.push(app);
				}
				return list;
			},
			getReleaseRoute(env, r)
			{
				if (!r.IsArchiving)
				{
					let route = this.getCleanRoute();
					route.query.environmentName = env.Name;
					route.query.releaseName = r.Name;
					return route;
				}
				return {};
			},
			getFileEditorRoute(fileName)
			{
				return {
					name: "fileEditor", params: { name: fileName }
				};
			},
			getCleanRoute()
			{
				let originalRoute = this.$route;
				let route = { name: originalRoute.name };
				route.query = Object.assign({}, originalRoute.query);
				route.params = Object.assign({}, originalRoute.params);
				delete route.query.releaseName;
				delete route.query.environmentName;
				return route;
			},
			openAppLinks(app)
			{
				for (let i = 0; i < app.Links.length; i++)
				{
					window.open(app.Links[i]);
				}
			},
			createRelease()
			{
				this.requestingCreateRelease = true;
				this.newReleaseError = null;
				CreateRelease(this.newReleaseEnvironmentName, this.newReleaseName, this.newReleaseBuildId, this.newReleaseAppSettingsFrom, this.newReleaseDescription)
					.then(data =>
					{
						if (data.success)
						{
						}
						else
							this.newReleaseError = data.error;
					})
					.catch(err =>
					{
						this.newReleaseError = err;
					})
					.finally(() =>
					{
						this.requestingCreateRelease = false;
					});
			},
			recycleAppPools(env, app, appPoolName)
			{
				RecycleAppPools(env.Name, app.AppName, appPoolName)
					.then(result =>
					{
						if (result.error)
							toaster.Error(result.error);
						else
							toaster.Success("Recycled app pools: " + result.appPoolNames.join(", "));
					})
					.catch(err =>
					{
						toaster.Error(err);
					});
			},
			openWebSocket()
			{
				if (this._isDestroyed)
					return;
				this.releaseStatusSocket = new WebSocket(location.origin.replace(/^http/i, "ws") + appContext.appPath + "DataStream/GetReleaseEngineStatusWS?sid=" + encodeURIComponent(this.$store.state.sid));
				this.releaseStatusSocket.onopen = this.onWebSocketOpen;
				this.releaseStatusSocket.onclose = this.onWebSocketClose;
				this.releaseStatusSocket.onerror = this.onWebSocketError;
				this.releaseStatusSocket.onmessage = this.onWebSocketMessage;
			},
			onWebSocketOpen(e)
			{
				this.releaseStatusSocketIsConnected = true;
				this.releaseStatusError = null;
				clearInterval(this.releaseStatusSocketPingInterval);
				this.releaseStatusSocketPingInterval = setInterval(() =>
				{
					if (this.releaseStatusSocketIsConnected)
						this.releaseStatusSocket.send('ping');
				}, 5000);
			},
			onWebSocketClose(e)
			{
				clearInterval(this.releaseStatusSocketPingInterval);
				this.releaseStatusSocketIsConnected = false;
				this.openWebSocket();
			},
			onWebSocketError(e)
			{
				console.error("WebSocket error:", e);
				this.releaseStatusSocketIsConnected = false;
				clearInterval(this.releaseStatusSocketPingInterval);
				if (e.message)
					this.releaseStatusError = e.message;
				if (this.releaseStatusSocket.readyState < 2)
					this.releaseStatusSocket.close();
			},
			onWebSocketMessage(e)
			{
				this.releaseStatus = e.data;
			},
			loadCleanRoute()
			{
				if (this.environmentName || this.releaseName)
					this.$router.replace(this.getCleanRoute());
			}
		},
		watch:
		{
			environments()
			{
				if (this.environments.length)
				{
					let mustSetEnvironmentName = true;
					for (let i = 0; i < this.environments.length; i++)
						if (this.environments.Name === this.newReleaseEnvironmentName)
							mustSetEnvironmentName = false;
					if (mustSetEnvironmentName)
						this.newReleaseEnvironmentName = this.environments[0].Name;
				}
			},
			newReleaseAppSettingsOptions()
			{
				// If the current `newReleaseAppSettingsFrom` value is not found in the list, then automatically select the first option.
				for (let i = 0; i < this.newReleaseAppSettingsOptions.length; i++)
				{
					if (this.newReleaseAppSettingsOptions[i].Value === this.newReleaseAppSettingsFrom)
						return;
				}
				if (this.newReleaseAppSettingsOptions.length)
					this.newReleaseAppSettingsFrom = this.newReleaseAppSettingsOptions[0].Value;
			},
			selectedRelease:
			{
				immediate: true,
				handler()
				{
					if (this.selectedRelease)
					{
						if (this.selectedRelease.IsArchiving)
						{
							this.loadCleanRoute();
						}
						else
							ReleaseDetailsDialog(this.selectedEnvironment, this.selectedRelease).then(() =>
							{
								this.loadCleanRoute();
							});
					}
				}
			}
		}
	}
</script>
<style scoped>
	.pageRoot
	{
		padding: 8px;
		background-color: #EFEDE6;
		display: flex;
		flex-direction: column;
	}

	.loading
	{
		margin-top: 80px;
		text-align: center;
	}

	.error
	{
		color: #FF0000;
		font-weight: bold;
		white-space: pre-wrap;
	}

	.outerContainer
	{
		border: 1px solid #DDDDDD;
		padding: 8px;
		background-color: #FFFFFF;
		margin-bottom: 2em;
		box-shadow: 2px 2px 2px rgba(0,0,0,0.4);
	}

	.newReleaseContainer
	{
		background-color: #ffffe6;
	}

	.primaryHeading
	{
		font-size: 32px;
		margin-bottom: 0.33em;
		margin-top: 1em;
	}

		.primaryHeading:first-of-type
		{
			margin-top: 0px;
		}

	.secondaryHeading
	{
		font-size: 24px;
		margin-bottom: 0.33em;
		margin-top: 0.33em;
	}

	.availableApps
	{
		margin-bottom: 1em;
	}

	.recycleAppPoolBtn
	{
		margin-right: 5px;
	}

	.darkBadge
	{
		text-decoration: none;
		user-select: none;
		display: inline-block;
		background: rgb(73,73,73);
		background: linear-gradient(0deg, rgba(73,73,73,1) 0%, rgba(94,94,94,1) 100%);
		color: #FFFFFF;
		border-radius: 0.25em;
		padding: 0.3em 0.6em;
		overflow: hidden;
		font-size: 16px;
		max-width: 140px;
		box-shadow: rgba(0,0,0,0.14) 0px -0.125em 0.25em 0.25em inset;
	}

	.releaseRow
	{
		display: flex;
		flex-wrap: wrap;
		align-items: baseline;
		margin-bottom: 6px;
	}

	.releaseDate
	{
		flex: 0 0 auto;
		margin-right: 12px;
		font-weight: bold;
	}

	.releaseInstalled
	{
		flex: 0 0 auto;
		cursor: pointer;
		margin-right: 6px;
	}

	.note
	{
		margin: 0.33em;
		font-style: italic;
		color: #222222;
	}

	.newReleases
	{
		margin-left: 12px;
	}

	.formInputRow
	{
		margin-bottom: 1em;
	}

	.formInputLabel
	{
		font-size: 1.2em;
		margin-right: 10px;
	}

	.formInputValue
	{
		display: inline-block;
		background-color: #FFFFFF;
	}

	.selectNewReleaseEnvironment,
	.inputNewReleaseName,
	.selectNewReleaseAppSettingsFrom
	{
		font-size: 1.1em;
	}

	.buildOptionRow
	{
		word-break: break-word;
	}

		.buildOptionRow:hover
		{
			background-color: #EEEEEE;
		}

	.buildOptionLabel
	{
		display: block;
	}

	.inputNewReleaseDescription
	{
		width: 300px;
		height: 100px;
	}

	.warningsContainer
	{
		background-color: #ffd6b0;
	}

	.warningsSection li
	{
		white-space: pre-wrap;
	}

	.errorsContainer
	{
		background-color: #ffb0b0;
	}

	.errorsSection
	{
		white-space: pre-wrap;
	}

	.releaseStatusContainer
	{
		background-color: #ffffe6;
	}

		.releaseStatusContainer p
		{
			white-space: pre-wrap;
		}

	.webSocketStatus
	{
		display: inline-block;
		padding: 0px 0px 10px 10px;
	}


		.webSocketStatus.connected
		{
			fill: #00AA00;
		}

		.webSocketStatus.notConnected
		{
			fill: #FF0000;
			animation: blink-animation 1s steps(5, start) infinite;
		}

	.webSocketIcon
	{
		width: 32px;
		height: 32px;
	}

	.newReleaseNameWarning
	{
		color: #c50000;
	}

	@keyframes blink-animation
	{
		to
		{
			visibility: hidden;
		}
	}
</style>
