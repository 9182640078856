var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "geolocationEmbed" }, [
    _vm.error ? _c("div", [_vm._v(_vm._s(_vm.error))]) : _vm._e(),
    _vm._v(" "),
    _vm.loading
      ? _c(
          "div",
          { staticClass: "loading" },
          [_c("ScaleLoader"), _vm._v(" Loading…")],
          1
        )
      : _c("div", { domProps: { innerHTML: _vm._s(_vm.html) } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }