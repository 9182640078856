<template>
	<div :class="{ inputDialogRoot: true }" ref="dialogRoot" role="dialog" aria-labelledby="mergeConflictsTitle" aria-describedby="mergeConflictsMsg">
		<div class="titleBar">
			<div id="mergeConflictsTitle" class="title" role="alert">Merge Conflicts</div>
		</div>
		<div class="dialogBody">
			<div id="mergeConflictsMsg" class="messageText">Your changes may conflict with someone else's recent changes.  Please select from the following options for each field:</div>
			<div class="conflictsContainer">
				<div class="conflict" v-for="c in mergeConflicts" :key="c.path">
					<div class="conflictPath">{{c.path}}</div>
					<div class="conflictValue baseValue" :class="{ selected: c.selected === 0, noneSelected: c.selected === -1 }"
						 tabindex="0"
						 @click="select(c, 0)"
						 @keypress.space.enter.prevent="select(c, 0)"
						 ref="baseValue">
						<div class="valueHeader">Original</div>
						<div class="conflictText">{{c.baseValue}}</div>
					</div>
					<div class="conflictValue yourValue" :class="{ selected: c.selected === 1, noneSelected: c.selected === -1 }"
						 tabindex="0"
						 @click="select(c, 1)"
						 @keypress.space.enter.prevent="select(c, 1)">
						<div class="valueHeader">Yours</div>
						<div class="conflictText" v-html="c.diffYours"></div>
					</div>
					<div class="conflictValue theirValue" :class="{ selected: c.selected === 2, noneSelected: c.selected === -1 }"
						 tabindex="0"
						 @click="select(c, 2)"
						 @keypress.space.enter.prevent="select(c, 2)">
						<div class="valueHeader">Theirs</div>
						<div class="conflictText" v-html="c.diffTheirs"></div>
					</div>
				</div>
			</div>
			<div class="buttons">
				<button class="dialogButton okButton" tabindex="0" @click="okClicked" :disabled="!okButtonEnabled">
					OK
				</button>
				<button ref="cancelBtn" class="dialogButton cancelButton" tabindex="0" @click="cancelClicked">
					Cancel
				</button>
			</div>
		</div>
	</div>
</template>

<script>
	import { makeWhitespaceVisible } from 'appRoot/scripts/Util';
	import Vue from 'vue';

	export default {
		props:
		{
			mergeConflicts: {
				type: Array,
				required: true
			}
		},
		data()
		{
			return {
			};
		},
		created()
		{
			for (let i = 0; i < this.mergeConflicts.length; i++)
			{
				Vue.set(this.mergeConflicts[i], "selected", -1);
			}
		},
		computed:
		{
			okButtonEnabled()
			{
				for (let i = 0; i < this.mergeConflicts.length; i++)
				{
					if (this.mergeConflicts[i].selected < 0)
						return false;
				}
				return true;
			}
		},
		methods:
		{
			SetFocus()
			{
				if (this.$refs.baseValue)
				{
					if (this.$refs.baseValue.length)
						this.$refs.baseValue[0].focus();
					else
						this.$refs.baseValue.focus();
				}
			},
			DefaultClose()
			{
			},
			okClicked()
			{
				let mergeResults = [];
				for (let i = 0; i < this.mergeConflicts.length; i++)
				{
					let conflict = this.mergeConflicts[i];
					let result = { path: conflict.path, value: null, newOriginalValue: conflict.theirValue, isJson: conflict.isJson };
					if (conflict.selected === 0)
						result.value = conflict.baseValue;
					else if (conflict.selected === 1)
						result.value = conflict.yourValue;
					else if (conflict.selected === 2)
						result.value = conflict.theirValue;
					else
					{
						toaster.info("Please make a selection for all fields");
						return;
					}
					mergeResults.push(result);
				}
				this.$emit("close", { mergeResults });
			},
			cancelClicked()
			{
				this.$emit("close");
			},
			wv(conflict, text)
			{
				if (conflict.isJson)
					return text;
				else
					return makeWhitespaceVisible(text);
			},
			select(conflict, choice)
			{
				if (conflict.selected === choice)
					conflict.selected = -1;
				else
					conflict.selected = choice;
			}
		}
	}
</script>

<style>
	/* unscoped */
	.wikEdDiffFragment
	{
		background: inherit;
		border: inherit;
		font-family: inherit;
		font-size: inherit;
		line-height: inherit;
		box-shadow: inherit;
		padding: inherit;
		margin: inherit;
	}

	del
	{
		background-color: #ffa6a6 !important;
		/*text-decoration: none;*/
		font-weight: bold;
	}

	ins
	{
		background-color: #4dff4d !important;
		font-weight: bold;
	}

	.paraSymbol
	{
		opacity: 0.48;
	}
</style>

<style scoped>
	.inputDialogRoot
	{
		max-width: calc(100vw - 40px);
		background-color: #FFFFFF;
	}

	.titleBar
	{
		background-color: #FFFFFF;
		padding: 8px 14px;
		box-sizing: border-box;
	}

	.title
	{
		text-align: center;
		color: black;
		font-weight: bold;
		font-size: 16pt;
	}

	.dialogBody
	{
		padding: 0px 5px 0px 5px;
	}

	.conflictsContainer
	{
		margin-top: 1em;
	}

	.conflict
	{
		margin-bottom: 1em;
	}

		.conflict:last-of-type
		{
			margin-bottom: 0px;
		}

	.conflictPath
	{
		font-weight: bold;
		margin-bottom: 1em;
		background-color:;
	}

	.conflictValue
	{
		border: 3px solid #E3E3E3;
		border-left-width: 10px;
		margin-bottom: 0.6em;
		white-space: pre-wrap;
		cursor: pointer;
		display: flex;
		flex-direction: column;
		border-radius: 5px;
		/*box-shadow: -1px -1px 8px 1px rgba(0,0,0,0.4) inset;*/
	}

		.conflictValue.noneSelected
		{
		}

		.conflictValue.selected
		{
			border-color: #000000;
			box-shadow: 1px 1px 8px 2px rgba(0,0,0,1);
			outline: 1px solid black;
		}

		.conflictValue:hover,
		.conflictValue:focus
		{
			outline: 4px solid black;
		}


	.valueHeader
	{
		font-size: 1.125em;
		background-color: #E3E3E3;
	}

	.conflictValue.selected .valueHeader
	{
		background-color: #000000;
		color: #FFFFFF;
		font-weight: bold;
	}

	.conflictText
	{
		padding: 0px 6px 8px 6px;
	}

	/*.baseValue
	{
		border-color: #DDDDCD;
	}

		.baseValue .valueHeader
		{
			background-color: #DDDDCD;
		}

	.yourValue
	{
		border-color: #D4D4DD;
	}

		.yourValue .valueHeader
		{
			background-color: #D4D4DD;
		}

	.theirValue
	{
		border-color: #D4DDD4;
	}

		.theirValue .valueHeader
		{
			background-color: #D4DDD4;
		}*/

	.baseValue.selected
	{
		border-color: #ffff00;
	}

		.baseValue.selected .valueHeader
		{
			background-color: #ffff00;
			color: #000000;
		}


	.yourValue.selected
	{
		border-color: #7de3ff;
	}

		.yourValue.selected .valueHeader
		{
			background-color: #7de3ff;
			color: #000000;
		}

	.theirValue.selected
	{
		border-color: #6fff6f;
	}

		.theirValue.selected .valueHeader
		{
			background-color: #6fff6f;
			color: #000000;
		}

	.buttons
	{
		display: flex;
	}

	.dialogButton
	{
		margin: 0px;
		border: none;
		display: inline-block;
		cursor: pointer;
		background-color: #FFFFFF;
		color: black;
		font-weight: bold;
		font-size: 12pt;
		box-sizing: border-box;
		position: relative;
		padding: 12px 5px;
		flex: 1 0 auto;
		text-align: center;
	}

		.dialogButton:hover
		{
			background-color: rgba(0,0,0,0.05);
		}

	.okButton
	{
		color: #4A9E42;
	}

	.cancelButton
	{
		color: #CE7D29;
		border-left: 1px solid #DDDDDD;
	}

	.dialogButton:disabled
	{
		opacity: 0.48;
		background-color: rgba(0,0,0,0.05);	
		color: black;
		cursor: default;
		cursor: not-allowed;
	}
</style>