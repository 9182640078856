<template>
	<div class="root">
		<div class="head">
			<ClientTopNav></ClientTopNav>
		</div>
		<div class="body">
			<ClientMenu class="clientMenu"></ClientMenu>
			<router-view></router-view>
		</div>
		<CmsConsole v-if="isOnCms" class="cmsConsole" />
	</div>
</template>

<script>
	import ClientTopNav from 'appRoot/vues/client/ClientTopNav.vue';
	import ClientMenu from 'appRoot/vues/client/ClientMenu.vue';
	import EventBus from 'appRoot/scripts/EventBus';
	import CmsConsole from 'appRoot/vues/client/controls/CmsConsole.vue';

	export default {
		components: { ClientTopNav, ClientMenu, CmsConsole },
		data: function ()
		{
			return {
			};
		},
		created()
		{
		},
		mounted()
		{
			EventBus.OnResize();
		},
		computed:
		{
			isOnCms()
			{
				return EventBus.isOnCms;
			}
		},
		methods:
		{
		}
	};
</script>

<style scoped>
	.root
	{
		font-size: 16px;
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		height: 100%;
	}

	.clientMenu
	{
		position: sticky;
		top: 0px;
	}

	.head
	{
		width: 100%;
	}

	.body
	{
		display: flex;
		position: relative;
		width: 100%;
		overflow: auto;
		flex: 1 1 auto;
	}

		.body > *:first-child
		{
		}

		.body > *:last-child
		{
			flex: 1 1 auto;
		}

	.cmsConsole
	{
		flex: 0 0 auto;
	}
</style>