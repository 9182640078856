<template>
	<div :class="{ uploadInput: true, hover: hover }">
		<template v-show="imageSrc">
			<img :src="imageSrc" role="presentation" alt="image" class="myImg" @load="imgLoaded" ref="myImg" />
			<img v-if="imageCanExpand" :src="imageSrc" role="presentation" alt="image" class="myImgLarge" v-show="hover" />
		</template>
		<div class="overlay">
			<vsvg sprite="upload" class="uploadIcon" />
			<!--<span v-if="selectedFile" class="selectedFileName">{{selectedFile.name}}</span>-->
		</div>
		<input ref="imageFileInput"
			   type="file"
			   :accept="accept"
			   @change="imageFileSelected"
			   @mouseenter="hover = true"
			   @mouseleave="hover = false" />
	</div>
</template>

<script>
	import svg1 from 'appRoot/images/sprite/upload.svg';

	export default {
		components: {},
		props:
		{
			accept: {
				type: String,
				default: ".jpg,.jpeg,.png,.gif,image/png,image/jpeg,image/gif",
			},
			imageSrc: {
				type: String,
				default: "",
			}
		},
		data()
		{
			return {
				hover: false,
				selectedFile: null,
				imageCanExpand: false
			};
		},
		computed:
		{
		},
		watch:
		{
		},
		methods:
		{
			async imageFileSelected()
			{
				if (this.$refs.imageFileInput.files.length > 0)
				{
					this.selectedFile = this.$refs.imageFileInput.files[0];
					let arrayBuffer = await this.selectedFile.arrayBuffer();
					console.log(this.selectedFile);
					let base64 = _arrayBufferToBase64(arrayBuffer);
					let ext = ".jpg";
					if (this.selectedFile.type === "image/png")
						ext = ".png";
					else if (this.selectedFile.type === "image/gif")
						ext = ".gif";
					else if (this.selectedFile.type === "image/bmp")
						ext = ".bmp";
					this.$emit("selected", {
						base64: base64,
						ext: ext,
						name: this.selectedFile.name,
						type: this.selectedFile.type
					});
					this.$refs.imageFileInput.value = null;
				}
			},
			imgLoaded(e)
			{
				try
				{
					if (this.$el.offsetWidth < e.target.naturalWidth || this.$el.offsetHeight < e.target.naturalHeight)
						this.imageCanExpand = true;

					else
						this.imageCanExpand = false;
				}
				catch (ex)
				{
					toaster.error(ex);
				}
			}
		}
	};
	function _arrayBufferToBase64(buffer)
	{
		var binary = '';
		var bytes = new Uint8Array(buffer);
		var len = bytes.byteLength;
		for (var i = 0; i < len; i++)
			binary += String.fromCharCode(bytes[i]);
		return window.btoa(binary);
	}
</script>

<style scoped>
	.uploadInput
	{
		position: relative;
		border: 2px dashed rgba(0,0,0,0);
		border-radius: 5px;
		/*box-sizing: border-box;*/
		overflow: visible;
	}

		.uploadInput.hover
		{
			border: 2px dashed rgba(0,0,0,1);
		}

		.uploadInput input[type="file"]
		{
			position: absolute;
			left: 0;
			opacity: 0;
			top: 0;
			bottom: 0;
			width: 100%;
			cursor: pointer;
		}

	.overlay
	{
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		width: 100%;
		background-color: rgba(255,255,255,0);
		display: inline-flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		overflow: hidden;
		opacity: 0;
	}

	.uploadInput.hover .overlay
	{
		background-color: rgba(255,255,255,0.25);
		opacity: 1;
	}

	.selectedFileName
	{
		font-size: 12px;
		text-overflow: ellipsis;
		width: 100%;
		overflow: hidden;
		padding: 2px;
		display: inline-block;
		text-shadow: 1px 1px 2px #FFFFFF;
	}

	.uploadIcon
	{
		width: 48px;
		height: 48px;
		stroke: #FFFFFF;
		stroke-width: 1px;
		filter: drop-shadow(0px 0px 4px rgb(255 255 255 / 1));
	}

	.myImg
	{
		max-width: 190px;
		height: 100%;
	}

	.myImgLarge
	{
		position: absolute;
		pointer-events: none;
		box-shadow: 2px 2px 10px 0px rgba(0,0,0,1);
		z-index: 1;
		top: 0px;
		left: calc(100% + 5px);
	}
</style>
