var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: { pageRoot: true } },
    [
      _vm.productSets
        ? _c(
            "div",
            { staticClass: "FloatRight" },
            [
              _c(
                "router-link",
                {
                  staticClass: "gradientButton green",
                  attrs: {
                    to: {
                      name: "fileEditor",
                      params: { name: "TitleUpdates" },
                    },
                  },
                },
                [_vm._v("Title Updates")]
              ),
              _vm._v(" "),
              _c(
                "button",
                { staticClass: "refreshButton", on: { click: _vm.loadData } },
                [
                  _c("vsvg", {
                    staticClass: "refreshIcon",
                    attrs: { sprite: "refresh" },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("h2", [_vm._v("STAT!Ref Content Management System")]),
      _vm._v(" "),
      _vm.error
        ? _c("div", { staticClass: "error" }, [_vm._v(_vm._s(_vm.error))])
        : _vm.loading
        ? _c(
            "div",
            { staticClass: "loading" },
            [_c("ScaleLoader"), _vm._v(" Loading…")],
            1
          )
        : _vm.productSets
        ? [
            _c("div", { staticClass: "filterContainer" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.filterText,
                    expression: "filterText",
                  },
                ],
                staticClass: "filterInput",
                attrs: { type: "text", placeholder: "Filter by…" },
                domProps: { value: _vm.filterText },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.filterText = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _vm.filterText
                ? _c("input", {
                    attrs: { type: "button", value: "Clear Filter" },
                    on: {
                      click: function ($event) {
                        _vm.filterText = ""
                      },
                    },
                  })
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("CmsProductList", {
              attrs: {
                title: "Live but still deploying online",
                setName: "liveDeployingOnline",
                productRoots: _vm.productSets.liveDeployingOnline,
                filterText: _vm.filterTextThrottled,
                lsMap: _vm.lsMap,
              },
            }),
            _vm._v(" "),
            _c("CmsProductList", {
              attrs: {
                title: "Starred by You",
                setName: "starredByYou",
                productRoots: _vm.ProductRootsStarredByYou,
                showStarredRevs: true,
                filterText: _vm.filterTextThrottled,
                lsMap: _vm.lsMap,
              },
            }),
            _vm._v(" "),
            _c("CmsProductList", {
              attrs: {
                title: "Staging",
                setName: "staging",
                productRoots: _vm.productSets.staging,
                filterText: _vm.filterTextThrottled,
                lsMap: _vm.lsMap,
              },
            }),
            _vm._v(" "),
            _c("CmsProductList", {
              attrs: {
                title: "Upcoming",
                setName: "upcoming",
                productRoots: _vm.productSets.upcoming,
                filterText: _vm.filterTextThrottled,
                lsMap: _vm.lsMap,
              },
            }),
            _vm._v(" "),
            _c("CmsProductList", {
              attrs: {
                title: "Titles at Rest",
                setName: "atRest",
                productRoots: _vm.productSets.atRest,
                filterText: _vm.filterTextThrottled,
                lsMap: _vm.lsMap,
              },
            }),
            _vm._v(" "),
            _c("CmsProductList", {
              attrs: {
                title: "All Revisions Inactive",
                setName: "allRevisionsInactive",
                productRoots: _vm.productSets.allRevisionsInactive,
                filterText: _vm.filterTextThrottled,
                lsMap: _vm.lsMap,
              },
            }),
            _vm._v(" "),
            _c("CmsProductList", {
              attrs: {
                title: "Titles Marked as Offline",
                setName: "offline",
                productRoots: _vm.productSets.offline,
                filterText: _vm.filterTextThrottled,
                lsMap: _vm.lsMap,
              },
            }),
            _vm._v(" "),
            _c("CmsProductList", {
              attrs: {
                title: "Titles in Trash",
                setName: "trash",
                productRoots: _vm.productSets.trash,
                filterText: _vm.filterTextThrottled,
                lsMap: _vm.lsMap,
              },
            }),
            _vm._v(" "),
            _vm.hasAnyProducts
              ? _c("div", { staticClass: "buttonBar" }, [
                  _c(
                    "button",
                    {
                      staticClass: "buttonBarButton",
                      attrs: {
                        title:
                          "Asks you for a product name and creates a new Root Product with it.",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.addNewProduct()
                        },
                      },
                    },
                    [
                      _c("vsvg", { attrs: { sprite: "add" } }),
                      _vm._v(" "),
                      _c("span", [_vm._v("New Root Product")]),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "buttonBar" },
              [
                _c("div", { staticClass: "buttonBarHeading" }, [
                  _vm._v("Advanced CMS Controls"),
                ]),
                _vm._v(" "),
                !_vm.hasAnyProducts
                  ? _c(
                      "button",
                      {
                        staticClass: "buttonBarButton",
                        attrs: {
                          title:
                            "Begins the first-time setup procedure if and only if there are no products in the CMS database.",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.doCMSFirstTimeSetup()
                          },
                        },
                      },
                      [
                        _c("vsvg", { attrs: { sprite: "upload" } }),
                        _vm._v(" "),
                        _c("span", [_vm._v("First-Time Setup")]),
                      ],
                      1
                    )
                  : [
                      _c(
                        "router-link",
                        {
                          staticClass: "buttonBarButton",
                          attrs: { to: { name: "clientCMSDataSync" } },
                        },
                        [_c("span", [_vm._v("Taylor DB Data Sync")])]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "buttonBarButton",
                          attrs: {
                            title:
                              "Runs the validation functions on all Product Roots and Product Revisions. Helpful when the validation logic has just been updated.",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.validateAllProductInformation()
                            },
                          },
                        },
                        [
                          _c("vsvg", { attrs: { sprite: "filter_alt" } }),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v("Validate all Product Information"),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "buttonBarButton",
                          attrs: {
                            title:
                              "The databases are backed up automatically, but you can create an additional backup by clicking this button.",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.doCMSDbBackup()
                            },
                          },
                        },
                        [
                          _c("vsvg", { attrs: { sprite: "cloud_upload" } }),
                          _vm._v(" "),
                          _c("span", [_vm._v("Backup Databases Now")]),
                        ],
                        1
                      ),
                    ],
              ],
              2
            ),
          ]
        : _c("div", [
            _vm._v("\n\t\tCMS data loading failed for an unknown reason.\n\t"),
          ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }