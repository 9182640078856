var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pageRoot" }, [
    _c("h2", [_vm._v("Ace Editor Tests")]),
    _vm._v(" "),
    _c("div", { staticClass: "buttonBar" }, [
      _c(
        "button",
        {
          staticClass: "buttonBarButton",
          on: {
            click: function ($event) {
              return _vm.testAceEditor("css")
            },
          },
        },
        [_c("span", [_vm._v("CSS")])]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "buttonBarButton",
          on: {
            click: function ($event) {
              return _vm.testAceEditor("javascript")
            },
          },
        },
        [_c("span", [_vm._v("JavaScript")])]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "buttonBarButton",
          on: {
            click: function ($event) {
              return _vm.testAceEditor("json")
            },
          },
        },
        [_c("span", [_vm._v("JSON")])]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "buttonBarButton",
          on: {
            click: function ($event) {
              return _vm.testAceEditor("html")
            },
          },
        },
        [_c("span", [_vm._v("HTML")])]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "buttonBarButton",
          on: {
            click: function ($event) {
              return _vm.testAceEditor("xml")
            },
          },
        },
        [_c("span", [_vm._v("XML")])]
      ),
    ]),
    _vm._v(" "),
    _c("h2", [_vm._v("Product Image Data")]),
    _vm._v(" "),
    _c("div", { staticClass: "buttonBar" }, [
      _c(
        "button",
        {
          staticClass: "buttonBarButton",
          on: {
            click: function ($event) {
              return _vm.GetProductImageData()
            },
          },
        },
        [_c("span", [_vm._v("Get ProductImage Data")])]
      ),
    ]),
    _vm._v(" "),
    _c("h2", [_vm._v("ChromeDriver Problems")]),
    _vm._v(" "),
    _c("p", [
      _vm._v(
        "Current overall CPU usage is " +
          _vm._s(_vm.overallCPU) +
          " with " +
          _vm._s(_vm.chromeInstanceCount) +
          " chrome processes using " +
          _vm._s(_vm.chromeCPU) +
          " CPU."
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "buttonBar" }, [
      _c(
        "button",
        {
          staticClass: "buttonBarButton",
          on: {
            click: function ($event) {
              return _vm.StopChromeInstances()
            },
          },
        },
        [_c("span", [_vm._v("Stop Chrome Instances")])]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }