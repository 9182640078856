var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "CmsProductLinksDialog",
      attrs: { role: "alertdialog", "aria-labelledby": "CmsProductLinksTitle" },
    },
    [
      _c("div", { staticClass: "titleBar" }, [
        _c(
          "div",
          {
            staticClass: "title",
            attrs: { id: "CmsProductLinksTitle", role: "alert" },
          },
          [_vm._v("Fxid " + _vm._s(_vm.fxid) + " Product Links")]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "dialogBody" },
        [
          _c("div", { staticClass: "sectionTitle" }, [_vm._v("TDS Links")]),
          _vm._v(" "),
          _c("div", { staticClass: "serverChoice" }, [
            _vm._v("Server Choice:"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "serverOptions" }, [
            _c("div", { staticClass: "LinkContainer" }, [
              _c("label", { staticClass: "onlineServerBtn" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.serverChoice,
                      expression: "serverChoice",
                    },
                  ],
                  attrs: {
                    type: "radio",
                    name: "serverChoiceRadioGroup",
                    value: "0",
                  },
                  domProps: { checked: _vm._q(_vm.serverChoice, "0") },
                  on: {
                    change: function ($event) {
                      _vm.serverChoice = "0"
                    },
                  },
                }),
                _vm._v("\n\t\t\t\t\tOnline (Live) Server\n\t\t\t\t"),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "LinkContainer" }, [
              _c("label", { staticClass: "productionServerBtn" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.serverChoice,
                      expression: "serverChoice",
                    },
                  ],
                  attrs: {
                    type: "radio",
                    name: "serverChoiceRadioGroup",
                    value: "1",
                  },
                  domProps: { checked: _vm._q(_vm.serverChoice, "1") },
                  on: {
                    change: function ($event) {
                      _vm.serverChoice = "1"
                    },
                  },
                }),
                _vm._v("\n\t\t\t\t\tProduction Server\n\t\t\t\t"),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "spacer" }),
          _vm._v(" "),
          _c("div", { class: "linksFor" + _vm.serverChoice }, [
            _c("div", { staticClass: "LinkContainer" }, [
              _c(
                "a",
                {
                  ref: "frontMatterLink",
                  staticClass: "alwaysUnvisited",
                  attrs: { href: _vm.FrontMatterLink, target: "_blank" },
                },
                [_vm._v("\n\t\t\t\t\tClient > Front Matter\n\t\t\t\t")]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "spacer" }),
            _vm._v(" "),
            _c("div", { staticClass: "LinkContainer" }, [
              _c(
                "a",
                {
                  staticClass: "alwaysUnvisited",
                  attrs: { href: _vm.StatusLink, target: "_blank" },
                },
                [_vm._v("\n\t\t\t\t\tAdmin > Status\n\t\t\t\t")]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "LinkContainer" }, [
              _c(
                "a",
                {
                  staticClass: "alwaysUnvisited",
                  attrs: { href: _vm.InformationLink, target: "_blank" },
                },
                [_vm._v("\n\t\t\t\t\tAdmin > Information\n\t\t\t\t")]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "LinkContainer" }, [
              _c(
                "a",
                {
                  staticClass: "alwaysUnvisited",
                  attrs: { href: _vm.DisplayLink, target: "_blank" },
                },
                [_vm._v("\n\t\t\t\t\tAdmin > Display\n\t\t\t\t")]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "LinkContainer" }, [
              _c(
                "a",
                {
                  staticClass: "alwaysUnvisited",
                  attrs: { href: _vm.BibliographyLink, target: "_blank" },
                },
                [_vm._v("\n\t\t\t\t\tAdmin > Bibliography\n\t\t\t\t")]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "LinkContainer" }, [
              _c(
                "a",
                {
                  staticClass: "alwaysUnvisited",
                  attrs: {
                    href: _vm.TitleInfoCategoriesLink,
                    target: "_blank",
                  },
                },
                [
                  _vm._v("\n\t\t\t\t\tAdmin > "),
                  _c("b", [_vm._v("TitleInfo Categories")]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "LinkContainer" }, [
              _c(
                "a",
                {
                  staticClass: "alwaysUnvisited",
                  attrs: { href: _vm.ProductCategoriesLink, target: "_blank" },
                },
                [
                  _vm._v("\n\t\t\t\t\tAdmin > "),
                  _c("b", [_vm._v("STAT!Ref Categories")]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "LinkContainer" }, [
              _c(
                "a",
                {
                  staticClass: "alwaysUnvisited",
                  attrs: { href: _vm.SubscriptionsLink, target: "_blank" },
                },
                [_vm._v("\n\t\t\t\t\tAdmin > Subscriptions\n\t\t\t\t")]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "spacer" }),
          _vm._v(" "),
          _c("div", { staticClass: "LinkContainer" }, [
            _c(
              "a",
              {
                staticClass: "alwaysUnvisited",
                attrs: { href: _vm.PublicSiteTitleInfoLink, target: "_blank" },
              },
              [
                _vm._v(
                  "\n\t\t\t\ttetondata.com/TitleInfo?id=" +
                    _vm._s(_vm.fxid) +
                    "\n\t\t\t"
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "spacer" }),
          _vm._v(" "),
          _c("div", { staticClass: "sectionTitle" }, [
            _vm._v("OpenLibrary Metadata Search"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "LinkContainer" }, [
            _c(
              "a",
              {
                staticClass: "alwaysUnvisited",
                attrs: {
                  href: _vm.OpenLibrarySearch(_vm.rootNameTxt),
                  target: "_blank",
                },
              },
              [_vm._v("\n\t\t\t\t" + _vm._s(_vm.rootNameTxt) + "\n\t\t\t")]
            ),
          ]),
          _vm._v(" "),
          _vm.revNameTxt && _vm.revNameTxt !== _vm.rootNameTxt
            ? _c("div", { staticClass: "LinkContainer" }, [
                _c(
                  "a",
                  {
                    staticClass: "alwaysUnvisited",
                    attrs: {
                      href: _vm.OpenLibrarySearch(_vm.revNameTxt),
                      target: "_blank",
                    },
                  },
                  [_vm._v("\n\t\t\t\t" + _vm._s(_vm.revNameTxt) + "\n\t\t\t")]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.oclcs, function (oclc, index) {
            return _c(
              "div",
              { key: "search_oclc_" + index, staticClass: "LinkContainer" },
              [
                _c(
                  "a",
                  {
                    staticClass: "alwaysUnvisited",
                    attrs: {
                      href: _vm.OpenLibrarySearch(oclc),
                      target: "_blank",
                    },
                  },
                  [_vm._v("\n\t\t\t\tOCLC: " + _vm._s(oclc) + "\n\t\t\t")]
                ),
              ]
            )
          }),
          _vm._v(" "),
          _vm._l(_vm.isbns, function (isbn, index) {
            return _c(
              "div",
              { key: "search_isbn_" + index, staticClass: "LinkContainer" },
              [
                _c(
                  "a",
                  {
                    staticClass: "alwaysUnvisited",
                    attrs: {
                      href: _vm.OpenLibrarySearch(isbn),
                      target: "_blank",
                    },
                  },
                  [_vm._v("\n\t\t\t\tISBN: " + _vm._s(isbn) + "\n\t\t\t")]
                ),
              ]
            )
          }),
          _vm._v(" "),
          _vm.oclcs.length || _vm.isbns.length
            ? [
                _c("div", { staticClass: "spacer" }),
                _vm._v(" "),
                _c("div", { staticClass: "sectionTitle" }, [
                  _vm._v("OpenLibrary Cover Search"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "LinkContainer" }, [
                  _c("label", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.loadImages,
                          expression: "loadImages",
                        },
                      ],
                      attrs: { type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(_vm.loadImages)
                          ? _vm._i(_vm.loadImages, null) > -1
                          : _vm.loadImages,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.loadImages,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.loadImages = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.loadImages = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.loadImages = $$c
                          }
                        },
                      },
                    }),
                    _vm._v("\n\t\t\t\t\tLoad Images Inline\n\t\t\t\t"),
                  ]),
                ]),
                _vm._v(" "),
                _vm._l(_vm.oclcs, function (oclc, index) {
                  return _c(
                    "div",
                    {
                      key: "cover_oclc_" + index,
                      staticClass: "LinkContainer",
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "alwaysUnvisited",
                          attrs: {
                            href: _vm.OpenLibraryCoverLink("oclc", oclc),
                            target: "_blank",
                          },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\tOCLC: " + _vm._s(oclc) + "\n\t\t\t\t\t"
                          ),
                          _vm.loadImages
                            ? [
                                _c("br"),
                                _vm._v(" "),
                                _c("img", {
                                  staticClass: "productImage",
                                  attrs: {
                                    src: _vm.OpenLibraryCoverLink("oclc", oclc),
                                    alt: "product image from openlibrary",
                                  },
                                }),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]
                  )
                }),
                _vm._v(" "),
                _vm._l(_vm.isbns, function (isbn, index) {
                  return _c(
                    "div",
                    {
                      key: "cover_isbn_" + index,
                      staticClass: "LinkContainer",
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "alwaysUnvisited",
                          attrs: {
                            href: _vm.OpenLibraryCoverLink("isbn", isbn),
                            target: "_blank",
                          },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\tISBN: " + _vm._s(isbn) + "\n\t\t\t\t\t"
                          ),
                          _vm.loadImages
                            ? [
                                _c("br"),
                                _vm._v(" "),
                                _c("img", {
                                  staticClass: "productImage",
                                  attrs: {
                                    src: _vm.OpenLibraryCoverLink("isbn", isbn),
                                    alt: "product image from openlibrary",
                                  },
                                }),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]
                  )
                }),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "spacer" }),
          _vm._v(" "),
          _c("div", { staticClass: "comment" }, [
            _vm._v("* All links open in a new tab"),
          ]),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }