var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("svg", _vm._g({ staticClass: "vsvg" }, _vm.$listeners), [
    _c("use", { attrs: { "xlink:href": "#" + _vm.sprite } }),
    _vm.title
      ? _c("title", { attrs: { role: "presentation" } }, [
          _vm._v(_vm._s(_vm.title)),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }