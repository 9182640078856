var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "aceCodeEditorRoot",
      attrs: { role: "dialog", "aria-labelledby": "aceCodeEditorTitle" },
    },
    [
      _c("div", { staticClass: "titleBar" }, [
        _c(
          "div",
          {
            staticClass: "title",
            attrs: { id: "aceCodeEditorTitle", role: "alert" },
          },
          [_vm._v("Code Editor: " + _vm._s(_vm.fileName))]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "dialogBody" }, [
        _c(
          "div",
          { staticClass: "codeEditorWrapper", style: _vm.codeEditorCalcStyle },
          [
            _c("AceCodeEditor", {
              ref: "editor",
              attrs: { mode: _vm.mode, disabled: _vm.isCommitting },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _vm.backupsAvailable
          ? _c("div", { ref: "backupList", staticClass: "backupList" }, [
              _c("p", [_vm._v("Backups Available:")]),
              _vm._v(" "),
              _c(
                "ul",
                _vm._l(_vm.backupInfo, function (b) {
                  return _c("li", { key: b.Name }, [
                    _c(
                      "a",
                      {
                        attrs: { role: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.openBackup(b)
                          },
                        },
                      },
                      [_vm._v(_vm._s(b.Name))]
                    ),
                    _vm._v(" - " + _vm._s(new Date(b.Date).toString())),
                  ])
                }),
                0
              ),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "buttons" }, [
        _c(
          "div",
          {
            staticClass: "dialogButton",
            attrs: { tabindex: "0", disabled: _vm.isCommitting },
            on: {
              click: _vm.CommitChanges,
              keydown: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "space", 32, $event.key, [
                    " ",
                    "Spacebar",
                  ]) &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                $event.preventDefault()
                return _vm.CommitChanges.apply(null, arguments)
              },
            },
          },
          [_vm._v("\n\t\t\tCommit Changes\n\t\t")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            ref: "discardChangesBtn",
            staticClass: "dialogButton",
            attrs: { tabindex: "0", disabled: _vm.isCommitting },
            on: {
              click: _vm.DiscardChanges,
              keydown: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "space", 32, $event.key, [
                    " ",
                    "Spacebar",
                  ]) &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                $event.preventDefault()
                return _vm.DiscardChanges.apply(null, arguments)
              },
            },
          },
          [_vm._v("\n\t\t\tDiscard Changes\n\t\t")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }