<template>
	<div class="pageRoot">
		<div v-if="error" class="error">{{error}}</div>
		<div v-else-if="loading" class="loading"><ScaleLoader /> Loading…</div>
		<div v-else>
			<h2>Notifications Setup</h2>
			<p v-if="notificationMethods.length === 0">Please add at least one email address to this user account in order to receive notifications.</p>
			<template v-else>
				<template v-if="eventNames && eventNames.length">
					<h3>Events</h3>
					<p>You may register to receive a notification upon certain events below:</p>
					<table class="notificationTable">
						<thead>
							<tr>
								<th>Event</th>
								<th v-for="nm in notificationMethods" :key="nm.key">{{nm.value}}</th>
							</tr>
						</thead>
						<tbody>
							<tr class="allMonitorsToggleRow">
								<td>-- All Events --</td>
								<td v-for="nm in notificationMethods" class="toggleNotificationCell" :key="nm.key">
									<input type="button" value="toggle all" @click="toggleNotificationsForMethod(nm, eventNames)" class="toggleNotificationButton" />
								</td>
							</tr>
							<tr v-for="eventName in eventNames" class="zebra">
								<td>{{eventName}}</td>
								<td v-for="nm in notificationMethods" :key="nm.key" :class="{ notificationCheckboxCell: true, isUpdating: isUpdating(eventName, nm) }">
									<input class="notificationCheckbox" type="checkbox" :checked="isChecked(eventName, nm)" @click="toggleNotification(eventName, nm)" :disabled="isUpdating(eventName, nm)" />
								</td>
							</tr>
						</tbody>
					</table>
				</template>
				<br />
				<h3>Status Monitors</h3>
				<p>Notifications will be sent via the following methods when any Monitor enters or exits the "Alerting" state. There is a cooldown between notifications to prevent excessive spamming during outages.</p>
				<table class="notificationTable">
					<thead>
						<tr>
							<th>Monitor</th>
							<th v-for="nm in notificationMethods" :key="nm.key">{{nm.value}}</th>
						</tr>
					</thead>
					<tbody>
						<tr class="allMonitorsToggleRow">
							<td>-- All Monitors --</td>
							<td v-for="nm in notificationMethods" class="toggleNotificationCell" :key="nm.key">
								<input type="button" value="toggle all" @click="toggleNotificationsForMethod(nm, jobNames)" class="toggleNotificationButton" />
							</td>
						</tr>
						<tr v-for="jobName in jobNames" class="zebra">
							<td>{{jobName}}</td>
							<td v-for="nm in notificationMethods" :key="nm.key" :class="{ notificationCheckboxCell: true, isUpdating: isUpdating(jobName, nm) }">
								<input class="notificationCheckbox" type="checkbox" :checked="isChecked(jobName, nm)" @click="toggleNotification(jobName, nm)" :disabled="isUpdating(jobName, nm)" />
							</td>
						</tr>
					</tbody>
				</table>
			</template>
		</div>
	</div>
</template>

<script>
	import Vue from 'vue';
	import { GetNotificationData, SetNotificationRegistration } from 'appRoot/api/NotificationData';

	export default {
		components: {},
		props:
		{
		},
		data()
		{
			return {
				error: null,
				loading: false,
				jobNames: [], // Array of job names
				eventNames: [], // Array of event names
				notificationMethods: [], // Array of notification methods
				registrations: [], // Array containing all notification registrations in the format { jobName: "", notificationMethod: {} }
				updatingMap: {}, // Map of job name to map of notification method to boolean flag indicating if the registration for this pair is currently being updated.
			};
		},
		created()
		{
			this.LoadNotificationData();
		},
		beforeDestroy()
		{
		},
		computed:
		{
			registrationsMap()
			{
				let map1 = {};
				for (let i = 0; i < this.registrations.length; i++)
				{
					let r = this.registrations[i];
					let map2 = map1[r.jobName];
					if (!map2)
						map1[r.jobName] = map2 = {};
					map2[r.notificationMethod.key] = true;
				}
				return map1;
			}
		},
		methods:
		{
			LoadNotificationData()
			{
				this.error = null;
				this.loading = true;
				GetNotificationData()
					.then(data =>
					{
						if (data.success)
						{
							this.jobNames = data.jobNames;
							this.eventNames = data.eventNames;
							this.notificationMethods = data.notificationMethods;
							this.registrations = data.registrations;
						}
						else
							this.error = data.error;
					})
					.catch(err =>
					{
						this.error = err.message;
					})
					.finally(() =>
					{
						this.loading = false;
					});
			},
			isChecked(jobName, notificationMethod)
			{
				let map2 = this.registrationsMap[jobName];
				if (map2)
					return map2[notificationMethod.key];
				return false;
			},
			setIsUpdating(jobName, notificationMethod, value)
			{
				let map2 = this.updatingMap[jobName];
				if (!map2)
				{
					map2 = {};
					Vue.set(this.updatingMap, jobName, map2);
				}
				Vue.set(map2, notificationMethod.key, value);
			},
			isUpdating(jobName, notificationMethod)
			{
				let map2 = this.updatingMap[jobName];
				if (map2)
					return map2[notificationMethod.key];
				return false;
			},
			/**
			 * Returns a reference to the checkbox (DOM element) for the given job name and notification method. Null if no checkbox is mapped to these values.
			 * @param {String} jobName Monitoring Job Name
			 * @param {String} notificationMethod Notification Method (email address, etc)
			 */
			getCheckbox(jobName, notificationMethod)
			{
				let map2 = this.notificationCheckboxMap[jobName];
				if (map2)
					return map2[notificationMethod.key];
				return null;
			},
			toggleNotification(jobName, notificationMethod)
			{
				let enable = this.toggleNotificationLocal(jobName, notificationMethod);

				this.setIsUpdating(jobName, notificationMethod, true);
				SetNotificationRegistration(jobName, notificationMethod, enable)
					.then(data =>
					{
						if (data.success)
						{
						}
						else
						{
							this.toggleNotificationLocal(jobName, notificationMethod);
							toaster.error(data.error);
						}
					})
					.catch(err =>
					{
						this.toggleNotificationLocal(jobName, notificationMethod);
						toaster.error(err);
					})
					.finally(() =>
					{
						this.setIsUpdating(jobName, notificationMethod, false);
					});
			},
			toggleNotificationLocal(jobName, notificationMethod)
			{
				let didRemove = false;
				for (let i = 0; i < this.registrations.length; i++)
				{
					let r = this.registrations[i];
					if (r.jobName === jobName && r.notificationMethod.key === notificationMethod.key)
					{
						this.registrations.splice(i, 1);
						didRemove = true;
					}
				}
				if (!didRemove)
					this.registrations.push({ jobName, notificationMethod });
				return !didRemove;
			},
			/**
				As a group, toggles the checked state of the given job or event names for the given notification method.
			*/
			toggleNotificationsForMethod(notificationMethod, names)
			{
				let enable = false;
				for (let i = 0; i < names.length; i++)
				{
					if (!this.isChecked(names[i], notificationMethod))
					{
						enable = true;
						break;
					}
				}
				for (let i = 0; i < names.length; i++)
				{
					if (this.isChecked(names[i], notificationMethod) !== enable)
					{
						this.toggleNotification(names[i], notificationMethod);
					}
				}
			}
		},
		watch:
		{
		}
	}
</script>

<style scoped>
	.pageRoot
	{
		padding: 16px;
		max-width: 1000px;
	}

	.loading
	{
		margin-top: 80px;
		text-align: center;
	}

	.error
	{
		color: #FF0000;
		font-weight: bold;
	}

	.notificationTable
	{
		border-collapse: collapse;
	}

		.notificationTable th
		{
			padding: 1px 8px;
		}

	.zebra:nth-child(2n)
	{
		background-color: #F2F2F2;
	}

	.toggleNotificationCell,
	.notificationCheckboxCell
	{
		text-align: center;
	}

		.notificationCheckboxCell.isUpdating
		{
			background-color: #FFCCAA;
			transition: background-color 0.5s;
		}

	.notificationCheckbox
	{
		width: 2em;
		height: 2em;
	}

	.allMonitorsToggleRow
	{
		background-color: #FFFFE2;
		font-size: 1.2em;
	}

		.allMonitorsToggleRow td
		{
			padding: 8px 1px;
		}

	.toggleNotificationButton
	{
		font-size: 1em;
	}

	@media (min-width: 600px)
	{
	}
</style>