<template>
	<div class="CmsRevisionSelectorDialog" role="alertdialog" aria-labelledby="CmsRevisionSelectorTitle">
		<div class="titleBar">
			<div class="title" id="CmsRevisionSelectorTitle" role="alert">Select a Revision to Copy</div>
		</div>
		<div class="dialogBody">
			<a role="button" tabindex="0" class="ProductRevisionClickable" @click="SelectRevision(0)">
				<img :src="appPath + 'Images/document_plain_new.png'" class="RevisionImageIcon" alt="" role="presentation" />
				<span class="ProductRevisionTitle">Empty Revision</span>
			</a>
			<div class="spacer"></div>
			<a role="button" tabindex="0" class="ProductRevisionClickable" @click="SelectRevision(rev.ProductRevisionId)"
			   v-for="rev in productRevisions" :key="rev.ProductRevisionId">
				<img :src="appPath + 'ProductImage/' + rev.ImageId" class="RevisionImage" alt="" role="presentation" />
				<span class="ProductRevisionTitle" v-html="rev.RevisionNameHtml"></span>
				<CmsLifecycleStateBadge :text="rev.LifecycleState" :compact="true" />
			</a>
			<div class="spacer"></div>
			<a role="button" tabindex="0" class="ProductRevisionClickable" @click="DefaultClose()">
				<img :src="appPath + 'Images/delete.png'" class="RevisionImageIcon" alt="" role="presentation" />
				<span class="ProductRevisionTitle">Cancel</span>
			</a>
		</div>
	</div>
</template>

<script>
	import CmsLifecycleStateBadge from 'appRoot/vues/client/controls/CmsLifecycleStateBadge.vue';

	export default {
		components: { CmsLifecycleStateBadge },
		props:
		{
			productRevisions: {
				type: Array,
				required: true
			}
		},
		data()
		{
			return {
				appPath: appContext.appPath
			};
		},
		created()
		{
		},
		mounted()
		{
		},
		computed:
		{
		},
		methods:
		{
			SetFocus()
			{

			},
			DefaultClose()
			{
				this.$emit('close', {});
			},
			SelectRevision(ProductRevisionId)
			{
				this.$emit('close', { ProductRevisionId });
			}
		},
		watch:
		{
		}
	}
</script>

<style scoped>
	.CmsRevisionSelectorDialog
	{
		background-color: #FFFFFF;
		box-shadow: rgba(0,0,0,0.7) 2px 9px 20px 7px;
		min-width: 300px;
		max-width: calc(100vw - 50px);
		word-break: break-word;
		word-wrap: break-word;
	}

	.titleBar
	{
		background-color: #FFFFFF;
		padding: 8px 14px;
		box-sizing: border-box;
	}

	.title
	{
		text-align: center;
		color: black;
		font-weight: bold;
		font-size: 16pt;
	}

	.dialogBody
	{
		padding: 4px 16px 16px 16px;
	}

	.RevisionImage
	{
		max-width: 22px;
		height: 22px;
		vertical-align: bottom;
	}

	.ProductRevisionClickable
	{
		display: block;
		padding: 3px 0px;
		cursor: pointer;
		color: #000000;
		text-decoration: none;
	}

		.ProductRevisionClickable:hover,
		.ProductRevisionClickable:focus
		{
			background-color: rgba(0,0,0,0.1);
		}

	.RevisionImageIcon
	{
		width: 16px;
		height: 16px;
		vertical-align: top;
	}

	.spacer
	{
		height: 1em;
	}
</style>