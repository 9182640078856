var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "root" },
    [
      _c("div", { staticClass: "head" }, [_c("ClientTopNav")], 1),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "body" },
        [
          _c("ClientMenu", { staticClass: "clientMenu" }),
          _vm._v(" "),
          _c("router-view"),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isOnCms ? _c("CmsConsole", { staticClass: "cmsConsole" }) : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }