<template>
	<div class="pageRoot">
		<div v-if="error" class="error">{{error}}</div>
		<div v-else-if="loading" class="loading"><ScaleLoader /> Loading…</div>
		<div v-else>
			<div :class="{ webSocketStatus: true, connected: isConnected, notConnected: !isConnected }" :title="isConnected ? 'WebSocket is connected. Health status is streaming into browser.' : 'WebSocket is not connected!'">
				<vsvg sprite="cable" class="webSocketIcon" />
			</div>
			<div class="overallHealth"><span :style="percentHealthStyle">{{Math.floor(overallHealth * 100)}}%</span> Overall Health</div>
			<div class="statusGraphLabel">Response time (milliseconds) of monitors:</div>
			<StatusGraph class="sharedStatusGraph" :jobStatus="graphData" :hoveredJob="hoveredJob" :labels="true"></StatusGraph>
			<JobProblemDetails class="healthProblems" :jobsAlerting="jobsAlerting" />
			<div class="statusLines">
				<StatusLine v-for="job in allJobStatus" :key="job.Name" :jobStatus="job" @hoverin="onStatusLineHoverIn" @hoverout="onStatusLineHoverOut"></StatusLine>
			</div>
		</div>
	</div>
</template>

<script>
	import JobProblemDetails from 'appRoot/vues/client/controls/JobProblemDetails.vue';
	import StatusLine from 'appRoot/vues/client/controls/StatusLine.vue';
	import StatusGraph from 'appRoot/vues/client/controls/StatusGraph.vue';
	import Vue from 'vue';
	import svg1 from 'appRoot/images/sprite/cable.svg';
	import { SessionCheck } from 'appRoot/scripts/Util';

	export default {
		components: { JobProblemDetails, StatusLine, StatusGraph },
		props:
		{
		},
		data()
		{
			return {
				error: null,
				loading: false,
				ws: null,
				allJobStatus: [],
				isConnected: false,
				hoveredJobName: null,
				wsPingInterval: null
			};
		},
		created()
		{
			SessionCheck();

			this.error = null;
			this.loading = true;

			this.openWebSocket();
		},
		beforeDestroy()
		{
			this.ws.close();
			this.destroyed;
		},
		computed:
		{
			jobsAlerting()
			{
				let arr = [];
				for (let i = 0; i < this.allJobStatus.length; i++)
				{
					if (this.allJobStatus[i].AlertState !== null)
						arr.push(this.allJobStatus[i]);
				}
				return arr;
			},
			jobsNotAlerting()
			{
				let arr = [];
				for (let i = 0; i < this.allJobStatus.length; i++)
				{
					if (this.allJobStatus[i].AlertState === null)
						arr.push(this.allJobStatus[i]);
				}
				return arr;
			},
			overallHealth()
			{
				return (this.jobsNotAlerting.length / this.allJobStatus.length);
			},
			percentHealthStyle()
			{
				return { color: this.overallHealth === 1 ? "#00AA00" : "#FF0000" };
			},
			graphData()
			{
				let arr = [];
				for (let i = 0; i < this.allJobStatus.length; i++)
				{
					if (this.allJobStatus[i].TypeName !== "KentIntegrationTestMonitor" // KentIntegrationTestMonitor retains data for a much longer time, so it is not appropriate to graph together with other items.
						&& this.allJobStatus[i].TypeName !== "FtpMonitor") // FTP tests are only done every 1 minute and are quite slow, so they retain data for a much longer time and are graphed separately
						arr.push(this.allJobStatus[i]);
				}
				return arr;
			},
			hoveredJob()
			{
				for (let i = 0; i < this.allJobStatus.length; i++)
				{
					if (this.allJobStatus[i].Name === this.hoveredJobName)
						return this.allJobStatus[i];
				}
				return null;
			},
			graphOfLabel()
			{
				if (this.hoveredJob)
					return this.hoveredJob.Name;
				else if (this.graphData.length > 1)
					return "multiple monitors";
				else if (this.graphData.length === 1)
					return this.graphData[0].Name;
				else
					return "Unknown";
			}
		},
		methods:
		{
			openWebSocket()
			{
				if (this._isDestroyed)
					return;
				this.ws = new WebSocket(location.origin.replace(/^http/i, "ws") + appContext.appPath + "DataStream/GetMonitoringDataWS?sid=" + encodeURIComponent(this.$store.state.sid));
				this.ws.onopen = this.onWebSocketOpen;
				this.ws.onclose = this.onWebSocketClose;
				this.ws.onerror = this.onWebSocketError;
				this.ws.onmessage = this.onWebSocketMessage;
			},
			onWebSocketOpen(e)
			{
				this.isConnected = true;
				this.loading = false;
				this.error = null;
				clearInterval(this.wsPingInterval);
				this.wsPingInterval = setInterval(() =>
				{
					if (this.isConnected)
						this.ws.send('ping');
				}, 5000);
			},
			onWebSocketClose(e)
			{
				clearInterval(this.wsPingInterval);
				this.isConnected = false;
				setTimeout(() =>
				{
					this.openWebSocket();
				}, 2000);
			},
			onWebSocketError(e)
			{
				console.error("WebSocket error:", e);
				this.isConnected = false;
				clearInterval(this.wsPingInterval);
				if (e.message)
					this.error = e.message;
				else if (this.error)
					this.error = "A generic WebSocket error occurred.";
				if (this.ws.readyState < 2)
					this.ws.close();
			},
			onWebSocketMessage(e)
			{
				const jobStatus = JSON.parse(e.data);
				this.setJobStatus(jobStatus.Name, jobStatus);
			},
			getJobStatus(name)
			{
				for (let i = 0; i < this.allJobStatus.length; i++)
				{
					if (this.allJobStatus[i].Name === name)
						return this.allJobStatus[i];
				}
			},
			setJobStatus(name, jobStatus)
			{
				for (let i = 0; i < this.allJobStatus.length; i++)
				{
					if (this.allJobStatus[i].Name === name)
					{
						Vue.set(this.allJobStatus, i, jobStatus);
						return;
					}
				}
				this.allJobStatus.push(jobStatus);
			},
			onStatusLineHoverIn(job)
			{
				this.hoveredJobName = job.Name;
			},
			onStatusLineHoverOut(job)
			{
				if (this.hoveredJobName === job.Name)
					this.hoveredJobName = null;
			}
		},
		watch:
		{
		}
	}
</script>

<style scoped>
	.pageRoot
	{
		padding: 16px;
		max-width: 1000px;
	}

	.loading
	{
		margin-top: 80px;
		text-align: center;
	}

	.error
	{
		color: #FF0000;
		font-weight: bold;
	}

	.webSocketStatus
	{
		float: right;
		padding: 0px 0px 10px 10px;
	}


		.webSocketStatus.connected
		{
			fill: #00AA00;
		}

		.webSocketStatus.notConnected
		{
			fill: #FF0000;
			animation: blink-animation 1s steps(5, start) infinite;
		}

	.webSocketIcon
	{
		width: 32px;
		height: 32px;
	}

	@keyframes blink-animation
	{
		to
		{
			visibility: hidden;
		}
	}

	.overallHealth
	{
		font-weight: bold;
		font-size: 1.5em;
	}

	.statusGraphLabel
	{
		margin-top: 1em;
		margin-bottom: 5px;
		/*white-space: nowrap;*/
		/*overflow-x: hidden;*/
		/*text-overflow: ellipsis;*/
	}

	.sharedStatusGraph
	{
		padding-top: 5px;
		height: 134px;
		position: sticky;
		top: 0px;
		z-index: 10;
	}

	.healthProblems
	{
		margin-top: 1em;
		word-break: break-word;
	}

	.statusLines
	{
		margin-top: 1em;
	}

		.statusLines > *:nth-child(2n+1)
		{
			background-color: #F2F2F2;
		}

	@media (min-width: 600px)
	{
		.statusLines
		{
			margin-top: 1em;
			min-width: 400px;
			max-width: 580px;
		}
	}
</style>