import { EscapeHTML } from 'appRoot/scripts/Util';

export default function ToasterHelper(toastMethod)
{
	this.error = this.Error = function (title, message, duration)
	{
		if (!duration)
			duration = 30000;
		makeToast('error', title, message, duration);
	};
	this.warning = this.Warning = function (title, message, duration)
	{
		if (!duration)
			duration = 30000;
		makeToast('warning', title, message, duration);
	};
	this.info = this.Info = function (title, message, duration)
	{
		makeToast('info', title, message, duration);
	};
	this.success = this.Success = function (title, message, duration)
	{
		makeToast('success', title, message, duration);
	};
	this.custom = this.Custom = function (options)
	{
		// Make a toastr with custom properties
		//	this.$toastr('add', {
		//		title: 'Heyy', // Toast Title
		//		msg: 'I am a custom property toastr', // Message
		//		timeout: 1000, // Timeout in ms
		//		position: 'toast-bottom-full-width', // Toastr position
		//		type: 'info', // Toastr type
		//		closeOnHover: true, // On mouse over stop timeout can be boolean; default true
		//		clickClose: false, // On click toast close can be boolean; default false
		//		// Available callbacks
		//		onCreated: () => console.log('toast was created'),
		//		onClicked: () => console.log('toast was clicked'),
		//		onClosed: () => console.log('toast was closed'),
		//		onMouseOver: () => console.log('toast was moused over'),
		//		onMouseOut: () => console.log('mouse left the toast')
		//	})
		toastMethod('add', options);
	};
	function makeToast(type, title, message, duration)
	{
		if (!duration)
			duration = 10000;
		if (!message)
		{
			message = title;
			title = null;
		}

		if (typeof message === "object")
		{
			let str = "";
			if (typeof message.name === "string")
				str = EscapeHTML(message.name);
			if (typeof message.code === "string")
				str += " (code " + EscapeHTML(message.code) + ")";
			str += ": ";
			if (typeof message.message === "string")
				str += EscapeHTML(message.message);
			else
				str += "[no error message]";
			if (typeof message.stack === "string")
			{
				let stack = message.stack;
				if (stack.startsWith(str))
					stack = stack.substr(str.length);
				str += ": " + EscapeHTML(stack);
			}
			message = str;
			console.error(type + " toast:", message);
		}
		else
		{
			if (type === "error")
				console.error(type + " toast:", message);
			else
				console.log(type + " toast:", message);
			message = EscapeHTML(message.toString());
		}
		toastMethod('add', {
			title: title,
			msg: message,
			timeout: duration,
			type: type
		});
	}
}