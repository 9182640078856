var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "forgotPw" }, [
    _c(
      "div",
      {
        staticClass: "forgotPwPanel",
        attrs: {
          title:
            "Note: Recovery emails have a 5 minute cooldown per user account.",
        },
      },
      [
        _c("div", { staticClass: "systemName" }, [
          _vm._v(_vm._s(_vm.systemName)),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "titleBar" }, [_vm._v("Password Recovery")]),
        _vm._v(" "),
        _c("p", { staticClass: "hint" }, [
          _vm._v("To reset "),
          _c("b", [_vm._v(_vm._s(_vm.initialUser))]),
          _vm._v(", paste the Security Code that has been emailed to you."),
        ]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.securityCode,
              expression: "securityCode",
            },
          ],
          staticClass: "txtUser",
          attrs: { type: "text", placeholder: "Security Code" },
          domProps: { value: _vm.securityCode },
          on: {
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.DoReset.apply(null, arguments)
            },
            input: function ($event) {
              if ($event.target.composing) return
              _vm.securityCode = $event.target.value
            },
          },
        }),
        _vm._v(" "),
        _c("input", {
          staticClass: "btnRequest",
          attrs: {
            type: "button",
            value: "Reset Password",
            disabled: !_vm.enabled,
          },
          on: {
            click: _vm.DoReset,
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "space", 32, $event.key, [
                  " ",
                  "Spacebar",
                ]) &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.DoReset.apply(null, arguments)
            },
          },
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "backToLoginLinkWrapper" },
          [
            _c(
              "router-link",
              {
                staticClass: "backToLoginLink",
                attrs: { to: { name: "login" } },
              },
              [_vm._v("Back to Login")]
            ),
          ],
          1
        ),
      ]
    ),
    _vm._v(" "),
    _vm.error
      ? _c("div", { staticClass: "error" }, [_vm._v(_vm._s(_vm.error))])
      : _vm._e(),
    _vm._v(" "),
    _vm.resetCompleted
      ? _c("div", { staticClass: "resetCompleted" }, [
          _vm._v("The password for "),
          _c("b", [_vm._v(_vm._s(_vm.initialUser))]),
          _vm._v(" has been reset and sent to their email."),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }