<template>
	<div class="badge" :class="badgeDynClasses">{{text}}</div>
</template>

<script>
	export default {
		props:
		{
			text: {
				type: String,
				required: true
			},
			disabled: {
				type: Boolean,
				default: false
			},
			compact: {
				type: Boolean,
				default: false
			}
		},
		computed:
		{
			badgeDynClasses()
			{
				let classes = [];
				classes.push(this.text);
				if (this.disabled)
					classes.push("disabled");
				if (this.compact)
					classes.push("compact");
				return classes;
			}
		}
	}
</script>

<style scoped>
	.badge
	{
		display: inline-block;
		font-weight: bold;
		background-color: #FFFFFF;
		border: 1px solid rgba(0,0,0,0.5);
		border-radius: 3px;
		padding: 3px 8px;
	}

		.badge.Upcoming
		{
			background-color: rgb(255 207 180);
			border-color: rgb(200 96 0);
		}

		.badge.Staging
		{
			background-color: rgb(255 245 180);
			border-color: rgb(200 165 0);
		}

		.badge.Live
		{
			background-color: rgb(132 255 132);
			border-color: rgb(0 149 0);
		}

		.badge.Inactive
		{
			background-color: rgb(151 216 255);
			border-color: rgb(19 81 119);
		}

		.badge.disabled
		{
			font-weight: normal;
			background-color: #FFFFFF;
			border-color: rgba(0,0,0,0.5);
			opacity: 0.38;
		}

		.badge.compact
		{
			font-size: 11px;
			font-weight: normal;
			vertical-align: bottom;
		}
</style>