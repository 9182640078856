import ExecAPI from 'appRoot/api/api';

/**
 * Returns the available monitoring job names, notification methods (addresses), and notification registrations for the user.
 */
export function GetNotificationData()
{
	return ExecAPI("NotificationData/GetNotificationData");
}
/**
 * Enables or disables notifications for a particular combination of job name and notification method (address).
 * @param {String} jobName Name of the monitoring job.
 * @param {String} notificationMethod Email address, push registration token, etc.
 * @param {Boolean} enable True to add the registration, false to remove it.
 */
export function SetNotificationRegistration(jobName, notificationMethod, enable)
{
	return ExecAPI("NotificationData/SetNotificationRegistration", { jobName, notificationMethod, enable });
}