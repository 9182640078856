var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "geolocateIpDialogRoot",
      attrs: { role: "dialog", "aria-labelledby": "geolocateIpTitle" },
    },
    [
      _c("div", { staticClass: "titleBar" }, [
        _c(
          "div",
          {
            staticClass: "title",
            attrs: { id: "geolocateIpTitle", role: "alert" },
          },
          [_vm._v("Geolocation Information for " + _vm._s(_vm.ip))]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "dialogBody" },
        [
          _c("GeolocationEmbed", {
            staticClass: "embedded",
            attrs: { ip: _vm.ip },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "buttons" }, [
            _c(
              "div",
              {
                ref: "closeBtn",
                staticClass: "dialogButton",
                attrs: { tabindex: "0" },
                on: {
                  click: _vm.DefaultClose,
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "space", 32, $event.key, [
                        " ",
                        "Spacebar",
                      ]) &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    $event.preventDefault()
                    return _vm.DefaultClose.apply(null, arguments)
                  },
                },
              },
              [_vm._v("\n\t\t\t\tClose\n\t\t\t")]
            ),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }