var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "changePassword" },
    [
      _c("h2", [_vm._v("Change Password")]),
      _vm._v(" "),
      !_vm.allowPasswordAuth
        ? _c("p", [
            _vm._v("Password authentication is disabled on this server."),
          ])
        : [
            _c("p", [
              _vm._v("User: "),
              _c("b", [_vm._v(_vm._s(_vm.userName))]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "inputs" }, [
              _c("div", { staticClass: "oldPasswordBox" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.oldPw,
                      expression: "oldPw",
                    },
                  ],
                  staticClass: "tb",
                  attrs: {
                    type: "password",
                    name: "oldPw",
                    "aria-label": "Enter Old Password Here",
                    placeholder: "Old Password…",
                    autocomplete: "current-password",
                  },
                  domProps: { value: _vm.oldPw },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.oldPw = $event.target.value
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "newPasswordBox" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.newPw,
                      expression: "newPw",
                    },
                  ],
                  staticClass: "tb",
                  attrs: {
                    type: "password",
                    name: "newPw",
                    "aria-label": "Enter New Password Here",
                    placeholder: "New Password…",
                    autocomplete: "new-password",
                  },
                  domProps: { value: _vm.newPw },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.newPw = $event.target.value
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "confirmNewPasswordBox" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.confirmNewPw,
                      expression: "confirmNewPw",
                    },
                  ],
                  staticClass: "tb",
                  attrs: {
                    type: "password",
                    name: "confirmNewPw",
                    "aria-label": "Confirm New Password Here",
                    placeholder: "Confirm New Password…",
                    autocomplete: "new-password",
                  },
                  domProps: { value: _vm.confirmNewPw },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.confirmNewPw = $event.target.value
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "changePasswordBox" }, [
                _c("input", {
                  staticClass: "changePasswordButton",
                  attrs: {
                    type: "button",
                    value: "Submit",
                    disabled: !_vm.enabled,
                  },
                  on: {
                    click: _vm.TryChangePassword,
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "space", 32, $event.key, [
                          " ",
                          "Spacebar",
                        ]) &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.TryChangePassword.apply(null, arguments)
                    },
                  },
                }),
              ]),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }