<template>
	<div class="ProductRevision">
		<img :src="starImgProductRevisionMap[this.rev.ProductRevisionId]" alt="star" class="product-star" role="button" tabindex="0" @click="toggleStarProductRevision(rev)" @keypress.enter.space.prevent="toggleStarProductRevision(rev)" />
		<router-link :to="GetRouteToProductRevision(rev)" class="ProductRevisionClickable">
			<img :src="appPath + 'ProductImage/' + rev.ImageId" class="ProductImage" />
			<span class="ProductRevisionTitle" v-html="myTitle"></span>
			<CmsLifecycleStateBadge :text="rev.LifecycleState" :compact="true" />
		</router-link>
	</div>
</template>

<script>
	import EventBus from 'appRoot/scripts/EventBus';
	import CmsLifecycleStateBadge from 'appRoot/vues/client/controls/CmsLifecycleStateBadge.vue';
	import StarredRowMixin from 'appRoot/scripts/StarredRowMixin.js';
	import { EscapeHTML } from 'appRoot/scripts/Util';

	export default {
		components: { CmsLifecycleStateBadge },
		mixins: [StarredRowMixin],
		props:
		{
			rev: {
				type: Object,
				required: true
			},
			showFxInfo: {
				type: Boolean,
				default: false
			}
		},
		data()
		{
			return {
				appPath: appContext.appPath
			};
		},
		created()
		{
		},
		mounted()
		{

		},
		computed:
		{
			star_ProductRootId()
			{
				return 0;
			},
			star_ProductRevisionIds()
			{
				return [this.rev.ProductRevisionId];
			},
			fxInfoString()
			{
				if (this.showFxInfo)
				{
					if (EventBus.productRootMap)
					{
						let proot = EventBus.productRootMap[this.rev.ProductRootId];
						if (proot)
						{
							return " [" + EscapeHTML(proot.FileName) + ", " + proot.TaylorProductId + "]";
						}
					}
				}
				return "";
			},
			myTitle()
			{
				return this.rev.RevisionNameHtml + this.fxInfoString;
			}
		},
		methods:
		{
			GetRouteToProductRevision(rev)
			{
				return {
					name: "clientCMSProductRevision",
					params: { id: rev.ProductRevisionId }
				};
			}
		},
		watch:
		{
		}
	}
</script>

<style scoped>
	.ProductImage
	{
		max-width: 22px;
		height: 22px;
		vertical-align: bottom;
	}

	.ProductRevision
	{
		display: flex;
	}

	.ProductRevisionClickable
	{
		flex: 1 1 auto;
		display: inline-block;
		padding: 3px 0px;
		cursor: pointer;
		color: #000000;
		text-decoration: none;
		font-size: 16px;
	}

		.ProductRevisionClickable:hover,
		.ProductRevisionClickable:focus
		{
			background-color: rgba(0,0,0,0.1);
		}

	.TreeGraphic
	{
		margin: 0px 5px 0px 29px;
	}
</style>