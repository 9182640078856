var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: { uploadInput: true, hover: _vm.hover } },
    [
      [
        _c("img", {
          ref: "myImg",
          staticClass: "myImg",
          attrs: { src: _vm.imageSrc, role: "presentation", alt: "image" },
          on: { load: _vm.imgLoaded },
        }),
        _vm._v(" "),
        _vm.imageCanExpand
          ? _c("img", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.hover,
                  expression: "hover",
                },
              ],
              staticClass: "myImgLarge",
              attrs: { src: _vm.imageSrc, role: "presentation", alt: "image" },
            })
          : _vm._e(),
      ],
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "overlay" },
        [
          _c("vsvg", {
            staticClass: "uploadIcon",
            attrs: { sprite: "upload" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("input", {
        ref: "imageFileInput",
        attrs: { type: "file", accept: _vm.accept },
        on: {
          change: _vm.imageFileSelected,
          mouseenter: function ($event) {
            _vm.hover = true
          },
          mouseleave: function ($event) {
            _vm.hover = false
          },
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }