var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: {
        healthProblems: true,
        hasHealthProblems: !!_vm.jobsAlerting.length,
      },
    },
    [
      _vm.jobsAlerting.length > 0
        ? _vm._l(_vm.jobsAlerting, function (job) {
            return _c("p", { staticClass: "alertMessage" }, [
              _vm._v(_vm._s(job.AlertState)),
            ])
          })
        : [_c("p", [_vm._v("All monitors report OK")])],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }