<template>
	<div :class="loginLayoutClasses">
		<div class="loginContainer">
			<router-view class="loginView"></router-view>
			<Footer class="loginFooter" />
			<VStyle>
				.loginLayout.wallpaper
				{
				background-image: url('{{appPath}}images/long-hallway-with-row-servers-center-10.webp');
				}
			</VStyle>
		</div>
	</div>
</template>
<script>
	import Footer from 'appRoot/vues/common/Footer.vue';
	import VStyle from 'appRoot/vues/common/controls/VStyle.vue';

	export default {
		components: { VStyle, Footer },
		data()
		{
			return {
				appPath: appContext.appPath,
				loginLayoutClasses: ["loginLayout"]
			};
		},
		created()
		{
			if (appContext.loginStyle)
				this.loginLayoutClasses.push(appContext.loginStyle);
		}
	}
</script>
<style scoped>
	.loginView
	{
		margin-top: auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
	}

	.loginLayout
	{
		width: 100%;
		height: 100%;
		font-size: 16px;
		background-color: #555555;
		background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);
	}

		.loginLayout.style2
		{
			background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
		}

		.loginLayout.wallpaper
		{
			background-size: cover;
			background-position: center;
		}

			.loginLayout.wallpaper .loginContainer
			{
				backdrop-filter: blur(5px);
			}

	.loginContainer
	{
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		height: 100%;
	}

	.loginFooter
	{
		background-color: rgba(255,255,255,0.5);
		backdrop-filter: blur(5px);
		box-shadow: 0 0 16px rgba(0,0,0,0.5);
		text-shadow: 0px 0px 4px rgba(255,255,255,1);
	}
</style>
