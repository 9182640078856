var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.loginLayoutClasses }, [
    _c(
      "div",
      { staticClass: "loginContainer" },
      [
        _c("router-view", { staticClass: "loginView" }),
        _vm._v(" "),
        _c("Footer", { staticClass: "loginFooter" }),
        _vm._v(" "),
        _c("VStyle", [
          _vm._v(
            "\n\t\t\t.loginLayout.wallpaper\n\t\t\t{\n\t\t\tbackground-image: url('" +
              _vm._s(_vm.appPath) +
              "images/long-hallway-with-row-servers-center-10.webp');\n\t\t\t}\n\t\t"
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }