import ExecAPI from 'appRoot/api/api';

/**
 * Gets the array of Monitor objects on the server.
 */
export function GetMonitoringConfig()
{
	return ExecAPI("MonitoringConfig/GetMonitoringConfig");
}
/**
 * Sets the array of Monitor objects on the server.
 * @param {Array} monitors Array of Monitor objects.
 */
export function SetMonitoringConfig(monitors)
{
	return ExecAPI("MonitoringConfig/SetMonitoringConfig", { monitors });
}