import ExecAPI from 'appRoot/api/api';

export function PasskeyRegisterChallenge(user, token, isAtLogin)
{
	return ExecAPI("PasskeyCreate/PasskeyRegisterChallenge", { user, token, isAtLogin });
}
export function PasskeyRegister(user, challenge, attestationResponse)
{
	return ExecAPI("PasskeyCreate/PasskeyRegister", { user, challenge, attestationResponse });
}
export function PasskeyLoginChallenge(user)
{
	return ExecAPI("PasskeyAuth/PasskeyLoginChallenge", { user });
}
export function PasskeyLogin(challenge, assertionResponse)
{
	return ExecAPI("PasskeyAuth/PasskeyLogin", { challenge, assertionResponse });
}