var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    { staticClass: "clientNav", attrs: { id: "topNav" } },
    [
      _c(
        "router-link",
        { staticClass: "systemName", attrs: { to: { name: "clientStatus" } } },
        [_vm._v(_vm._s(_vm.title) + " " + _vm._s(_vm.serverVersion))]
      ),
      _vm._v(" "),
      _c(
        "router-link",
        { attrs: { to: { name: "clientNotificationsSetup" } } },
        [
          _c("vsvg", {
            staticClass: "notifications_icon",
            attrs: { sprite: "notifications_active" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("router-link", { attrs: { to: { name: "clientManage" } } }, [
        _vm._v(_vm._s(_vm.userName)),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }