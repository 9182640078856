<template>
	<div class="releaseDetailsRoot" role="dialog" aria-labelledby="releaseDetailsTitle">
		<div class="titleBar">
			<div id="releaseDetailsTitle" class="title" role="alert">Release Manager: {{release.Name}} @ {{environment.Name}}</div>
		</div>
		<div class="dialogBody">
			<div class="primaryHeading">Release Name</div>
			<EditableText :value="release.Name" :disabled="changingReleaseName || installingRelease || release.IsArchiving" @input="onReleaseNameTextInput" />

			<div class="primaryHeading">Build Date</div>
			<div>{{timestampToDateString(release.BuildDate)}}</div>

			<div class="primaryHeading">Description</div>
			<EditableText :multiline="true" :value="release.Manifest.Description" :disabled="changingDescription || release.IsArchiving" @input="onDescriptionTextInput" />

			<div class="primaryHeading">AppSettings <SvgButton sprite="edit_note" class="editButton" :contained="true" :round="true" @click="editAppSettings" /></div>
			<!--<div><input type="button" value="View/Edit AppSettings.spdx" /></div>-->

			<div class="primaryHeading">Installation</div>
			<div class="installation" v-for="(app, index) in environment.Apps" :key="'installation_' + index">
				<div class="installedApp" v-if="app.ReleaseName === release.Name">
					✨ Installed in {{app.AppName}} app.
					<a v-if="app.Links.length" :href="app.Links[0]" target="_blank" @click.prevent="openAppLinks(app)">{{app.Links.length > 1 ? 'open ' + app.Links.length + ' links' : app.Links[0]}}</a>
					<input type="button" class="integrationTestBtn" :disabled="app.isIntegrationTesting" value="Run Tests" @click="runIntegrationTests(app)" />
					<span v-if="app.integrationTestResults" class="integrationTestResults">{{app.integrationTestResults}}</span>
				</div>
				<template v-else>
					<slide-button :key="'slideBtn_' + index + '_' + slideBtnCounter"
								  ref="installBtns"
								  :auto-width="false"
								  :circle="true"
								  :disabled="false"
								  :noanimate="true"
								  :width="300"
								  :height="60"
								  text="slide to install"
								  success-text="installing"
								  @completed="onInstallCommanded(app)"
								  :requireReleaseToComplete="true" />
					<div class="labelAfter">
						in {{app.AppName}} app.
					</div>
				</template>
			</div>
			<template>
				<!-- v-if="!release.Installs || !release.Installs.length"-->
				<div class="primaryHeading">Archival</div>
				<p>Archiving a release compresses it and removes it from the environment display.</p>
				<p><input type="button" :value="'Archive ' + release.Name + ' now'" @click="archiveRelease" :disabled="release.Installs && release.Installs.length > 0" /></p>
			</template>
			<template v-if="offerToOverrideLockouts">
				<div class="primaryHeading">Critical Operation Lockout</div>
				<p>The last release installation failed the Critical Operation Lockout test.  You may override the lockout if desired by checking the box below and attempting the installation again.</p>
				<p><label><input type="checkbox" v-model="overrideLockouts" /> Allow release installation to proceed regardless of ongoing critical operations.</label></p>
			</template>
		</div>
		<div class="buttons">
			<div ref="closeBtn" class="dialogButton" tabindex="0" @click="DefaultClose" @keydown.space.enter.prevent="DefaultClose">
				Close
			</div>
		</div>
	</div>
</template>

<script>
	import SlideButton from 'appRoot/vues/common/controls/SlideButton.vue';
	import EditableText from 'appRoot/vues/common/controls/EditableText.vue';
	import svg1 from 'appRoot/images/sprite/edit_note.svg';
	import { ChangeReleaseName, ChangeReleaseDescription, ChangeReleaseAppSettings, InstallRelease, ArchiveRelease, RunIntegrationTests } from 'appRoot/api/ReleaseData';
	import { AceCodeEditorDialog, ModalConfirmDialog } from 'appRoot/scripts/ModalDialog';
	import Vue from 'vue';

	export default {
		components: { SlideButton, EditableText },
		props:
		{
			environment: {
				type: Object,
				required: true
			},
			release: {
				type: Object,
				required: true
			}
		},
		data()
		{
			return {
				changingReleaseName: false,
				changingDescription: false,
				installingRelease: false,
				runningIntegrationTests: 0,
				offerToOverrideLockouts: false,
				overrideLockouts: false,
				slideBtnCounter: 0
			};
		},
		created()
		{
			for (let i = 0; i < this.environment.Apps.length; i++)
			{
				let app = this.environment.Apps[i];
				if (!app.integrationTestResults)
					Vue.set(app, "integrationTestResults", null);
				if (!app.isIntegrationTesting)
					Vue.set(app, "isIntegrationTesting", false);
			}
		},
		beforeDestroy()
		{
		},
		computed:
		{
			isBusy()
			{
				return this.changingReleaseName || this.changingDescription || this.installingRelease || this.runningIntegrationTests > 0;
			}
		},
		methods:
		{
			SetFocus()
			{
				if (this.$refs.closeBtn)
					this.$refs.closeBtn.focus();
			},
			DefaultClose()
			{
				if (this.isBusy)
					toaster.Warning("This release is undergoing a background operation. Closing the dialog is not currently allowed.");
				else
					this.$emit("close");
			},
			timestampToDateString(timestamp)
			{
				let date = new Date(timestamp);
				var str = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
				return str;
			},
			onInstallCommanded(app)
			{
				if (!app.isIntegrationTesting)
				{
					app.isIntegrationTesting = false;
					app.integrationTestResults = null;
				}
				this.installingRelease = true;
				let overrideLockouts = this.offerToOverrideLockouts && this.overrideLockouts;
				InstallRelease(this.environment.Name, this.release.Name, app.AppName, overrideLockouts)
					.then(result =>
					{
						if (!result.error)
						{
							this.offerToOverrideLockouts = false;
							app.ReleaseName = this.release.Name;
							for (let i = 0; i < this.environment.Releases.length; i++)
							{
								let r = this.environment.Releases[i];
								if (r.Installs)
									for (let n = 0; n < r.Installs.length; n++)
										if (r.Installs[n] === app.AppName)
										{
											r.Installs.splice(n, 1);
											n--;
										}
							}
							if (!this.release.Installs)
								this.release.Installs = [];
							this.release.Installs.push(app.AppName);
						}
						else
						{
							toaster.Error(result.error);
							if (result.error.toUpperCase().indexOf("CRITICAL OPERATION") > -1)
								this.offerToOverrideLockouts = true;
						}
					})
					.catch(err =>
					{
						toaster.Error(err);
					})
					.finally(() =>
					{
						this.installingRelease = false;
						this.slideBtnCounter++;
						//if (this.$refs.installBtns)
						//{
						//	if (this.$refs.installBtns.length)
						//	{
						//		for (let i = 0; this.$refs.installBtns.length; i++)
						//			this.resetSlideButton(this.$refs.installBtns[i]);
						//	}
						//	else
						//	{
						//		this.resetSlideButton(this.$refs.installBtns);
						//	}
						//}
					});
			},
			resetSlideButton(slideBtn)
			{
				if (slideBtn)
				{
					if (typeof slideBtn.reset === "function")
						slideBtn.reset();
				}
			},
			openAppLinks(app)
			{
				for (let i = 0; i < app.Links.length; i++)
				{
					window.open(app.Links[i]);
				}
			},
			editAppSettings()
			{
				AceCodeEditorDialog(this.release.Name + "/AppSettings.spdx", this.release.AppSettings, "ace/mode/xml",
					value => // Called when committing changes, before closing the dialog.
					{
						return ChangeReleaseAppSettings(this.environment.Name, this.release.Name, value)
							.then(result =>
							{
								if (result.error)
									return Promise.reject(result.error);
								return true;
							});
					})
					.then(value => // Called when changes have been committed and the dialog is closed.
					{
						if (value !== null)
							this.release.AppSettings = value;

					});
			},
			onReleaseNameTextInput(value)
			{
				this.changingReleaseName = true;
				let newName = value;
				if (newName !== this.release.Name)
				{
					ChangeReleaseName(this.environment.Name, this.release.Name, newName)
						.then(result =>
						{
							if (!result.error)
								this.release.Name = newName;
							else
								toaster.Error(result.error);
						})
						.catch(err =>
						{
							toaster.Error(err);
						})
						.finally(() =>
						{
							this.changingReleaseName = false;
						});
				}
			},
			onDescriptionTextInput(value)
			{
				this.changingDescription = true;
				let newDescription = value;
				if (newDescription !== this.release.Manifest.Description)
				{
					ChangeReleaseDescription(this.environment.Name, this.release.Name, newDescription)
						.then(result =>
						{
							if (!result.error)
								this.release.Manifest.Description = newDescription;
							else
								toaster.Error(result.error);
						})
						.catch(err =>
						{
							toaster.Error(err);
						})
						.finally(() =>
						{
							this.changingDescription = false;
						});
				}
			},
			archiveRelease()
			{
				ModalConfirmDialog("Archiving " + this.release.Name + " will remove it from the environment display and compress its files. Do you want to proceed?", "Confirm Archival", "Yes, Archive", "Cancel")
					.then(result =>
					{
						if (result)
						{
							this.release.IsArchiving = true;
							ArchiveRelease(this.environment.Name, this.release.Name)
								.then(result =>
								{
									if (result.error)
									{
										toaster.Error(result.error);
										this.release.IsArchiving = false;
									}
									else
										this.DefaultClose();
								})
								.catch(err =>
								{
									toaster.Error(err);
									this.release.IsArchiving = false;
								});
						}
					});
			},
			runIntegrationTests(app)
			{
				this.runningIntegrationTests++;
				app.isIntegrationTesting = true;
				app.integrationTestResults = "running…";
				RunIntegrationTests(this.environment.Name, app.AppName)
					.then(result =>
					{
						if (result.error)
						{
							app.integrationTestResults = result.error;
							toaster.Error("Integration test failed against app " + app.AppName + ":\n" + result.error);
						}
						else
							app.integrationTestResults = "Tests Passed";
					})
					.catch(err =>
					{
						toaster.Error("Integration test error for app " + app.AppName + ":\n" + err.toString());
						app.integrationTestResults = err.toString();
					})
					.finally(() =>
					{
						this.runningIntegrationTests--;
						app.isIntegrationTesting = false;
					});
			}
		},
		watch:
		{
			release: {
				deep: true,
				handler()
				{
					this.releaseNameInput = this.release.Name;
					this.descriptionInput = this.release.Manifest.Description;
				}
			}
		}
	}
</script>

<style scoped>
	.releaseDetailsRoot
	{
		width: calc(100vw - 30px);
		max-height: calc(100vh - 30px);
		background-color: #FFFFFF;
	}

	.titleBar
	{
		background-color: #FFFFFF;
		padding: 8px 14px;
		box-sizing: border-box;
	}

	.title
	{
		text-align: center;
		color: black;
		font-weight: bold;
		font-size: 16pt;
	}

	.dialogBody
	{
		padding: 4px 12px 0px 12px;
	}

	.buttons
	{
		display: flex;
	}

	.dialogButton
	{
		display: inline-block;
		cursor: pointer;
		color: #1e78af;
		font-weight: bold;
		font-size: 16pt;
		box-sizing: border-box;
		position: relative;
		padding: 9px 5px 8px 5px;
		flex: 1 0 auto;
		text-align: center;
	}

		.dialogButton:hover
		{
			background-color: rgba(0,0,0,0.05);
		}

	.primaryHeading
	{
		font-size: 1.5em;
		margin-top: 0.8em;
		margin-bottom: 0.5em;
		display: flex;
		align-items: center;
	}

		.primaryHeading:first-child
		{
			margin-top: 0px;
		}

	.editButton
	{
		margin-left: 0.667em;
	}

	.installation
	{
		display: flex;
		flex-wrap: wrap;
		align-items: baseline;
		margin-bottom: 0.5em;
		font-size: 24px;
	}

	.installedApp
	{
		line-height: 72px;
	}

	.labelAfter
	{
		margin-left: 0.667em;
	}

	.integrationTestBtn
	{
		font-size: 24px;
	}

	.integrationTestResults
	{
		font-size: 16px;
		line-height: normal;
		white-space: pre-wrap;
	}

	@media (min-width: 600px)
	{
		.releaseDetailsRoot
		{
			width: 90vw;
		}
	}
</style>