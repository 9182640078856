<template>
	<div class="pageRoot">
		<h1>TdsAppService Administration Homepage</h1>
		<p>Please select a menu item above.</p>
	</div>
</template>

<script>
	import { SessionCheck } from 'appRoot/scripts/Util';

	export default {
		components: {},
		data()
		{
			return {
			};
		},
		created()
		{
			SessionCheck();
		}
	}
</script>

<style scoped>
	.pageRoot
	{
		margin: 8px;
	}
</style>