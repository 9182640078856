var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ProductRevision" },
    [
      _c("img", {
        staticClass: "product-star",
        attrs: {
          src: _vm.starImgProductRevisionMap[this.rev.ProductRevisionId],
          alt: "star",
          role: "button",
          tabindex: "0",
        },
        on: {
          click: function ($event) {
            return _vm.toggleStarProductRevision(_vm.rev)
          },
          keypress: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter") &&
              _vm._k($event.keyCode, "space", 32, $event.key, [" ", "Spacebar"])
            )
              return null
            $event.preventDefault()
            return _vm.toggleStarProductRevision(_vm.rev)
          },
        },
      }),
      _vm._v(" "),
      _c(
        "router-link",
        {
          staticClass: "ProductRevisionClickable",
          attrs: { to: _vm.GetRouteToProductRevision(_vm.rev) },
        },
        [
          _c("img", {
            staticClass: "ProductImage",
            attrs: { src: _vm.appPath + "ProductImage/" + _vm.rev.ImageId },
          }),
          _vm._v(" "),
          _c("span", {
            staticClass: "ProductRevisionTitle",
            domProps: { innerHTML: _vm._s(_vm.myTitle) },
          }),
          _vm._v(" "),
          _c("CmsLifecycleStateBadge", {
            attrs: { text: _vm.rev.LifecycleState, compact: true },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }