var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "releaseDetailsRoot",
      attrs: { role: "dialog", "aria-labelledby": "releaseDetailsTitle" },
    },
    [
      _c("div", { staticClass: "titleBar" }, [
        _c(
          "div",
          {
            staticClass: "title",
            attrs: { id: "releaseDetailsTitle", role: "alert" },
          },
          [
            _vm._v(
              "Release Manager: " +
                _vm._s(_vm.release.Name) +
                " @ " +
                _vm._s(_vm.environment.Name)
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "dialogBody" },
        [
          _c("div", { staticClass: "primaryHeading" }, [
            _vm._v("Release Name"),
          ]),
          _vm._v(" "),
          _c("EditableText", {
            attrs: {
              value: _vm.release.Name,
              disabled:
                _vm.changingReleaseName ||
                _vm.installingRelease ||
                _vm.release.IsArchiving,
            },
            on: { input: _vm.onReleaseNameTextInput },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "primaryHeading" }, [_vm._v("Build Date")]),
          _vm._v(" "),
          _c("div", [
            _vm._v(_vm._s(_vm.timestampToDateString(_vm.release.BuildDate))),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "primaryHeading" }, [_vm._v("Description")]),
          _vm._v(" "),
          _c("EditableText", {
            attrs: {
              multiline: true,
              value: _vm.release.Manifest.Description,
              disabled: _vm.changingDescription || _vm.release.IsArchiving,
            },
            on: { input: _vm.onDescriptionTextInput },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "primaryHeading" },
            [
              _vm._v("AppSettings "),
              _c("SvgButton", {
                staticClass: "editButton",
                attrs: { sprite: "edit_note", contained: true, round: true },
                on: { click: _vm.editAppSettings },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "primaryHeading" }, [
            _vm._v("Installation"),
          ]),
          _vm._v(" "),
          _vm._l(_vm.environment.Apps, function (app, index) {
            return _c(
              "div",
              { key: "installation_" + index, staticClass: "installation" },
              [
                app.ReleaseName === _vm.release.Name
                  ? _c("div", { staticClass: "installedApp" }, [
                      _vm._v(
                        "\n\t\t\t\t✨ Installed in " +
                          _vm._s(app.AppName) +
                          " app.\n\t\t\t\t"
                      ),
                      app.Links.length
                        ? _c(
                            "a",
                            {
                              attrs: { href: app.Links[0], target: "_blank" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.openAppLinks(app)
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  app.Links.length > 1
                                    ? "open " + app.Links.length + " links"
                                    : app.Links[0]
                                )
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("input", {
                        staticClass: "integrationTestBtn",
                        attrs: {
                          type: "button",
                          disabled: app.isIntegrationTesting,
                          value: "Run Tests",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.runIntegrationTests(app)
                          },
                        },
                      }),
                      _vm._v(" "),
                      app.integrationTestResults
                        ? _c(
                            "span",
                            { staticClass: "integrationTestResults" },
                            [_vm._v(_vm._s(app.integrationTestResults))]
                          )
                        : _vm._e(),
                    ])
                  : [
                      _c("slide-button", {
                        key: "slideBtn_" + index + "_" + _vm.slideBtnCounter,
                        ref: "installBtns",
                        refInFor: true,
                        attrs: {
                          "auto-width": false,
                          circle: true,
                          disabled: false,
                          noanimate: true,
                          width: 300,
                          height: 60,
                          text: "slide to install",
                          "success-text": "installing",
                          requireReleaseToComplete: true,
                        },
                        on: {
                          completed: function ($event) {
                            return _vm.onInstallCommanded(app)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "labelAfter" }, [
                        _vm._v(
                          "\n\t\t\t\t\tin " +
                            _vm._s(app.AppName) +
                            " app.\n\t\t\t\t"
                        ),
                      ]),
                    ],
              ],
              2
            )
          }),
          _vm._v(" "),
          [
            _c("div", { staticClass: "primaryHeading" }, [_vm._v("Archival")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Archiving a release compresses it and removes it from the environment display."
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _c("input", {
                attrs: {
                  type: "button",
                  value: "Archive " + _vm.release.Name + " now",
                  disabled:
                    _vm.release.Installs && _vm.release.Installs.length > 0,
                },
                on: { click: _vm.archiveRelease },
              }),
            ]),
          ],
          _vm._v(" "),
          _vm.offerToOverrideLockouts
            ? [
                _c("div", { staticClass: "primaryHeading" }, [
                  _vm._v("Critical Operation Lockout"),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "The last release installation failed the Critical Operation Lockout test.  You may override the lockout if desired by checking the box below and attempting the installation again."
                  ),
                ]),
                _vm._v(" "),
                _c("p", [
                  _c("label", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.overrideLockouts,
                          expression: "overrideLockouts",
                        },
                      ],
                      attrs: { type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(_vm.overrideLockouts)
                          ? _vm._i(_vm.overrideLockouts, null) > -1
                          : _vm.overrideLockouts,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.overrideLockouts,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                (_vm.overrideLockouts = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.overrideLockouts = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.overrideLockouts = $$c
                          }
                        },
                      },
                    }),
                    _vm._v(
                      " Allow release installation to proceed regardless of ongoing critical operations."
                    ),
                  ]),
                ]),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "buttons" }, [
        _c(
          "div",
          {
            ref: "closeBtn",
            staticClass: "dialogButton",
            attrs: { tabindex: "0" },
            on: {
              click: _vm.DefaultClose,
              keydown: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "space", 32, $event.key, [
                    " ",
                    "Spacebar",
                  ]) &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                $event.preventDefault()
                return _vm.DefaultClose.apply(null, arguments)
              },
            },
          },
          [_vm._v("\n\t\t\tClose\n\t\t")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }