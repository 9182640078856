<template>
	<div class="CustomProgress" :style="{ borderColor: borderColor }" :title="title">
		<div class="CustomProgressTextContainer"><div class="CustomProgressText">{{text}}</div></div>
		<div class="CustomProgressValue" :style="{ width: value + '%', backgroundColor: color }">
			<div class="CustomProgressText" :style="{ width: (10000 / value) + '%' }">{{text}}</div>
		</div>
	</div>
</template>

<script>
	export default {
		props:
		{
			value: {
				type: Number,
				default: 0
			},
			color: {
				type: String,
				default: "#008800"
			},
			borderColor: {
				type: String,
				default: "#6068AB"
			},
			title: {
				type: String,
				default: ""
			},
			text: {
				type: String,
				default: ""
			}
		}
	}
</script>

<style scoped>
	.CustomProgress
	{
		display: inline-block;
		position: relative;
		width: 100px;
		block-size: 1em;
		inline-size: 10em;
		vertical-align: -0.2em;
		border: 1px solid #999999;
		box-sizing: border-box;
		overflow: hidden;
	}

	.CustomProgressTextContainer,
	.CustomProgressValue
	{
		position: absolute;
		top: 0px;
		left: 0px;
		bottom: 0px;
		width: 100%;
		overflow: hidden;
		text-align: center;
		font-size: 1em;
		text-overflow: clip;
		white-space: nowrap;
	}

	.CustomProgressValue
	{
		pointer-events: none;
		user-select: none;
		color: #000000;
	}

	.CustomProgressTextContainer
	{
		color: #FFFFFF;
		user-select: all;
	}

	.CustomProgressText
	{
		position: absolute;
		font-weight: bold;
		top: -0.2em;
		width: 100%;
	}
</style>