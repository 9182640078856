var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "LifecycleStateDialog",
      attrs: { role: "alertdialog", "aria-labelledby": "lifecycleStateTitle" },
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "dialogBody" },
        [
          _c("div", {
            staticClass: "revisionName",
            domProps: {
              innerHTML: _vm._s(_vm.productRevision.RevisionNameHtml),
            },
          }),
          _vm._v(" "),
          _c("CmsLifecycleStateBadge", {
            attrs: { text: _vm.productRevision.LifecycleState },
          }),
          _vm._v(" "),
          _vm.productRevision.LifecycleState === "Upcoming"
            ? [
                !_vm.productRevision.ArchiveFile
                  ? _c("div", { staticClass: "Message" }, [
                      _vm._v(
                        "\n\t\t\t\tStaging will be available after an Fx/Media Archive is assigned.\n\t\t\t"
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.productRevision.ArchiveFile
                  ? _c("div", { staticClass: "Message" }, [
                      _vm._v("\n\t\t\t\tSee the "),
                      _c("b", [_vm._v("Fx/Media")]),
                      _vm._v(
                        " box outside this dialog for Archival options.\n\t\t\t"
                      ),
                    ])
                  : _c(
                      "div",
                      { staticClass: "SliderContainer" },
                      [
                        _c("slide-button", {
                          key: "sb1",
                          ref: "slideBtn1",
                          attrs: {
                            "auto-width": false,
                            circle: true,
                            disabled: false,
                            noanimate: false,
                            width: 256,
                            height: 44,
                            requireReleaseToComplete: true,
                            text: "stage",
                            "success-text": "staging…",
                          },
                          on: {
                            completed: function ($event) {
                              return _vm.onStageCommanded()
                            },
                          },
                        }),
                      ],
                      1
                    ),
              ]
            : _vm.productRevision.LifecycleState === "Staging"
            ? [
                _vm.loadingAgentStatus
                  ? _c(
                      "div",
                      { staticClass: "Message" },
                      [_c("ScaleLoader"), _vm._v(" Loading Agent Status…")],
                      1
                    )
                  : _vm.errorLoadingAgentStatus
                  ? _c(
                      "div",
                      { staticClass: "Message" },
                      [
                        _c("vsvg", {
                          staticClass: "warningIcon",
                          attrs: { sprite: "warning" },
                        }),
                        _vm._v(
                          " " + _vm._s(_vm.errorLoadingAgentStatus) + "\n\t\t\t"
                        ),
                      ],
                      1
                    )
                  : [
                      _vm.agentNamesNotSynced && _vm.agentNamesNotSynced.length
                        ? _c(
                            "div",
                            {
                              staticClass: "Message",
                              attrs: {
                                title:
                                  "These agents from the 'Online' environment are not fully staged yet and will be unable to immediately install the product revision.",
                              },
                            },
                            [
                              _c("vsvg", {
                                staticClass: "warningIcon",
                                attrs: { sprite: "warning" },
                              }),
                              _vm._v("\n\t\t\t\t\tNot Fully Staged On:"),
                              _c("br"),
                              _c("b", [
                                _vm._v(
                                  _vm._s(_vm.agentNamesNotSynced.join(", "))
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.agentNamesNotConnected &&
                      _vm.agentNamesNotConnected.length
                        ? _c(
                            "div",
                            {
                              staticClass: "Message",
                              attrs: {
                                title:
                                  "These agents from the 'Online' environment are not connected and will be unable to immediately install the product revision.",
                              },
                            },
                            [
                              _c("vsvg", {
                                staticClass: "warningIcon",
                                attrs: { sprite: "warning" },
                              }),
                              _vm._v("\n\t\t\t\t\tAgents Not Connected:"),
                              _c("br"),
                              _c("b", [
                                _vm._v(
                                  _vm._s(_vm.agentNamesNotConnected.join(", "))
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "SliderContainer" },
                        [
                          _c("slide-button", {
                            key: "sb2",
                            ref: "slideBtn2",
                            attrs: {
                              "auto-width": false,
                              circle: true,
                              disabled: false,
                              noanimate: false,
                              width: 256,
                              height: 44,
                              requireReleaseToComplete: true,
                              text: "live",
                              "success-text": "going live…",
                            },
                            on: {
                              completed: function ($event) {
                                return _vm.onLiveCommanded()
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "SliderContainer" },
                        [
                          _c("slide-button", {
                            key: "sb3",
                            ref: "slideBtn3",
                            attrs: {
                              "auto-width": false,
                              circle: true,
                              disabled: false,
                              noanimate: false,
                              width: 256,
                              height: 44,
                              requireReleaseToComplete: true,
                              text: "deactivate",
                              "success-text": "deactivating…",
                            },
                            on: {
                              completed: function ($event) {
                                return _vm.onInactiveCommanded()
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
              ]
            : _vm.productRevision.LifecycleState === "Live"
            ? [
                _c(
                  "div",
                  { staticClass: "SliderContainer" },
                  [
                    _c("slide-button", {
                      key: "sb4",
                      ref: "slideBtn4",
                      attrs: {
                        "auto-width": false,
                        circle: true,
                        disabled: false,
                        noanimate: false,
                        width: 256,
                        height: 44,
                        requireReleaseToComplete: true,
                        text: "deactivate",
                        "success-text": "deactivating…",
                      },
                      on: {
                        completed: function ($event) {
                          return _vm.onInactiveCommanded()
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]
            : _vm.productRevision.LifecycleState === "Inactive"
            ? [
                _c(
                  "div",
                  { staticClass: "SliderContainer" },
                  [
                    _c("slide-button", {
                      key: "sb5",
                      ref: "slideBtn5",
                      attrs: {
                        "auto-width": false,
                        circle: true,
                        disabled: false,
                        noanimate: false,
                        width: 256,
                        height: 44,
                        requireReleaseToComplete: true,
                        text: "stage",
                        "success-text": "staging…",
                      },
                      on: {
                        completed: function ($event) {
                          return _vm.onStageCommanded()
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "titleBar" }, [
      _c(
        "div",
        {
          staticClass: "title",
          attrs: { id: "lifecycleStateTitle", role: "alert" },
        },
        [_vm._v("Lifecycle State")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }