var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "CustomProgress",
      style: { borderColor: _vm.borderColor },
      attrs: { title: _vm.title },
    },
    [
      _c("div", { staticClass: "CustomProgressTextContainer" }, [
        _c("div", { staticClass: "CustomProgressText" }, [
          _vm._v(_vm._s(_vm.text)),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "CustomProgressValue",
          style: { width: _vm.value + "%", backgroundColor: _vm.color },
        },
        [
          _c(
            "div",
            {
              staticClass: "CustomProgressText",
              style: { width: 10000 / _vm.value + "%" },
            },
            [_vm._v(_vm._s(_vm.text))]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }