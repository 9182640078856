import ExecAPI from 'appRoot/api/api';

/**
 * Returns live details about all configured environments.
 */
export function GetEnvironmentInfo()
{
	return ExecAPI("ReleaseData/GetEnvironmentInfo");
}
/**
 * Returns details about recent builds.
 */
export function GetRecentBuilds()
{
	return ExecAPI("ReleaseData/GetRecentBuilds");
}
/**
 * Begins creating a new release.
 */
export function CreateRelease(environmentName, name, buildId, appSettingsFrom, description)
{
	return ExecAPI("ReleaseData/CreateRelease", { environmentName, name, buildId, appSettingsFrom, description });
}
/**
 * Renames a release. Fails if the release is currently installed in any web applications.
 */
export function ChangeReleaseName(environmentName, releaseName, newName)
{
	return ExecAPI("ReleaseData/ChangeReleaseName", { environmentName, releaseName, newName });
}
/**
 * Changes the description of a release.
 */
export function ChangeReleaseDescription(environmentName, releaseName, description)
{
	return ExecAPI("ReleaseData/ChangeReleaseDescription", { environmentName, releaseName, description });
}
/**
 * Changes the AppSettings installed in a release.
 */
export function ChangeReleaseAppSettings(environmentName, releaseName, appSettings)
{
	return ExecAPI("ReleaseData/ChangeReleaseAppSettings", { environmentName, releaseName, appSettings });
}
/**
 * Installs the release in one of the predefined web apps.
 */
export function InstallRelease(environmentName, releaseName, appName, overrideLockouts)
{
	return ExecAPI("ReleaseData/InstallRelease", { environmentName, releaseName, appName, overrideLockouts });
}
/**
 * Moves the release into a compressed archive where the release will no longer be accessible to this service unless manually extracted by an administrator.
 */
export function ArchiveRelease(environmentName, releaseName)
{
	return ExecAPI("ReleaseData/ArchiveRelease", { environmentName, releaseName });
}
/**
 * Recycles the app pools for the given app and returns the names of the app pools that were recycled.
 * @param appPoolName {String} If not null or empty, this is the name of the app pool which should be recycled.
 */
export function RecycleAppPools(environmentName, appName, appPoolName)
{
	return ExecAPI("ReleaseData/RecycleAppPools", { environmentName, appName, appPoolName });
}
/**
 * Runs integration tests for the given app and returns a basic success response if tests succeed, otherwise returns an error response containing the error result(s) as the error string.
 */
export function RunIntegrationTests(environmentName, appName)
{
	return ExecAPI("ReleaseData/RunIntegrationTests", { environmentName, appName });
}