<template>
	<div :class="{ healthProblems: true, hasHealthProblems: !!jobsAlerting.length }">
		<template v-if="jobsAlerting.length > 0">
			<p v-for="job in jobsAlerting" class="alertMessage">{{job.AlertState}}</p>
		</template>
		<template v-else>
			<p>All monitors report OK</p>
		</template>
	</div>
</template>

<script>
	export default {
		props:
		{
			jobsAlerting: {
				type: Array,
				required: true
			}
		},
		data()
		{
			return {
			};
		},
		computed:
		{
		},
		methods:
		{
		},
		watch:
		{
		}
	}
</script>

<style scoped>
	.healthProblems
	{
		border: 3px solid #000000;
		padding: 5px 3.3vw;
	}

		.healthProblems.hasHealthProblems
		{
			border-color: #CC0000;
			color: #990000;
			font-weight: bold;
		}

	.alertMessage
	{
		white-space: pre-line;
	}

	@media (min-width: 600px)
	{
		.healthProblems
		{
			border: 3px solid #000000;
			padding: 5px 20px;
		}
	}
</style>