var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: {
        cmsConsole: true,
        connected: _vm.cmsConsoleConnected,
        disconnected: !_vm.cmsConsoleConnected,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "cmsConsoleDragHandle",
          on: {
            mousedown: function ($event) {
              $event.preventDefault()
              return _vm.onDragStart.apply(null, arguments)
            },
            mouseup: _vm.onDragEnd,
            dblclick: function ($event) {
              $event.preventDefault()
              return _vm.onDoubleClick.apply(null, arguments)
            },
          },
        },
        [
          _c("vsvg", {
            staticClass: "dragHandle",
            attrs: { sprite: "drag_handle" },
          }),
          _vm._v(" "),
          _c("span", [
            _c(
              "a",
              {
                class: _vm.dynTabClasses["All"],
                attrs: { role: "button", tabindex: "0" },
                on: {
                  click: function ($event) {
                    return _vm.onTabClick("All")
                  },
                  keypress: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "space", 32, $event.key, [
                        " ",
                        "Spacebar",
                      ]) &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    $event.preventDefault()
                    return _vm.onTabClick("All")
                  },
                },
              },
              [_vm._v("All")]
            ),
            _vm._v(" /\n\t\t\t"),
            _c(
              "a",
              {
                class: _vm.dynTabClasses["Production"],
                attrs: { role: "button", tabindex: "0" },
                on: {
                  click: function ($event) {
                    return _vm.onTabClick("Production")
                  },
                  keypress: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "space", 32, $event.key, [
                        " ",
                        "Spacebar",
                      ]) &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    $event.preventDefault()
                    return _vm.onTabClick("Production")
                  },
                },
              },
              [_vm._v("Production")]
            ),
            _vm._v(" /\n\t\t\t"),
            _c(
              "a",
              {
                class: _vm.dynTabClasses["Deployment"],
                attrs: { role: "button", tabindex: "0" },
                on: {
                  click: function ($event) {
                    return _vm.onTabClick("Deployment")
                  },
                  keypress: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "space", 32, $event.key, [
                        " ",
                        "Spacebar",
                      ]) &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    $event.preventDefault()
                    return _vm.onTabClick("Deployment")
                  },
                },
              },
              [_vm._v("Deployment")]
            ),
          ]),
          _vm._v(" "),
          _c("vsvg", {
            staticClass: "connectionIcon",
            attrs: {
              sprite: "cable",
              title:
                "Green if the console is connected to the server. Red if disconnected.",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "cmsConsoleBody", style: _vm.consoleBodyStyle },
        [
          _vm.tabName === "All"
            ? [
                _c(
                  "div",
                  { staticClass: "consoleScrollable leftSide" },
                  [_c("CmsConsoleProduction")],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "consoleScrollable rightSide" },
                  [_c("CmsConsoleDeployment")],
                  1
                ),
              ]
            : _vm.tabName === "Production"
            ? [
                _c(
                  "div",
                  { staticClass: "consoleScrollable solo" },
                  [_c("CmsConsoleProduction")],
                  1
                ),
              ]
            : _vm.tabName === "Deployment"
            ? [
                _c(
                  "div",
                  { staticClass: "consoleScrollable solo" },
                  [_c("CmsConsoleDeployment")],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }