<template>
	<div :class="{ pageRoot: true }">
		<div v-if="error" class="error">{{error}}</div>
		<div v-else-if="!this.myAttributes" class="error">Invalid URL</div>
		<div v-else-if="loading" class="loading"><ScaleLoader /> Loading…</div>
		<template v-else>
			<div id="editorMenuBar">
				<span class="title">{{myAttributes.displayName}} Editor</span>
				<button @click="OpenBackupList" class="openBackupListBtn">Load Backup</button>
				<button class="SaveButton" :class="{ disabled: !hasChanges }" @click="SaveChanges">Save</button>
			</div>
			<div class="editorSection">
				<AceReactiveEditor v-model="fileContent" name="genericFileEditor" :mode="myAttributes.aceMode" :userSizable="false" :style="editorHeightStyle" />
				<div v-if="showHtmlPreviewPane" class="htmlPreview" v-html="fileContent" :style="editorHeightStyle"></div>
			</div>
		</template>
	</div>
</template>
<script>
	import EventBus from 'appRoot/scripts/EventBus';
	import { ProgressDialog, ModalMessageDialog, TextFileLoadBackupDialog } from 'appRoot/scripts/ModalDialog';
	import AceReactiveEditor from 'appRoot/vues/common/controls/AceReactiveEditor.vue';
	import { GetLocalTextFile, SetLocalTextFile } from 'appRoot/api/FileEditor';
	import AbandonChangesMixin from 'appRoot/scripts/AbandonChangesMixin';

	export default {
		components: { AceReactiveEditor },
		mixins: [AbandonChangesMixin],
		props:
		{
			name: {
				type: String,
				default: ""
			}
		},
		data()
		{
			return {
				error: null,
				loading: false,
				fileContent: null,
				originalFileContent: null,
				backupDates: [],
			};
		},
		created()
		{
			this.loadFileContent();
		},
		computed:
		{
			myAttributes()
			{
				if (this.name.toLowerCase() === "releasenotes")
					return {
						displayName: "Release Notes",
						aceMode: "ace/mode/html"
					};
				else if (this.name.toLowerCase() === "titleupdates")
					return {
						displayName: "Title Updates",
						aceMode: "ace/mode/html"
					};
				else if (this.name.toLowerCase() === "adminipwhitelist")
					return {
						displayName: "Admin IP Whitelist",
						aceMode: "ace/mode/json"
					};
				else
					return null;
			},
			showHtmlPreviewPane()
			{
				return this.myAttributes.aceMode === "ace/mode/html";
			},
			editorHeightPx()
			{
				return EventBus.windowHeight - EventBus.verticalConsumers;
			},
			editorHeightStyle()
			{
				return {
					height: this.editorHeightPx + "px"
				};
			},
			hasChanges()
			{
				return this.fileContent !== this.originalFileContent;
			}
		},
		updated()
		{
			EventBus.OnResize();
		},
		methods:
		{
			async loadFileContent()
			{
				if (!this.myAttributes)
					return;
				this.loading = true;
				try
				{
					let data = await GetLocalTextFile(this.name);
					if (data.success)
						this.handleFileContentResult(data);
					else
						this.error = data.error;
				}
				catch (ex)
				{
					this.error = ex.message;
				}
				finally
				{
					this.loading = false;
				}
			},
			async SaveChanges()
			{
				let progressDialog = await ProgressDialog("Saving…");
				try
				{
					let data = await SetLocalTextFile(this.name, this.fileContent)
					this.handleFileContentResult(data);
				}
				catch (ex)
				{
					ModalMessageDialog(ex.stack, "Error");
				}
				finally
				{
					progressDialog.close();
				}
			},
			async OpenBackupList()
			{
				let result = await TextFileLoadBackupDialog(this.backupDates);
				if (result && typeof result.date !== "undefined")
				{
					let progressDialog = await ProgressDialog("Loading backup…");
					try
					{
						let data = await GetLocalTextFile(this.name, result.date)
						if (data.success)
						{
							if (this.fileContent !== data.fileContent)
								this.fileContent = data.fileContent;
							this.backupDates = data.backupDates;
						}
						else
							ModalMessageDialog(data.error, "Error");
					}
					catch (ex)
					{
						ModalMessageDialog(ex.stack, "Error");
					}
					finally
					{
						progressDialog.close();
					}
				}
			},
			handleFileContentResult(data)
			{
				if (data.success)
				{
					this.originalFileContent = data.fileContent;
					if (this.fileContent !== data.fileContent)
						this.fileContent = data.fileContent;
					this.backupDates = data.backupDates;
				}
				else
					ModalMessageDialog(data.error, "Error");
			}
		},
		watch:
		{
		}
	}
</script>
<style scoped>
	.pageRoot
	{
		background-color: #FFFFFF;
		display: flex;
		flex-direction: column;
		overflow: hidden;
	}

	.loading
	{
		padding: 8px;
		margin-top: 80px;
		text-align: center;
	}

	.error
	{
		padding: 8px;
		color: #FF0000;
		font-weight: bold;
		white-space: pre-wrap;
	}

	#editorMenuBar
	{
		box-sizing: border-box;
		padding: 4px 8px;
		font-size: 18px;
		background-color: #FFFFFF;
		border-bottom: 1px solid #000000;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
	}

	.title
	{
		flex: 1 1 auto;
	}

	.openBackupListBtn
	{
		margin: 0px 8px;
	}

	.SaveButton
	{
		display: inline-flex;
		align-items: center;
		border: 1px solid #888888;
		border-radius: 5px;
		height: 21px;
		box-sizing: border-box;
		padding: 0px 1em;
		font-size: 1.1em;
		cursor: pointer;
		background-color: #DDFFDD;
		box-shadow: rgba(0,0,0,0.4) 1px 1px 1px 1px;
		animation: SaveBackground 1s steps(2, end) infinite;
	}

	@keyframes SaveBackground
	{
		from
		{
			background-color: #DDFFDD;
		}

		to
		{
			background-color: #88FF88;
		}
	}

	.SaveButton:hover
	{
		background-color: #44FF44;
		animation: none;
	}

	.SaveButton.disabled
	{
		background-color: #DDDDDD !important;
		color: #999999;
		animation: none !important;
		cursor: inherit;
	}

	.editorSection
	{
		display: flex;
	}

		.editorSection > *
		{
			flex: 1 1;
			overflow: auto;
		}
</style>
