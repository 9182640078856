<template>
	<div :id="editorId" class="aceEditor" :class="{ userSizable: userSizable }">
	</div>
</template>

<script>
	import ace from 'ace-builds/src-noconflict/ace';
	import 'ace-builds/src-noconflict/theme-chrome';
	import 'ace-builds/src-noconflict/keybinding-vscode';
	import 'ace-builds/src-noconflict/mode-css';
	import 'ace-builds/src-noconflict/mode-javascript';
	import 'ace-builds/src-noconflict/mode-json';
	import 'ace-builds/src-noconflict/mode-html';
	import 'ace-builds/src-noconflict/mode-xml';
	import 'ace-builds/src-noconflict/ext-searchbox';
	// Define web workers to load in the CopyWebpackPlugin constructor in webpack.config.js.  See the list in C:\TFS2010\Utilities\TdsAppService\TdsAppService\node_modules\ace-builds\src-noconflict
	import EventBus from 'appRoot/scripts/EventBus';
	import { throttle, Clamp } from 'appRoot/scripts/Util';

	export default {
		props:
		{
			value: {
				type: String,
				required: true
			},
			name: {
				type: String,
				required: true
			},
			mode: {
				type: String,
				default: ""
			},
			disabled: {
				type: Boolean,
				default: false
			},
			userSizable: {
				type: Boolean,
				default: true
			},
		},
		data()
		{
			return {
				editorId: "aceEditor" + (++EventBus.aceEditorCounter),
				editor: null,
				lastContent: "",
				resizeObserver: null,
				onResizeThrottled: null,
			};
		},
		created()
		{
			this.onResizeThrottled = throttle(() => this.onResize(), 100);
		},
		mounted()
		{
			ace.config.set('basePath', '/dist/ace');
			this.editor = ace.edit(this.editorId);

			this.editor.setTheme("ace/theme/chrome");
			this.editor.setKeyboardHandler("ace/keyboard/vscode");
			if (this.mode)
				this.editor.session.setMode(this.mode);
			this.editor.setOption("wrapBehavioursEnabled", true); // Maybe not necessary
			this.editor.setOption("useWorker", false); // Prevents script error due to not knowing how to load the web worker scripts into the www files (dist folder).
			this.editor.session.setOption("wrap", true);
			this.editor.on('change', this.onChange);

			this.editor.setReadOnly(this.disabled);

			this.lastContent = this.value;
			this.editor.setValue(this.value, 1);
			this.editor.getSession().setUndoManager(new ace.UndoManager()); // Clear undo stack.

			let el = document.getElementById(this.editorId);
			if (localStorage && this.userSizable)
			{
				let size = parseInt(localStorage[this.localStorageKey]);
				if (size && !isNaN(size))
				{
					//console.log("Loading AceReactiveEditor size:", size, this.localStorageKey);
					el.style.height = Clamp(size, 10, 2000) + "px";
				}
			}
			this.resizeObserver = new ResizeObserver(this.onResizeThrottled);
			this.resizeObserver.observe(el);
		},
		beforeDestroy()
		{
			if (this.editor)
			{
				this.editor.session.off('change', this.onChange);
			}
			if (this.resizeObserver)
			{
				this.resizeObserver.disconnect();
				this.resizeObserver = null;
			}
		},
		computed:
		{
			localStorageKey()
			{
				return "cms_acereact_size_" + this.name.replace(/[ \/\(\)\[\]:;'"<>,.\\]/g, '_');
			}
		},
		methods:
		{
			onChange(e)
			{
				let content = this.editor.getValue();
				this.lastContent = content;
				this.$emit('input', content);
			},
			onResize()
			{
				if (!this.resizeObserver)
				{
					console.error("AceReactiveEditor.onResize called without ResizeObserver being defined.", this);
					return;
				}
				let el = document.getElementById(this.editorId);
				if (!el)
				{
					this.textareaResizeObserver.disconnect();
					this.textareaResizeObserver = null;
					return;
				}
				this.editor.resize(true);
				//console.log("AceReactiveEditor resized:", el.offsetHeight, this.localStorageKey);
				if (localStorage && this.userSizable)
					localStorage[this.localStorageKey] = el.offsetHeight;
			},
		},
		watch:
		{
			value()
			{
				//console.log("AceCodeEditor > watch > Vue value", { v: this.value });
				if (this.editor && this.value !== this.lastContent)
				{
					this.editor.setValue(this.value, 1);
				}
			},
			disabled()
			{
				if (this.editor)
					this.editor.setReadOnly(this.disabled);
			}
		}
	}
</script>

<style scoped>
	.aceEditor
	{
		border: 1px solid #767676;
		border-radius: 3px;
		box-sizing: border-box;
		overflow: hidden;
		z-index: 0; /* supposedly this creates a new stacking context to prevent the ace editor appearing above other sticky things */
	}

	.userSizable
	{
		height: 200px;
		resize: vertical;
	}
</style>