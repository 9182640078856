var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "slider",
      staticClass: "slideunlock",
      class: {
        "is-disabled": _vm.disabled,
        "is-noanimate": _vm.noanimate,
        "is-circle": _vm.circle,
        "is-complete": _vm.IsComplete,
      },
      style: _vm.slideUnlockStyle,
      on: {
        mousemove: _vm.slideMoving,
        mouseup: _vm.slideFinish,
        touchmove: _vm.slideMoving,
        touchend: _vm.slideFinish,
      },
    },
    [
      _c("div", {
        staticClass: "slideunlock-progressbar",
        style: _vm.progressBarStyle,
      }),
      _vm._v(" "),
      _c("div", { staticClass: "slideunlock-text", style: _vm.Text }, [
        _vm._v("\n\t\t" + _vm._s(_vm.message) + "\n\t"),
      ]),
      _vm._v(" "),
      _c("div", {
        ref: "handler",
        staticClass: "slideunlock-handler",
        style: _vm.handlerStyle,
        on: { mousedown: _vm.slideStart, touchstart: _vm.slideStart },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }