<template>
	<div :class="{ statusLine: true, isAlerting: isAlerting, mobileLayout: mobileLayout }" :title="jobStatus.AlertState" @mouseenter="onMouseIn" @mouseleave="onMouseOut">

		<div class="name">{{jobStatus.Name}}</div>
		<div class="details">
			<StatusGraph class="inlineGraph" :jobStatus="[jobStatus]"></StatusGraph>
			<div class="status">{{statusText}}</div>
		</div>
	</div>
</template>

<script>
	import EventBus from 'appRoot/scripts/EventBus';
	import StatusGraph from 'appRoot/vues/client/controls/StatusGraph.vue';

	export default {
		components: { StatusGraph },
		props:
		{
			jobStatus: {
				type: Object,
				required: true
			}
		},
		data()
		{
			return {
			};
		},
		computed:
		{
			isAlerting()
			{
				return this.jobStatus.AlertState !== null;
			},
			statusText()
			{
				if (this.isAlerting)
					return "ALERTING";
				else
					return "OK";
			},
			mobileLayout()
			{
				return EventBus.mobileLayout;
			}
		},
		methods:
		{
			onMouseIn()
			{
				this.$emit("hoverin", this.jobStatus);
			},
			onMouseOut()
			{
				this.$emit("hoverout", this.jobStatus);
			}
		},
		watch:
		{
		}
	}
</script>

<style scoped>
	.statusLine
	{
		border-bottom: 2px solid #000000;
		padding: 15px 5px;
	}

		.statusLine:last-child
		{
			border-bottom: none;
		}

		.statusLine .status
		{
			padding-left: 10px;
			color: #008800;
		}

		.statusLine.isAlerting .status
		{
			color: #FF0000;
		}

		.statusLine.isAlerting
		{
			font-weight: bold;
			color: #990000;
		}

		.statusLine:hover
		{
			background-color: rgba(0,0,0,0.125);
		}

		.statusLine.mobileLayout
		{
			flex-direction: column;
			align-items: flex-start;
		}

	.name
	{
		flex: 1 1 auto;
		word-break: break-word;
	}

	.inlineGraph
	{
		flex: 1 1 auto;
		height: 1em;
	}

	.details
	{
		display: flex;
		align-items: center;
		margin-top: 4px;
		justify-content: space-between;
	}

		.details .inlineGraph
		{
			flex: 1 1 auto;
			max-width: 250px;
		}

	@media (min-width: 600px)
	{
		.statusLine
		{
			border-width: 1px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 5px;
		}

		.details
		{
			width: auto;
			margin-top: 0px;
		}


		.inlineGraph
		{
			margin: 0px 5px;
			width: 67px;
			height: 1em;
		}
	}
</style>