var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "CmsProductHistorySelector" }, [
    _c("div", { staticClass: "title" }, [
      _vm._v(_vm._s(_vm.type) + " History"),
    ]),
    _vm._v(" "),
    _vm.error
      ? _c("div", { staticClass: "error" }, [_vm._v(_vm._s(_vm.error))])
      : _vm.loading
      ? _c("div", { staticClass: "loading" }, [_vm._v("Loading…")])
      : !_vm.history || !_vm.history.length
      ? _c("div", { staticClass: "history" }, [_vm._v("\n\t\tNo History\n\t")])
      : _c("div", { staticClass: "history" }, [
          _c("table", [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "tbody",
              [
                _vm._l(_vm.history, function (item, index) {
                  return [
                    _c(
                      "tr",
                      { key: "history_" + index, staticClass: "historyItem" },
                      [
                        _c("td", [
                          _vm._v(
                            _vm._s(_vm.GetDateStr(new Date(item.Timestamp)))
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(item.UserName))]),
                        _vm._v(" "),
                        _c("td", { staticClass: "DiffCell" }, [
                          _c(
                            "button",
                            {
                              staticClass: "DiffExpandBtn",
                              attrs: { title: "Expand" },
                              on: {
                                click: function ($event) {
                                  return _vm.ExpandDiff(item, _vm.diffs[index])
                                },
                                keypress: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "space",
                                      32,
                                      $event.key,
                                      [" ", "Spacebar"]
                                    ) &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  $event.preventDefault()
                                  return _vm.ExpandDiff(item, _vm.diffs[index])
                                },
                              },
                            },
                            [_c("vsvg", { attrs: { sprite: "open_in_new" } })],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", {
                            staticClass: "DiffBlock",
                            domProps: { innerHTML: _vm._s(_vm.diffs[index]) },
                          }),
                        ]),
                      ]
                    ),
                  ]
                }),
              ],
              2
            ),
          ]),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Date")]),
        _vm._v(" "),
        _c("th", [_vm._v("User")]),
        _vm._v(" "),
        _c("th", [_vm._v("Diff")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }