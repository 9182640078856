var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "CmsConsoleProduction" }, [
    _c("div", { staticClass: "sectionHeading" }, [
      _vm._v("Transfer From Production"),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "archiveTransfer" },
      [
        _vm.tamStatus && _vm.tamStatus.titlesReady.length
          ? _vm._l(_vm.tamStatus.titlesReady, function (item) {
              return item.Downloader
                ? _c(
                    "div",
                    {
                      key: "r" + item.ProductRevisionId,
                      staticClass: "archiveTransfer titleRow",
                      attrs: {
                        title: item.Name + " rev " + item.ProductRevisionId,
                      },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(item.FileName) +
                          " - " +
                          _vm._s(item.Size) +
                          " @ " +
                          _vm._s(item.Downloader.BitsPerSecondStr) +
                          " "
                      ),
                      _c("progress", {
                        attrs: {
                          max: "100",
                          title:
                            item.Downloader.Progress +
                            "% " +
                            item.Name +
                            " rev " +
                            item.ProductRevisionId,
                        },
                        domProps: { value: item.Downloader.Progress },
                      }),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v("ETA: " + _vm._s(item.Downloader.RemainingTime)),
                      ]),
                    ]
                  )
                : _vm._e()
            })
          : _vm._e(),
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "productionServ" },
      [
        _c("div", { staticClass: "sectionHeading" }, [
          _vm._v("Production Server"),
        ]),
        _vm._v(" "),
        _vm.loeStatus
          ? _c("div", [_vm._v("LoE creation: " + _vm._s(_vm.loeStatus))])
          : _vm._e(),
        _vm._v(" "),
        _vm.tamStatus
          ? [
              _vm.tamStatus.titleProcessing
                ? _c(
                    "div",
                    {
                      staticClass: "activeArchival",
                      attrs: { title: _vm.tamStatus.titleProcessing.StatusStr },
                    },
                    [
                      _c("div", [
                        _vm._v(_vm._s(_vm.tamStatus.titleProcessing.FileName)),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c(
                          "progress",
                          {
                            attrs: { max: "100" },
                            domProps: {
                              value: _vm.tamStatus.titleProcessing.Progress,
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                Math.max(
                                  0,
                                  _vm.tamStatus.titleProcessing.Progress
                                )
                              ) + "%"
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "plainText" }, [
                        _vm._v(_vm._s(_vm.tamStatus.titleProcessing.StatusStr)),
                      ]),
                    ]
                  )
                : _c("div", { staticClass: "activeArchival" }, [
                    _vm._v("No active archival job"),
                  ]),
              _vm._v(" "),
              _vm.tamStatus.unsubmittedArchivalRequestCount > 0
                ? _c(
                    "div",
                    {
                      attrs: {
                        title:
                          "Count of archival requests not yet submitted to Production Server",
                      },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\tPending Request Count: " +
                          _vm._s(
                            _vm.tamStatus.unsubmittedArchivalRequestCount
                          ) +
                          "\n\t\t\t"
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "ready" },
                [
                  _c("div", { staticClass: "subHeading" }, [
                    _vm._v(
                      "Ready (" +
                        _vm._s(_vm.tamStatus.titlesReady.length) +
                        "): "
                    ),
                    !_vm.tamStatus.titlesReady.length
                      ? _c("span", [_vm._v("[None]")])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _vm.tamStatus.titlesReady.length
                    ? _vm._l(_vm.tamStatus.titlesReady, function (item) {
                        return _c(
                          "div",
                          {
                            key: "r" + item.ProductRevisionId,
                            staticClass: "titleRow",
                            attrs: {
                              title:
                                item.Name + " rev " + item.ProductRevisionId,
                            },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(item.FileName) +
                                " - " +
                                _vm._s(item.Size) +
                                "\n\t\t\t\t\t"
                            ),
                          ]
                        )
                      })
                    : _vm._e(),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "queued" },
                [
                  _c("div", { staticClass: "subHeading" }, [
                    _vm._v(
                      "Queued (" +
                        _vm._s(_vm.tamStatus.titlesQueued.length) +
                        "): "
                    ),
                    !_vm.tamStatus.titlesQueued.length
                      ? _c("span", [_vm._v("[None]")])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _vm.tamStatus.titlesQueued.length
                    ? _vm._l(_vm.tamStatus.titlesQueued, function (item) {
                        return _c(
                          "span",
                          {
                            key: "q" + item.ProductRevisionId,
                            staticClass: "acronymCell",
                            attrs: {
                              title:
                                item.Name + " rev " + item.ProductRevisionId,
                            },
                          },
                          [_vm._v(_vm._s(item.FileName))]
                        )
                      })
                    : _vm._e(),
                ],
                2
              ),
            ]
          : _vm._e(),
        _vm._v(" "),
        _c("br"),
      ],
      2
    ),
    _vm._v(" "),
    _c("div", { staticClass: "sectionHeading" }, [
      _vm._v("Title Archive Manager Status"),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "appServiceStatus plainText" }, [
      _vm._v(_vm._s(_vm.tamEngineText)),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }