var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: { pageRoot: true } }, [
    _vm.error
      ? _c("div", { staticClass: "error" }, [_vm._v(_vm._s(_vm.error))])
      : _vm.loading
      ? _c(
          "div",
          { staticClass: "loading" },
          [_c("ScaleLoader"), _vm._v(" Loading…")],
          1
        )
      : _c("div", [_vm._v("\n\t\tThis page is not implemented.\n\t")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }