import { ModalConfirmDialog } from 'appRoot/scripts/ModalDialog';

export default { /* AbandonChangesMixin warns the user if they try to leave this component while they have unsaved changes. Requires `hasChanges` property. */
	beforeDestroy()
	{
		window.removeEventListener('beforeunload', this.confirmExit);
	},
	methods:
	{
		confirmExit(e)
		{
			try
			{
				e.preventDefault();
			}
			catch (ex) { }
			return true;
		}
	},
	watch:
	{
		hasChanges(newValue, oldValue)
		{
			//window.onbeforeunload = e => { try { e.preventDefault(); } catch (ex) { } return true; };
			if (newValue && !oldValue)
				window.addEventListener('beforeunload', this.confirmExit);
			else
				window.removeEventListener('beforeunload', this.confirmExit);
		}
	},
	async beforeRouteLeave(to, from, next)
	{
		if (this.hasChanges)
		{
			const answer = await ModalConfirmDialog('Do you really want to leave? You have unsaved changes!', "Unsaved Changes", "Discard my Changes", "Cancel");
			if (answer)
				next();
			else
				next(false);
		}
		else
			next();
	}
};