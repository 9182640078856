var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pageRoot" },
    [
      _vm.error
        ? _c("div", { staticClass: "error" }, [_vm._v(_vm._s(_vm.error))])
        : _vm.loading
        ? _c(
            "div",
            { staticClass: "loading" },
            [_c("ScaleLoader"), _vm._v(" Loading…")],
            1
          )
        : _vm.monitors
        ? [
            _c(
              "draggable",
              {
                staticClass: "monitorsContainer",
                model: {
                  value: _vm.monitors,
                  callback: function ($$v) {
                    _vm.monitors = $$v
                  },
                  expression: "monitors",
                },
              },
              _vm._l(_vm.monitors, function (m, index) {
                return _c("div", {
                  key: index,
                  class: { monitorRow: true, disabled: !m.Enabled },
                  domProps: { innerHTML: _vm._s(_vm.serializeMonitorHtml(m)) },
                  on: {
                    click: function ($event) {
                      return _vm.rowClick(m, index)
                    },
                  },
                })
              }),
              0
            ),
            _vm._v(" "),
            _c("div", { staticClass: "buttonRow" }, [
              _c("input", {
                attrs: { type: "button", value: "Add Monitor" },
                on: { click: _vm.addMonitor },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "buttonRow" }, [
              _c("input", {
                attrs: { type: "button", value: "Save Changes" },
                on: { click: _vm.SaveChanges },
              }),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "When you save changes to the monitor list, TDS App Service will immediately adopt the new list.  A service restart is not required."
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Please note: Any actions which change the name of a monitor will not migrate existing notification registrations.  Users will need to resubscribe to notifications for the monitor."
              ),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }