<template>
	<nav id="topNav" class="clientNav">
		<router-link :to="{name: 'clientStatus' }" class="systemName">{{title}} {{serverVersion}}</router-link>
		<!--<a role="button" @click="notifyClicked" class="notifyButton"><vsvg sprite="notifications_active" class="notifications_icon" /></a>-->
		<router-link :to="{ name: 'clientNotificationsSetup' }"><vsvg sprite="notifications_active" class="notifications_icon" /></router-link>
		<router-link :to="{name: 'clientManage' }">{{userName}}</router-link>
	</nav>
</template>

<script>
	import svg1 from 'appRoot/images/sprite/notifications_active.svg';
	import svg2 from 'appRoot/images/sprite/notifications_off.svg';

	export default {
		data()
		{
			return {
				serverVersion: appContext.serverVersion
			};
		},
		computed:
		{
			title()
			{
				return appContext.systemName;
			},
			userName()
			{
				let un = this.$store.state.userData.Name;
				if (!un)
					un = "User";
				return un;
			}
		},
		methods:
		{
			notifyClicked()
			{
			}
		}
	}
</script>

<style scoped>
	.clientNav
	{
		position: sticky;
		top: 0px;
		display: flex;
		flex-wrap: nowrap;
		flex-direction: row;
		background-color: var(--primary-color);
		border-bottom: 1px solid #AAAAAA;
	}

		.clientNav > *
		{
			padding: 10px 10px;
			cursor: pointer;
			font-size: 20px;
			color: var(--text-color-on-primary-color);
			text-decoration: none;
		}

			.clientNav > *:hover
			{
				/*background-color: #1e78af;*/
				/*background-color: #2187c5;*/
				/*background-color: #2596da;*/
				background-color: var(--primary-color-light1);
			}

	.router-link-exact-active
	{
		text-decoration: underline;
	}

	.systemName
	{
		flex: 1 1 auto;
		align-content: flex-start;
		text-decoration: none;
		white-space: nowrap;
		text-overflow: clip;
		overflow-x: hidden;
	}

	.notifyButton
	{
		padding: 0px 10px;
		line-height: 40px;
	}

	.notifications_icon
	{
		width: 24px;
		height: 24px;
		fill: currentColor;
		vertical-align: middle;
	}

	@media (min-width: 600px)
	{
	}
</style>