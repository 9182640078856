<template>
	<div class="forgotPw">
		<div class="forgotPwPanel" title="Note: Recovery emails have a 5 minute cooldown per user account.">
			<div class="systemName">{{systemName}}</div>
			<p class="titleBar">Password Recovery</p>
			<p class="hint">Enter your <b>user name</b> and we'll send an email with a code you will need to paste on the next page.</p>
			<input type="text" class="txtUser" v-model="user" placeholder="User Name" v-on:keyup.enter="InitiateRequest" />
			<input type="button" class="btnRequest" value="Send Recovery Email" :disabled="!requestEnabled" @click="InitiateRequest" @keyup.space.enter="InitiateRequest" />
			<div class="backToLoginLinkWrapper">
				<router-link :to="{ name: 'login' }" class="backToLoginLink">Back to Login</router-link>
			</div>
		</div>
		<div class="error" v-if="error">{{error}}</div>
	</div>
</template>

<script>
	import ExecAPI from 'appRoot/api/api';

	export default {
		components: {},
		props:
		{
			initialUser: {
				type: String,
				default: ""
			}
		},
		data()
		{
			return {
				systemName: appContext.systemName,
				user: "",
				requestEnabled: true,
				error: null
			};
		},
		created()
		{
			this.user = this.initialUser;
		},
		methods:
		{
			InitiateRequest()
			{
				if (!this.requestEnabled)
					return;
				this.requestEnabled = false;
				this.error = null;

				let user = this.user;

				return ExecAPI("ForgotPW/InitiateRequest", { accountIdentifier: user })
					.then(data =>
					{
						this.requestEnabled = true;
						if (data.success)
						{
							this.$router.push({ name: "resetPassword", query: { u: user } });
						}
						else
							this.error = data.error;
					})
					.catch(err =>
					{
						this.error = err.message;
						this.requestEnabled = true;
					});
			}
		},
		watch:
		{
			initialUser()
			{
				this.user = this.initialUser;
			},
			user()
			{
				let query = Object.assign({}, this.$route.query);
				if (query.u !== this.user)
				{
					query.u = this.user;
					this.$router.replace({ name: this.$route.name, query });
				}
			}
		}
	}
</script>

<style scoped>
	.forgotPwPanel
	{
		padding: 20px 20px;
		background-color: rgba(255,255,255,0.75);
		border: 1px solid rgba(0,0,0,1);
		border-radius: 8px;
		box-shadow: 0 0 16px rgba(0,0,0,0.5);
		backdrop-filter: blur(8px);
		max-width: 200px;
	}

	.titleBar
	{
		text-align: center;
		font-weight: bold;
	}

	.systemName, .txtUser, .btnRequest
	{
		width: 200px;
		box-sizing: border-box;
	}

	.systemName
	{
		font-size: 24px;
		text-shadow: 1px 1px 4px rgba(255,255,255,1);
		margin-bottom: 15px;
		text-align: center;
		overflow-x: hidden;
		word-break: break-word;
		white-space: pre-line;
	}

	.txtUser, .btnRequest
	{
		display: block;
		border: 1px solid gray;
		border-radius: 3px;
		padding: 2px 4px;
	}

	.txtUser
	{
		border-bottom-left-radius: 0px;
		border-bottom-right-radius: 0px;
	}

	.btnRequest
	{
		margin-top: 10px;
		cursor: pointer;
		background-image: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%);
	}

		.btnRequest:hover
		{
			background-image: linear-gradient(to top, #f0f0f3 0%, #f3f7fb 100%);
		}

		.btnRequest:active
		{
			background-image: linear-gradient(to top, #ffffff 0%, #ffffff 100%);
		}

		.btnRequest:disabled
		{
			cursor: default;
		}

	.error
	{
		margin: 20px 10px;
		border: 1px solid #000000;
		border-radius: 5px;
		background-color: rgba(255,180,180,0.75);
		padding: 8px;
		max-width: 600px;
		box-sizing: border-box;
		word-break: break-word;
		white-space: pre-line;
		backdrop-filter: blur(8px);
	}

	.backToLoginLinkWrapper
	{
		text-align: center;
		margin-top: 10px;
		font-size: 10pt;
	}
</style>