import ExecAPI from 'appRoot/api/api';

export function GetUserActivityForSelf()
{
	return ExecAPI("UserActivityData/GetUserActivityForSelf");
}
/**
 * Requires Admin Session
 * @param {Number} userId User ID
 * @param {Number} startDate Oldest allowed Date to filter by.
 * @param {Number} endDate Newest allowed Date to filter by.
 */
export function GetUserActivity(userId, startDate, endDate)
{
	return ExecAPI("UserActivityData/GetUserActivity", { userId, startDate, endDate });
}