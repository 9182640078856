<template>
	<div :class="{ cmsConsole: true, connected: cmsConsoleConnected, disconnected: !cmsConsoleConnected }">
		<div class="cmsConsoleDragHandle" @mousedown.prevent="onDragStart" @mouseup="onDragEnd" @dblclick.prevent="onDoubleClick">
			<vsvg sprite="drag_handle" class="dragHandle" />
			<span>
				<a role="button" tabindex="0" :class="dynTabClasses['All']"
				   @click="onTabClick('All')" @keypress.space.enter.prevent="onTabClick('All')">All</a> /
				<a role="button" tabindex="0" :class="dynTabClasses['Production']"
				   @click="onTabClick('Production')" @keypress.space.enter.prevent="onTabClick('Production')">Production</a> /
				<a role="button" tabindex="0" :class="dynTabClasses['Deployment']"
				   @click="onTabClick('Deployment')" @keypress.space.enter.prevent="onTabClick('Deployment')">Deployment</a>
			</span>
			<vsvg sprite="cable" class="connectionIcon" title="Green if the console is connected to the server. Red if disconnected." />
		</div>
		<div class="cmsConsoleBody" :style="consoleBodyStyle">
			<template v-if="tabName === 'All'">
				<div class="consoleScrollable leftSide">
					<CmsConsoleProduction />
				</div>
				<div class="consoleScrollable rightSide">
					<CmsConsoleDeployment />
				</div>
			</template>
			<template v-else-if="tabName === 'Production'">
				<div class="consoleScrollable solo">
					<CmsConsoleProduction />
				</div>
			</template>
			<template v-else-if="tabName === 'Deployment'">
				<div class="consoleScrollable solo">
					<CmsConsoleDeployment />
				</div>
			</template>
		</div>
	</div>
</template>

<script>
	import svg1 from 'appRoot/images/sprite/drag_handle.svg';
	import svg2 from 'appRoot/images/sprite/cable.svg';
	import EventBus from 'appRoot/scripts/EventBus';
	import { Clamp, throttle } from 'appRoot/scripts/Util';
	import CmsConsoleProduction from 'appRoot/vues/client/controls/CmsConsoleProduction.vue';
	import CmsConsoleDeployment from 'appRoot/vues/client/controls/CmsConsoleDeployment.vue';

	var SaveSize = throttle(SaveSize_raw, 400);

	export default {
		components: { CmsConsoleProduction, CmsConsoleDeployment },
		props:
		{
		},
		data()
		{
			return {
				size: 16,
				dragStatus: { isDragging: false, startY: 0, startSize: 0 },
				allTabNames: ["All", "Production", "Deployment"],
				tabName: "All",
			};
		},
		created()
		{
			let storedSize = parseInt(localStorage.cms_console_size);
			this.setSize(storedSize);

			let storedTabName = localStorage.cms_console_tabName;
			if (storedTabName && this.allTabNames.indexOf(storedTabName) > -1)
				this.tabName = storedTabName;
		},
		computed:
		{
			dynTabClasses()
			{
				let classes = {};
				for (let i = 0; i < this.allTabNames.length; i++)
				{
					let tn = this.allTabNames[i];
					classes[tn] = this.genTabClasses(tn);
				}
				return classes;
			},
			effectiveSize()
			{
				return Clamp(Clamp(this.size, 0, this.windowHeight - 150), 0, 1000);
			},
			consoleBodyStyle()
			{
				return {
					height: this.effectiveSize + "px"
				};
			},
			windowHeight()
			{
				return EventBus.windowHeight;
			},
			mouseY()
			{
				return EventBus.mouseClientY;
			},
			mouseUps()
			{
				return EventBus.mouseUps;
			},
			cmsConsoleConnected()
			{
				return EventBus.cmsConsoleConnected;
			},
		},
		methods:
		{
			genTabClasses(tabName)
			{
				let classes = ["tabname", tabName];
				if (tabName === this.tabName)
					classes.push("selected");
				return classes;
			},
			onTabClick(tabName)
			{
				this.tabName = tabName;
				localStorage.cms_console_tabName = tabName;
			},
			setSize(size)
			{
				if (typeof size === "number" && !isNaN(size))
				{
					this.size = size;
					SaveSize(size);
				}
			},
			onDragStart(e)
			{
				this.dragStatus.startY = this.mouseY;
				this.dragStatus.startSize = this.effectiveSize;
				this.dragStatus.isDragging = true;
			},
			onDragEnd(e)
			{
				this.dragStatus.isDragging = false;
			},
			onDoubleClick(e)
			{
				this.setSize(16);
			},
		},
		watch:
		{
			mouseY()
			{
				if (this.dragStatus.isDragging)
				{
					let diff = this.mouseY - this.dragStatus.startY;
					let size = this.dragStatus.startSize - diff;
					this.setSize(size);
				}
			},
			mouseUps()
			{
				this.dragStatus.isDragging = false;
			}
		}
	}
	function SaveSize_raw(size)
	{
		if (localStorage.cms_console_size != size)
			localStorage.cms_console_size = size;
	}
</script>

<style scoped>
	.cmsConsole
	{
		left: 0px;
		bottom: 0px;
		width: 100vw;
		box-sizing: border-box;
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
		padding: 0px;
		padding-bottom: 0px !important;
		display: flex;
		flex-direction: column;
		overflow: hidden;
		background-color: #000000;
		color: #00FF00;
		font-family: Consolas, monospace;
		font-size: 14px;
	}

	.cmsConsoleDragHandle
	{
		display: flex;
		justify-content: space-between;
		align-items: center;
		border: 1px solid black;
		border-bottom: none;
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
		height: 26px;
		box-sizing: border-box;
		/*background-color: #666666;*/
		color: #FFFFFF;
		cursor: ns-resize;
	}

		.cmsConsoleDragHandle .titleBar
		{
			margin-right: 5px;
		}

		.cmsConsoleDragHandle > svg.dragHandle
		{
			margin: 0px 5px 0px 3px;
			width: 24px;
			height: 24px;
			fill: #DDDDDD;
		}

		.cmsConsoleDragHandle .connectionIcon
		{
			margin-right: 3px;
			width: 24px;
			height: 24px;
			filter: drop-shadow(0px 0px 2px rgba(0,0,0,0.667));
		}


	.disconnected.cmsConsole
	{
		background-color: #370000;
	}

	.disconnected .cmsConsoleDragHandle > svg.dragHandle
	{
		fill: #ff6060;
	}

	.disconnected .cmsConsoleDragHandle
	{
		background-color: #550000;
		color: #ff9696;
		font-weight: bold;
	}

		.disconnected .cmsConsoleDragHandle .connectionIcon
		{
			fill: #FF0000;
		}

	.connected .cmsConsoleDragHandle .connectionIcon
	{
		fill: #00FF00;
	}

	.cmsConsoleBody
	{
		flex: 1 1 auto;
		display: flex;
		justify-content: space-between;
	}

	.consoleScrollable
	{
		padding: 4px 1px 4px 6px;
		margin-right: 1px;
		flex: 1 1 auto;
		width: 50%;
		overflow-y: scroll;
		word-break: break-word;
	}

	.rightSide
	{
		border-left: 1px solid #008800;
	}

	.consoleScrollable::-webkit-scrollbar
	{
		width: 5px;
		height: 5px;
	}

	.consoleScrollable::-webkit-scrollbar-thumb
	{
		background: #008800;
		border-radius: 2.5px;
	}

		.consoleScrollable::-webkit-scrollbar-thumb:hover
		{
			background: #006600;
		}

	.tabname
	{
		opacity: 0.48;
		color: #FFFFFF;
	}

		.tabname.selected
		{
			color: #FFFFFF;
			opacity: 1;
			font-weight: bold;
		}

	@media (min-width: 430px)
	{
	}
</style>