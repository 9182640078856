<template>
	<div class="pageRoot">
		<h2>Adding a Release Agent</h2>
		<p>To configure a Release Agent, run the TdsAppReleaseAgent service on a new machine, then use the <code class="inline">Begin Adoption</code> button in its service manager GUI. This will cause the agent to request adoption.  You can discover and approve adoption requests below.</p>
		<div v-if="error" class="error">{{error}}</div>
		<div v-else>
			<h2>Approval Settings</h2>
			<p>The selected settings will be applied to the agent you choose to approve below.</p>
			<div class="settingsRow">
				<div class="settingsTitle">Environment</div>
				<div class="settingsValue">
					<select v-model="environmentName">
						<option v-for="env in environmentNames" :key="env">{{env}}</option>
					</select>
				</div>
			</div>
			<div class="settingsRow">
				<div class="settingsTitle">CMS Role</div>
				<div class="settingsValue">
					<select v-model="cmsRole">
						<option v-for="role in cmsRoles" :key="role">{{role}}</option>
					</select>
				</div>
			</div>
			<div class="FloatRight">
				<button class="refreshButton" @click="loadData">
					<vsvg sprite="refresh" class="refreshIcon" />
				</button>
			</div>
			<h2>Adoption Requests</h2>
			<div class="adoptionRequests">
				<div v-if="loading" class="loading"><ScaleLoader /> Loading…</div>
				<div v-else-if="!pendingAdoptions.length">No adoptions pending.</div>
				<template v-else>
					<div class="adoptionRequest" v-for="(ar, index) in pendingAdoptions" :key="index">
						<div class="agentName">Agent Name: {{ar.AgentName}}</div>
						<div class="agentIp">IP Address: {{ar.AgentIp}}</div>
						<div class="agentPublicKey">Public Key: <input type="text" :value="ar.AgentPublicKey" disabled="disabled" /></div>
						<input type="button" :value="'Approve ' + ar.AgentName" @click="approve(ar)" :disabled="!canApprove" />
					</div>
				</template>
			</div>
			<h2>A Note on Security</h2>
			<p>TdsAppService and each Release Agent must be configured with each other's public keys.  These public keys are used for encryption and signing to prove the identity of each party when the services communicate with each other.  More details on the implementation of this security mechanism can be found in each application's source code.</p>
		</div>
		<div v-else>Failed to load.</div>
	</div>
</template>

<script>
	import { GetPendingAdoptions, ApproveAdoption } from 'appRoot/api/AddAgentData';
	import { ModalMessageDialog, TextInputDialog, ProgressDialog, ModalConfirmDialog } from 'appRoot/scripts/ModalDialog';
	import svg1 from 'appRoot/images/sprite/refresh.svg';

	export default {
		components: {},
		data()
		{
			return {
				error: null,
				loading: false,
				pendingAdoptions: [],
				cmsRoles: [],
				environmentNames: [],
				agentName: "",
				agentPublicKey: "",
				cmsRole: "",
				environmentName: ""
			};
		},
		created()
		{
			this.loadData();
		},
		computed:
		{
			canApprove()
			{
				return !!this.cmsRole && !!this.environmentName;
			}
		},
		methods:
		{
			async loadData()
			{
				if (this.loading)
					return;
				this.loading = true;
				this.error = null;
				this.utilityData = null;
				let data = await GetPendingAdoptions();
				try
				{
					if (data.success)
					{
						this.pendingAdoptions = data.pendingAdoptions;
						this.cmsRoles = data.cmsRoles;
						this.environmentNames = data.environmentNames;
						if (!this.cmsRole && this.cmsRoles.length)
							this.cmsRole = this.cmsRoles[0];
						if (!this.environmentName && this.environmentNames.length)
							this.environmentName = this.environmentNames[this.environmentNames.length - 1];
					}
					else
						this.error = data.error;
				}
				catch (ex)
				{

					this.error = ex.message;
				}
				finally
				{
					this.loading = false;
				}
			},
			async approve(adoptionRequest)
			{
				let confirmed = await ModalConfirmDialog("Are you sure you want to approve the adoption request from the agent claiming to be \"" + adoptionRequest.AgentName + "\" from IP " + adoptionRequest.AgentIp + "?\n\nThey will be added to environment \"" + this.environmentName + "\", and granted CMS Role: " + this.cmsRole + ".", "Confirm Approval", "Confirm", "Cancel");
				if (confirmed)
				{
					try
					{
						let data = await ApproveAdoption(adoptionRequest, this.cmsRole, this.environmentName);
						if (data.success)
							ModalMessageDialog(data.message, "Success");
						else
							ModalMessageDialog(data.error, "Error");
					}
					catch (ex)
					{
						ModalMessageDialog(ex.toString(), "Error");
					}
				}
			}
		}
	}
</script>

<style scoped>
	.pageRoot
	{
		margin: 8px;
	}

	.loading
	{
		margin-top: 80px;
		text-align: center;
	}

	.error
	{
		color: #FF0000;
		font-weight: bold;
	}

	.settingsRow
	{
		margin-bottom: 1em;
	}

	.settingsValue
	{
	}

	.FloatRight
	{
		float: right;
		margin-top: 3px;
	}

	.refreshButton
	{
		width: 44px;
		height: 44px;
		padding: 0px;
	}

	.refreshIcon
	{
		width: 32px;
		height: 32px;
		vertical-align: middle;
	}

	.adoptionRequests
	{
		margin-bottom: 1em;
	}

	.adoptionRequest
	{
		margin-bottom: 1em;
		padding: 1em 0px;
		border: 1px solid rgba(0,0,0,0.5);
		border-left: none;
		border-right: none;
	}

	.agentPublicKey input
	{
		width: 400px;
		max-width: 90vw;
		text-overflow: ellipsis;
	}
</style>