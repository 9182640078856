var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "scaledImageItem",
      class: { selected: _vm.selected },
      attrs: { role: "button", tabindex: "0" },
      on: {
        click: function ($event) {
          return _vm.selectImage(_vm.img)
        },
        keypress: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter") &&
            _vm._k($event.keyCode, "space", 32, $event.key, [" ", "Spacebar"])
          )
            return null
          $event.preventDefault()
          return _vm.selectImage(_vm.img)
        },
      },
    },
    [
      _c("img", {
        staticClass: "scaledImage",
        attrs: { src: _vm.dataUri, alt: "image" },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "caption" }, [
        _c(
          "a",
          {
            attrs: {
              href: _vm.dataUri,
              download: _vm.downloadName,
              title: "download image",
            },
          },
          [
            _vm._v(_vm._s(_vm.downloadName) + " "),
            _c("vsvg", {
              staticClass: "download_img",
              attrs: { sprite: "upload" },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", [_vm._v(_vm._s(_vm.fileSize))]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }