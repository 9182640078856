var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "appRoot" },
    [
      _c("ModalDialogContainer", {
        attrs: { name: "dialogFade", "transition-name": "dialogFade" },
      }),
      _vm._v(" "),
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.tooltipHtml,
            expression: "tooltipHtml",
          },
        ],
        staticClass: "cursorAttached",
        style: _vm.cursorAttachedStyle,
        domProps: { innerHTML: _vm._s(_vm.tooltipHtml) },
      }),
      _vm._v(" "),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }