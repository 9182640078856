var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "CmsRevisionSelectorDialog",
      attrs: {
        role: "alertdialog",
        "aria-labelledby": "CmsRevisionSelectorTitle",
      },
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "dialogBody" },
        [
          _c(
            "a",
            {
              staticClass: "ProductRevisionClickable",
              attrs: { role: "button", tabindex: "0" },
              on: {
                click: function ($event) {
                  return _vm.SelectRevision(0)
                },
              },
            },
            [
              _c("img", {
                staticClass: "RevisionImageIcon",
                attrs: {
                  src: _vm.appPath + "Images/document_plain_new.png",
                  alt: "",
                  role: "presentation",
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "ProductRevisionTitle" }, [
                _vm._v("Empty Revision"),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "spacer" }),
          _vm._v(" "),
          _vm._l(_vm.productRevisions, function (rev) {
            return _c(
              "a",
              {
                key: rev.ProductRevisionId,
                staticClass: "ProductRevisionClickable",
                attrs: { role: "button", tabindex: "0" },
                on: {
                  click: function ($event) {
                    return _vm.SelectRevision(rev.ProductRevisionId)
                  },
                },
              },
              [
                _c("img", {
                  staticClass: "RevisionImage",
                  attrs: {
                    src: _vm.appPath + "ProductImage/" + rev.ImageId,
                    alt: "",
                    role: "presentation",
                  },
                }),
                _vm._v(" "),
                _c("span", {
                  staticClass: "ProductRevisionTitle",
                  domProps: { innerHTML: _vm._s(rev.RevisionNameHtml) },
                }),
                _vm._v(" "),
                _c("CmsLifecycleStateBadge", {
                  attrs: { text: rev.LifecycleState, compact: true },
                }),
              ],
              1
            )
          }),
          _vm._v(" "),
          _c("div", { staticClass: "spacer" }),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "ProductRevisionClickable",
              attrs: { role: "button", tabindex: "0" },
              on: {
                click: function ($event) {
                  return _vm.DefaultClose()
                },
              },
            },
            [
              _c("img", {
                staticClass: "RevisionImageIcon",
                attrs: {
                  src: _vm.appPath + "Images/delete.png",
                  alt: "",
                  role: "presentation",
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "ProductRevisionTitle" }, [
                _vm._v("Cancel"),
              ]),
            ]
          ),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "titleBar" }, [
      _c(
        "div",
        {
          staticClass: "title",
          attrs: { id: "CmsRevisionSelectorTitle", role: "alert" },
        },
        [_vm._v("Select a Revision to Copy")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }