import ModalDialogComponent from 'appRoot/vues/common/controls/ModalDialog.vue';
const makeModalDialog = create({ component: ModalDialogComponent, wrapper: 'dialogFade' });

import ConfirmPopup from 'appRoot/vues/common/controls/ConfirmPopup.vue';
import TextInputPopup from 'appRoot/vues/common/controls/TextInputPopup.vue';
import ColorInputPopup from 'appRoot/vues/common/controls/ColorInputPopup.vue';
import GeolocateIPPopup from 'appRoot/vues/common/controls/GeolocateIPPopup.vue';
import ProgressPopup from 'appRoot/vues/common/controls/ProgressPopup.vue';
import ReleaseDetails from 'appRoot/vues/client/controls/ReleaseDetails.vue';
import AceCodeEditorPopup from 'appRoot/vues/common/controls/AceCodeEditorPopup.vue';
import MergeConflictsPopup from 'appRoot/vues/common/controls/MergeConflictsPopup.vue';
import CmsLifecycleStatePopup from 'appRoot/vues/client/controls/CmsLifecycleStatePopup.vue';
import CmsRevisionSelectorPopup from 'appRoot/vues/client/controls/CmsRevisionSelectorPopup.vue';
import CmsProductLinksPopup from 'appRoot/vues/client/controls/CmsProductLinksPopup.vue';
import CmsValidationResultsPopup from 'appRoot/vues/client/controls/CmsValidationResultsPopup.vue';
import CreatePasskeyPopup from 'appRoot/vues/client/controls/CreatePasskeyPopup.vue';
import ImageOptimizerPopup from 'appRoot/vues/client/controls/ImageOptimizerPopup.vue';
import TextFileLoadBackupPopup from 'appRoot/vues/client/controls/TextFileLoadBackupPopup.vue';
import LoECreatingPopup from 'appRoot/vues/client/controls/LoECreatingPopup.vue';

//////////////////////////////////////////////////
// Container Registration / Dialog Registration //
//////////////////////////////////////////////////
let allContainers = {};
export function RegisterModalDialogContainer(containerComponent)
{
	if (allContainers[containerComponent.name] !== containerComponent)
		allContainers[containerComponent.name] = containerComponent;
}
export function UnregisterModalDialogContainer(containerComponent)
{
	if (allContainers[containerComponent.name] === containerComponent)
	{
		delete allContainers[containerComponent.name];
	}
}
/**
 * Returns a function to create a dialog from the specified component, in the specified container.
 * @param {Object} param0 An object containing two properties. [component] should be a reference to a component (suggestion: load via "import"). [wrapper] should be a string name of a ModalDialogContainer element that has been added to the root vue component.
 * @returns {Function} Returns a function.  The function accepts as an argument an object defining the props to be passed to the created component.  The function returns a promise which resolves when the dialog is closed.
 */
function create({ component, wrapper })
{
	return props =>
	{
		return new Promise((resolve /* this never rejects */) =>
		{
			let container = allContainers[wrapper];
			if (!container)
			{
				console.error('Dialog container "' + wrapper + '" does not exist. Make sure you have added <ModalDialogContainer name="' + wrapper + '" /> somewhere in the project.');
				resolve(false);
				return;
			}
			container.CreateDialog(component, props, dialogResult =>
			{
				// Called upon dialog close
				resolve(dialogResult);
			});
		});
	};
}
/**
 * Closes all open dialogs.
 */
export function CloseAllDialogs()
{
	for (let key in allContainers)
		if (allContainers.hasOwnProperty(key))
		{
			let c = allContainers[key];
			if (c && typeof c.CloseAllDialogs === "function")
				c.CloseAllDialogs();
		}
}
export function CountOpenDialogs(condition)
{
	let total = 0;
	for (let key in allContainers)
		if (allContainers.hasOwnProperty(key))
		{
			let c = allContainers[key];
			if (c && c.components)
			{
				if (typeof condition === "function")
				{
					for (let i = 0; i < c.components.length; i++)
						if (condition(c.components[i]))
							total++;
				}
				else if (c.components.length)
					total += c.components.length;
			}
		}
	return total;
}
///////////////////////////////
// Dialog-Creation Functions //
///////////////////////////////
/**
 * Creates a modal dialog containing the specified component, passing along the specified props.
 * Returns a promise which resolves when the dialog closes. Does not reject.
 * @param {any} contentComponent A vue component to serve as the content component for the dialog (suggestion: get this via an import statement).
 * @param {Object} contentProps Props to be passed to the content component.
 * @param {Object} options Options for the dialog.
 * @returns {Promise} Returns a promise which resolves when the dialog closes. Does not reject.
 */
export function ModalDialog(contentComponent, contentProps, options)
{
	options = Object.assign({
		zIndex: null, // (default is defined in ModalDialog.vue's style block). Beware that setting this will break normal dialog ordering where the last dialog opened is the top dialog.
		positionAbsolute: false,
		halfHeight: false,
		alignTop: false,
		defaultCloseOnOverlayClick: true
	}, options);

	let args = {
		contentComponent,
		contentProps,
		zIndex: options.zIndex,
		positionAbsolute: options.positionAbsolute,
		halfHeight: options.halfHeight,
		alignTop: options.alignTop,
		defaultCloseOnOverlayClick: options.defaultCloseOnOverlayClick
	};
	if (!args.contentComponent)
		console.error("No valid contentComponent was provided to ModalDialog function");
	return makeModalDialog(args);
}
/**
 * Creates a modal message dialog, returning a promise which resolves when the dialog closes. Does not reject.
 * @param {String} message A message for the dialog.
 * @param {String} title Optional title for the dialog.
 * @param {Object} props Optional object containing additional properties for the dialog.
 * @param {Object} options Optional object containing options for the dialog.
 * @returns {Promise} Returns a promise which resolves when the dialog closes. Does not reject.
 */
export function ModalMessageDialog(message, title = undefined, props = null, options = null)
{
	let args = { message: message };
	if (props)
		Object.assign(args, props);
	if (typeof title !== "undefined")
		args.title = title;

	return ModalDialog(ConfirmPopup, args, options);
}
/**
 * Creates a modal message dialog, returning a promise which resolves when the dialog closes. Does not reject.
 * @param {String} message A message for the dialog containing HTML markup.
 * @param {String} title Optional title for the dialog.
 * @param {Object} props Optional object containing additional properties for the dialog.
 * @param {Object} options Optional object containing options for the dialog.
 * @returns {Promise} Returns a promise which resolves when the dialog closes. Does not reject.
 */
export function ModalHtmlMessageDialog(message, title, props = null, options = null)
{
	let args = { message: message, messageHtml: true };
	if (props)
		Object.assign(args, props);
	if (typeof title !== "undefined")
		args.title = title;

	return ModalDialog(ConfirmPopup, args, options);
}
/**
 * Creates a modal confirm dialog, returning a promise which resolves when the dialog closes. Does not reject.
 * The resolve value is true if the user clicked the accept button.
 * @param {any} message A string message for the dialog. Or, optionally, an args object (for advanced use).
 * @param {String} title Optional title for the dialog.
 * @param {String} yesText Text to show in the accept button.
 * @param {String} noText Text to show in the decline button.
 * @returns {Promise} Returns a promise which resolves when the dialog closes. Does not reject.
 */
export function ModalConfirmDialog(message, title, yesText, noText)
{
	let args;
	if (typeof message === "object")
	{
		args = message;
		args.confirm = true;
	}
	else
	{
		args = { message: message, confirm: true };
		if (typeof title !== "undefined")
			args.title = title;
		if (typeof yesText !== "undefined")
			args.yesText = yesText;
		if (typeof noText !== "undefined")
			args.noText = noText;
	}
	return ModalDialog(ConfirmPopup, args);
}
/**
 * Opens a dialog box with a text input field inside, and returns a promise that resolves with an object { value: "input text" }, or false if the dialog was canceled.
 * Returns a promise which resolves when the dialog closes. Does not reject.
 * @param {any} title Optional title for the dialog box. Appears specially styled. (omitted if null or empty). May optionally be an object containing all the arguments for the text input dialog.
 * @param {String} message Optional message for the dialog box. (omitted if null or empty)
 * @param {String} placeholder Optional placeholder text for the text input. (omitted if null or empty)
 * @param {String} initialText Optional text that should be in the text input when it first appears.
 * @param {String} checkboxText If provided, a checkbox will be inserted into the dialog box with this text, and the resolve value will also have a "checked" field.
 * @returns {Promise} Returns a promise which resolves when the dialog closes. Does not reject.
 */
export function TextInputDialog(title, message, placeholder, initialText, checkboxText = undefined)
{
	let args;
	if (typeof title === "object")
		args = title;
	else
	{
		args = { title: title };
		if (typeof message !== "undefined") args.message = message;
		if (typeof placeholder !== "undefined") args.placeholder = placeholder;
		if (typeof initialText !== "undefined") args.initialText = initialText;
		if (typeof checkboxText !== "undefined") args.checkboxText = checkboxText;
	}
	return ModalDialog(TextInputPopup, args);
}
/**
 * Opens a dialog box with a color input field inside, and returns a promise that resolves with an object { value: "F0F0F0" }, or false if the dialog was canceled.
 * Returns a promise which resolves when the dialog closes. Does not reject.
 * @param {String} title Optional title for the dialog box. Appears specially styled. (omitted if null or empty).
 * @param {String} message Optional message for the dialog box. (omitted if null or empty)
 * @param {String} initialColor Optional hex color (not including #) that should be in the color input when it first appears.
 * @returns {Promise} Returns a promise which resolves when the dialog closes. Does not reject.
 */
export function ColorInputDialog(title, message, initialColor)
{
	let args;
	args = { title: title };
	if (typeof message !== "undefined") args.message = message;
	if (typeof initialColor !== "undefined") args.initialColor = initialColor;
	return ModalDialog(ColorInputPopup, args);
}
/**
 * Opens a dialog box which loads geolocation information for an IP Address. Returns a promise which resolves when the dialog closes. Does not reject.
 * @param {String} ip IP Address to geolocate.
 * @returns {Promise} Returns a promise which resolves when the dialog closes. Does not reject.
 */
export function GeolocateIPDialog(ip)
{
	let args = { ip };
	return ModalDialog(GeolocateIPPopup, args);
}
/**
 * Opens a dialog box which shows a loading spinner and a short message. Returns an object with a "close" method which you MUST call later.
 * @param {String} text Optional text to show in the progress dialog. Default: "Loading…"
 * @returns {Object} Returns an object with a "close" method which you MUST call later.
 */
export function ProgressDialog(text)
{
	let args = { close: false };
	if (text)
		args.text = text;
	ModalDialog(ProgressPopup, args);
	return {
		close: () =>
		{
			args.close = true;
		}
	};
}
/**
 * Opens a dialog box which loads details for a TDS Health web app release. Returns a promise which resolves when the dialog closes. Does not reject.
 * @param {Object} environment Environment object.
 * @param {Object} release Release object.
 * @returns {Promise} Returns a promise which resolves when the dialog closes. Does not reject.
 */
export function ReleaseDetailsDialog(environment, release)
{
	let args = { environment, release };
	return ModalDialog(ReleaseDetails, args);
}
/**
 * Opens a dialog box with an "ace" code editor in it, pre-set to a given text value. Returns a promise which resolves with the updated text value when the dialog closes. Does not reject. If the user chooses to "discard their changes", the value sent to the promise resolve will be null. This differs from a situation where the user chooses to commit the text with no changes.
 * @param {String} fileName File name to show in the title bar.
 * @param {String} value Initial text value.
 * @param {String} mode Ace text editor mode, e.g. "ace/mode/javascript".  Be sure to write an import statement for the mode you are using.  E.g. `import 'ace-builds/src-noconflict/mode-javascript';`
 * @param {Function} commitFn Optional function that will be called when committing changes, before closing the dialog. If provided, the function must accept the updated text as an argument, and return a promise that resolves with true when the dialog is allowed to close. The function may return false, indicating the dialog is not allowed to close.  In such a case, the user is not informed of why the dialog did not close.
 * @param {Array} backupInfo Optional array of BackupInfo objects
 * @param {Function} loadBackupFn Optional callback method that accepts a BackupInfo and resolves with the content of the backup file.
 * @returns {Promise} Returns a promise which resolves when the dialog closes. Does not reject.
 */
export function AceCodeEditorDialog(fileName, value, mode, commitFn, backupInfo, loadBackupFn)
{
	let args = { fileName, value, mode, commitFn, backupInfo, loadBackupFn };
	return ModalDialog(AceCodeEditorPopup, args);
}
/**
 * Opens a dialog box showing merge conflicts in a well-structured manner.
 * @param {Array} mergeConflicts Array of merge conflicts.
 */
export function ModalMergeConflictsDialog(mergeConflicts)
{
	let args = { mergeConflicts };
	return ModalDialog(MergeConflictsPopup, args);
}
/**
 * Opens a dialog box allowing the user to change the lifecycle state of the ProductRevision.
 * @param {Object} productRevision The ProductRevision being worked on.
 * @returns {Promise} Returns a promise which resolves when the dialog closes. Does not reject. The result will contain a `newState` property.
 */
export function CmsLifecycleStateDialog(productRevision)
{
	let args = { productRevision };
	return ModalDialog(CmsLifecycleStatePopup, args);
}
/**
 * Opens a dialog box allowing the user to decide which ProductRevision they want to clone or if they want to create a new Empty ProductRevision.
 * @param {Array} productRevisions The array of ProductRevisions available to choose from.
 * @returns {Promise} Returns a promise which resolves when the dialog closes. Does not reject. The result will contain a `ProductRevisionId` property.
 */
export function CmsRevisionSelectorDialog(productRevisions)
{
	let args = { productRevisions };
	return ModalDialog(CmsRevisionSelectorPopup, args);
}
/**
 * Opens a dialog box allowing the use to open links to this product in external services.
 * @param {Object} productRoot The ProductRoot being worked on.
 * @param {Object} productRevision The ProductRevision being worked on, or null.
 * @returns {Promise} Returns a promise which resolves when the dialog closes. Does not reject.
 */
export function CmsProductLinksDialog(productRoot, productRevision)
{
	let args = { productRoot, productRevision };
	return ModalDialog(CmsProductLinksPopup, args, { alignTop: true });
}
/**
 * Opens a dialog box to show product information validation results.
 * @param {Object} results The result object from the server.
 * @returns {Promise} Returns a promise which resolves when the dialog closes. Does not reject.
 */
export function CmsValidationResultsDialog(results)
{
	let args = { results };
	return ModalDialog(CmsValidationResultsPopup, args);
}
/**
 * Opens a dialog box to show a message informing the user about Passkey login and asking them to create one.  Mean to be opened on the Client > Manage page after logging in to an account without a passkey.
 * @returns {Promise} Returns a promise which resolves when the dialog closes. Does not reject.
 */
export function CreatePasskeyDialog()
{
	return ModalDialog(CreatePasskeyPopup);
}
/**
 * Opens a dialog box to show options for product image optimization.
 * @param {String} imageBase64 The product image data (base64 encoded) you want to upload for optimization.
 * @returns {Promise} Returns a promise which resolves when the dialog closes. Does not reject.
 */
export function ImageOptimizerDialog(imageBase64)
{
	let args = { imageBase64 };
	return ModalDialog(ImageOptimizerPopup, args, { defaultCloseOnOverlayClick: false });
}
/**
 * Opens a dialog box for the user to select a backup file to load.
 * @param {Array} backupDates Array of dates where backups are available.
 * @returns {Promise} Returns a promise which resolves when the dialog closes. Does not reject.
 */
export function TextFileLoadBackupDialog(backupDates)
{
	let args = { backupDates };
	return ModalDialog(TextFileLoadBackupPopup, args);
}
/**
 * Opens a dialog box which confirms the user's intent to create an LoE product revision.
 * @param {Number} fxid FXID of the product being copied.
 * @param {Number} productRevisionId ID of the Product Revision which should be copied to make a new LoE revision.
 * @param {Number} imageId ID of the image representing this Product Revision.
 * @returns {Promise} Returns a promise which resolves when the dialog closes. Does not reject.
 */
export function LoECreatingDialog(fxid, productRevisionId, imageId)
{
	let args = { fxid, productRevisionId, imageId };
	return ModalDialog(LoECreatingPopup, args, { defaultCloseOnOverlayClick: false });
}