<template>
	<div class="activeSessionListRoot">
		<div v-if="error">{{error}}</div>
		<div v-else-if="loading" class="loading"><ScaleLoader /> Loading…</div>
		<div v-else-if="rows" class="recordsTableContainer">
			<vue-good-table :columns="columns" :rows="rows" />
			<p v-if="typeof unauthenticatedSessionCount !== 'undefined'" title="The number of sessions which have been created for password login but have not yet been authenticated.  A high number could indicate a brute force attack in progress.">Unauthenticated Session Count: {{unauthenticatedSessionCount}}</p>
		</div>
		<div v-else>
			No data.
		</div>
	</div>
</template>

<script>
	import { GetActiveSessions, GetAllActiveSessions } from 'appRoot/api/SessionStatus';

	export default {
		components: {},
		props:
		{
			allSessions: {
				type: Boolean,
				required: true
			}
		},
		data()
		{
			return {
				error: null,
				loading: false,
				columns: [
					{ label: "User ID", field: "userId" },
					{ label: "User Name", field: "userName" },
					{ label: "IP Address", field: "ip" },
					{ label: "Session ID", field: "sid" },
				],
				rows: null,
				unauthenticatedSessionCount: undefined
			};
		},
		created()
		{
			this.loadRecords();
		},
		methods:
		{
			async loadRecords()
			{
				this.loading = true;
				try
				{
					let result;
					if (this.allSessions)
						result = await GetAllActiveSessions();
					else
					{
						this.columns.splice(0, 2);
						result = await GetActiveSessions();
					}

					if (result.success)
					{
						this.unauthenticatedSessionCount = result.unauthenticatedSessionCount;
						this.rows = result.sessions;
					}
					else
						this.error = result.error;
				}
				catch (ex)
				{
					this.error = ex.message;
				}
				finally
				{
					this.loading = false;
				}
			},
		}
	}
</script>

<style scoped>
	.loading
	{
		margin: 20px;
		text-align: center;
	}
</style>