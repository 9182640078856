var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "ProductRoot", class: _vm.dynClasses }, [
    _c(
      "div",
      { staticClass: "ProductRootRow" },
      [
        _c(
          "router-link",
          {
            staticClass: "TreeExpandClickable",
            attrs: {
              to: _vm.GetRouteToToggleProductRoot(),
              replace: "",
              title: "toggle tree expansion",
            },
          },
          [
            _c("vsvg", {
              staticClass: "expand_more",
              class: { expanded: !!_vm.ProductRevisionsState },
              attrs: { sprite: "expand_more" },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("img", {
          staticClass: "product-star",
          attrs: {
            src: _vm.starImgProductRoot,
            alt: "star",
            role: "button",
            tabindex: "0",
          },
          on: {
            click: function ($event) {
              return _vm.toggleStarProductRoot(_vm.proot)
            },
            keypress: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter") &&
                _vm._k($event.keyCode, "space", 32, $event.key, [
                  " ",
                  "Spacebar",
                ])
              )
                return null
              $event.preventDefault()
              return _vm.toggleStarProductRoot(_vm.proot)
            },
          },
        }),
        _vm._v(" "),
        _c(
          "router-link",
          {
            staticClass: "ProductRootClickable",
            attrs: { to: _vm.GetRouteToProductRoot(_vm.proot) },
          },
          [
            _c("img", {
              staticClass: "ProductImage",
              attrs: { src: _vm.GetProductImgSrc(_vm.proot) },
            }),
            _vm._v(" "),
            _c("span", { staticClass: "ProductRootTitle" }, [
              _c("span", {
                domProps: { innerHTML: _vm._s(_vm.proot.InternalNameHtml) },
              }),
              _vm._v(
                " [" +
                  _vm._s(_vm.proot.FileName) +
                  ", " +
                  _vm._s(_vm.proot.TaylorProductId) +
                  "]"
              ),
            ]),
            _vm._v(" "),
            _vm.lsText
              ? _c("CmsLifecycleStateBadge", {
                  attrs: {
                    text: _vm.lsText,
                    compact: true,
                    title:
                      "This badge seemed appropriate to show, given the revisions currently available for this product.",
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _vm.ProductRevisionsState === 1
      ? _c(
          "div",
          { staticClass: "ProductRevisions" },
          [
            _c("ScaleLoader", {
              staticClass: "LoaderAlignLeft",
              attrs: { height: "1em" },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.ProductRevisionsState === 2
      ? _c(
          "div",
          { staticClass: "ProductRevisions" },
          [
            _vm.ProductRevisionsList.length === 0
              ? _c("div", { staticClass: "ProductRevision" }, [
                  _vm._m(0),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "NotClickableListItem NoRevisions" },
                    [_vm._v("\n\t\t\t\t[No Revisions Yet]\n\t\t\t")]
                  ),
                ])
              : _vm._l(_vm.ProductRevisionsList, function (rev, index) {
                  return _c(
                    "div",
                    {
                      key: rev.ProductRevisionId,
                      staticClass: "ProductRevision",
                    },
                    [
                      _c("div", { staticClass: "TreeGraphic" }, [
                        _c("div", { staticClass: "TreeTop" }),
                        _vm._v(" "),
                        index < _vm.ProductRevisionsList.length - 1
                          ? _c("div", { staticClass: "TreeBot" })
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("img", {
                        staticClass: "product-star",
                        attrs: {
                          src: _vm.starImgProductRevisionMap[
                            rev.ProductRevisionId
                          ],
                          alt: "star",
                          role: "button",
                          tabindex: "0",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.toggleStarProductRevision(rev)
                          },
                          keypress: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              ) &&
                              _vm._k($event.keyCode, "space", 32, $event.key, [
                                " ",
                                "Spacebar",
                              ])
                            )
                              return null
                            $event.preventDefault()
                            return _vm.toggleStarProductRevision(rev)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        {
                          staticClass: "ProductRevisionClickable",
                          attrs: { to: _vm.GetRouteToProductRevision(rev) },
                        },
                        [
                          _c("img", {
                            staticClass: "ProductImage",
                            attrs: { src: _vm.productImages[rev.ImageId] },
                          }),
                          _vm._v(" "),
                          _c("span", {
                            staticClass: "ProductRevisionTitle",
                            domProps: {
                              innerHTML: _vm._s(rev.RevisionNameHtml),
                            },
                          }),
                          _vm._v(" "),
                          _c("CmsLifecycleStateBadge", {
                            attrs: { text: rev.LifecycleState, compact: true },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.ProductRevisionsState === 3
      ? _c("div", { staticClass: "ProductRevisions" }, [
          _c("div", { staticClass: "ProductRevision" }, [
            _vm._m(1),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "ProductRevisionClickable FailedToLoad",
                attrs: { role: "button", tabindex: "0" },
                on: {
                  click: _vm.LoadProductRevisions,
                  keypress: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k(
                        $event.keyCode,
                        "enter",
                        13,
                        $event.key,
                        "Enter"
                      ) &&
                      _vm._k($event.keyCode, "space", 32, $event.key, [
                        " ",
                        "Spacebar",
                      ])
                    )
                      return null
                    $event.preventDefault()
                    return _vm.LoadProductRevisions.apply(null, arguments)
                  },
                },
              },
              [
                _c("vsvg", {
                  staticClass: "warningIcon",
                  attrs: { sprite: "warning" },
                }),
                _vm._v(" Failed to load. Click to try again.\n\t\t\t"),
              ],
              1
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "TreeGraphic" }, [
      _c("div", { staticClass: "TreeTop" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "TreeGraphic" }, [
      _c("div", { staticClass: "TreeTop" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }