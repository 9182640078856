<template>
	<div class="LoECreatingPopupRoot">
		<div class="heading">LoE Creation (fxid {{fxid}})</div>
		<div class="imgContainer">
			<img class="book_copy" :src="appPath + 'Images/book_copy.webp'" alt="" role="presentation" />
			<img class="product_img" :src="imageUrl" alt="" role="presentation" />
		</div>
		<div class="loading" v-if="loading">
			<ScaleLoader /> Loading…
		</div>
		<div class="error" v-else-if="error">
			{{error}}
		</div>
		<template v-else-if="loeData">
			<template v-if="!creating">
				<div>When you click "Create", the following procedure is executed:</div>
				<ol>
					<li v-if="loeData.rootLoE">
						The LoE product already exists:
						<router-link :to="GetRouteToProductRoot(loeData.rootLoE.ProductRootId)" v-html="loeData.rootLoE.InternalNameHtml + ' [fxid ' + loeData.rootLoE.TaylorProductId + ']'" target="_blank"></router-link>

					</li>
					<li v-else>Fxid {{fxid}} is copied to create a new product: <span v-html="loeData.loeNameHtml"></span>.</li>
					<li><b>{{loeData.originalAcronym}}</b> files will be copied to <b>{{loeData.loeAcronym}}</b> on the production server.  The CMS's stored archive files are not used for this copy.</li>
					<li>The <b>Product Revision</b> visible behind this dialog will be copied and added to the LoE Product, its Fx/Media archive will begin building, and if this succeeds, the new revision will begin Staging automatically.</li>
					<li>This browser window will be <b>redirected</b> to the new Product Revision.</li>
				</ol>
				<label class="overwriteLabel" title="If you've already created the LoE for this edition before, you will need to check this box in order to allow the CMS to modify files on the production server.">
					<input type="checkbox" v-model="overwrite" :disabled="creating" /> Allow overwriting files for <b>{{loeData.loeAcronym}}</b>
				</label>
				<div class="buttonBar">
					<button class="buttonBarButton green" @click="create" :disabled="creating">Create</button>
				</div>
			</template>
			<img v-if="creating" class="progress_img" :src="appPath + 'Images/ajax-loader-c.gif'" alt="" role="presentation" />
			<p v-if="creating">Please keep this window open.  If you leave, you won't be notified upon success or error.</p>
		</template>
		<div v-else>
			LoE Data failed to load from the CMS server.
		</div>
		<button v-if="!creating" class="buttonBarButton" @click="cancel" :disabled="creating" ref="cancelBtn">Cancel</button>
	</div>
</template>

<script>
	import { ModalMessageDialog } from 'appRoot/scripts/ModalDialog';
	import { CMSGetLoeData, CMSCreateLoeProduct } from 'appRoot/api/CMSUserData';

	export default {
		props:
		{
			fxid: {
				type: Number,
				required: true
			},
			productRevisionId: {
				type: Number,
				required: true
			},
			imageId: {
				type: Number,
				default: 0
			}
		},
		data()
		{
			return {
				appPath: appContext.appPath,
				error: null,
				loading: false,
				loeData: null,
				creating: false,
				inputText: "",
				overwrite: false,
			};
		},
		async created()
		{
			this.loading = true;
			try
			{
				let data = await CMSGetLoeData(this.productRevisionId);
				if (data.success)
				{
					this.loeData = data;
				}
				else
					this.error = data.error;
			}
			catch (ex)
			{
				this.error = ex.message;
			}
			finally
			{
				this.loading = false;
			}
		},
		beforeDestroy()
		{
			window.removeEventListener('beforeunload', this.confirmExit);
		},
		computed:
		{
			imageUrl()
			{
				return appContext.appPath + 'ProductImage/' + this.imageId;
			}
		},
		methods:
		{
			SetFocus()
			{
				if (this.$refs.cancelBtn)
					this.$refs.cancelBtn.focus();
			},
			DefaultClose()
			{
			},
			confirmExit(e)
			{
				try
				{
					e.preventDefault();
				}
				catch (ex) { }
				return true;
			},
			GetRouteToProductRoot(ProductRootId)
			{
				return {
					name: "clientCMSProductRoot",
					params: { id: ProductRootId }
				};
			},
			async create()
			{
				this.creating = true;
				try
				{
					let data = await CMSCreateLoeProduct(this.productRevisionId, this.overwrite);
					if (data.success)
					{
						this.$router.push({
							name: "clientCMSProductRevision",
							params: { id: data.ProductRevisionId }
						});
						this.$emit("close");
					}
					else
						ModalMessageDialog(data.error);
				}
				catch (ex)
				{
					ModalMessageDialog(ex.message);
				}
				finally
				{
					this.creating = false;
				}
			},
			cancel()
			{
				this.$emit("close");
			}
		},
		watch:
		{
			creating(newValue, oldValue)
			{
				if (newValue && !oldValue)
					window.addEventListener('beforeunload', this.confirmExit);
				else
					window.removeEventListener('beforeunload', this.confirmExit);
			}
		}
	}
</script>

<style scoped>
	.LoECreatingPopupRoot
	{
		max-width: 320px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 1em;
	}

	.loading
	{
		margin-top: 10px;
		align-self: center;
	}

	.error
	{
		color: #FF0000;
		font-weight: bold;
	}

	.heading
	{
		align-self: center;
		font-size: 1.6em;
		text-align: center;
		margin-top: 20px;
	}

	.imgContainer
	{
		position: relative;
		align-self: center;
		margin: 0.75em 0px;
	}

	.progress_img
	{
		margin-top: 0.5em;
		align-self: center;
	}

	.book_copy
	{
		width: 256px;
		height: 256px;
	}

	li
	{
		margin-bottom: 0.5em;
	}

	img.product_img
	{
		position: absolute;
		max-width: 60px;
		height: 54px;
		border: 1px solid gray;
		transform: rotate(30deg) skew(329deg, 360deg);
		top: 100px;
		left: 109px;
	}

	input[type="text"]
	{
		width: 100%;
		box-sizing: border-box;
		font-size: 1.5em;
		padding: 0.1em;
	}

	.overwriteLabel
	{
		margin: 0.75em 0px;
	}

	.buttonBar
	{
		align-self: center;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.buttonBarButton
	{
		align-self: center;
	}
</style>