var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "EditField", class: _vm.dynClasses },
    [
      _vm.kind === "checkbox"
        ? _c("label", [
            _c("input", {
              ref: "myInput",
              staticClass: "CheckboxInput",
              attrs: { type: "checkbox" },
              domProps: { checked: _vm.value },
              on: { change: _vm.onChangeValue },
            }),
            _vm._v(" " + _vm._s(_vm.name) + "\n\t"),
          ])
        : [
            _c(
              "div",
              { staticClass: "EditFieldTitle" },
              [
                _vm._v("\n\t\t\t" + _vm._s(_vm.name) + "\n\t\t\t"),
                _vm.download
                  ? _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.contentDataUri,
                          download: _vm.download,
                          title: "download " + _vm.name,
                        },
                      },
                      [
                        _c("vsvg", {
                          staticClass: "download_img",
                          attrs: { sprite: "upload" },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.changed
                  ? _c("vsvg", {
                      staticClass: "resetBtn",
                      attrs: {
                        sprite: "replay",
                        role: "button",
                        tabindex: "0",
                        title: "reset to original value",
                      },
                      on: {
                        click: _vm.resetValue,
                        keypress: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k($event.keyCode, "space", 32, $event.key, [
                              " ",
                              "Spacebar",
                            ]) &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          $event.preventDefault()
                          return _vm.resetValue.apply(null, arguments)
                        },
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.html
                  ? _c(
                      "a",
                      {
                        staticClass: "alwaysUnvisited htmlBtn",
                        attrs: {
                          role: "button",
                          tabindex: "0",
                          title: "view rendered HTML",
                        },
                        on: {
                          click: _vm.renderHtml,
                          keypress: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "space", 32, $event.key, [
                                " ",
                                "Spacebar",
                              ]) &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            $event.preventDefault()
                            return _vm.renderHtml.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v("HTML")]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "EditFieldValue" },
              [
                _vm.kind === "ace_html"
                  ? _c("AceReactiveEditor", {
                      ref: "myInput",
                      attrs: {
                        name: _vm.name,
                        mode: "ace/mode/html",
                        disabled: false,
                        value: _vm.value,
                      },
                      on: { input: _vm.onAceInputValue },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.kind === "textarea"
                  ? _c("textarea", {
                      ref: "myInput",
                      staticClass: "TextArea",
                      domProps: { value: _vm.value },
                      on: {
                        input: _vm.onInputValue,
                        change: _vm.onChangeValue,
                      },
                    })
                  : _vm.kind === "text"
                  ? _c("input", {
                      ref: "myInput",
                      staticClass: "TextInput",
                      attrs: { type: "text" },
                      domProps: { value: _vm.value },
                      on: {
                        input: _vm.onInputValue,
                        change: _vm.onChangeValue,
                      },
                    })
                  : _vm.kind === "select"
                  ? _c(
                      "select",
                      {
                        ref: "myInput",
                        staticClass: "Select",
                        domProps: { value: _vm.value },
                        on: {
                          input: _vm.onInputValue,
                          change: _vm.onChangeValue,
                        },
                      },
                      _vm._l(_vm.options, function (opt, index) {
                        return _c("option", { key: "opt" + index }, [
                          _vm._v(_vm._s(opt)),
                        ])
                      }),
                      0
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _vm.comment
              ? _c("div", { staticClass: "EditFieldComment" }, [
                  _vm._v(_vm._s(_vm.comment)),
                ])
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }