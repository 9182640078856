import Vue from 'vue';
import VueRouter from 'vue-router';
import ExecAPI from 'appRoot/api/api';
import EventBus from 'appRoot/scripts/EventBus';
import { CopyRoute } from 'appRoot/scripts/Util';

import LoginLayout from 'appRoot/vues/login/LoginLayout.vue';
import Login from 'appRoot/vues/login/Login.vue';
import ForgotPassword from 'appRoot/vues/login/ForgotPassword.vue';
import ForgotPasswordReset from 'appRoot/vues/login/ForgotPasswordReset.vue';
import AutoEntry from 'appRoot/vues/login/AutoEntry.vue';

import ClientLayout from 'appRoot/vues/client/ClientLayout.vue';
import ClientStatus from 'appRoot/vues/client/ClientStatus.vue';
import ClientRelease from 'appRoot/vues/client/ClientRelease.vue';
import ClientFileEditor from 'appRoot/vues/client/ClientFileEditor.vue';
import ClientCMSContainer from 'appRoot/vues/client/ClientCMSContainer.vue';
import ClientCMSRoot from 'appRoot/vues/client/ClientCMSRoot.vue';
import ClientCMSProductRoot from 'appRoot/vues/client/ClientCMSProductRoot.vue';
import ClientCMSProductRevision from 'appRoot/vues/client/ClientCMSProductRevision.vue';
import ClientCMSAgentStatus from 'appRoot/vues/client/ClientCMSAgentStatus.vue';
import ClientCMSDataSync from 'appRoot/vues/client/ClientCMSDataSync.vue';
import ClientGraphite from 'appRoot/vues/client/ClientGraphite.vue';
import ClientNotificationsSetup from 'appRoot/vues/client/ClientNotificationsSetup.vue';
import ClientManage from 'appRoot/vues/client/ClientManage.vue';
import ClientServerInfo from 'appRoot/vues/client/ClientServerInfo.vue';
import ChangePassword from 'appRoot/vues/client/ChangePassword.vue';

import AdminLayout from 'appRoot/vues/admin/AdminLayout.vue';
import AdminHome from 'appRoot/vues/admin/AdminHome.vue';
import AdminMonitors from 'appRoot/vues/admin/AdminMonitors.vue';
import AdminSettings from 'appRoot/vues/admin/AdminSettings.vue';
import AdminUsers from 'appRoot/vues/admin/AdminUsers.vue';
import AdminAddAgent from 'appRoot/vues/admin/AdminAddAgent.vue';
import AdminGraphite from 'appRoot/vues/admin/AdminGraphite.vue';
import AdminTest from 'appRoot/vues/admin/AdminTest.vue';
import UserLoginHistory from 'appRoot/vues/admin/UserLoginHistory.vue';

Vue.use(VueRouter);

function safeJsonParse(str)
{
	try
	{
		return JSON.parse(str);
	}
	catch (ex)
	{
		return null;
	}
}
function buildSearchArgsFromRoute(route)
{
	return {
		query: route.query.q,
		matchAll: route.query.matchAll ? route.query.matchAll === "1" : false,
		conditions: route.query.scon ? safeJsonParse(route.query.scon) : null
	};
}

export default function CreateRouter(store, basePath)
{
	// Detect if a custom basePath string is being used by the browser right now.  If not, replace it with the default "/" basePath.
	let addBasePathSpecialRoute = false;
	let basePathLower = basePath.toLowerCase();
	let pathLower = location.pathname.toLowerCase();
	if (!pathLower.startsWith(basePathLower))
	{
		// Our base path is not found at the start of the URL
		// But maybe we have everything except the ending forward slash?
		// E.g. "http://127.0.0.1/basePath"
		if (basePathLower.charAt(basePathLower.length - 1) === '/' && pathLower === basePathLower.substr(0, basePathLower.length - 1))
			addBasePathSpecialRoute = true;
		else
			basePath = "/"; // Nope. Base path is not being used.
	}
	const router = new VueRouter({
		mode: 'history',
		routes: [
			{
				path: basePath + '', component: AutoEntry, name: 'autoEntry', meta: { title: "Redirecting" }
			},
			{
				path: basePath + 'login', component: LoginLayout,
				children: [
					{
						path: '', component: Login, name: 'login', meta: { title: "Login" }
					},
					{
						path: 'forgotPassword', component: ForgotPassword, name: 'forgotPassword',
						props: (route) => ({
							initialUser: route.query.u
						}),
						meta: { title: "Forgot Password" }
					},
					{
						path: 'resetPassword', component: ForgotPasswordReset, name: 'resetPassword',
						props: (route) => ({
							initialUser: route.query.u
						}),
						meta: { title: "Password Recovery" }
					}
				]
			},
			{
				path: basePath + 'client', component: ClientLayout,
				children: [
					{ path: '', redirect: 'status' },
					{
						path: 'status', component: ClientStatus, name: 'clientStatus', meta: {
							title(r)
							{
								return "Status";
							}
						},
						props: (route) => ({
						})
					},
					{
						path: 'release', component: ClientRelease, name: 'clientRelease', meta: {
							title(r)
							{
								return "Release";
							}
						},
						props: (route) => ({
							environmentName: route.query.environmentName,
							releaseName: route.query.releaseName
						})
					},
					{
						path: 'fileEditor/:name', component: ClientFileEditor, name: 'fileEditor', meta: {
							title(r)
							{
								return "File Editor";
							}
						},
						props: (route) => ({
							name: route.params.name
						})
					},
					{
						path: 'cms', component: ClientCMSContainer,
						children: [
							{
								path: '', component: ClientCMSRoot, name: 'clientCMS', meta: { title: "CMS" }
							},
							{
								path: 'rev/:id', component: ClientCMSProductRevision, name: 'clientCMSProductRevision', meta: {
									title(r)
									{
										return "CMS Product Revision";
									}
								},
								props: (route) => ({
									productRevisionId: parseInt(route.params.id)
								})
							},
							{
								path: 'prod/:id', component: ClientCMSProductRoot, name: 'clientCMSProductRoot', meta: {
									title(r)
									{
										return "CMS Product Root";
									}
								},
								props: (route) => ({
									productRootId: parseInt(route.params.id)
								})
							},
							{
								path: 'agent/:id', component: ClientCMSAgentStatus, name: 'clientCMSAgentStatus', meta: {
									title(r)
									{
										return "CMS Agent Status";
									}
								},
								props: (route) => ({
									agentId: route.params.id,
									agentName: route.query.name
								})
							},
							{
								path: 'datasync', component: ClientCMSDataSync, name: 'clientCMSDataSync', meta: {
									title(r)
									{
										return "CMS-Taylor DB Data Sync";
									}
								}
							}
						]
					},
					{
						path: 'graphite', component: ClientGraphite, name: 'clientGraphite', meta: { title(r) { return "Graphite"; } }
					},
					{
						path: 'serverInfo', component: ClientServerInfo, name: 'clientServerInfo', meta: {
							title(r)
							{
								return (r.query && r.query.sn) ? r.query.sn : "Server Info";
							}
						},
						props: (route) => ({
							serverName: route.query && route.query.sn ? route.query.sn : ""
						})
					},
					{
						path: 'notificationsSetup', component: ClientNotificationsSetup, name: 'clientNotificationsSetup', meta: {
							title(r)
							{
								return "Notifications Setup";
							}
						}
					},
					{
						path: 'manage', component: ClientManage, name: 'clientManage', meta: {
							title(r)
							{
								return "Manage User";
							}
						},
						props: (route) => ({
							createPasskey: route.query.createPasskey === "1"
						})
					},
					{
						path: 'changePassword', component: ChangePassword, name: 'changePassword', meta: { title: "Change Password" }
					}
				]
			},
			{
				path: basePath + 'admin', component: AdminLayout,
				children: [
					{ path: '', redirect: 'home' },
					{ path: 'home', component: AdminHome, name: 'adminHome', meta: { title: "Home" } },
					{ path: 'monitors', component: AdminMonitors, name: 'adminMonitors', meta: { title: "Monitors" } },
					{ path: 'settings', component: AdminSettings, name: 'adminSettings', meta: { title: "Settings" } },
					{ path: 'users', component: AdminUsers, name: 'adminUsers', meta: { title: "Users" } },
					{ path: 'addAgent', component: AdminAddAgent, name: 'adminAddAgent', meta: { title: "Add Agent" } },
					{ path: 'graphite', component: AdminGraphite, name: 'adminGraphite', meta: { title: "Graphite" } },
					{ path: 'test', component: AdminTest, name: 'adminTest', meta: { title: "Test" } },
					{
						path: 'userLoginHistory/:userId', component: UserLoginHistory, name: 'adminUserLoginHistory', meta: { title: "User Activity and Logs" }, props: (route) =>
						{
							return { userId: route.params.userId };
						}
					}
				]
			}
		],
		$store: store
	});
	if (addBasePathSpecialRoute)
		router.addRoutes([{ path: basePath.substr(0, basePath.length - 1), redirect: basePath + 'login' }]);
	router.onError(function (error)
	{
		console.error("Error while routing", error);
		toaster.error('Routing Error', error);
	});

	//router.beforeEach((to, from, next) =>
	//{
	//	next();
	//});

	router.afterEach((to, from) =>
	{
		if (to && to.query && to.query.sid)
		{
			let route = CopyRoute(to);
			delete route.query.sid;
			router.replace(route);
			return;
		}
		EventBus.isOnCms = to ? to.path.startsWith('/client/cms/') : false;
		Vue.nextTick(() =>
		{
			// Set the page title. This is being done on next tick in order to ensure that the title is applied to the correct history item in the browser's html5 history stack.
			let titleArr = [];
			let routeSetsOwnTitle = false;
			for (let i = to.matched.length - 1; i >= 0; i--)
			{
				let r = to.matched[i];
				if (typeof r.meta !== 'undefined' && r.meta)
				{
					if (!routeSetsOwnTitle)
						routeSetsOwnTitle = typeof r.meta.setsOwnTitle !== 'undefined' && r.meta.setsOwnTitle;
					if (typeof r.meta.title !== 'undefined')
					{
						if (typeof r.meta.title === 'function')
							titleArr.push(r.meta.title(to));
						else
							titleArr.push(r.meta.title);
					}
				}
			}
			if (titleArr.length === 0 || titleArr[titleArr.length - 1].toLowerCase() !== appContext.systemName.toLowerCase())
				titleArr.push(appContext.systemName);
			if (!routeSetsOwnTitle)
			{
				document.title = titleArr.join(" - ");
			}

			// Check session status
			// If the session is not active, the API framework will redirect us to login automatically.
			if (myApp.$store.state.sid)
			{
				//console.log("Route changed", from, to);
				// We use the route for a lot of UI state, so we should only check session status if the path has changed.
				if (from.path !== to.path)
				{
					ExecAPI("SessionStatus/IsSessionActive").catch(err => { });
				}
			}
		});
	});

	return router;
}