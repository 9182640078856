import ExecAPI from 'appRoot/api/api';

/**
 * Sets the primary email address for the user.
 * @param {String} email new email address
 * @returns {Promise} Promise that delivers the user's email address and array of additional email addresses as stored on the server.
 */
export function SetPrimaryEmail(email)
{
	return ExecAPI("UserManageData/SetPrimaryEmail", { email });
}
/**
 * Sends a test message to the given email address, only if it is one of the user's configured email addresses.
 * @param {String} email email address
 * @returns {Promise} Promise
 */
export function TestEmail(email)
{
	return ExecAPI("UserManageData/TestEmail", { email });
}
/**
 * Returns an object containing user data, including the array of additional email addresses which the user has configured.
 * @returns {Promise} Promise that delivers the user's data.
 */
export function GetUserData()
{
	return ExecAPI("UserManageData/GetUserData");
}
/**
 * Sets the list of additional email addresses which the user has configured.
 * @param {Array} additionalEmails Array of email address strings.
 * @returns {Promise} Promise that delivers the user's email address and array of additional email addresses as stored on the server.
 */
export function SetAdditionalEmails(additionalEmails)
{
	return ExecAPI("UserManageData/SetAdditionalEmails", { additionalEmails });
}
/**
 * Deletes the passkey with the specified Base64Url-encoded id from the user who is logged in with the current session.
 * @param {String} id Base64URL-encoded Passkey Id.
 * @returns {Promise} Promise that resolves when the deletion is complete.
 */
export function DeletePasskey(id)
{
	return ExecAPI("UserManageData/DeletePasskey", { id });
}