<template>
	<div class="cmsContainer">
		<router-view></router-view>
	</div>
</template>

<script>

	export default {
		components: { },
		props:
		{
		},
		data()
		{
			return {
			};
		},
		created()
		{
		},
		computed:
		{
		},
		methods:
		{
		},
		watch:
		{
		}
	}
</script>

<style scoped>
	.cmsContainer
	{
		box-sizing: border-box;
		overflow-x: clip;
		overflow-y: auto;
		width: calc(100vw - 100px);
	}
</style>