<template>
	<div class="CmsValidationResultsDialog" role="alertdialog" aria-labelledby="CmsValidationResultsTitle">
		<div class="titleBar">
			<div class="title" id="CmsValidationResultsTitle" role="alert">CMS Product Information Validation Results</div>
		</div>
		<div class="dialogBody" ref="dialogBody">
			<div class="resultSet" v-for="set in sets" :key="'set_' + set.name">
				<div class="sectionTitle">{{set.list.length}} {{set.name}}</div>
				<div class="result" v-for="(item, index) in set.list" :key="'item_' + set.name + '_' + index">
					<div class="location">
						In
						<router-link v-if="item.ProductRevisionId" :to="GetProductRevisionRoute(item.ProductRevisionId)">ProductRevision {{item.ProductRevisionId}}</router-link>
						<router-link v-else :to="GetProductRootRoute(item.ProductRootId)">ProductRoot {{item.ProductRootId}}</router-link>
						-
						<a :href="GetFxidLink(item.TaylorProductId)" class="alwaysUnvisited">fxid {{item.TaylorProductId}}</a>
					</div>
					<div class="message">{{item.Message}}</div>
				</div>
			</div>
			<div class="comment">* All links open in a new tab</div>
		</div>
	</div>
</template>

<script>

	export default {
		components: {},
		props:
		{
			results: {
				type: Object,
				required: true
			}
		},
		data()
		{
			return {
			};
		},
		created()
		{
		},
		computed:
		{
			sets()
			{
				return [
					{ list: this.results.errors, name: "Errors" },
					{ list: this.results.warnings, name: "Warnings" }
				];
			}
		},
		methods:
		{
			SetFocus()
			{
				this.$refs.dialogBody.focus();
			},
			DefaultClose()
			{
				this.$emit('close', {});
			},
			GetProductRootRoute(productRootId)
			{
				return {
					name: "clientCMSProductRoot",
					params: { id: productRootId }
				};
			},
			GetProductRevisionRoute(productRevisionId)
			{
				return {
					name: "clientCMSProductRevision",
					params: { id: productRevisionId }
				};
			},
			GetFxidLink(fxid)
			{
				return "https://online.statref.com/admin/titles/titlesinformation.aspx?AddTitle=False&TitleID=" + fxid;
			}
		},
		watch:
		{
		}
	}
</script>

<style scoped>
	.CmsValidationResultsDialog
	{
		background-color: #FFFFFF;
		box-shadow: rgba(0,0,0,0.7) 2px 9px 20px 7px;
		min-width: 300px;
		max-width: calc(90vw - 50px);
		word-break: break-word;
		word-wrap: break-word;
	}

	.titleBar
	{
		background-color: #FFFFFF;
		padding: 8px 14px;
		box-sizing: border-box;
	}

	.title
	{
		text-align: center;
		color: black;
		font-weight: bold;
		font-size: 16pt;
	}

	.dialogBody
	{
		padding: 4px 16px 16px 16px;
	}

	.sectionTitle
	{
		font-size: 1.2em;
		font-weight: bold;
		margin: 1em 0px 0.5em 0px;
	}

	.result
	{
		margin-bottom: 1em;
	}

	.location
	{
		margin-bottom: 0.25em;
	}

	.message
	{
		margin-left: 1em;
	}

	.comment
	{
		font-style: italic;
		color: #006600;
	}
</style>