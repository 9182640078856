var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "activeSessionListRoot" }, [
    _vm.error
      ? _c("div", [_vm._v(_vm._s(_vm.error))])
      : _vm.loading
      ? _c(
          "div",
          { staticClass: "loading" },
          [_c("ScaleLoader"), _vm._v(" Loading…")],
          1
        )
      : _vm.rows
      ? _c(
          "div",
          { staticClass: "recordsTableContainer" },
          [
            _c("vue-good-table", {
              attrs: { columns: _vm.columns, rows: _vm.rows },
            }),
            _vm._v(" "),
            typeof _vm.unauthenticatedSessionCount !== "undefined"
              ? _c(
                  "p",
                  {
                    attrs: {
                      title:
                        "The number of sessions which have been created for password login but have not yet been authenticated.  A high number could indicate a brute force attack in progress.",
                    },
                  },
                  [
                    _vm._v(
                      "Unauthenticated Session Count: " +
                        _vm._s(_vm.unauthenticatedSessionCount)
                    ),
                  ]
                )
              : _vm._e(),
          ],
          1
        )
      : _c("div", [_vm._v("\n\t\tNo data.\n\t")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }