<template>
	<div :class="{ statusGraphWrapper: true, hasLabels: labels }">
		<div class="statusGraph">
			<canvas class="graphCanvas" ref="graphCanvas">HTML5 canvas is not supported</canvas>
			<template v-if="labels">
				<div class="axisLabel xAxisLeftLabel">{{xAxisLeftLabel}}</div>
				<div class="axisLabel xAxisRightLabel">{{xAxisRightLabel}}</div>
				<div class="axisLabel yAxisTopLabel">{{yAxisTopLabel}}</div>
				<div class="axisLabel yAxisBottomLabel">{{yAxisBottomLabel}}</div>
			</template>
		</div>
	</div>
</template>

<script>
	import EventBus from 'appRoot/scripts/EventBus';
	import { throttle, GetDevicePixelRatio } from 'appRoot/scripts/Util';

	export default {
		components: {},
		props:
		{
			jobStatus: {
				type: Array,
				required: true
			},
			minValue: {
				type: Number,
				default: 0
			},
			maxValue: {
				type: Number,
				default: 100
			},
			hoveredJob: {
				type: Object,
				default: null
			},
			labels: {
				type: Boolean,
				default: false
			},
		},
		data()
		{
			return {
				xAxisLeftLabel: "",
				xAxisRightLabel: "",
				yAxisTopLabel: "",
				yAxisBottomLabel: "",
				drawGraph: null
			};
		},
		created()
		{
			this.drawGraph = throttle(this.drawGraph_internal, 33);
		},
		mounted()
		{
			this.ResizeCanvas();
		},
		beforeDestroy()
		{
		},
		computed:
		{
			windowW()
			{
				return EventBus.windowWidth;
			},
			windowH()
			{
				return EventBus.windowHeight;
			}
		},
		methods:
		{
			drawGraph_internal()
			{
				let canvas = this.$refs.graphCanvas;
				if (!canvas)
					return;
				let ctx = canvas.getContext("2d");

				// Find boundaries
				let minTs = Number.MAX_SAFE_INTEGER;
				let maxTs = Number.MIN_SAFE_INTEGER;
				let minValue = this.minValue;
				let maxValue = this.maxValue;
				let allJobs = this.jobStatus;
				if (this.hoveredJob)
				{
					let hoveredJobIsIncluded = false;
					for (let n = 0; n < allJobs.length; n++)
					{
						if (allJobs[n].Name === this.hoveredJob.Name)
						{
							hoveredJobIsIncluded = true;
							break;
						}
					}
					if (!hoveredJobIsIncluded)
						allJobs = [this.hoveredJob];
				}
				for (let n = 0; n < allJobs.length; n++)
				{
					let job = allJobs[n];
					for (let i = 0; i < job.Data.length; i++)
					{
						let d = job.Data[i];
						if (d.Timestamp < minTs)
							minTs = d.Timestamp;
						if (d.Timestamp > maxTs)
							maxTs = d.Timestamp;
						//if (d.Value < minValue)
						//	minValue = d.Value;
						if (d.Value > maxValue)
							maxValue = d.Value;
					}
				}
				this.xAxisLeftLabel = new Date(minTs).toLocaleTimeString();
				this.xAxisRightLabel = new Date(maxTs).toLocaleTimeString();
				this.yAxisTopLabel = maxValue;
				this.yAxisBottomLabel = minValue;
				//let tsMult = TsRange / canvasW;

				// Draw all lines
				ctx.clearRect(0, 0, canvas.width, canvas.height);
				for (let n = 0; n < allJobs.length; n++)
				{
					if (!this.hoveredJob || allJobs[n].Name !== this.hoveredJob.Name)
						this.drawJob(allJobs[n], canvas, ctx, minTs, maxTs, minValue, maxValue);
				}
				if (this.hoveredJob)
					this.drawJob(this.hoveredJob, canvas, ctx, minTs, maxTs, minValue, maxValue);
			},
			drawJob(job, canvas, ctx, minTs, maxTs, minValue, maxValue)
			{
				let canvasW = canvas.width;
				let canvasH = canvas.height;
				let isHoveredJob = this.hoveredJob && job.Name === this.hoveredJob.Name;
				if (job.Data.length === 0)
				{
					if (isHoveredJob || this.jobStatus.length === 1)
					{
						let fontSizePx = 36;
						if (canvasW < 100)
							fontSizePx = 11;
						ctx.font = "bold " + fontSizePx + "px sans-serif";
						ctx.fillStyle = "rgba(0,0,0,1)";
						let text = ctx.measureText('NO DATA');
						text.height = text.actualBoundingBoxAscent + text.actualBoundingBoxDescent;
						if (text.height <= 0)
							text.height = fontSizePx;
						let x = (canvas.width / 2) - (text.width / 2);
						let y = (canvas.height / 2) + (text.height / 2);

						ctx.fillRect(x - 2, y - text.height - 8, text.width + 4, text.height + 16);

						ctx.fillStyle = "rgba(255,128,0,1)";
						ctx.fillText("NO DATA", x, y);
					}
					return;
				}
				let TsRange = maxTs - minTs;
				let ValueRange = maxValue - minValue;
				ctx.beginPath();
				let x = 0;
				let y = 0;
				if (isHoveredJob)
				{
					ctx.fillStyle = "rgba(0,255,0,0.25)";
					ctx.strokeStyle = "#00FF00";
					ctx.lineWidth = 2;
				}
				else
				{
					ctx.fillStyle = "rgba(0,0,0," + Math.min(0.1, 1 / (this.jobStatus.length * 1.2)) + ")";
					ctx.strokeStyle = "#000000";
					ctx.lineWidth = 1;
				}
				let leftmostX = 0;
				let lastX = 0;
				let lastValue = 0;
				for (let i = 0; i < job.Data.length; i++)
				{
					let d = job.Data[i];
					let ts = d.Timestamp;
					let value = d.Value;
					let badZone = value < 0;
					if (badZone)
						value = lastValue;

					if (TsRange === 0)
						x = 0;
					else
						x = ((ts - minTs) / TsRange) * canvasW;

					if (ValueRange === 0)
						y = canvasH / 2;
					else
						y = canvasH - (((value - minValue) / ValueRange) * canvasH);

					if (i === 0)
					{
						leftmostX = x;
						ctx.moveTo(leftmostX - 3, y);
					}
					ctx.lineTo(x, y);
					if (badZone)
					{
						if (job.Data.length === 1)
							x = canvasW;
						this.drawRedRect(ctx, this.jobStatus.length === 1 || isHoveredJob ? 1 : 0.1, lastX, 0, x - lastX, canvasH);
					}
					lastX = x;
					lastValue = value;
				}
				ctx.lineTo(canvasW + 3, y);
				ctx.lineTo(canvasW + 3, canvasH + 3);
				ctx.lineTo(leftmostX - 3, canvasH + 3);
				ctx.closePath();
				ctx.fill();
				ctx.stroke();
			},
			drawRedRect(ctx, opacity, x, y, w, h)
			{
				let saveFillStyle = ctx.fillStyle;
				ctx.fillStyle = "rgba(255,0,0," + opacity + ")";
				ctx.fillRect(x, y, w, h);
				ctx.fillStyle = saveFillStyle;
			},
			ResizeCanvas(isUserInteracting)
			{
				let canvas = this.$refs.graphCanvas;
				if (canvas)
				{
					let dpr = GetDevicePixelRatio();
					canvas.width = canvas.offsetWidth * dpr;
					canvas.height = canvas.offsetHeight * dpr;
					if (isUserInteracting)
						this.drawGraph_internal();
					else
						this.drawGraph();
				}
			}
		},
		watch:
		{
			jobStatus: {
				handler(newValue, oldValue)
				{
					this.drawGraph();
				},
				deep: true
			},
			hoveredJob()
			{
				this.drawGraph();
			},
			windowW()
			{
				this.ResizeCanvas(true);
			},
			windowH()
			{
				this.ResizeCanvas(true);
			}
		}
	}
</script>

<style scoped>
	.statusGraphWrapper
	{
		box-sizing: border-box;
		position: relative;
		background-color: #FFFFFF;
		height: 100%;
	}

		.statusGraphWrapper.hasLabels
		{
			padding-right: 51px;
			padding-bottom: 24px;
		}

	.statusGraph
	{
		border: 1px solid #000000;
		position: relative;
		height: 100%;
	}

		.statusGraph.hasLabels
		{
			margin-right: 35px;
			margin-bottom: 24px;
		}

	.graphCanvas
	{
		width: 100%;
		height: 100%;
	}

	.axisLabel
	{
		font-size: 12px;
		position: absolute;
		background-color: #FFFFFF;
		/*text-shadow: -1px -1px 0 #FFFFFF, 1px -1px 0 #FFFFFF, -1px 1px 0 #FFFFFF, 1px 1px 0 #FFFFFF;*/
	}

	.xAxisLeftLabel
	{
		top: 100%;
		left: 2px;
		margin-top: 1px;
	}

	.xAxisRightLabel
	{
		top: 100%;
		right: 2px;
		margin-top: 1px;
	}

	.yAxisTopLabel
	{
		top: 1px;
		left: 100%;
		margin-left: 2px;
	}

	.yAxisBottomLabel
	{
		bottom: 2px;
		left: 100%;
		margin-left: 2px;
	}
</style>