var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "prop" }, [
    _c(
      "div",
      { staticClass: "propWrapper" },
      [
        _vm.spec.isReadonly
          ? _c("div", { staticClass: "propLabel" }, [
              _c("span", {
                domProps: { innerHTML: _vm._s(_vm.spec.labelHtml) },
              }),
              _vm._v(": " + _vm._s(_vm.myValue)),
            ])
          : [
              _vm.spec.inputType !== "checkbox"
                ? _c("label", {
                    staticClass: "propLabel",
                    attrs: { for: _vm.inputId },
                    domProps: { innerHTML: _vm._s(_vm.spec.labelHtml) },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.spec.inputType === "range"
                ? _c("span", [_vm._v(": " + _vm._s(_vm.myValue))])
                : _vm._e(),
              _vm._v(" "),
              _vm.spec.inputType === "select"
                ? _c("div", [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.myValue,
                            expression: "myValue",
                          },
                        ],
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.myValue = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                            _vm.onChange,
                          ],
                        },
                      },
                      _vm._l(_vm.spec.enumValues, function (v) {
                        return _c("option", { key: v }, [_vm._v(_vm._s(v))])
                      }),
                      0
                    ),
                  ])
                : _vm.spec.inputType === "array"
                ? _c(
                    "div",
                    { staticClass: "arrayEditor" },
                    [
                      _vm._l(_vm.arrayValues, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "arrayElement" },
                          [
                            _vm.spec.allowedValues
                              ? _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: item.value,
                                        expression: "item.value",
                                      },
                                    ],
                                    on: {
                                      change: [
                                        function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.$set(
                                            item,
                                            "value",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                        _vm.onChange,
                                      ],
                                    },
                                  },
                                  _vm._l(_vm.spec.allowedValues, function (v) {
                                    return _c("option", { key: v }, [
                                      _vm._v(_vm._s(v)),
                                    ])
                                  }),
                                  0
                                )
                              : _vm.spec.arrayType === "checkbox"
                              ? _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: item.value,
                                      expression: "item.value",
                                    },
                                  ],
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(item.value)
                                      ? _vm._i(item.value, null) > -1
                                      : item.value,
                                  },
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$a = item.value,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                item,
                                                "value",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                item,
                                                "value",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(item, "value", $$c)
                                        }
                                      },
                                      _vm.onChange,
                                    ],
                                  },
                                })
                              : _vm.spec.arrayType === "radio"
                              ? _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: item.value,
                                      expression: "item.value",
                                    },
                                  ],
                                  attrs: { type: "radio" },
                                  domProps: {
                                    checked: _vm._q(item.value, null),
                                  },
                                  on: {
                                    change: [
                                      function ($event) {
                                        return _vm.$set(item, "value", null)
                                      },
                                      _vm.onChange,
                                    ],
                                  },
                                })
                              : _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: item.value,
                                      expression: "item.value",
                                    },
                                  ],
                                  attrs: { type: _vm.spec.arrayType },
                                  domProps: { value: item.value },
                                  on: {
                                    change: _vm.onChange,
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        item,
                                        "value",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                            _vm._v(" "),
                            _c("input", {
                              attrs: { type: "button", value: "-" },
                              on: {
                                click: function ($event) {
                                  return _vm.removeArrayItem(index)
                                },
                              },
                            }),
                          ]
                        )
                      }),
                      _vm._v(" "),
                      _c("div", [
                        _c("input", {
                          attrs: { type: "button", value: "+" },
                          on: { click: _vm.addArrayItem },
                        }),
                      ]),
                    ],
                    2
                  )
                : [
                    _vm.spec.inputType === "checkbox"
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.myValue,
                              expression: "myValue",
                            },
                          ],
                          ref: "inputEle",
                          staticClass: "propInput",
                          attrs: {
                            id: _vm.inputId,
                            min: _vm.spec.min,
                            max: _vm.spec.max,
                            type: "checkbox",
                          },
                          domProps: {
                            checked: Array.isArray(_vm.myValue)
                              ? _vm._i(_vm.myValue, null) > -1
                              : _vm.myValue,
                          },
                          on: {
                            change: [
                              function ($event) {
                                var $$a = _vm.myValue,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 && (_vm.myValue = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.myValue = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.myValue = $$c
                                }
                              },
                              _vm.onChange,
                            ],
                            dblclick: _vm.onDoubleClick,
                          },
                        })
                      : _vm.spec.inputType === "radio"
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.myValue,
                              expression: "myValue",
                            },
                          ],
                          ref: "inputEle",
                          staticClass: "propInput",
                          attrs: {
                            id: _vm.inputId,
                            min: _vm.spec.min,
                            max: _vm.spec.max,
                            type: "radio",
                          },
                          domProps: { checked: _vm._q(_vm.myValue, null) },
                          on: {
                            change: [
                              function ($event) {
                                _vm.myValue = null
                              },
                              _vm.onChange,
                            ],
                            dblclick: _vm.onDoubleClick,
                          },
                        })
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.myValue,
                              expression: "myValue",
                            },
                          ],
                          ref: "inputEle",
                          staticClass: "propInput",
                          attrs: {
                            id: _vm.inputId,
                            min: _vm.spec.min,
                            max: _vm.spec.max,
                            type: _vm.spec.inputType,
                          },
                          domProps: { value: _vm.myValue },
                          on: {
                            change: _vm.onChange,
                            dblclick: _vm.onDoubleClick,
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.myValue = $event.target.value
                            },
                          },
                        }),
                    _vm._v(" "),
                    _vm.spec.inputType === "checkbox"
                      ? _c("label", {
                          staticClass: "checkboxLabel",
                          attrs: { for: _vm.inputId },
                          domProps: { innerHTML: _vm._s(_vm.spec.labelHtml) },
                        })
                      : _vm._e(),
                  ],
            ],
      ],
      2
    ),
    _vm._v(" "),
    _vm.spec.helpHtml
      ? _c("div", {
          staticClass: "help",
          domProps: { innerHTML: _vm._s(_vm.spec.helpHtml) },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }