<template>
	<div class="EditField" :class="dynClasses">
		<label v-if="kind === 'checkbox'">
			<input ref="myInput" type="checkbox" class="CheckboxInput" :checked="value" @change="onChangeValue" /> {{name}}
		</label>
		<template v-else>
			<div class="EditFieldTitle">
				{{name}}
				<a v-if="download" :href="contentDataUri" :download="download" :title="'download ' + name"><vsvg sprite="upload" class="download_img" /></a>
				<vsvg v-if="changed" sprite="replay" class="resetBtn" role="button" tabindex="0" @click="resetValue" @keypress.space.enter.prevent="resetValue" title="reset to original value" />
				<a v-if="html" class="alwaysUnvisited htmlBtn" role="button" tabindex="0" @click="renderHtml" @keypress.space.enter.prevent="renderHtml" title="view rendered HTML">HTML</a>
			</div>
			<div class="EditFieldValue">
				<AceReactiveEditor v-if="kind === 'ace_html'" ref="myInput" :name="name" mode="ace/mode/html" :disabled="false" :value="value" @input="onAceInputValue" />
				<textarea v-if="kind === 'textarea'" ref="myInput" class="TextArea" :value="value" @input="onInputValue" @change="onChangeValue"></textarea>
				<input v-else-if="kind === 'text'" ref="myInput" type="text" class="TextInput" :value="value" @input="onInputValue" @change="onChangeValue" />
				<select v-else-if="kind === 'select'" ref="myInput" class="Select" :value="value" @input="onInputValue" @change="onChangeValue">
					<option v-for="(opt, index) in options" :key="'opt' + index">{{opt}}</option>
				</select>
			</div>
			<div class="EditFieldComment" v-if="comment">{{comment}}</div>
		</template>
	</div>
</template>

<script>
	import svg1 from 'appRoot/images/sprite/replay.svg';
	import svg2 from 'appRoot/images/sprite/upload.svg';
	import { ModalHtmlMessageDialog } from 'appRoot/scripts/ModalDialog';
	import AceReactiveEditor from 'appRoot/vues/common/controls/AceReactiveEditor.vue';
	import { throttle, Clamp } from 'appRoot/scripts/Util';

	export default {
		components: { AceReactiveEditor },
		props:
		{
			value: null,
			orig: null,
			name: {
				type: String,
				required: true
			},
			kind: {
				type: String,
				default: "text"
			},
			multiLine: { // If false (default), line breaks will be stripped from inputs.
				type: Boolean,
				default: false
			},
			options: {
				type: Array,
				default: () => []
			},
			download: {
				type: String,
				default: ""
			},
			downloadType: {
				type: String,
				default: "application/octet-stream"
			},
			comment: {
				type: String,
				default: ""
			},
			html: { // If true, a link will be shown next to the title to view the content as rendered HTML
				type: Boolean,
				default: false
			},
		},
		data()
		{
			return {
				textareaResizeObserver: null,
				onResizeThrottled: null,
			};
		},
		created()
		{
			this.onResizeThrottled = throttle(() => this.onResizeTextArea(), 250);
		},
		mounted()
		{
			if (this.kind === "textarea")
			{
				if (localStorage)
				{
					let size = parseInt(localStorage[this.localStorageKey]);
					if (size && !isNaN(size))
					{
						//console.log("loading textarea size:", size);
						this.$refs.myInput.style.height = Clamp(size, 10, 2000) + "px";
					}
					this.textareaResizeObserver = new ResizeObserver(this.onResizeThrottled);
					this.textareaResizeObserver.observe(this.$refs.myInput);
				}
			}
		},
		beforeDestroy()
		{
			if (this.textareaResizeObserver)
			{
				this.textareaResizeObserver.disconnect();
				this.textareaResizeObserver = null;
			}
		},
		computed:
		{
			changed()
			{
				return this.orig !== this.value;
			},
			dynClasses()
			{
				if (this.changed)
					return "changed";
				else
					return "";
			},
			localStorageKey()
			{
				if (this.kind === "textarea")
					return "cms_ta_size_" + this.name.replace(/[ \/\(\)\[\]:;'"<>,.\\]/g, '_');
				return undefined;
			},
			contentDataUri()
			{
				if (this.download)
				{
					return "data:" + this.downloadType + "," + encodeURIComponent(this.value);
				}
				return "";
			}
		},
		methods:
		{
			onInputValue()
			{
				if (this.$refs.myInput)
				{
					let val;
					if (this.kind === "checkbox")
						val = this.$refs.myInput.checked;
					else
					{
						val = this.$refs.myInput.value;
						if (!this.multiLine)
						{
							let val2 = val.replace(/\r|\n/g, '');
							if (val !== val2)
								this.$refs.myInput.value = val = val2;
						}
					}
					this.$emit('input', val);
				}
			},
			onChangeValue()
			{
				this.onInputValue();
			},
			onAceInputValue(val)
			{
				if (!this.multiLine)
				{
					let val2 = val.replace(/\r|\n/g, '');
					if (val !== val2)
						val = val2;
				}
				this.$emit('input', val);
			},
			resetValue()
			{
				if (this.$refs.myInput)
				{
					this.$refs.myInput.value = this.orig;
					this.onInputValue();
				}
				else
				{
					//console.log("CmsEditField.resetValue() called but myInput is undefined.");
					this.$emit('input', this.orig);
				}
			},
			onResizeTextArea()
			{
				if (!this.textareaResizeObserver)
				{
					console.error("CmsEditField.onResizeTextArea called without ResizeObserver being defined.", this);
					return;
				}
				if (!this.$refs.myInput)
				{
					//console.log("CmsEditField is disconnecting the ResizeObserver.");
					this.textareaResizeObserver.disconnect();
					this.textareaResizeObserver = null;
					return;
				}
				//console.log("CmsEditField textarea resized:", this.$refs.myInput.offsetHeight, this.localStorageKey);
				localStorage[this.localStorageKey] = this.$refs.myInput.offsetHeight;
			},
			renderHtml()
			{
				ModalHtmlMessageDialog(this.value, this.name, { customDialogClasses: "publicSiteHtml" });
			}
		},
		watch:
		{
		}
	}
</script>

<style>
	/* unscoped */
	.publicSiteHtml
	{
		font-size: 14px;
		line-height: 1.71429;
		color: #4b4b4b;
	}

		.publicSiteHtml .messageText
		{
			white-space: normal;
		}

		.publicSiteHtml p
		{
			margin: 0;
			margin-top: 20px;
		}

		.publicSiteHtml ul,
		.publicSiteHtml ol
		{
			display: block;
			list-style: none;
			padding: 0;
			margin: 0;
		}

			.publicSiteHtml ul li,
			.publicSiteHtml ol li
			{
				display: block;
				padding: 0;
				margin: 0;
			}
</style>

<style scoped>
	.TextArea
	{
		width: 100% !important;
		height: 200px;
		box-sizing: border-box;
	}

	.TextInput
	{
		min-width: 100%;
		max-width: 100%;
		width: 100%;
		box-sizing: border-box;
	}

	.Select
	{
		width: 100%;
		box-sizing: border-box;
	}

	.download_img
	{
		fill: currentColor;
		height: 1em;
		width: 1em;
		vertical-align: text-bottom;
		transform: rotate(180deg);
	}

	.resetBtn
	{
		width: 18px;
		height: 18px;
		vertical-align: text-top;
		cursor: pointer;
	}

		.resetBtn:hover,
		.resetBtn:focus
		{
			background-color: rgba(0,0,0,0.2);
			outline: 1px solid rgba(0,0,255,0.5);
		}

	.EditFieldComment
	{
		opacity: 0.6;
		font-size: 0.9em;
	}

	.htmlBtn
	{
		text-decoration: underline;
		vertical-align: top;
		font-size: 0.7em;
	}
</style>