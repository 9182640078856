var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "SavableRegion" }, [
    _c("div", { staticClass: "SavableContent" }, [_vm._t("default")], 2),
    _vm._v(" "),
    _vm.saving
      ? _c("div", { staticClass: "SavableOverlay" }, [
          _c(
            "div",
            { staticClass: "SavingProgress" },
            [
              _c("ScaleLoader"),
              _vm._v(" "),
              _c("br"),
              _vm._v("\n\t\t\t" + _vm._s(_vm.text) + "\n\t\t"),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }