<template>
	<div class="productImageOptimizerRoot">
		<div class="heading">Select an Image Variant</div>
		<div v-if="error" class="error">{{error}}</div>
		<div v-else-if="loading" class="loading"><ScaleLoader /> Loading…</div>
		<div v-else-if="rows">
			<div class="scaledImagesCollection">
				<table>
					<thead>
						<tr>
							<th>Name</th>
							<th>No Border</th>
							<th>Yes Border</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(row, index) in rows" :key="index">
							<td>{{row.Name}}</td>
							<td><ImageOptimizerImage v-if="row.NoBorder" :img="row.NoBorder" @select="onImageSelect" :selected="selectedImage === row.NoBorder" /></td>
							<td><ImageOptimizerImage v-if="row.Border" :img="row.Border" @select="onImageSelect" :selected="selectedImage === row.Border" /></td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="buttonBar">
				<button class="buttonBarButton green" @click="AssignSelected()">
					<span>Assign Selected</span>
				</button>
				<button class="buttonBarButton" @click="DefaultClose()" ref="cancelButton">
					<span>Cancel Image Assignment</span>
				</button>
			</div>
		</div>
		<div v-else>
			No data.
		</div>
	</div>
</template>

<script>
	import ImageOptimizerImage from 'appRoot/vues/client/controls/ImageOptimizerImage.vue';
	import { ScaleImage } from 'appRoot/api/ImageScaler';

	export default {
		components: { ImageOptimizerImage },
		props:
		{
			imageBase64: {
				type: String,
				required: true
			}
		},
		data()
		{
			return {
				error: null,
				loading: false,
				rows: [],
				selectedImage: null
			};
		},
		created()
		{
			this.loadImages();
		},
		computed:
		{
			allImages()
			{
				let arr = [];
				if (this.rows)
				{
					for (let i = 0; i < this.rows.length; i++)
					{
						let row = this.rows[i];
						if (row.NoBorder)
							arr.push(row.NoBorder);
						if (row.Border)
							arr.push(row.Border);
					}
				}
				return arr;
			}
		},
		methods:
		{
			async loadImages()
			{
				this.loading = true;
				try
				{
					if (this.imageBase64.length > 5000000)
					{
						this.error = "Input image is too large at " + this.imageBase64.length + " base64 chars. Please choose a smaller image.";
						return;
					}
					let result = await ScaleImage(this.imageBase64);

					if (result.success)
					{
						this.rows = result.Rows;
					}
					else
						this.error = result.error;
				}
				catch (ex)
				{
					this.error = ex.message;
				}
				finally
				{
					this.loading = false;
				}
			},
			onImageSelect(arg)
			{
				this.selectedImage = arg.img;
			},
			SetFocus()
			{
				if (this.$refs.cancelButton)
					this.$refs.cancelButton.focus();
			},
			DefaultClose()
			{
				this.$emit('close', {});
			},
			AssignSelected()
			{
				for (let i = 0; i < this.allImages.length; i++)
				{
					if (this.allImages[i] === this.selectedImage)
					{
						return this.$emit('close', { image: this.allImages[i] });
					}
				}
			},
		}
	}
</script>

<style scoped>
	.productImageOptimizerRoot
	{
	}

	.heading
	{
		font-size: 1.6em;
		text-align: center;
		margin: 20px;
		margin-bottom: 1em;
	}

	.loading
	{
		text-align: center;
	}

	.error,
	.loading,
	.scaledImagesCollection
	{
		margin: 20px;
	}

	.scaledImagesCollection
	{
		display: flex;
		flex-wrap: wrap;
	}

	.buttonBar
	{
		position: sticky;
		bottom: 0px;
		padding-bottom: 8px;
		background-color: #FFFFFF;
		box-shadow: 0px -1px 5px 1px rgba(0,0,0,1);
		text-align: center;
	}
</style>