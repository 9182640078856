var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "CmsValidationResultsDialog",
      attrs: {
        role: "alertdialog",
        "aria-labelledby": "CmsValidationResultsTitle",
      },
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { ref: "dialogBody", staticClass: "dialogBody" },
        [
          _vm._l(_vm.sets, function (set) {
            return _c(
              "div",
              { key: "set_" + set.name, staticClass: "resultSet" },
              [
                _c("div", { staticClass: "sectionTitle" }, [
                  _vm._v(_vm._s(set.list.length) + " " + _vm._s(set.name)),
                ]),
                _vm._v(" "),
                _vm._l(set.list, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: "item_" + set.name + "_" + index,
                      staticClass: "result",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "location" },
                        [
                          _vm._v("\n\t\t\t\t\tIn\n\t\t\t\t\t"),
                          item.ProductRevisionId
                            ? _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: _vm.GetProductRevisionRoute(
                                      item.ProductRevisionId
                                    ),
                                  },
                                },
                                [
                                  _vm._v(
                                    "ProductRevision " +
                                      _vm._s(item.ProductRevisionId)
                                  ),
                                ]
                              )
                            : _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: _vm.GetProductRootRoute(
                                      item.ProductRootId
                                    ),
                                  },
                                },
                                [
                                  _vm._v(
                                    "ProductRoot " + _vm._s(item.ProductRootId)
                                  ),
                                ]
                              ),
                          _vm._v("\n\t\t\t\t\t-\n\t\t\t\t\t"),
                          _c(
                            "a",
                            {
                              staticClass: "alwaysUnvisited",
                              attrs: {
                                href: _vm.GetFxidLink(item.TaylorProductId),
                              },
                            },
                            [_vm._v("fxid " + _vm._s(item.TaylorProductId))]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "message" }, [
                        _vm._v(_vm._s(item.Message)),
                      ]),
                    ]
                  )
                }),
              ],
              2
            )
          }),
          _vm._v(" "),
          _c("div", { staticClass: "comment" }, [
            _vm._v("* All links open in a new tab"),
          ]),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "titleBar" }, [
      _c(
        "div",
        {
          staticClass: "title",
          attrs: { id: "CmsValidationResultsTitle", role: "alert" },
        },
        [_vm._v("CMS Product Information Validation Results")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }