import Vue from 'vue';
/////////////////////////////////////////
// READ THIS BEFORE IMPORTING ANYTHING //
/////////////////////////////////////////
// EventBus is a vue component that is created when this file is imported.
//
// Circular dependencies can create a complex situation where a dependency imported by EventBus.js is not loaded before the EventBus component is created.

// If you need to import something here, make sure that EventBus.js is imported very early in the application initialization.  A good place would be just after importing babel-polyfill (which should typically be the first thing imported).
/////////////////////////////////////////

import { ProgressDialog } from 'appRoot/scripts/ModalDialog';
import { GetAllProductImages } from 'appRoot/api/CMSUserData';
import { CmsEventStream, CmsKey } from 'appRoot/scripts/CMSEventStream';

/**
 * This object can be used to provide "global" event handling.  Based on https://alligator.io/vuejs/global-event-bus/
 * Import the EventBus and use EventBus.$on('eventName', …), EventBus.$off('eventName', …), and EventBus.$emit('EventName', …)
 * Or depending on your need, simply create reactive data using the EventBus data property.
 * The EventBus is good for things that need to be shared between components with fast reactivity and should not be persisted between page reloads.
 * EventBus is orders of magnitude faster than the vuex store.
 */
const EventBus = new Vue({
	data:
	{
		windowWidth: -1,
		windowHeight: -1,
		pageXOffset: 0,
		pageYOffset: 0,
		mouseX: 0,
		mouseY: 0,
		mouseClientX: 0,
		mouseClientY: 0,
		topNavHeight: 0,
		controlBarHeight: 0,
		footerHeight: 0,
		editorMenuBarHeight: 0,
		tooltipHtml: "", // HTML shown in a tooltip that follows the cursor.
		mouseUps: 0, // Counter of mouse up events at the document level.
		pushNotificationsAvailable: false, // True if all preconditions for push notification registration have been met.
		notificationPermission: "default", // The latest value of Notification.permission. May be "default", "granted", "denied"
		pushSubscription: null, // Push notification subscription object. null if the browser is not registered for push.
		mobileLayoutMediaQuery: null,
		mobileLayout: false,
		aceEditorCounter: 0,
		productImageData: { rootIdMap: {}, imageMap: {} },
		browserInstanceId: sessionStorage.myGuid,
		cmsEventStream: new CmsEventStream(),
		cmsConsoleConnected: false,
		tamStatus: null, // TitleArchiveManager status
		contentSyncStatus: null, // Status of ContentSync (shows what each Agent is doing related to CMS archive or metadata deployment or installation).
		loeStatus: null, // Status of LoE creation
		productRootMap: {}, // Map of Product Root instances keyed by ProductRootId, assigned a new map each time the CMS homepage is loaded.
		myStarredProductRootIds: {},
		myStarredProductRevisions: {},
		isOnCms: false
	},
	created()
	{
		if (!sessionStorage.myGuid)
			sessionStorage.myGuid = appContext.guid;
		this.browserInstanceId = sessionStorage.myGuid;

		let ss = sessionStorage.getItem("et_eb_undoStack");
		if (ss)
			this.undoStack = JSON.parse(ss);
		ss = sessionStorage.getItem("et_eb_redoStack");
		if (ss)
			this.redoStack = JSON.parse(ss);

		window.addEventListener('resize', this.OnResize);
		window.addEventListener('scroll', this.OnScroll);
		document.addEventListener('mousemove', this.OnMouseMove);
		document.addEventListener('mouseup', this.OnMouseUp);
		this.OnResize();

		if (window.matchMedia)
		{
			this.mobileLayoutMediaQuery = window.matchMedia("(min-width: 600px)");
			this.mobileLayoutMediaQuery.addListener(this.onMobileLayoutChanged);
			this.onMobileLayoutChanged(this.mobileLayoutMediaQuery);
		}

		document.addEventListener("CmsConsoleConnected", e => this.cmsConsoleConnected = true);
		document.addEventListener("CmsConsoleDisconnected", e => this.cmsConsoleConnected = false);
		this.cmsConsoleConnected = this.cmsEventStream.isConnected();

		this.tamStatus = this.cmsEventStream.get(CmsKey.CmsTAMStatus);
		document.addEventListener(CmsKey.CmsTAMStatus, e => { this.tamStatus = e.detail; });

		this.contentSyncStatus = this.cmsEventStream.get(CmsKey.CmsContentSyncStatus);
		document.addEventListener(CmsKey.CmsContentSyncStatus, e => { this.contentSyncStatus = e.detail; });

		this.loeStatus = this.cmsEventStream.get(CmsKey.CmsLoEStatus);
		document.addEventListener(CmsKey.CmsLoEStatus, e => { this.loeStatus = e.detail; });
	},
	beforeDestroy()
	{
		window.removeEventListener('resize', this.OnResize);
		window.removeEventListener('scroll', this.OnScroll);
		document.removeEventListener('mousemove', this.OnMouseMove);
		document.removeEventListener('mouseup', this.OnMouseUp);

		if (window.matchMedia)
		{
			this.mobileLayoutMediaQuery.removeListener(this.onMobileLayoutChanged);
		}
	},
	computed:
	{
		routeQueryExpand()
		{
			let expand = myApp.$route.query.expand;
			if (!expand)
				expand = "";
			return expand;
		},
		expandedProductRootIdsArray()
		{
			let arr = [];
			let idStrings = this.routeQueryExpand.split(',');
			for (let i = 0; i < idStrings.length; i++)
			{
				let id = parseInt(idStrings[i]);
				if (!isNaN(id))
					arr.push(id);
			}
			return arr;
		},
		expandedProductRootIdsMap()
		{
			let map = {};
			let arr = this.expandedProductRootIdsArray;
			for (let i = 0; i < arr.length; i++)
				map[arr[i]] = true;
			return map;
		},
		verticalConsumers()
		{
			return this.topNavHeight + this.footerHeight + this.editorMenuBarHeight;
		}
	},
	methods:
	{
		OnResize(event)
		{
			this.windowWidth = window.innerWidth;
			this.windowHeight = window.innerHeight;
			this.topNavHeight = MeasureHeightOfId("topNav");
			this.controlBarHeight = MeasureHeightOfId("controlBar");
			this.footerHeight = MeasureHeightOfId("appFooter");
			this.editorMenuBarHeight = MeasureHeightOfId("editorMenuBar");
		},
		OnScroll(event)
		{
			this.pageXOffset = window.pageXOffset;
			this.pageYOffset = window.pageYOffset;
		},
		OnMouseMove(event)
		{
			this.mouseX = event.pageX;
			this.mouseY = event.pageY;
			this.mouseClientX = event.clientX;
			this.mouseClientY = event.clientY;
		},
		OnMouseUp(event)
		{
			this.mouseUps++;
		},
		stopMovingItem()
		{
			if (this.movingItem)
			{
				this.movingItem = null;
				this.tooltipHtml = "";
			}
		},
		onMobileLayoutChanged(mediaQuery)
		{
			this.mobileLayout = !mediaQuery.matches;
		},
		LoadProductImages()
		{
			GetAllProductImages()
				.then(result =>
				{
					if (result.success)
					{
						this.productImageData = { rootIdMap: result.productRootIdToImageIdMap, imageMap: result.productImages };
					}
					else
						toaster.error(result.error);
				})
				.catch(err =>
				{
					toaster.error(err);
				});
		}
	}
});
function MeasureHeightOfId(id)
{
	let ele = document.getElementById(id);
	if (ele)
		return ele.offsetHeight;
	return 0;
}
window.appEventBus = EventBus; // Handle for debugging
export default EventBus;