<template>
	<div class="CmsProductLinksDialog" role="alertdialog" aria-labelledby="CmsProductLinksTitle">
		<div class="titleBar">
			<div class="title" id="CmsProductLinksTitle" role="alert">Fxid {{fxid}} Product Links</div>
		</div>
		<div class="dialogBody">
			<div class="sectionTitle">TDS Links</div>
			<div class="serverChoice">Server Choice:</div>
			<div class="serverOptions">
				<div class="LinkContainer">
					<label class="onlineServerBtn">
						<input type="radio" name="serverChoiceRadioGroup" value="0" v-model="serverChoice">
						Online (Live) Server
					</label>
				</div>
				<div class="LinkContainer">
					<label class="productionServerBtn">
						<input type="radio" name="serverChoiceRadioGroup" value="1" v-model="serverChoice">
						Production Server
					</label>
				</div>
			</div>
			<div class="spacer"></div>
			<div :class="'linksFor' + serverChoice">
				<div class="LinkContainer">
					<a :href="FrontMatterLink" class="alwaysUnvisited" ref="frontMatterLink" target="_blank">
						Client &gt; Front Matter
					</a>
				</div>
				<div class="spacer"></div>
				<div class="LinkContainer">
					<a :href="StatusLink" class="alwaysUnvisited" target="_blank">
						Admin &gt; Status
					</a>
				</div>
				<div class="LinkContainer">
					<a :href="InformationLink" class="alwaysUnvisited" target="_blank">
						Admin &gt; Information
					</a>
				</div>
				<div class="LinkContainer">
					<a :href="DisplayLink" class="alwaysUnvisited" target="_blank">
						Admin &gt; Display
					</a>
				</div>
				<div class="LinkContainer">
					<a :href="BibliographyLink" class="alwaysUnvisited" target="_blank">
						Admin &gt; Bibliography
					</a>
				</div>
				<div class="LinkContainer">
					<a :href="TitleInfoCategoriesLink" class="alwaysUnvisited" target="_blank">
						Admin &gt; <b>TitleInfo Categories</b>
					</a>
				</div>
				<div class="LinkContainer">
					<a :href="ProductCategoriesLink" class="alwaysUnvisited" target="_blank">
						Admin &gt; <b>STAT!Ref Categories</b>
					</a>
				</div>
				<div class="LinkContainer">
					<a :href="SubscriptionsLink" class="alwaysUnvisited" target="_blank">
						Admin &gt; Subscriptions
					</a>
				</div>
			</div>
			<div class="spacer"></div>
			<div class="LinkContainer">
				<a :href="PublicSiteTitleInfoLink" class="alwaysUnvisited" target="_blank">
					tetondata.com/TitleInfo?id={{fxid}}
				</a>
			</div>
			<div class="spacer"></div>
			<div class="sectionTitle">OpenLibrary Metadata Search</div>
			<div class="LinkContainer">
				<a :href="OpenLibrarySearch(rootNameTxt)" class="alwaysUnvisited" target="_blank">
					{{rootNameTxt}}
				</a>
			</div>
			<div class="LinkContainer" v-if="revNameTxt && revNameTxt !== rootNameTxt">
				<a :href="OpenLibrarySearch(revNameTxt)" class="alwaysUnvisited" target="_blank">
					{{revNameTxt}}
				</a>
			</div>
			<div class="LinkContainer" v-for="(oclc, index) in oclcs" :key="'search_oclc_' + index">
				<a :href="OpenLibrarySearch(oclc)" class="alwaysUnvisited" target="_blank">
					OCLC: {{oclc}}
				</a>
			</div>
			<div class="LinkContainer" v-for="(isbn, index) in isbns" :key="'search_isbn_' + index">
				<a :href="OpenLibrarySearch(isbn)" class="alwaysUnvisited" target="_blank">
					ISBN: {{isbn}}
				</a>
			</div>
			<template v-if="oclcs.length || isbns.length">
				<div class="spacer"></div>
				<div class="sectionTitle">OpenLibrary Cover Search</div>
				<div class="LinkContainer">
					<label>
						<input type="checkbox" v-model="loadImages" />
						Load Images Inline
					</label>
				</div>
				<div class="LinkContainer" v-for="(oclc, index) in oclcs" :key="'cover_oclc_' + index">
					<a :href="OpenLibraryCoverLink('oclc', oclc)" class="alwaysUnvisited" target="_blank">
						OCLC: {{oclc}}
						<template v-if="loadImages">
							<br />
							<img :src="OpenLibraryCoverLink('oclc', oclc)" alt="product image from openlibrary" class="productImage" />
						</template>
					</a>
				</div>
				<div class="LinkContainer" v-for="(isbn, index) in isbns" :key="'cover_isbn_' + index">
					<a :href="OpenLibraryCoverLink('isbn', isbn)" class="alwaysUnvisited" target="_blank">
						ISBN: {{isbn}}
						<template v-if="loadImages">
							<br />
							<img :src="OpenLibraryCoverLink('isbn', isbn)" alt="product image from openlibrary" class="productImage" />
						</template>
					</a>
				</div>
			</template>
			<div class="spacer"></div>
			<div class="comment">* All links open in a new tab</div>
		</div>
	</div>
</template>

<script>
	import { HTMLToText } from 'appRoot/scripts/Util';

	export default {
		components: { },
		props:
		{
			productRoot: {
				type: Object,
				required: true
			},
			productRevision: {
				type: Object,
				default: null
			}
		},
		data()
		{
			return {
				serverChoice: "0",
				loadImages: true
			};
		},
		created()
		{
			let storedServerChoice = localStorage.cms_product_links_server_choice;
			if (storedServerChoice === "0" || storedServerChoice === "1")
				this.serverChoice = storedServerChoice;
			let storedLoadImages = localStorage.cms_product_links_load_images_inline;
			if (storedLoadImages === "0")
				this.loadImages = false;
			else if (storedLoadImages === "1")
				this.loadImages = true;
		},
		computed:
		{
			fxid()
			{
				return this.productRoot.TaylorProductId;
			},
			baseLink()
			{
				if (this.serverChoice === "1")
					return "https://production_serv.statref.com/";
				else
					return "https://online.statref.com/";
			},
			adminBaseLink()
			{
				return this.baseLink + "admin/";
			},
			FrontMatterLink()
			{
				return this.baseLink + "p/" + this.fxid;
			},
			StatusLink()
			{
				return this.adminBaseLink + "titles/titlesstatus.aspx?AddTitle=False&TitleID=" + this.fxid
			},
			InformationLink()
			{
				return this.adminBaseLink + "titles/titlesinformation.aspx?AddTitle=False&TitleID=" + this.fxid
			},
			DisplayLink()
			{
				return this.adminBaseLink + "titles/titledisplay.aspx?AddTitle=False&TitleID=" + this.fxid
			},
			BibliographyLink()
			{
				return this.adminBaseLink + "titles/titlesbibliography.aspx?AddTitle=False&TitleID=" + this.fxid
			},
			TitleInfoCategoriesLink()
			{
				return this.adminBaseLink + "titles/TitleInfoCategories.aspx?AddTitle=False&TitleID=" + this.fxid
			},
			ProductCategoriesLink()
			{
				return this.adminBaseLink + "titles/TitleMyCategories.aspx?AddTitle=False&TitleID=" + this.fxid
			},
			SubscriptionsLink()
			{
				return this.adminBaseLink + "titles/TitlesSubscriptions.aspx?AddTitle=False&TitleID=" + this.fxid
			},
			PublicSiteTitleInfoLink()
			{
				return "https://www.tetondata.com/TitleInfo?id=" + this.fxid;
			},
			rootNameTxt()
			{
				return HTMLToText(this.productRoot.InternalNameHtml);
			},
			revNameTxt()
			{
				if (this.productRevision)
					return HTMLToText(this.productRevision.RevisionNameHtml);
				return null;
			},
			oclcs()
			{
				return this.GetBibRecords('OCLC Number');
			},
			isbns()
			{
				return this.GetBibRecords(['ISBN-10', 'ISBN-13', 'EISBN-13']);
			}
		},
		methods:
		{
			SetFocus()
			{
				this.$refs.frontMatterLink.focus();
			},
			DefaultClose()
			{
				this.$emit('close', {});
			},
			GetBibRecords(types)
			{
				if (typeof types === "string")
					types = [types];
				let arr = [];
				if (this.productRevision && this.productRevision.Bibliography)
				{
					for (let i = 0; i < this.productRevision.Bibliography.length; i++)
					{
						let record = this.productRevision.Bibliography[i];
						if (types.indexOf(record.Type) !== -1)
							arr.push(record.Value.trim());
					}
				}
				return arr;
			},
			OpenLibrarySearch(query)
			{
				return "https://openlibrary.org/search?q=" + encodeURIComponent(query) + "&mode=everything";
			},
			OpenLibraryCoverLink(name, id)
			{
				return "https://covers.openlibrary.org/b/" + name + "/" + id + "-L.jpg"
			}
		},
		watch:
		{
			serverChoice()
			{
				localStorage.cms_product_links_server_choice = this.serverChoice;
			},
			loadImages()
			{
				localStorage.cms_product_links_load_images_inline = this.loadImages ? "1" : "0";
			}
		}
	}
</script>

<style scoped>
	.CmsProductLinksDialog
	{
		background-color: #FFFFFF;
		box-shadow: rgba(0,0,0,0.7) 2px 9px 20px 7px;
		min-width: 300px;
		max-width: calc(100vw - 50px);
		word-break: break-word;
		word-wrap: break-word;
	}

	.titleBar
	{
		background-color: #FFFFFF;
		padding: 8px 14px;
		box-sizing: border-box;
	}

	.title
	{
		text-align: center;
		color: black;
		font-weight: bold;
		font-size: 16pt;
	}

	.dialogBody
	{
		padding: 4px 16px 16px 16px;
	}

	.sectionTitle
	{
		font-size: 1.2em;
		font-weight: bold;
		margin: 0.25em 0px 0.5em 0px;
	}

	.LinkContainer a,
	.LinkContainer label
	{
		padding: 0.5em;
		display: block;
	}

		.LinkContainer a:hover,
		.LinkContainer label:hover
		{
			background-color: rgba(0,0,0,0.1);
		}

	.spacer
	{
		height: 1em;
	}

	.comment
	{
		font-style: italic;
		color: #006600;
	}

	.onlineServerBtn
	{
		color: var(--link-color);
	}

	.linksFor1 a.alwaysUnvisited,
	.productionServerBtn
	{
		color: #FF3D00;
	}

	.productImage
	{
		margin-left: 1em;
		max-width: 100px;
		max-height: 100px;
	}
</style>