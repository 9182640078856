var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: {
        pageRoot: true,
        inTrash: _vm.productRoot && _vm.productRoot.Trash,
      },
    },
    [
      _vm.error
        ? _c("div", { staticClass: "error SidePadding" }, [
            _c("h2", [
              _vm._v("CMS Product Revision " + _vm._s(_vm.productRevisionId)),
            ]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.error))]),
          ])
        : _vm.loading && !_vm.productRevision
        ? _c("div", { staticClass: "loading SidePadding" }, [
            _c("h2", [
              _vm._v("CMS Product Revision " + _vm._s(_vm.productRevisionId)),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "center" },
              [_c("ScaleLoader"), _vm._v(" Loading…")],
              1
            ),
          ])
        : _vm.productRevision
        ? _c(
            "SavableRegion",
            {
              attrs: {
                saving: _vm.saving || _vm.loading,
                text: _vm.loading ? "Updating" : "Saving",
              },
            },
            [
              _c(
                "div",
                { staticClass: "stickyTop", class: _vm.stickyTopDynClasses },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "backButton",
                      attrs: {
                        to: _vm.GetRouteToProductRoot(_vm.productRoot),
                        title: "Open Root Product",
                      },
                    },
                    [_c("vsvg", { attrs: { sprite: "expand_more" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c("h3", { staticClass: "name" }, [
                    _c("img", {
                      staticClass: "product-star",
                      attrs: {
                        src: _vm.starImgProductRevisionMap[
                          _vm.productRevisionId
                        ],
                        alt: "star",
                        role: "button",
                        tabindex: "0",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.toggleStarProductRevision(
                            _vm.productRevision
                          )
                        },
                        keypress: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            ) &&
                            _vm._k($event.keyCode, "space", 32, $event.key, [
                              " ",
                              "Spacebar",
                            ])
                          )
                            return null
                          $event.preventDefault()
                          return _vm.toggleStarProductRevision(
                            _vm.productRevision
                          )
                        },
                      },
                    }),
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(_vm.productRevision.RevisionNameHtml),
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "SaveButton",
                      class: { disabled: !_vm.hasChanges },
                      on: { click: _vm.saveRevision },
                    },
                    [_vm._v("Save")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "FloatingBelow" },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "alwaysUnvisited",
                          attrs: { role: "button", tabindex: "0" },
                          on: {
                            click: _vm.OpenCmsProductLinksDialog,
                            keypress: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                ) &&
                                _vm._k(
                                  $event.keyCode,
                                  "space",
                                  32,
                                  $event.key,
                                  [" ", "Spacebar"]
                                )
                              )
                                return null
                              $event.preventDefault()
                              return _vm.OpenCmsProductLinksDialog.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [
                          _vm._v(
                            "fxid " + _vm._s(_vm.productRoot.TaylorProductId)
                          ),
                        ]
                      ),
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.currentUsersHtml ? "-" : "") +
                          "\n\t\t\t\t"
                      ),
                      _vm.pageStateUnavailable
                        ? _c("vsvg", {
                            staticClass: "page_state_unavailable_img",
                            attrs: {
                              sprite: "cable",
                              title:
                                "This icon means the current page state could not be loaded. Some status indicators may be stale.",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("span", {
                        domProps: { innerHTML: _vm._s(_vm.currentUsersHtml) },
                      }),
                      _vm._v(" "),
                      _vm.recordChangedServerside
                        ? _c("div", [_vm._v("Record Changed Serverside!")])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "SidePadding" },
                [
                  _vm.productRoot.Trash
                    ? _c("div", { staticClass: "trashContainer" }, [
                        _c(
                          "div",
                          { staticClass: "trash" },
                          [
                            _c("vsvg", {
                              staticClass: "trash_img",
                              attrs: { sprite: "delete" },
                            }),
                            _vm._v(
                              " This product is in the trash, making it inaccessible to customers."
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("CmsLifecycleState", {
                    attrs: { productRevision: _vm.productRevision },
                    on: { change: _vm.onLifecycleStateChange },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "TopFields" }, [
                    _c(
                      "div",
                      {
                        staticClass: "EditField ProductImageEditField",
                        class: { changed: _vm.imageChanged },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "EditFieldTitle" },
                          [
                            _vm._v("\n\t\t\t\t\t\tProduct Image "),
                            _c(
                              "a",
                              {
                                staticClass: "openImgLink",
                                attrs: {
                                  href: _vm.productImgSrc,
                                  download:
                                    _vm.productRevisionImageDownloadName,
                                  title: "download image",
                                },
                              },
                              [
                                _c("vsvg", {
                                  staticClass: "download_img",
                                  attrs: { sprite: "upload" },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.imageChanged
                              ? _c("vsvg", {
                                  staticClass: "resetBtn",
                                  attrs: {
                                    sprite: "replay",
                                    role: "button",
                                    tabindex: "0",
                                    title: "undo pending image change",
                                  },
                                  on: {
                                    click: _vm.resetProductImage,
                                    keypress: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "space",
                                          32,
                                          $event.key,
                                          [" ", "Spacebar"]
                                        ) &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      )
                                        return null
                                      $event.preventDefault()
                                      return _vm.resetProductImage.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "EditFieldValue" },
                          [
                            _c("SelectImageControl", {
                              staticClass: "ProductImage",
                              attrs: {
                                imageSrc: _vm.productImgSrc,
                                title: "Upload a different image",
                              },
                              on: { selected: _vm.newImageSelected },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "EditField FxMediaField",
                        class: { assigned: _vm.archiveFileName },
                      },
                      [
                        _c(
                          "SavableRegion",
                          {
                            attrs: {
                              saving:
                                _vm.savingFlags || _vm.archivePendingOperation,
                              text: "Working",
                            },
                          },
                          [
                            _c("div", { staticClass: "EditFieldTitle" }, [
                              _vm._v("Fx/Media"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "EditFieldValue" },
                              [
                                _vm.archiveFileName
                                  ? [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(_vm.archiveFileName) +
                                            _vm._s(
                                              _vm.archiveFileSize
                                                ? " (" +
                                                    _vm.archiveFileSize +
                                                    ")"
                                                : ""
                                            )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _vm.archiveFileDate
                                        ? _c("div", [
                                            _vm._v(_vm._s(_vm.archiveFileDate)),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.productRevision.LifecycleState ==
                                      "Upcoming"
                                        ? [
                                            _c("div", [
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "buttonBarButton DeleteArchiveFileButton",
                                                  attrs: {
                                                    title:
                                                      "Deletes the title archive assigned to this revision.",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.deleteArchiveFile()
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("vsvg", {
                                                    staticClass: "publish_img",
                                                    attrs: {
                                                      sprite: "delete_forever",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v("Delete"),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ]),
                                          ]
                                        : _vm._e(),
                                    ]
                                  : [
                                      !_vm.productRoot.FileName
                                        ? [
                                            _c("div", [
                                              _vm._v(
                                                "ProductRoot needs a FileName assigned."
                                              ),
                                            ]),
                                          ]
                                        : _vm.archivePending
                                        ? [
                                            _c(
                                              "div",
                                              { staticClass: "center" },
                                              [_c("ScaleLoader")],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c("div", [
                                              _vm._v(
                                                "Pending. See console below."
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("div", [
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "buttonBarButton CancelArchiveFileButton",
                                                  attrs: {
                                                    title:
                                                      "Attempts to cancel creation of this archive.",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.cancelArchiveFile()
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("vsvg", {
                                                    staticClass: "publish_img",
                                                    attrs: { sprite: "cancel" },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v("Cancel"),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ]),
                                          ]
                                        : [
                                            _c("div", [
                                              _vm._v("None Assigned"),
                                            ]),
                                            _vm._v(" "),
                                            _vm.productRevision
                                              .LifecycleState == "Upcoming"
                                              ? [
                                                  _c(
                                                    "div",
                                                    [
                                                      _c("slide-button", {
                                                        key: "sb1",
                                                        ref: "slideBtn1",
                                                        staticClass:
                                                          "slideToStage",
                                                        attrs: {
                                                          "auto-width": false,
                                                          circle: true,
                                                          disabled: false,
                                                          noanimate: false,
                                                          width: 235,
                                                          height: 28,
                                                          requireReleaseToComplete: true,
                                                          text: "Archive & Stage",
                                                          "success-text":
                                                            "starting…",
                                                          title:
                                                            "Assigns a new title archive to this revision built from Fx/Media currently on production_serv, then puts this revision into the 'Staging' lifecycle state.",
                                                        },
                                                        on: {
                                                          completed: function (
                                                            $event
                                                          ) {
                                                            return _vm.addArchiveFile(
                                                              true
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              : _vm._e(),
                                          ],
                                    ],
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    attrs: {
                                      title:
                                        "Automatically set this Revision to 'Live' when all Agents in the Online environment finish staging.",
                                    },
                                  },
                                  [
                                    _c("label", [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.revisionAutomationFlags
                                                .LiveAfterStagingOnlineCompleted,
                                            expression:
                                              "revisionAutomationFlags.LiveAfterStagingOnlineCompleted",
                                          },
                                        ],
                                        attrs: { type: "checkbox" },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.revisionAutomationFlags
                                              .LiveAfterStagingOnlineCompleted
                                          )
                                            ? _vm._i(
                                                _vm.revisionAutomationFlags
                                                  .LiveAfterStagingOnlineCompleted,
                                                null
                                              ) > -1
                                            : _vm.revisionAutomationFlags
                                                .LiveAfterStagingOnlineCompleted,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a =
                                                _vm.revisionAutomationFlags
                                                  .LiveAfterStagingOnlineCompleted,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.revisionAutomationFlags,
                                                    "LiveAfterStagingOnlineCompleted",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.revisionAutomationFlags,
                                                    "LiveAfterStagingOnlineCompleted",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.revisionAutomationFlags,
                                                "LiveAfterStagingOnlineCompleted",
                                                $$c
                                              )
                                            }
                                          },
                                        },
                                      }),
                                      _vm._v(" Automatically go Live"),
                                    ]),
                                  ]
                                ),
                              ],
                              2
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("CmsEditField", {
                    attrs: {
                      orig: _vm.orig.RevisionNameHtml,
                      name: "Revision Name (Html Supported)",
                      comment: "©®™",
                    },
                    model: {
                      value: _vm.productRevision.RevisionNameHtml,
                      callback: function ($$v) {
                        _vm.$set(_vm.productRevision, "RevisionNameHtml", $$v)
                      },
                      expression: "productRevision.RevisionNameHtml",
                    },
                  }),
                  _vm._v(" "),
                  _c("CmsEditField", {
                    attrs: {
                      orig: _vm.orig.Marc21Record,
                      name: "Marc21Record",
                      kind: "textarea",
                      download: _vm.marc21RecordFileName,
                      downloadType: "application/marc; charset=utf-8",
                    },
                    model: {
                      value: _vm.productRevision.Marc21Record,
                      callback: function ($$v) {
                        _vm.$set(_vm.productRevision, "Marc21Record", $$v)
                      },
                      expression: "productRevision.Marc21Record",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "EditField BibliographySection" }, [
                    _c("div", { staticClass: "EditFieldTitle" }, [
                      _vm._v("Bibliography"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "EditFieldValue" },
                      [
                        _c("BibliographyEditor", {
                          attrs: { rev: _vm.productRevision },
                        }),
                        _vm._v(" "),
                        _vm.isbnValidationResults
                          ? _c(
                              "div",
                              { staticClass: "bibWarnings" },
                              _vm._l(
                                _vm.isbnValidationResults,
                                function (w, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: "isbnWarning_" + index,
                                      staticClass: "bibWarning",
                                    },
                                    [_vm._v(_vm._s(w))]
                                  )
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.bibWarnings && _vm.bibWarnings.length
                          ? _c(
                              "div",
                              { staticClass: "bibWarnings" },
                              _vm._l(_vm.bibWarnings, function (w, index) {
                                return _c(
                                  "div",
                                  {
                                    key: "bibWarning_" + index,
                                    staticClass: "bibWarning",
                                  },
                                  [_vm._v("• " + _vm._s(w))]
                                )
                              }),
                              0
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "PublicSiteFields" },
                    [
                      _c("div", { staticClass: "PublicSiteFieldsLabel" }, [
                        _vm._v("Public Site Fields"),
                      ]),
                      _vm._v(" "),
                      _c("CmsEditField", {
                        attrs: {
                          orig: _vm.orig.PublicMetadata.DisplayTitle,
                          name: "Display Title (optional)",
                          title:
                            "The title to display on the public-facing title info page. If omitted, the displayed title will come from the Bibliography tab.  If that is also omitted, the title will come from the Information tab.",
                        },
                        model: {
                          value:
                            _vm.productRevision.PublicMetadata.DisplayTitle,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.productRevision.PublicMetadata,
                              "DisplayTitle",
                              $$v
                            )
                          },
                          expression:
                            "productRevision.PublicMetadata.DisplayTitle",
                        },
                      }),
                      _vm._v(" "),
                      _c("CmsEditField", {
                        attrs: {
                          orig: _vm.orig.PublicMetadata.SubTitle,
                          name: "Display Subtitle (optional)",
                          title:
                            "The subtitle to display near/below the title on the public-facing title info page.",
                        },
                        model: {
                          value: _vm.productRevision.PublicMetadata.SubTitle,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.productRevision.PublicMetadata,
                              "SubTitle",
                              $$v
                            )
                          },
                          expression: "productRevision.PublicMetadata.SubTitle",
                        },
                      }),
                      _vm._v(" "),
                      _c("CmsEditField", {
                        attrs: {
                          orig: _vm.orig.PublisherName,
                          name: "Simple Publisher",
                          kind: "select",
                          options: _vm.publisherTypes,
                          title:
                            "This is mainly for categorizing the title in 'Titles by Publisher' lists. Leave blank to assign no publisher.",
                        },
                        model: {
                          value: _vm.productRevision.PublisherName,
                          callback: function ($$v) {
                            _vm.$set(_vm.productRevision, "PublisherName", $$v)
                          },
                          expression: "productRevision.PublisherName",
                        },
                      }),
                      _vm._v(" "),
                      _c("CmsEditField", {
                        attrs: {
                          orig: _vm.orig.PublicMetadata.Copyright,
                          name: "Display Copyright (optional)",
                          title:
                            "The copyright text to use in the bibliography section on the public-facing title info page.",
                        },
                        model: {
                          value: _vm.productRevision.PublicMetadata.Copyright,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.productRevision.PublicMetadata,
                              "Copyright",
                              $$v
                            )
                          },
                          expression:
                            "productRevision.PublicMetadata.Copyright",
                        },
                      }),
                      _vm._v(" "),
                      _c("CmsEditField", {
                        attrs: {
                          orig: _vm.orig.PublicMetadata.HtmlDescription,
                          name: "Description (HTML Supported)",
                          kind: "ace_html",
                          multiLine: true,
                          html: true,
                          title:
                            "Use full HTML formatting, including <p> tags.",
                        },
                        model: {
                          value:
                            _vm.productRevision.PublicMetadata.HtmlDescription,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.productRevision.PublicMetadata,
                              "HtmlDescription",
                              $$v
                            )
                          },
                          expression:
                            "productRevision.PublicMetadata.HtmlDescription",
                        },
                      }),
                      _vm._v(" "),
                      _c("CmsEditField", {
                        attrs: {
                          orig: _vm.orig.PublicMetadata.MetadataDescription,
                          name: "Metadata Description",
                          kind: "textarea",
                          title:
                            "A shorter plain text description to be inserted in the description meta tag.  If not provided, a snippet of the full description may be used instead.",
                        },
                        model: {
                          value:
                            _vm.productRevision.PublicMetadata
                              .MetadataDescription,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.productRevision.PublicMetadata,
                              "MetadataDescription",
                              $$v
                            )
                          },
                          expression:
                            "productRevision.PublicMetadata.MetadataDescription",
                        },
                      }),
                      _vm._v(" "),
                      _c("CmsEditField", {
                        attrs: {
                          orig: _vm.orig.PublicMetadata.Keywords,
                          name: "Keywords",
                          kind: "textarea",
                        },
                        model: {
                          value: _vm.productRevision.PublicMetadata.Keywords,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.productRevision.PublicMetadata,
                              "Keywords",
                              $$v
                            )
                          },
                          expression: "productRevision.PublicMetadata.Keywords",
                        },
                      }),
                      _vm._v(" "),
                      _c("CmsEditField", {
                        attrs: {
                          orig: _vm.orig.PublicMetadata
                            .TitleInformationPageURLOverride,
                          name: "Title Information Page URL Override (optional)",
                          title:
                            "If specified, this URL will replace the dynamically generated title info page.",
                        },
                        model: {
                          value:
                            _vm.productRevision.PublicMetadata
                              .TitleInformationPageURLOverride,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.productRevision.PublicMetadata,
                              "TitleInformationPageURLOverride",
                              $$v
                            )
                          },
                          expression:
                            "productRevision.PublicMetadata.TitleInformationPageURLOverride",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "CmsConfigFields" },
                    [
                      _c("div", { staticClass: "CmsConfigFieldsLabel" }, [
                        _vm._v("Internal Configuration (ProductRevision)"),
                      ]),
                      _vm._v(" "),
                      _c("CmsEditField", {
                        attrs: {
                          orig: _vm.orig.CmsConfig.Comment,
                          name: "Internal notes for this ProductRevision",
                          title:
                            "A comment field for internal documentation or note-taking purposes.  For example, it could talk about the production schedule for this Revision or explain why something about it is unusual.",
                          kind: "textarea",
                          multiLine: true,
                        },
                        model: {
                          value: _vm.productRevision.CmsConfig.Comment,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.productRevision.CmsConfig,
                              "Comment",
                              $$v
                            )
                          },
                          expression: "productRevision.CmsConfig.Comment",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "buttonBar" }, [
                    _c(
                      "button",
                      {
                        staticClass: "buttonBarButton",
                        attrs: {
                          title:
                            "We will ask for confirmation before deleting.",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.onDeleteRevision()
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "btnIconNoHover",
                          attrs: {
                            src: _vm.appPath + "Images/delete.png",
                            alt: "",
                            role: "presentation",
                          },
                        }),
                        _vm._v(" "),
                        _c("span", [_vm._v("Delete This Revision")]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "buttonBarButton",
                        on: {
                          click: function ($event) {
                            _vm.showHistory = !_vm.showHistory
                          },
                        },
                      },
                      [
                        _c("vsvg", { attrs: { sprite: "history" } }),
                        _vm._v(
                          " " +
                            _vm._s(_vm.showHistory ? "Hide" : "Show") +
                            " History\n\t\t\t\t"
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "buttonBarButton",
                        attrs: { disabled: _vm.hasChanges },
                        on: {
                          click: function ($event) {
                            return _vm.createLoeFromThisRevision()
                          },
                        },
                      },
                      [
                        _c("vsvg", { attrs: { sprite: "add" } }),
                        _vm._v(" Create LoE\n\t\t\t\t"),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "historySelectorContainer" },
                    [
                      _vm.showHistory
                        ? _c("CmsProductHistorySelector", {
                            attrs: {
                              type: "ProductRevision",
                              id: _vm.productRevisionId,
                            },
                            on: { select: _vm.HistoryItemSelected },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }