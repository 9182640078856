var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pageRoot" }, [
    _vm.error
      ? _c("div", { staticClass: "error" }, [_vm._v(_vm._s(_vm.error))])
      : _vm.loading
      ? _c(
          "div",
          { staticClass: "loading" },
          [_c("ScaleLoader"), _vm._v(" Loading…")],
          1
        )
      : _c(
          "div",
          [
            _c(
              "div",
              {
                class: {
                  webSocketStatus: true,
                  connected: _vm.isConnected,
                  notConnected: !_vm.isConnected,
                },
                attrs: {
                  title: _vm.isConnected
                    ? "WebSocket is connected. Health status is streaming into browser."
                    : "WebSocket is not connected!",
                },
              },
              [
                _c("vsvg", {
                  staticClass: "webSocketIcon",
                  attrs: { sprite: "cable" },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "overallHealth" }, [
              _c("span", { style: _vm.percentHealthStyle }, [
                _vm._v(_vm._s(Math.floor(_vm.overallHealth * 100)) + "%"),
              ]),
              _vm._v(" Overall Health"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "statusGraphLabel" }, [
              _vm._v("Response time (milliseconds) of monitors:"),
            ]),
            _vm._v(" "),
            _c("StatusGraph", {
              staticClass: "sharedStatusGraph",
              attrs: {
                jobStatus: _vm.graphData,
                hoveredJob: _vm.hoveredJob,
                labels: true,
              },
            }),
            _vm._v(" "),
            _c("JobProblemDetails", {
              staticClass: "healthProblems",
              attrs: { jobsAlerting: _vm.jobsAlerting },
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "statusLines" },
              _vm._l(_vm.allJobStatus, function (job) {
                return _c("StatusLine", {
                  key: job.Name,
                  attrs: { jobStatus: job },
                  on: {
                    hoverin: _vm.onStatusLineHoverIn,
                    hoverout: _vm.onStatusLineHoverOut,
                  },
                })
              }),
              1
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }