var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "productImageOptimizerRoot" }, [
    _c("div", { staticClass: "heading" }, [_vm._v("Select an Image Variant")]),
    _vm._v(" "),
    _vm.error
      ? _c("div", { staticClass: "error" }, [_vm._v(_vm._s(_vm.error))])
      : _vm.loading
      ? _c(
          "div",
          { staticClass: "loading" },
          [_c("ScaleLoader"), _vm._v(" Loading…")],
          1
        )
      : _vm.rows
      ? _c("div", [
          _c("div", { staticClass: "scaledImagesCollection" }, [
            _c("table", [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.rows, function (row, index) {
                  return _c("tr", { key: index }, [
                    _c("td", [_vm._v(_vm._s(row.Name))]),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        row.NoBorder
                          ? _c("ImageOptimizerImage", {
                              attrs: {
                                img: row.NoBorder,
                                selected: _vm.selectedImage === row.NoBorder,
                              },
                              on: { select: _vm.onImageSelect },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        row.Border
                          ? _c("ImageOptimizerImage", {
                              attrs: {
                                img: row.Border,
                                selected: _vm.selectedImage === row.Border,
                              },
                              on: { select: _vm.onImageSelect },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ])
                }),
                0
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "buttonBar" }, [
            _c(
              "button",
              {
                staticClass: "buttonBarButton green",
                on: {
                  click: function ($event) {
                    return _vm.AssignSelected()
                  },
                },
              },
              [_c("span", [_vm._v("Assign Selected")])]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                ref: "cancelButton",
                staticClass: "buttonBarButton",
                on: {
                  click: function ($event) {
                    return _vm.DefaultClose()
                  },
                },
              },
              [_c("span", [_vm._v("Cancel Image Assignment")])]
            ),
          ]),
        ])
      : _c("div", [_vm._v("\n\t\tNo data.\n\t")]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Name")]),
        _vm._v(" "),
        _c("th", [_vm._v("No Border")]),
        _vm._v(" "),
        _c("th", [_vm._v("Yes Border")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }