var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    { staticClass: "adminNav", attrs: { id: "topNav" } },
    [
      _c(
        "router-link",
        { staticClass: "systemName", attrs: { to: { name: "adminHome" } } },
        [_vm._v(_vm._s(_vm.systemName) + " Admin")]
      ),
      _vm._v(" "),
      _c("router-link", { attrs: { to: { name: "adminSettings" } } }, [
        _vm._v("Settings"),
      ]),
      _vm._v(" "),
      _c("router-link", { attrs: { to: { name: "adminMonitors" } } }, [
        _vm._v("Monitors"),
      ]),
      _vm._v(" "),
      _c("router-link", { attrs: { to: { name: "adminUsers" } } }, [
        _vm._v("Users"),
      ]),
      _vm._v(" "),
      _c("router-link", { attrs: { to: { name: "adminAddAgent" } } }, [
        _vm._v("Add Agent"),
      ]),
      _vm._v(" "),
      _c("router-link", { attrs: { to: { name: "adminGraphite" } } }, [
        _vm._v("Graphite"),
      ]),
      _vm._v(" "),
      _c(
        "router-link",
        {
          staticClass: "linkToClient",
          attrs: { to: { name: "clientStatus" } },
        },
        [_vm._v("Client")]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "logoutButton",
          attrs: { role: "button" },
          on: { click: _vm.logoutClicked },
        },
        [_vm._v("Log Out")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }