<template>
	<div :class="{ pageRoot: true }">
		<div class="FloatRight" v-if="productSets">
			<router-link :to="{ name: 'fileEditor', params: { name: 'TitleUpdates' }}" class="gradientButton green">Title Updates</router-link>
			<button class="refreshButton" @click="loadData">
				<vsvg sprite="refresh" class="refreshIcon" />
			</button>
		</div>
		<h2>STAT!Ref Content Management System</h2>
		<div v-if="error" class="error">{{error}}</div>
		<div v-else-if="loading" class="loading"><ScaleLoader /> Loading…</div>
		<template v-else-if="productSets">
			<div class="filterContainer">
				<input type="text" v-model="filterText" placeholder="Filter by…" class="filterInput" /> <input type="button" v-if="filterText" value="Clear Filter" @click="filterText = ''" />
			</div>
			<CmsProductList title="Live but still deploying online" setName="liveDeployingOnline" :productRoots="productSets.liveDeployingOnline" :filterText="filterTextThrottled" :lsMap="lsMap" />
			<CmsProductList title="Starred by You" setName="starredByYou" :productRoots="ProductRootsStarredByYou" :showStarredRevs="true" :filterText="filterTextThrottled" :lsMap="lsMap" />
			<CmsProductList title="Staging" setName="staging" :productRoots="productSets.staging" :filterText="filterTextThrottled" :lsMap="lsMap" />
			<CmsProductList title="Upcoming" setName="upcoming" :productRoots="productSets.upcoming" :filterText="filterTextThrottled" :lsMap="lsMap" />
			<CmsProductList title="Titles at Rest" setName="atRest" :productRoots="productSets.atRest" :filterText="filterTextThrottled" :lsMap="lsMap" />
			<CmsProductList title="All Revisions Inactive" setName="allRevisionsInactive" :productRoots="productSets.allRevisionsInactive" :filterText="filterTextThrottled" :lsMap="lsMap" />
			<CmsProductList title="Titles Marked as Offline" setName="offline" :productRoots="productSets.offline" :filterText="filterTextThrottled" :lsMap="lsMap" />
			<CmsProductList title="Titles in Trash" setName="trash" :productRoots="productSets.trash" :filterText="filterTextThrottled" :lsMap="lsMap" />
			<div class="buttonBar" v-if="hasAnyProducts">
				<button class="buttonBarButton" @click="addNewProduct()" title="Asks you for a product name and creates a new Root Product with it.">
					<vsvg sprite="add" />
					<span>New Root Product</span>
				</button>
			</div>
			<div class="buttonBar">
				<div class="buttonBarHeading">Advanced CMS Controls</div>
				<button v-if="!hasAnyProducts" class="buttonBarButton" @click="doCMSFirstTimeSetup()" title="Begins the first-time setup procedure if and only if there are no products in the CMS database.">
					<vsvg sprite="upload" />
					<span>First-Time Setup</span>
				</button>
				<template v-else>
					<router-link class="buttonBarButton" :to="{ name: 'clientCMSDataSync' }">
						<span>Taylor DB Data Sync</span>
					</router-link>
					<button class="buttonBarButton" @click="validateAllProductInformation()" title="Runs the validation functions on all Product Roots and Product Revisions. Helpful when the validation logic has just been updated.">
						<vsvg sprite="filter_alt" />
						<span>Validate all Product Information</span>
					</button>
					<button class="buttonBarButton" @click="doCMSDbBackup()" title="The databases are backed up automatically, but you can create an additional backup by clicking this button.">
						<vsvg sprite="cloud_upload" />
						<span>Backup Databases Now</span>
					</button>
				</template>
			</div>
		</template>
		<div v-else>
			CMS data loading failed for an unknown reason.
		</div>
	</div>
</template>

<script>
	import { GetCMSProductRoots, DoCMSFirstTimeSetup, DoCMSDbBackup, CMSSyncNow, ValidateAllCMSProductInformation, CMSCreateNewProductRoot } from 'appRoot/api/CMSUserData';
	import CmsProductList from 'appRoot/vues/client/controls/CmsProductList.vue';
	import svg1 from 'appRoot/images/sprite/update.svg';
	import svg2 from 'appRoot/images/sprite/refresh.svg';
	import svg3 from 'appRoot/images/sprite/filter_alt.svg';
	import svg4 from 'appRoot/images/sprite/add.svg';
	import svg5 from 'appRoot/images/sprite/upload.svg';
	import svg6 from 'appRoot/images/sprite/cloud_upload.svg';
	import EventBus from 'appRoot/scripts/EventBus';
	import { throttle, ArrayToMap } from 'appRoot/scripts/Util';
	import { ModalMessageDialog, ProgressDialog, TextInputDialog, ModalConfirmDialog, CmsValidationResultsDialog } from 'appRoot/scripts/ModalDialog';

	export default {
		components: { CmsProductList },
		props:
		{
		},
		data()
		{
			return {
				error: null,
				loading: false,
				productSets: null,
				lsMap: {},
				filterText: "",
				filterTextThrottled: "",
				setFilterTextThrottled: null,
				myStarredIds: {}
			};
		},
		created()
		{
			this.setFilterTextThrottled = throttle(() => { this.filterTextThrottled = this.filterText; }, 250);
			this.loadData();
			EventBus.LoadProductImages();
		},
		computed:
		{
			hasAnyProducts()
			{
				for (let key in this.productSets)
				{
					if (this.productSets.hasOwnProperty(key))
						if (this.productSets[key] && this.productSets[key].length)
							return true;
				}
				return false;
			},
			ProductRootsStarredByYou()
			{
				let arr = [];
				for (let key in this.productSets)
				{
					if (this.productSets.hasOwnProperty(key))
					{
						if (this.productSets[key] && this.productSets[key].length)
						{
							let set = this.productSets[key];
							for (let i = 0; i < set.length; i++)
							{
								if (EventBus.myStarredProductRootIds[set[i].ProductRootId])
									arr.push(set[i]);
							}
						}
					}
				}
				return arr;
			}
		},
		methods:
		{
			loadData()
			{
				this.loading = true;
				this.error = null;
				GetCMSProductRoots()
					.then(data =>
					{
						if (data.success)
						{
							let productRootMap = {};
							this.preprocessSet(productRootMap, data.staging);
							this.preprocessSet(productRootMap, data.upcoming);
							this.preprocessSet(productRootMap, data.liveDeployingOnline);
							this.preprocessSet(productRootMap, data.allRevisionsInactive);
							this.preprocessSet(productRootMap, data.atRest);
							this.preprocessSet(productRootMap, data.offline);
							this.preprocessSet(productRootMap, data.trash);
							this.productSets = {
								staging: data.staging,
								upcoming: data.upcoming,
								liveDeployingOnline: data.liveDeployingOnline,
								allRevisionsInactive: data.allRevisionsInactive,
								atRest: data.atRest,
								offline: data.offline,
								trash: data.trash
							};
							this.lsMap = data.productRootDisplayLifecycleStates;
							EventBus.myStarredProductRootIds = ArrayToMap(data.myStarredProductRootIds, item => item, item => true);
							EventBus.myStarredProductRevisions = ArrayToMap(data.myStarredProductRevisions, item => item.ProductRevisionId, item => item);
							EventBus.productRootMap = productRootMap;
						}
						else
							this.error = data.error;
					})
					.catch(err =>
					{
						this.error = err;
					})
					.finally(() =>
					{
						this.loading = false;
					});
			},
			preprocessSet(productRootMap, set)
			{
				for (let i = 0; i < set.length; i++)
				{
					set[i].ProductRevisionsState = 0;
					set[i].ProductRevisionsList = null;
					productRootMap[set[i].ProductRootId] = set[i];
				}
			},
			doCMSFirstTimeSetup()
			{
				this.loading = true;
				this.error = null;
				DoCMSFirstTimeSetup()
					.then(data =>
					{
						if (data.success)
							this.loadData();
						else
						{
							this.error = data.error;
							this.loading = false;
						}
					})
					.catch(err =>
					{
						this.error = err;
						this.loading = false;
					})
					.finally(() =>
					{
					});
			},
			async doCMSDbBackup()
			{
				let result = await ModalConfirmDialog("You are about to create a backup of the all TDS App Service databases outside of the normal schedule. Proceed?", "Confirm", "Create Backup", "Cancel");
				if (result)
				{
					let progressDialog = ProgressDialog("Backing up…");
					try
					{
						let data = await DoCMSDbBackup();
						if (data.success)
							toaster.success("Backup Completed");
						else
							toaster.error("Backup failed: " + data.error);
					}
					catch (err)
					{
						toaster.error(err);
					}
					finally
					{
						progressDialog.close();
					}
				}
			},
			async cmsSyncNow()
			{
				this.loading = true;
				this.error = null;
				try
				{
					let data = await CMSSyncNow();
					if (data.success)
						this.loadData();
					else
					{
						this.error = data.error;
						this.loading = false;
					}
				}
				catch (err)
				{
					this.error = err;
					this.loading = false;
				}
			},
			async validateAllProductInformation()
			{
				let progressDialog = ProgressDialog("Validating…");
				try
				{
					let data = await ValidateAllCMSProductInformation();
					if (data.success)
					{
						CmsValidationResultsDialog(data).then(result => { });
					}
					else
					{
						ModalMessageDialog(data.error, "Validation Error", { focusMessage: true });
					}
				}
				catch (err)
				{
					ModalMessageDialog(err, "Validation Exception");
				}
				finally
				{
					progressDialog.close();
				}
			},
			async addNewProduct(nameStr)
			{
				if (!nameStr)
					nameStr = "";
				let result = await TextInputDialog("New Root Product", "Enter a name for the new root product (HTML allowed):", "Product Name", nameStr);
				if (result && result.value)
				{
					let progressDialog = ProgressDialog("Creating new Root Product…");
					try
					{
						let data = await CMSCreateNewProductRoot(result.value);
						if (data.success)
						{
							this.$router.push({
								name: "clientCMSProductRoot",
								params: { id: data.ProductRootId }
							});
						}
						else
						{
							await ModalMessageDialog(data.error, "Error");
							await this.addNewProduct(result.value);
						}
					}
					catch (err)
					{
						await ModalMessageDialog(err, "Error");
						await this.addNewProduct(result.value);
					}
					finally
					{
						if (progressDialog)
							progressDialog.close();
					}
				}
			}
		},
		watch:
		{
			filterText()
			{
				this.setFilterTextThrottled();
			}
		}
	}
</script>

<style scoped>
	.pageRoot
	{
		padding-bottom: 8px;
		box-sizing: border-box;
		overflow-x: hidden;
	}

		.pageRoot > *
		{
			padding-left: 8px;
			padding-right: 8px;
		}

			.pageRoot > *:first-child
			{
				margin-top: 8px;
			}

			.pageRoot > *:last-child
			{
				margin-bottom: 8px;
			}

	h2
	{
		margin-top: 0.2em;
	}

	.loading
	{
		margin-top: 80px;
		text-align: center;
	}

	.error
	{
		color: #FF0000;
		font-weight: bold;
	}

	.FloatRight
	{
		float: right;
	}

	.refreshButton
	{
		width: 44px;
		height: 44px;
		padding: 0px;
	}

	.refreshIcon
	{
		width: 32px;
		height: 32px;
		vertical-align: middle;
	}

	.filterContainer input
	{
		font-size: 20px;
	}

	.buttonBar
	{
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	.buttonBarHeading
	{
		font-size: 1.1em;
		font-weight: bold;
		margin-top: 1em;
	}
</style>