var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "LifecycleStateContainer",
      attrs: {
        role: "button",
        tabindex: "0",
        title: "Current lifecycle state: " + _vm.productRevision.LifecycleState,
      },
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.showPopout()
        },
        keypress: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter") &&
            _vm._k($event.keyCode, "space", 32, $event.key, [" ", "Spacebar"])
          )
            return null
          $event.preventDefault()
          return _vm.showPopout()
        },
      },
    },
    _vm._l(["Upcoming", "Staging", "Live", "Inactive"], function (state) {
      return _c(
        "div",
        { key: "badge_" + state, staticClass: "BadgeContainer" },
        [
          _c("CmsLifecycleStateBadge", {
            attrs: {
              text: state,
              disabled: state !== _vm.productRevision.LifecycleState,
            },
          }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }