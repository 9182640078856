var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: {
        modalDialogRoot: true,
        positionAbsolute: _vm.usePositionAbsolute,
      },
      style: _vm.rootStyle,
      attrs: { "aria-hidden": "false", "aria-modal": "true", tabindex: "0" },
      on: {
        keyup: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          $event.preventDefault()
          return _vm.defaultClose.apply(null, arguments)
        },
      },
    },
    [
      _c("div", {
        staticClass: "modalDialogOverlay",
        on: { mousedown: _vm.overlayMouseDown, click: _vm.overlayClick },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modalDialogPositioner",
          style: _vm.dynPosStyle,
          on: { mousedown: _vm.overlayMouseDown, click: _vm.overlayClick },
        },
        [
          _c("div", {
            staticClass: "FocusCatcher",
            attrs: { tabindex: "0" },
            on: {
              focus: function ($event) {
                return _vm.FocusCatcher(false)
              },
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "modalDialogContent",
              style: _vm.dynContentStyle,
              on: { mousedown: _vm.contentMouseDown, click: _vm.contentClick },
            },
            [
              _c(
                _vm.contentComponent,
                _vm._b(
                  {
                    ref: "contentComponent",
                    tag: "component",
                    on: { close: _vm.closeRequested },
                  },
                  "component",
                  _vm.contentProps,
                  false
                )
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", {
            staticClass: "FocusCatcher",
            attrs: { tabindex: "0" },
            on: {
              focus: function ($event) {
                return _vm.FocusCatcher(true)
              },
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }