var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: { pageRoot: true } },
    [
      _vm.error
        ? _c("div", { staticClass: "error" }, [_vm._v(_vm._s(_vm.error))])
        : !this.myAttributes
        ? _c("div", { staticClass: "error" }, [_vm._v("Invalid URL")])
        : _vm.loading
        ? _c(
            "div",
            { staticClass: "loading" },
            [_c("ScaleLoader"), _vm._v(" Loading…")],
            1
          )
        : [
            _c("div", { attrs: { id: "editorMenuBar" } }, [
              _c("span", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.myAttributes.displayName) + " Editor"),
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "openBackupListBtn",
                  on: { click: _vm.OpenBackupList },
                },
                [_vm._v("Load Backup")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "SaveButton",
                  class: { disabled: !_vm.hasChanges },
                  on: { click: _vm.SaveChanges },
                },
                [_vm._v("Save")]
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "editorSection" },
              [
                _c("AceReactiveEditor", {
                  style: _vm.editorHeightStyle,
                  attrs: {
                    name: "genericFileEditor",
                    mode: _vm.myAttributes.aceMode,
                    userSizable: false,
                  },
                  model: {
                    value: _vm.fileContent,
                    callback: function ($$v) {
                      _vm.fileContent = $$v
                    },
                    expression: "fileContent",
                  },
                }),
                _vm._v(" "),
                _vm.showHtmlPreviewPane
                  ? _c("div", {
                      staticClass: "htmlPreview",
                      style: _vm.editorHeightStyle,
                      domProps: { innerHTML: _vm._s(_vm.fileContent) },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }