<template>
	<div :class="{ pageRoot: true }">
		<div v-if="error" class="error">{{error}}</div>
		<div v-else-if="loading" class="loading"><ScaleLoader /> Loading…</div>
		<div v-else-if="graphiteData">
			<h2>General Status</h2>
			<div class="graphContainer">
				<img v-for="g in graphiteData.SingleServerQueryStrings" :src="getImgSrc(g)" alt="Graph" class="graph" />
			</div>
			<h2>Individual Server Status</h2>
			<div class="graphColumnContainer">
				<div v-for="sn in graphiteData.ServerNames" class="graphColumn">
					<div class="serverName">{{sn}}</div>
					<img v-for="g in graphiteData.MultiServerQueryStrings" :src="getImgSrc(g, sn)" alt="Graph" class="graph" />
				</div>
			</div>
			<h2>Database Status</h2>
			<div class="graphContainer">
				<img v-for="g in graphiteData.DatabaseQueryStrings" :src="getImgSrc(g)" alt="Graph" class="graph" />
			</div>
		</div>
		<div v-else>
			Graphite data loading failed for an unknown reason.
		</div>
	</div>
</template>

<script>
	import { GetGraphiteUserData } from 'appRoot/api/GraphiteUserData';

	export default {
		components: {},
		props:
		{
		},
		data()
		{
			return {
				error: null,
				loading: false,
				graphiteData: null
			};
		},
		created()
		{
			this.loadData()
			{
			}
		},
		computed:
		{
		},
		methods:
		{
			loadData()
			{
				this.loading = true;
				this.error = null;
				GetGraphiteUserData()
					.then(data =>
					{
						if (data.success)
							this.graphiteData = data.data;
						else
							this.error = data.error;
					})
					.catch(err =>
					{
						this.error = err;
					})
					.finally(() =>
					{
						this.loading = false;
					});
			},
			getImgSrc(graph, serverName)
			{
				let queryString = graph;
				if (serverName)
					queryString = queryString.replace(/%%ServerName%%/gi, serverName);
				return appContext.appPath + "GraphProxy?" + queryString;
			}
		},
		watch:
		{
		}
	}
</script>

<style scoped>
	.pageRoot
	{
		margin: 8px;
	}

	.loading
	{
		margin-top: 80px;
		text-align: center;
	}

	.error
	{
		color: #FF0000;
		font-weight: bold;
	}

	.graphContainer
	{
		max-width: 1100px;
	}

		.graphContainer .graph
		{
			margin: 10px;
		}

	.graphColumnContainer
	{
		max-width: 1100px;
		display: flex;
		flex-wrap: wrap;
	}

	.graphColumn
	{
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.serverName
	{
		font-weight: bold;
		font-size: 1.2em;
		margin-bottom: 1em;
	}

	.graph
	{
		width: 500px;
		height: 250px;
	}
</style>