<template>
	<div class="pageRoot">
		<div v-if="(error)" class="error">{{error}}</div>
		<div v-else-if="loading" class="loading"><ScaleLoader /> Loading…</div>
		<div v-else-if="users" class="">
			<vue-good-table :columns="columns" :rows="users" @on-row-click="rowClick" :sort-options="{ enabled: true, initialSortBy: {field: 'Name', type: 'asc'} }" />
			<div class="buttonRow">
				<input type="button" value="Add User" @click="addUser" />
			</div>
			<div class="heading">Note About Permission Changes</div>
			<div class="bodySection">
				<p>After granting Read access to privileged tab, the user must log out and back in before the tab becomes visible.</p>
				<p><img :src="appPath + 'images/PrivilegedTabs.png'" alt="screenshot" class="borderImage" /></p>
			</div>
			<div class="heading">Active Sessions</div>
			<div class="bodySection">
				<ActiveSessionList :allSessions="true" />
			</div>
			<div class="heading">Login History</div>
			<div class="bodySection">
				<LoginRecordsTable :allUsers="true" />
			</div>
			<div class="heading">User Activity Logs</div>
			<div class="bodySection">
				<UserActivityTable :allUsers="true" />
			</div>
			<div class="heading">Login Failures</div>
			<div class="bodySection">
				<p>Login failures are counted as an aggregate per hour, per IP address.</p>
				<FailedLoginsTable />
			</div>
		</div>
	</div>
</template>

<script>
	import { GetUserData } from 'appRoot/api/UserData';
	import { ModalDialog } from 'appRoot/scripts/ModalDialog';
	import EditUser from 'appRoot/vues/admin/controls/EditUser.vue';
	import LoginRecordsTable from 'appRoot/vues/common/loginrecords/LoginRecordsTable.vue';
	import UserActivityTable from 'appRoot/vues/common/useractivity/UserActivityTable.vue';
	import FailedLoginsTable from 'appRoot/vues/common/failedlogins/FailedLoginsTable.vue';
	import ActiveSessionList from 'appRoot/vues/common/controls/ActiveSessionList.vue';
	import { CreateEditorObject } from 'appRoot/scripts/Util';

	export default {
		components: { LoginRecordsTable, ActiveSessionList, UserActivityTable, FailedLoginsTable },
		data()
		{
			return {
				appPath: appContext.appPath,
				error: null,
				loading: false,
				columns: [
					{ label: "ID", field: "UserId" },
					{ label: "Name", field: "Name" },
					{ label: "Email", field: "Email" },
					//{ label: "Is Admin", field: "IsAdmin", type: "boolean" },
					//{ label: "Release Tab Full Write Access", field: "ReleaseWriteAccess", type: "boolean" },
					//{ label: "App Pool Recycle", field: "AllowAppPoolRecycle", type: "boolean" },
					{ label: "Permissions", field: "PermissionsHtml", html: true, tdClass: "PermissionsHtml" }
				],
				users: null,
				editSpec: null
			};
		},
		created()
		{
			this.loadUsers();
		},
		computed:
		{
		},
		methods:
		{
			loadUsers()
			{
				this.loading = true;
				this.users = null;
				this.editSpec = null;
				GetUserData()
					.then(data =>
					{
						if (data.success)
						{
							this.users = data.users;
							this.editSpec = data.editSpec;
						}
						else
							this.error = data.error;
					})
					.catch(err =>
					{
						this.error = err.message;
					})
					.finally(() =>
					{
						this.loading = false;
					});
			},
			rowClick(params)
			{
				if (this.users)
					for (let i = 0; i < this.users.length; i++)
						if (this.users[i].Name === params.row.Name)
						{
							ModalDialog(EditUser, { user: this.users[i], editSpec: this.editSpec, isAddingUser: false })
								.then(result =>
								{
									if (result)
										this.loadUsers();
								});
						}
			},
			addUser()
			{
				ModalDialog(EditUser, { user: CreateEditorObject(this.editSpec), editSpec: this.editSpec, isAddingUser: true })
					.then(result =>
					{
						if (result)
							this.loadUsers();
					});
			}
		}
	}
</script>

<style scoped>
	.pageRoot
	{
		margin: 8px;
	}

	.loading
	{
		margin-top: 80px;
		text-align: center;
	}

	.error
	{
		color: #FF0000;
		font-weight: bold;
	}

	.buttonRow
	{
		margin-top: 30px;
		border-top: 1px solid #AAAAAA;
		padding-top: 10px;
	}

	.heading
	{
		font-size: 20px;
		border-bottom: 1px solid #000000;
		margin-bottom: 10px;
		margin-top: 30px;
		max-width: 400px;
	}

		.heading:first-child
		{
			margin-top: 0px;
		}

	.bodySection
	{
		margin: 0px 20px;
	}

	.pageRoot::v-deep .PermissionsHtml .Permission
	{
		background-color: #daf1ff;
		border: 1px solid #95cef1;
		border-radius: 4px;
		padding: 2px;
		line-height: 28px;
		font-size: 16px;
	}

	.borderImage
	{
		border: 4px solid #000000;
		box-shadow: 2px 2px 3px 1px rgba(0,0,0,0.4);
	}
</style>