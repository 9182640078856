var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "CmsConsoleDeployment" }, [
    _vm.contentSyncStatus
      ? _c("table", { staticClass: "deployTbl" }, [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "tbody",
            _vm._l(_vm.contentSyncStatus.agents, function (agent) {
              return agent.CMSRole !== "None"
                ? _c(
                    "tr",
                    {
                      key: "contentSyncStatus_" + agent.name,
                      class: {
                        contentSyncAgentRow: true,
                        connected: agent.connected,
                        disconnected: !agent.connected,
                      },
                    },
                    [
                      _c(
                        "td",
                        [
                          _c(
                            "router-link",
                            {
                              style: { color: agent.color },
                              attrs: { to: _vm.getAgentStatusRoute(agent) },
                            },
                            [_vm._v(_vm._s(agent.name))]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _c("CustomProgress", {
                            staticClass: "CustomProgress",
                            attrs: {
                              value: agent.fileSync
                                ? agent.fileSync.Progress
                                : 0,
                              color: agent.color,
                              borderColor: agent.fileSync
                                ? agent.color
                                : "transparent",
                              title: _vm.getAgentUploadTooltip(agent),
                              text: _vm.getAgentUploadText(agent),
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _c("CustomProgress", {
                            staticClass: "CustomProgress",
                            attrs: {
                              value: agent.installationProgressPercent
                                ? agent.installationProgressPercent
                                : 0,
                              color: agent.color,
                              borderColor: agent.installStatus
                                ? agent.color
                                : "transparent",
                              title: _vm.getAgentInstallationTooltip(agent),
                              text: agent.installAcronym,
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(agent.syncAcronym))]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          _vm._s(agent.connected ? agent.queueSizes : "D/C")
                        ),
                      ]),
                    ]
                  )
                : _vm._e()
            }),
            0
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Agent")]),
        _vm._v(" "),
        _c("th", { attrs: { title: "This title is being uploaded" } }, [
          _vm._v("Upload"),
        ]),
        _vm._v(" "),
        _c("th", { attrs: { title: "This title is being installed" } }, [
          _vm._v("Install"),
        ]),
        _vm._v(" "),
        _c(
          "th",
          { attrs: { title: "This title's DB metadata is being synced" } },
          [_vm._v("Sync")]
        ),
        _vm._v(" "),
        _c("th", { attrs: { title: "Queue Size (Upload / Install)" } }, [
          _vm._v("Q"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }