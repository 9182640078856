<template>
	<div class="ProductRoot" :class="dynClasses">
		<div class="ProductRootRow">
			<router-link :to="GetRouteToToggleProductRoot()" class="TreeExpandClickable" replace title="toggle tree expansion">
				<vsvg sprite="expand_more" class="expand_more" :class="{ expanded: !!ProductRevisionsState }" />
			</router-link>
			<img :src="starImgProductRoot" alt="star" class="product-star" role="button" tabindex="0" @click="toggleStarProductRoot(proot)" @keypress.enter.space.prevent="toggleStarProductRoot(proot)" />
			<router-link :to="GetRouteToProductRoot(proot)" class="ProductRootClickable">
				<img :src="GetProductImgSrc(proot)" class="ProductImage" />
				<span class="ProductRootTitle"><span v-html="proot.InternalNameHtml"></span> [{{proot.FileName}}, {{proot.TaylorProductId}}]</span>
				<CmsLifecycleStateBadge v-if="lsText" :text="lsText" :compact="true" title="This badge seemed appropriate to show, given the revisions currently available for this product." />
			</router-link>
		</div>
		<div class="ProductRevisions" v-if="ProductRevisionsState === 1">
			<ScaleLoader height="1em" class="LoaderAlignLeft" />
		</div>
		<div class="ProductRevisions" v-if="ProductRevisionsState === 2">
			<div v-if="ProductRevisionsList.length === 0" class="ProductRevision">
				<div class="TreeGraphic">
					<div class="TreeTop"></div>
				</div>
				<div class="NotClickableListItem NoRevisions">
					[No Revisions Yet]
				</div>
			</div>
			<template v-else>
				<div class="ProductRevision" v-for="(rev, index) in ProductRevisionsList" :key="rev.ProductRevisionId">
					<div class="TreeGraphic">
						<div class="TreeTop"></div>
						<div class="TreeBot" v-if="index < ProductRevisionsList.length - 1"></div>
					</div>
					<img :src="starImgProductRevisionMap[rev.ProductRevisionId]" alt="star" class="product-star" role="button" tabindex="0" @click="toggleStarProductRevision(rev)" @keypress.enter.space.prevent="toggleStarProductRevision(rev)" />
					<router-link :to="GetRouteToProductRevision(rev)" class="ProductRevisionClickable">
						<img :src="productImages[rev.ImageId]" class="ProductImage" />
						<span class="ProductRevisionTitle" v-html="rev.RevisionNameHtml"></span>
						<CmsLifecycleStateBadge :text="rev.LifecycleState" :compact="true" />
					</router-link>
				</div>
			</template>
		</div>
		<div class="ProductRevisions" v-if="ProductRevisionsState === 3">
			<div class="ProductRevision">
				<div class="TreeGraphic">
					<div class="TreeTop"></div>
				</div>
				<a class="ProductRevisionClickable FailedToLoad" role="button" tabindex="0" @click="LoadProductRevisions" @keypress.enter.space.prevent="LoadProductRevisions">
					<vsvg sprite="warning" class="warningIcon" /> Failed to load. Click to try again.
				</a>
			</div>
		</div>
	</div>
</template>

<script>
	import { GetCMSProductRevisions } from 'appRoot/api/CMSUserData';
	import { CopyRoute } from 'appRoot/scripts/Util';
	import EventBus from 'appRoot/scripts/EventBus';
	import svg1 from 'appRoot/images/sprite/expand_more.svg';
	import svg2 from 'appRoot/images/sprite/warning.svg';
	import CmsLifecycleStateBadge from 'appRoot/vues/client/controls/CmsLifecycleStateBadge.vue';
	import StarredRowMixin from 'appRoot/scripts/StarredRowMixin.js';

	export default {
		components: { CmsLifecycleStateBadge },
		mixins: [StarredRowMixin],
		props:
		{
			proot: {
				type: Object,
				required: true
			},
			lsText: { // Lifecycle State text to show in a badge for this ProductRoot.
				type: String,
				default: ""
			}
		},
		data()
		{
			return {
				appPath: appContext.appPath,
				ProductRevisionsState: 0,
				ProductRevisionsList: null
			};
		},
		created()
		{
		},
		mounted()
		{

		},
		computed:
		{
			routeQueryExpand()
			{
				return EventBus.routeQueryExpand;
			},
			productImageRootIdMap()
			{
				return EventBus.productImageData.rootIdMap;
			},
			productImages()
			{
				return EventBus.productImageData.imageMap;
			},
			dynClasses()
			{
				if (this.proot.Trash)
					return "trash";
				return "";
			},
			star_ProductRootId()
			{
				return this.proot.ProductRootId;
			},
			star_ProductRevisionIds()
			{
				let arr = [];
				if (this.ProductRevisionsList)
				{
					for (let i = 0; i < this.ProductRevisionsList.length; i++)
					{
						arr.push(this.ProductRevisionsList[i].ProductRevisionId);
					}
				}
				return arr;
			}
		},
		methods:
		{
			GetRouteToToggleProductRoot()
			{
				let route = CopyRoute(this.$route);
				let arr = JSON.parse(JSON.stringify(EventBus.expandedProductRootIdsArray));
				for (let i = 0; i < arr.length; i++)
				{
					if (arr[i] === this.proot.ProductRootId)
					{
						arr.splice(i, 1);
						if (arr.length)
							route.query.expand = arr.join(",");
						else
							delete route.query.expand;
						return route;
					}
				}
				arr.push(this.proot.ProductRootId);
				route.query.expand = arr.join(",");
				return route;
			},
			GetRouteToProductRoot()
			{
				return {
					name: "clientCMSProductRoot",
					params: { id: this.proot.ProductRootId }
				};
			},
			GetRouteToProductRevision(rev)
			{
				return {
					name: "clientCMSProductRevision",
					params: { id: rev.ProductRevisionId }
				};
			},
			ProcessTreeExpansion()
			{
				if (EventBus.expandedProductRootIdsMap[this.proot.ProductRootId])
				{
					// Product should be expanded
					if (!this.ProductRevisionsState)
					{
						this.LoadProductRevisions();
					}
				}
				else
				{
					// Product should be collapsed.
					if (this.ProductRevisionsState)
					{
						this.ProductRevisionsList = null;
						this.ProductRevisionsState = 0;
					}
				}
			},
			LoadProductRevisions()
			{
				this.ProductRevisionsState = 1;
				GetCMSProductRevisions(this.proot.ProductRootId)
					.then(data =>
					{
						if (data.success)
						{
							if (this.ProductRevisionsState === 1)
							{
								this.ProductRevisionsList = data.productRevisions;
								this.ProductRevisionsState = 2;
							}
						}
						else
						{
							toaster.error("Failed to load Product Revisions: " + data.error);
							this.ProductRevisionsState = 3;
						}
					})
					.catch(err =>
					{
						toaster.error(err);
						this.ProductRevisionsState = 3;
					});
			},
			GetProductImgSrc(proot)
			{
				let src = this.productImages[this.productImageRootIdMap[proot.ProductRootId]];
				if (!src)
					src = this.productImages[1];
				return src;
			}
		},
		watch:
		{
			routeQueryExpand:
			{
				handler()
				{
					this.ProcessTreeExpansion();
				},
				immediate: true // Run this handler immediately upon component creation; don't wait for a change.
			}
		}
	}
</script>

<style scoped>
	.ProductRoot
	{
	}

		.ProductRoot.trash
		{
			background-color: #FFEEEE;
		}

	.ProductRootRow
	{
		display: flex;
		align-items: stretch;
	}

	.TreeExpandClickable,
	.ProductRootClickable,
	.ProductRevisionClickable,
	.NotClickableListItem
	{
		display: inline-block;
		padding: 3px 0px;
		cursor: pointer;
		color: #000000;
		text-decoration: none;
		font-size: 16px;
	}

		.TreeExpandClickable:hover,
		.TreeExpandClickable:focus,
		.ProductRootClickable:hover,
		.ProductRootClickable:focus,
		.ProductRevisionClickable:hover,
		.ProductRevisionClickable:focus
		{
			background-color: rgba(0,0,0,0.1);
		}

	.ProductRootClickable,
	.ProductRevisionClickable
	{
		flex: 1 1 auto;
	}

	.NotClickableListItem
	{
		cursor: default;
	}

	.TreeExpandClickable
	{
		padding-right: 3px;
	}

	.ProductImage
	{
		max-width: 22px;
		height: 22px;
		vertical-align: bottom;
	}

	.ProductRootTitle
	{
		vertical-align: bottom;
		line-height: 20px;
	}

	.ProductRevisions
	{
		word-break: break-word;
		padding: 4px 0px;
	}

		.ProductRevisions .LoaderAlignLeft
		{
			text-align: left;
			margin-left: 20px;
		}

	.ProductRevision
	{
		display: flex;
	}

	.expand_more
	{
		width: 18px;
		height: 18px;
		vertical-align: middle;
		transform: rotate(270deg);
	}

		.expand_more.expanded
		{
			transform: rotate(0deg);
		}

	.TreeGraphic
	{
		margin: 0px 5px 0px 29px;
	}

	.TreeTop
	{
		border-left: 2px solid #000000;
		border-bottom: 2px solid #000000;
		width: 8px;
		height: 12px;
	}

	.TreeBot
	{
		border-left: 2px solid #000000;
		width: 8px;
		height: 100%;
	}

	.NoRevisions
	{
		opacity: 0.48;
	}

	.FailedToLoad
	{
		color: #FF0000;
		font-weight: bold;
	}

	.warningIcon
	{
		width: 22px;
		height: 22px;
		fill: #FF0000;
		/*margin-right: 8px;*/
		vertical-align: bottom;
	}
</style>