var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(_vm.tag, {
    ref: "element",
    tag: "component",
    attrs: { contenteditable: _vm.contenteditable },
    on: {
      input: _vm.update,
      blur: _vm.update,
      paste: _vm.onPaste,
      keypress: _vm.onKeypress,
      keydown: _vm.fwdEv,
      keyup: _vm.fwdEv,
      mouseenter: _vm.fwdEv,
      mouseover: _vm.fwdEv,
      mousemove: _vm.fwdEv,
      mousedown: _vm.fwdEv,
      mouseup: _vm.fwdEv,
      auxclick: _vm.fwdEv,
      click: _vm.fwdEv,
      dblclick: _vm.fwdEv,
      contextmenu: _vm.fwdEv,
      wheel: _vm.fwdEv,
      mouseleave: _vm.fwdEv,
      mouseout: _vm.fwdEv,
      select: _vm.fwdEv,
      pointerlockchange: _vm.fwdEv,
      pointerlockerror: _vm.fwdEv,
      dragstart: _vm.fwdEv,
      drag: _vm.fwdEv,
      dragend: _vm.fwdEv,
      dragenter: _vm.fwdEv,
      dragover: _vm.fwdEv,
      dragleave: _vm.fwdEv,
      drop: _vm.fwdEv,
      transitionstart: _vm.fwdEv,
      transitioncancel: _vm.fwdEv,
      transitionend: _vm.fwdEv,
      transitionrun: _vm.fwdEv,
      compositionstart: _vm.fwdEv,
      compositionupdate: _vm.fwdEv,
      compositionend: _vm.fwdEv,
      cut: _vm.fwdEv,
      copy: _vm.fwdEv,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }